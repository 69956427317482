import { Button } from "@mui/material";
import {
    AdminContentContainer,
    AdminPageTitle,
    AdminSingleBlockLayout,
} from "components/Layouts";
import { ReservationDetail } from "components/ReservationDetail";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { IoChevronBack } from "react-icons/io5";

const ReservationDetailPage = () => {
    const [redirectToChat, setRedirectToChat] = useState<boolean>(false);
    const goToChat = () => {
        //trigger go to chat
        setRedirectToChat(true);
    };
    return (
        <>
            <Helmet>
                <title>Joes & Cos | Reservation Details</title>
            </Helmet>
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <div className="max-w-[720px] mx-auto">
                        <AdminPageTitle
                            pageTitle="Reservation Details"
                            icon={<IoChevronBack />}
                            className="flex flex-col sm:flex-row sm:justify-between sm:items-end"
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                className="mt-4 sm:mt-0"
                                sx={{ minWidth: "120px !important" }}
                                onClick={goToChat}
                            >
                                Go To Chat
                            </Button>
                        </AdminPageTitle>
                        <ReservationDetail redirectToChat={redirectToChat} />
                    </div>
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};

export default ReservationDetailPage;
