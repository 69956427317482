import { Alert, AlertColor, Snackbar, SnackbarOrigin } from "@mui/material";
import React from "react";
import MESSAGES from "shared/constants/messages.constant";

export interface JCSnackbarProps extends SnackbarOrigin {
    open: boolean;
    description: string;
    severity: AlertColor;
    autoHideDuration?: number;
    onClose: () => void;
}

export const JCSnackbar: React.FC<JCSnackbarProps> = (props) => {
    const { onClose } = props;

    return (
        <Snackbar
            open={props.open}
            autoHideDuration={props.autoHideDuration ?? 10000}
            onClose={onClose}
            anchorOrigin={{
                vertical: props.vertical,
                horizontal: props.horizontal,
            }}
            {...props}
        >
            <Alert
                onClose={props.onClose}
                severity={props.severity}
                className="w-full"
            >
                {props.description ?? MESSAGES.BASIC_ERROR}
            </Alert>
        </Snackbar>
    );
};
