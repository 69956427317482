import {
    Avatar,
    Box,
    Divider,
    ListItem,
    ListItemText,
    Skeleton,
} from "@mui/material";
export const InboxItemSkeleton = () => {
    return (
        <>
            <ListItem
                alignItems="flex-start"
                className="px-4 xl:px-10 cursor-pointer space-x-2"
                component={"div"}
            >
                <Skeleton variant="circular">
                    <Avatar className="w-12 h-12" />
                </Skeleton>
                <ListItemText>
                    <div className="flex flex-wrap items-center justify-between">
                        <Skeleton variant="text" width="70%" />
                        <Skeleton variant="text" width="20%" />
                    </div>
                    <Skeleton variant="text" width="100%" />
                </ListItemText>
            </ListItem>
            <Divider component="div" />
        </>
    );
};
const InboxesSkeleton = () => {
    return (
        <>
            <Box
                className="relative z-10 bg-white"
                sx={{
                    maxWidth: { xs: "100%", lg: 460 },
                    width: { xs: "100%", lg: "25%" },
                }}
            >
                <div className="px-4 pt-6 xl:px-10">
                    <Skeleton variant="text" width="50%"></Skeleton>
                </div>
                <InboxItemSkeleton />
                <InboxItemSkeleton />
                <InboxItemSkeleton />
                <InboxItemSkeleton />
                <InboxItemSkeleton />
            </Box>
        </>
    );
};

export default InboxesSkeleton;
