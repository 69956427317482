import { Button, Divider, Typography } from "@mui/material";
import { AdminPageTitle } from "components/Layouts";
import { EmptyList, JCSnackbar, ModalConfirmation } from "components/UI";
import ListingItem from "components/UI/Vendors/Listing/ListingItem";
import ListingItemSkeleton from "components/UI/Vendors/Listing/ListingItemSkeleton";
import { limitListings } from "config/setting";
import { useAuthContext } from "context/AuthContext";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { PaginationModel } from "models";
import { MeetingRoom } from "models/meeting-room.model";
import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import { listingService } from "services/listing.service";
import MESSAGES from "shared/constants/messages.constant";
import ROUTES from "shared/constants/routes.constant";

const MeetingRooms = () => {
    const auth = useAuthContext();
    const [searchParams] = useSearchParams();
    const refetchList = searchParams.get("refetchList");
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [deleteResult, setDeleteResult] = useState({
        result: false,
        description: "Error",
    });
    const fullPageLoaderContext = useFullPageLoadingContext();
    const [isLoading, setIsLoading] = useState(false);
    const queryKey = `vendor-${auth.authUser.userId}-listing`;
    const queryClient = useQueryClient();

    useEffect(() => {
        if (refetchList) {
            queryClient.removeQueries(queryKey);
            refetch();
        }
    }, []);

    const handleDeleteBtn = async () => {
        setIsLoading(true);
        const result = await listingService.removeMeetingRoom(
            items.pages[currentPageIndex].lists[currentDataIndex].id,
        );
        await refetch();
        if (isFetched) setIsLoading(false);
        if (isSuccess || isError) {
            setDeleteResult({
                result: Boolean(result),
                description: result
                    ? MESSAGES.LISTING_DELETE_SUCCESSFULLY
                    : MESSAGES.LISTING_DELETE_UNSUCCESSFULLY,
            });
            setOpenSnackbar(true);
        }
    };

    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [currentDataIndex, setCurrentDataIndex] = useState(0);
    const setCurrentIndex = (pageIndex: number, dataIndex: number) => {
        setCurrentPageIndex(pageIndex);
        setCurrentDataIndex(dataIndex);
    };

    const {
        data: items,
        isFetching,
        fetchNextPage,
        refetch,
        isFetched,
        isSuccess,
        isError,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        queryKey,
        async ({ pageParam }) =>
            await listingService.getMeetingRooms(pageParam, limitListings),
        {
            getNextPageParam: (
                lastPage: PaginationModel<MeetingRoom[]>,
                pages,
            ) => {
                return lastPage.page.isLastPage
                    ? undefined
                    : lastPage.page.lastId;
            },
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    );

    const itemSize = items?.pages?.reduce((x, y) => x + y.lists.length, 0) ?? 0;

    useEffect(() => {
        if (isLoading) fullPageLoaderContext.show();
        else fullPageLoaderContext.hide();
    }, [isLoading]);

    return (
        <>
            <div className="max-w-[720px] mx-auto">
                <AdminPageTitle
                    pageTitle="Listings"
                    className="flex flex-col sm:flex-row sm:justify-between sm:items-center"
                >
                    <Link to={ROUTES.ListCreate}>
                        <Button
                            variant="contained"
                            startIcon={
                                <BsPlusLg className="w-4 h-4 text-base" />
                            }
                            className="min-w-[140px] mt-4 sm:mt-0"
                        >
                            Add New Listing
                        </Button>
                    </Link>
                </AdminPageTitle>

                <Divider className="my-4 mb-7" />
                <InfiniteScroll
                    dataLength={items?.pages ? itemSize : 0}
                    next={fetchNextPage}
                    hasMore={hasNextPage ?? false}
                    loader={
                        (isFetching || isFetchingNextPage) && (
                            <>
                                <div className="text-center">
                                    <ListingItemSkeleton />
                                    <Divider className="my-7 border-grey-300" />
                                    <ListingItemSkeleton />
                                    <Divider className="my-7 border-grey-300" />
                                    <ListingItemSkeleton />
                                    <Divider className="my-7 border-grey-300" />
                                </div>
                            </>
                        )
                    }
                    style={{ overflow: "visible" }}
                >
                    {items &&
                        items?.pages?.map((page, pageIndex) =>
                            page?.lists?.map((data: MeetingRoom, dataIndex) => (
                                <React.Fragment key={data.id}>
                                    <ListingItem
                                        listing={data}
                                        {...data}
                                        setOpenConfirmation={
                                            setOpenConfirmation
                                        }
                                        getCurrentIndex={(
                                            pageIndex: number,
                                            dataIndex: number,
                                        ) => {
                                            setCurrentIndex(
                                                pageIndex,
                                                dataIndex,
                                            );
                                        }}
                                        pageIndex={pageIndex}
                                        dataIndex={dataIndex}
                                    />
                                    <Divider className="my-7" />
                                </React.Fragment>
                            )),
                        )}

                    {!isFetching && itemSize === 0 && (
                        <div className="mt-1.5">
                            <EmptyList
                                bgTransparent
                                label={MESSAGES.LISTING_EMPTY_LIST}
                            />
                        </div>
                    )}
                </InfiniteScroll>
                {isFetching && (
                    <div className="text-center">
                        <ListingItemSkeleton />
                        <Divider className="my-7 border-grey-300" />
                        <ListingItemSkeleton />
                        <Divider className="my-7 border-grey-300" />
                        <ListingItemSkeleton />
                    </div>
                )}

                {/* CONFIRMATION MODAL */}
                <ModalConfirmation
                    severity="primary"
                    title={MESSAGES.LISTING_DELETE_CONFIRMATION}
                    btnCancelText={MESSAGES.LISTING_DELETE_CANCEL_BUTTON}
                    btnText={MESSAGES.LISTING_DELETE_CONFIRMATION_BUTTON}
                    open={openConfirmation}
                    closeFn={() => setOpenConfirmation(false)}
                    confirmFn={() => {
                        handleDeleteBtn();
                        setOpenConfirmation(false);
                    }}
                >
                    <Typography variant="body2" color="initial">
                        {MESSAGES.LISTING_DELETE_BODY_CONFIRMATION}
                    </Typography>
                </ModalConfirmation>

                {/* TOAST */}
                <JCSnackbar
                    open={openSnackbar}
                    onClose={() => setOpenSnackbar(false)}
                    vertical={"bottom"}
                    horizontal={"right"}
                    severity={deleteResult.result ? "success" : "error"}
                    description={deleteResult.description}
                ></JCSnackbar>
            </div>
        </>
    );
};

export default MeetingRooms;
