import { Avatar, Button, ButtonProps } from "@mui/material";
import { ReactNode } from "react";

interface HeaderIconProps extends ButtonProps {
    children?: ReactNode;
    buttonclass?: string;
    hasNotification?: boolean;
}
export const HeaderIcon: React.FC<HeaderIconProps> = ({
    hasNotification,
    ...props
}) => {
    return (
        <>
            <Button
                id={props.id}
                variant="text"
                className={`p-0 m-0 min-w-0 bg-inherit ${props.buttonclass}`}
                {...props}
            >
                <Avatar className="w-8 h-8 text-xl overflow-visible relative bg-transparent text-black hover:bg-grey-200 btn-transition">
                    {props.children}
                    {hasNotification && (
                        <div className="w-3 h-3 bg-error rounded-full absolute -right-1 -top-[1px]"></div>
                    )}
                </Avatar>
            </Button>
        </>
    );
};
