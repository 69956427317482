import { DocumentBase } from "./document-base.model";

export interface VendorSettingsModel extends DocumentBase {
    id: string;
    name?: string;
    ownerEmail?: string;
    vendorSettings: VendorSettings;
}

export interface VendorSettings {
    vatType: string;
    serviceFeePercent: number;
    availableSpaceIds: string[];
}

export const defaultVendorSettings = {
    name: "",
    ownerEmail: "",
    vendorSettings: {
        vatType: "",
        serviceFeePercent: 0,
        availableSpaceIds: [],
    },
} as VendorSettingsModel;

export const defaultVendorSettingCustomErrors = {
    ownerEmail: "",
};
