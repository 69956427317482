import { DocumentBase } from "./document-base.model";

export interface ChatMessage extends DocumentBase {
    senderId: string;
    text: string;
    type: MessageType;
    systemMessageType: SystemMessageType;
    sendToUserRoles: string[];
    status: string;
    consolidateStatus: string;
    bookingId: string;
}
export interface ActiveChatMessage extends ChatMessage {
    submitStatus: SubmitStatus;
}
export enum MessageType {
    text = "text",
    system = "system",
}
export enum SubmitStatus {
    new,
    sending,
    success,
    failed,
}

export enum SystemMessageType {
    BookingRequest,
    BookingChangeRequest,
    VendorAccepted,
    VendorDeclined,
    PaymentReceived,
    UserCanceled,
    BookingRequestExpired,
    BookingComplete,
}
