import { ChatMessage } from "./chat.model";
import { DocumentBase } from "./document-base.model";

export interface VendorInbox extends DocumentBase {
    userId: string;
    userName: string;
    userImageURL: string;
    lastMessage: ChatMessage;
    unreadBookingIds: string[];
    lastVisitBookingId: string;
    totalBookings: number;
    active: boolean;
}

export const defaultVendorInbox = {
    id: "",
    dateTimeCreated: null,
    dateTimeUpdated: null,
    userId: "",
    userName: "John Doe",
    userImageURL: "",
    lastMessage: null,
    unreadBookingIds: [],
    lastVisitBookingId: "BTz7SWz1DcqKhjMAJ91M", //BTz7SWz1DcqKhjMAJ91M
    totalBookings: 10,
} as VendorInbox;
