import { Radio } from "@mui/material";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { JCRadioProps } from "./type";

export const BaseRadio: React.FC<JCRadioProps> = (props) => {
  return (
    <>
      <Radio
        checkedIcon={<IoCheckmarkCircleSharp className="text-2xl" />}
        {...props}
      />
    </>
  );
};
