export const MAX_FILE_SIZE_KB: number = Number(
    process.env.REACT_APP_FILE_MAX_FILE_SIZE_KB,
);
export const MIN_WIDTH = Number(process.env.REACT_APP_FILE_MIN_WIDTH);
export const MIN_HEIGHT = Number(process.env.REACT_APP_FILE_MIN_HEIGHT);
export const MIN_RESOLUTION = Number(process.env.REACT_APP_FILE_MIN_RESOLUTION);
export const MAX_FILE_NUMBER = Number(
    process.env.REACT_APP_FILE_MAX_FILE_NUMBER,
);
export const SUPPORTED_IMAGE_TYPES = String(
    process.env.REACT_APP_FILE_IMAGE_TYPES,
);

export const DEFAULT_START_TIME: string =
    process.env.REACT_APP_DEFAULT_START_TIME;
export const DEFAULT_END_TIME: string = process.env.REACT_APP_DEFAULT_END_TIME;
