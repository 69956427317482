import { Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AdminPageTitleProps } from "../types";
type IconWithTextProps = {
    icon: ReactNode;
};
const IconWithText = ({ icon }: IconWithTextProps) => {
    return (
        <>
            <span className="text-3xl leading-none">{icon}</span>
            Go back
        </>
    );
};
export const AdminPageTitle: React.FunctionComponent<AdminPageTitleProps> = (
    props,
) => {
    const { children, pageTitle, icon, className, iconURL, titleClass } = props;
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <div className={`mt-6 mb-5 ${className}`}>
            <div className="font-bold text-lg">
                {icon ? (
                    <>
                        {iconURL ? (
                            <>
                                <Link
                                    to={iconURL}
                                    className="flex items-center"
                                >
                                    <IconWithText icon={icon} />
                                </Link>
                                <Typography
                                    variant="h1"
                                    className={`page-title mt-4 ${
                                        titleClass !== undefined
                                            ? titleClass
                                            : ""
                                    }`}
                                >
                                    {pageTitle}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Link
                                    to=""
                                    onClick={goBack}
                                    className="flex items-center"
                                >
                                    <IconWithText icon={icon} />
                                </Link>
                                <Typography
                                    variant="h1"
                                    className={`page-title mt-4 ${
                                        titleClass !== undefined
                                            ? titleClass
                                            : ""
                                    }`}
                                >
                                    {pageTitle}
                                </Typography>
                            </>
                        )}
                    </>
                ) : (
                    <Typography
                        variant="h1"
                        className={`page-title ${
                            titleClass !== undefined ? titleClass : ""
                        }`}
                    >
                        {pageTitle}
                    </Typography>
                )}
            </div>
            {children}
        </div>
    );
};
