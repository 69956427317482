import { Container } from "@mui/material";
import { SignInForm } from "components";
import { Helmet } from "react-helmet-async";

const SignInPage = () => {
  return (
    <>
      <Helmet>
        <title>Joes & Cos | Sign in</title>
      </Helmet>
      <Container maxWidth="lg">
        <SignInForm />
      </Container>
    </>
  );
};

export default SignInPage;
