import {
    dateStringFormat,
    dateTimeStringFormat,
} from "shared/constants/shared.constant";
import { Timestamp } from "firebaseSetup";
import moment from "moment";

const convertDateToTimeString = (dateTime: Date): string => {
    if (!dateTime) return "";
    const date = new Date(dateTime);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${hours}:${minutes}`;
};

const convertTimeStringToDate = (time: string): Date => {
    const timeDispartArr = time ? time.split(":") : "";
    const date = new Date(0);
    if (timeDispartArr.length < 2) {
        date.setHours(Number(timeDispartArr[0]));
        date.setMinutes(Number(0));
    } else {
        date.setHours(Number(timeDispartArr[0]));
        date.setMinutes(Number(timeDispartArr[1]));
    }
    return date;
};

const isBeforeClosingTimeAndAfterMidNight = (
    openingTime: string,
    closingTime: string,
) => {
    const startTime = moment(openingTime, "HH:mm");
    const endTime = moment(closingTime, "HH:mm");

    if (openingTime === "00:00" && closingTime === "00:00") return false;
    return startTime.isBefore(endTime);
};

const convertFirestoreTimestampToDate = (
    seconds: number,
    nanoSeconds: number,
) => {
    return new Timestamp(seconds, nanoSeconds).toDate();
};

const isAfterOpeningTimeAndBeforeMidNight = (
    openingTime: string,
    closingTime: string,
) => {
    const startTime = moment(openingTime, "HH:mm");
    const endTime = moment(closingTime, "HH:mm");

    const midNight = moment("24:00", "HH:mm");
    return endTime.isAfter(startTime) && endTime.isBetween(startTime, midNight);
};

const convertTimeStampToString = (
    ts: Timestamp,
    format: string = dateStringFormat,
) => {
    return moment(ts).format(format);
};
const convertDateTimeToString = (
    date: Date,
    format: string = dateStringFormat,
) => {
    return moment(date).format(format);
};
const displayDateTime = (date: Date) => {
    if (!date) return "";
    if (moment(new Date()).isSame(moment(date), "day"))
        return "Today, " + convertDateToTimeString(date);
    else return convertDateTimeToString(date, dateTimeStringFormat);
};
const isDifferentDate = (currentItemDate: Date, previousItemDate: Date) => {
    if (!previousItemDate) return false;
    const dateFormat = "YYYY-MM-DD";
    const currentItemDateString = moment(currentItemDate).format(dateFormat);
    const previousItemDateString = moment(previousItemDate).format(dateFormat);
    return currentItemDateString !== previousItemDateString;
};
export const dateTimeHelper = {
    convertDateToTimeString,
    convertTimeStringToDate,
    isBeforeClosingTimeAndAfterMidNight,
    isAfterOpeningTimeAndBeforeMidNight,
    convertFirestoreTimestampToDate,
    convertTimeStampToString,
    convertDateTimeToString,
    displayDateTime,
    isDifferentDate,
};
