import { ReactNode } from "react";

interface EmptyListProps {
    label?: string;
    children?: ReactNode;
    bgTransparent?: boolean;
    hasBorder?: boolean;
}

export const EmptyList: React.FC<EmptyListProps> = (props) => {
    return (
        <div
            className={`flex flex-col justify-items-center items-center p-8 ${
                props.bgTransparent ? "" : "bg-[#eee]"
            } ${props.hasBorder && "border border-solid border-grey-300"}`}
        >
            <span className="text-grey-500 text-center">{props.label}</span>
            {props.children}
        </div>
    );
};
