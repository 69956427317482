import { Skeleton } from "@mui/material";

const ProfileDetailsSkeleton = () => {
    return (
        <>
            <div className="flex flex-col sm:flex-row">
                <Skeleton
                    variant="circular"
                    sx={{ width: 130, height: 130 }}
                    className="mr-9"
                />
                <div className="flex flex-col mt-3 sm:mt-0">
                    <Skeleton variant="text" className="mb-3 w-40 h-8" />
                    <div className="flex mb-1 items-center">
                        <Skeleton
                            variant="rectangular"
                            className="mr-4 w-5 h-5 rounded-md"
                        />
                        <Skeleton variant="text" className="w-40 h-6" />
                    </div>
                    <div className="flex mb-1 items-center">
                        <Skeleton
                            variant="rectangular"
                            className="mr-4 w-5 h-5 rounded-md"
                        />
                        <Skeleton variant="text" className="w-40 h-6" />
                    </div>
                    <div className="flex mb-1 items-center">
                        <Skeleton
                            variant="rectangular"
                            className="mr-4 w-5 h-5 rounded-md"
                        />
                        <Skeleton variant="text" className="w-40 h-6" />
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <Skeleton variant="text" className="mb-3 w-20" />
                <div className="space-y-2">
                    <Skeleton variant="text" className="w-full" />
                    <Skeleton variant="text" className="w-1/2" />
                </div>
            </div>
        </>
    );
};

export default ProfileDetailsSkeleton;
