import { FormControl, FormHelperText, Grid, RadioGroup } from "@mui/material";
import { SectionTitle } from "components";
import { DynamicIcon, modifyIconNameToPascal } from "components/UI/DynamicIcon";
import { BaseRadio, RadioBoxCardView } from "components/UI/Radio";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import { useQuery } from "react-query";
import { moodService } from "services/mood.service";
import MESSAGES from "shared/constants/messages.constant";

export interface MoodProps {
    formik: FormikProps<MeetingRoom>;
}

export const Mood = (props: MoodProps) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
    } = props.formik;

    const { data: moods, isFetching } = useQuery(
        "getMoods",
        () => moodService.getMoods(),
        {
            refetchOnWindowFocus: false,
        },
    );

    return (
        <>
            <SectionTitle
                title="Select Mood"
                helpText={MESSAGES.LISTING_SELECT_MOOD_TOOLTIP}
                hasTooltip
                tooltipPosition="bottom"
                showRequiredSymbol
            />
            <FormControl
                error={!!errors.mood?.id && touched.mood?.id}
                className="overflow-hidden"
            >
                <RadioGroup
                    id="mood.id"
                    name="mood.id"
                    value={values.mood.id}
                    aria-labelledby="demo-radio-buttons-group-label"
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 3 }}
                        className="mb-5"
                    >
                        {moods &&
                            moods.map((mood) => (
                                <Grid item xs={12} md={4} key={mood?.id}>
                                    <RadioBoxCardView
                                        label={mood?.title}
                                        icon={
                                            <DynamicIcon
                                                name={modifyIconNameToPascal(
                                                    mood?.icon,
                                                )}
                                                className="-mt-1"
                                            />
                                        }
                                    >
                                        <BaseRadio
                                            className="radio-top-right"
                                            inputProps={{
                                                "aria-label": mood?.title,
                                            }}
                                            value={mood?.id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </RadioBoxCardView>
                                </Grid>
                            ))}
                    </Grid>
                </RadioGroup>
                {touched.mood?.id && errors.mood?.id && (
                    <FormHelperText className="mx-0 mb-6">
                        {errors.mood?.id}
                    </FormHelperText>
                )}
            </FormControl>
        </>
    );
};
