import React from "react";
import { Container, Grid, Box, Typography, Button } from "@mui/material";
import {
  AdminContentContainer,
  AdminSingleBlockLayout,
} from "components/Layouts";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>Joes & Cos | 404</title>
      </Helmet>
      <AdminContentContainer>
        <AdminSingleBlockLayout>
          <Container maxWidth={false} className="px-4 lg:px-10 mt-9">
            <Grid container rowSpacing={0} columnSpacing={{ xs: 2, lg: 5 }}>
              <Grid item xs={12}>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    px: { xs: 2, lg: 5 },
                    py: { xs: 4 },
                    width: { xl: "100%" },
                  }}
                  className="bg-white"
                >
                  <div className="text-center py-4">
                    <div className="mb-6">
                      {/** the issue is you need to add prefix, such as basePath, usually browser base path */}
                      {/* <Image
                        src={`${publicRuntimeConfig.IMAGE_BASE_PATH}/browser-warning.svg`}
                        width="80"
                        height="66"
                        alt="Page not found"
                      /> */}
                    </div>
                    <Typography
                      variant="h1"
                      className="text-black font-bold tracking-wider mb-5"
                    >
                      Sorry, page not found.
                    </Typography>
                    {/* <Typography variant="body2" className="text-black mb-8">
                      This booking may have been deleted.
                    </Typography> */}
                    <Link to="/dashboard">
                      <Button
                        variant="contained"
                        color="primary"
                        className="tracking-widest"
                      >
                        Go to dashboard
                      </Button>
                    </Link>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </AdminSingleBlockLayout>
      </AdminContentContainer>
    </>
  );
};
