const ROUTES = {
    SignIn: "/sign-in",
    Dashboard: "/dashboard",
    Listings: "/listings",
    ListCreate: "/listings/create",
    ListEdit: "/listings/edit/:id",
    Vendors: "/vendors",
    CreateVendor: "/vendors/create",
    EditVendorProfile: "/vendors/edit/:id",
    Components: "/components",
    Unauthorized: "/unauthorized",
    Notifications: "/notifications",
    Profile: "/profile",
    Account: "/account",
    Messages: "/messages/:chatRoomId/:bookingId",
    ReservationDetail: "/reservation-details/:bookingId",
    PageNotFound: "/pageNotFound",
};

export default ROUTES;
