import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    RadioGroup,
} from "@mui/material";
import { SectionTitle } from "components";
import { BaseRadio, RadioDesc } from "components/UI/Radio";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import { useQuery } from "react-query";
import { listingService } from "services/listing.service";
import MESSAGES from "shared/constants/messages.constant";

export interface CancellationPolicyProps {
    formik: FormikProps<MeetingRoom>;
}

export const CancellationPolicy = (props: CancellationPolicyProps) => {
    const { errors, touched, handleChange, handleBlur, values } = props.formik;

    const { data: cancellationPolicies, isFetching } = useQuery(
        "cancellation",
        () => listingService.getAllCancellationPolicy(),
        {
            refetchOnWindowFocus: false,
        },
    );

    return (
        <>
            <SectionTitle
                title="Cancellation Policy"
                helpText={MESSAGES.LISTING_CANCELLATION_POLICY_TOOLTIP}
                hasTooltip
                tooltipPosition="bottom"
                showRequiredSymbol
            />
            <FormControl
                error={
                    !!errors.cancellationPolicyId &&
                    touched.cancellationPolicyId
                }
            >
                <RadioGroup
                    id="cancellationPolicyId"
                    name="cancellationPolicyId"
                    aria-labelledby="cancellation-group-label"
                    className="space-y-3"
                    value={values.cancellationPolicyId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    {cancellationPolicies &&
                        cancellationPolicies
                            .sort(function (a, b) {
                                return a.order - b.order;
                            })
                            .map((policy) => (
                                <RadioDesc
                                    key={policy?.id}
                                    description={policy?.description}
                                >
                                    <FormControlLabel
                                        control={<BaseRadio />}
                                        label={policy?.title}
                                        value={policy?.id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </RadioDesc>
                            ))}
                </RadioGroup>
                {touched.cancellationPolicyId &&
                    errors.cancellationPolicyId && (
                        <FormHelperText className="pl-7 ml-0.5 my-6">
                            {errors.cancellationPolicyId}
                        </FormHelperText>
                    )}
            </FormControl>
        </>
    );
};
