import { JCSnackbarProps } from "components/UI/Snackbar";
import React, { useContext } from "react";

interface NotificationContextType {
    notification: JCSnackbarProps;
    showSuccess: (description: string) => void;
    showError: (description: string) => void;
    clear: () => void;
}

export const NotificationContext = React.createContext<NotificationContextType>(
    null!,
);

export const useNotificationContext = () => {
    return useContext(NotificationContext);
};
