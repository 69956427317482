import { Box } from "@mui/material";
import { Loading } from ".";

export const FullPageLoading = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="flex-1 text-center">
          <Loading />
        </div>
      </Box>
    </>
  );
};
