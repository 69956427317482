import { Typography } from "@mui/material";
import { CancellationPolicy } from "models/bookings.model";

export interface VendorProfileProps {
    cancellationPolicy: CancellationPolicy | null;
}
export const CancellationDetails = (props: VendorProfileProps) => {
    const { cancellationPolicy } = props;
    return (
        <>
            <Typography
                variant="h2"
                color="initial"
                className="mb-3 text-3xl font-semibold pr-8"
            >
                Cancellation Policy
            </Typography>
            <div className="mt-5">
                <Typography variant="h3" color="initial" className="mb-3">
                    {cancellationPolicy.title}
                </Typography>
                <div
                    className="cancellation-content"
                    dangerouslySetInnerHTML={{
                        __html: cancellationPolicy.description,
                    }}
                />
            </div>
        </>
    );
};
