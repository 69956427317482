import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
    collection,
    DocumentData,
    endAt,
    FieldValue,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    OrderByDirection,
    query,
    Query,
    QuerySnapshot,
    startAfter,
    startAt,
    Timestamp,
    where,
} from "firebase/firestore";
import {
    connectFunctionsEmulator,
    getFunctions,
    httpsCallable,
} from "firebase/functions";
import {
    deleteObject,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytesResumable,
} from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "europe-west1");
if (process.env.REACT_APP_ENV.toUpperCase() === "LOCALHOST") {
    //**open the below line to connect local backend functions.
    connectFunctionsEmulator(functions, "localhost", 5000);
}

export {
    auth,
    functions,
    httpsCallable,
    db,
    storage,
    ref,
    getDownloadURL,
    uploadBytesResumable,
    deleteObject,
    collection,
    limit,
    onSnapshot,
    orderBy,
    query,
    where,
    startAt,
    endAt,
    startAfter,
    getDocs,
    getDoc,
    Timestamp,
    FieldValue,
    Query,
};
export type { OrderByDirection, DocumentData, QuerySnapshot };
