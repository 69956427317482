import MESSAGES from "shared/constants/messages.constant";
import * as Yup from "yup";
export const vendorValidation = Yup.object().shape({
    image: Yup.object().shape({
        imageUrl: Yup.string(),
        imageReference: Yup.string(),
    }),
    company: Yup.string().when("vendorType", {
        is: "company",
        then: (d) =>
            d.required(MESSAGES.VENDOR_PROFILE_VALIDATION_COMPANY_REQUIRED),
        otherwise: (d) => d.nullable(),
    }),
    firstName: Yup.string().when("vendorType", {
        is: "individual",
        then: (d) =>
            d.required(MESSAGES.VENDOR_PROFILE_VALIDATION_FIRST_NAME_REQUIRED),
        otherwise: (d) => d.nullable(),
    }),
    lastName: Yup.string().when("vendorType", {
        is: "individual",
        then: (d) =>
            d.required(MESSAGES.VENDOR_PROFILE_VALIDATION_LAST_NAME_REQUIRED),
        otherwise: (d) => d.nullable(),
    }),
    bio: Yup.string()
        .required(MESSAGES.VENDOR_PROFILE_VALIDATION_ABOUT_REQUIRED)
        .nullable(),
});
