import { Skeleton } from "@mui/material";
import { SectionTitle } from "components/UI";
import { useAuthContext } from "context/AuthContext";
import { FormikProps } from "formik";
import { VendorSettingsModel } from "models/vendor-settings.model";
import { Workspace } from "models/workspace.model";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { spaceService } from "services/space.service";
import MESSAGES from "shared/constants/messages.constant";
import { defaultPaginationLimit } from "shared/constants/shared.constant";
import { VendorWorkspacesSkeleton } from "./VendorWorkspacesSkeleton";
import { WorkspaceWithCheckbox } from "./WorkspaceWithCheckbox";

export interface VendorWorkspacesProps {
    formik: FormikProps<VendorSettingsModel>;
}

export const VendorWorkspaces = (props: VendorWorkspacesProps) => {
    const { values, setFieldValue, setFieldTouched } = props.formik;

    const fetchSpaces = async ({ pageParam }: any) => {
        return await spaceService.getSpaces(defaultPaginationLimit, pageParam);
    };

    const {
        data: WorkspaceData,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
    } = useInfiniteQuery(
        `admin-${useAuthContext().authUser.userId}-spaces`,
        fetchSpaces,
        {
            getNextPageParam: (lastPage, pages) =>
                lastPage.page.isLastPage ? undefined : lastPage.page.lastId,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    );

    const itemSize =
        WorkspaceData?.pages?.reduce((x, y) => x + y.lists.length, 0) ?? 0;

    const addItem = (itemId: string) => {
        if (itemId) {
            setFieldTouched("vendorSettings.availableSpaceIds", true, false);
            setFieldValue("vendorSettings.availableSpaceIds", [
                ...values.vendorSettings?.availableSpaceIds,
                itemId,
            ]);
        }
    };
    const removeItem = (itemId: string) => {
        if (itemId) {
            setFieldTouched("vendorSettings.availableSpaceIds", true, false);
            setFieldValue("vendorSettings.availableSpaceIds", [
                ...values.vendorSettings?.availableSpaceIds.filter(
                    (x) => x !== itemId,
                ),
            ]);
        }
    };
    return (
        <>
            <div className="mb-6 md:mb-8">
                <SectionTitle
                    title="Workspaces"
                    helpText={MESSAGES.VENDOR_ACCOUNT_WORKSPACE_HELP_TEXT}
                    hasTooltip
                />
                {
                    <>
                        {isLoading && <VendorWorkspacesSkeleton />}
                        <div
                            style={{ maxHeight: 180, overflow: "auto" }}
                            id="spaces-container"
                            className="custom-scrollbar"
                        >
                            <InfiniteScroll
                                dataLength={WorkspaceData?.pages ? itemSize : 0}
                                next={fetchNextPage}
                                hasMore={hasNextPage ?? false}
                                loader={null}
                                scrollableTarget="spaces-container"
                                className="custom-scrollbar"
                            >
                                {(!isFetching || isFetchingNextPage) &&
                                    WorkspaceData &&
                                    WorkspaceData?.pages?.map((spaces) =>
                                        spaces.lists.map((space: Workspace) => (
                                            <WorkspaceWithCheckbox
                                                data={space}
                                                key={space.id}
                                                itemIsSelected={
                                                    values.vendorSettings?.availableSpaceIds?.indexOf(
                                                        space.id,
                                                    ) !== -1
                                                }
                                                selectItem={(
                                                    selected: boolean,
                                                ) => {
                                                    if (selected) {
                                                        addItem(space.id);
                                                    } else {
                                                        removeItem(space.id);
                                                    }
                                                }}
                                            />
                                        )),
                                    )}

                                {isFetchingNextPage && (
                                    <Skeleton
                                        variant="text"
                                        className="w-full my-1"
                                    ></Skeleton>
                                )}
                            </InfiniteScroll>
                        </div>
                    </>
                }
            </div>
        </>
    );
};
