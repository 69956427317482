/* eslint-disable import/no-anonymous-default-export */
import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#222';

export default {
  primary: {
    contrastText: white,
    dark: '#222',
    main: '#222',
    light: '#212121',
    text: '#222',
  },
  secondary: {
    contrastText: white,
    dark: '#111827',
    main: '#1f2937',
    light: '#4b5563',
  },
  success: {
    contrastText: white,
    dark: "#54cb7b",
    main: "#4EC274",
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: '#737373',
    main: '#949494',
    light: '#949494',
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: '#960010',
    main: '#F72220',
    light: '#ff6d61',
  },
  text: {
    primary: black,
    secondary: '#949494',
    link: '#222',
  },
  background: {
    default: '#f9f9f9', // #F4F6F8
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: '#E0E0E0',
  border: '#cecece',
};
