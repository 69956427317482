import { FullPageLoading } from "components/UI";
import { Navigate, useLocation } from "react-router-dom";
import ROUTES from "shared/constants/routes.constant";
import { useAuthContext } from "../context/AuthContext";

export const PublicRoute: React.FC<{ children: JSX.Element }> = ({
    children,
}) => {
    let auth = useAuthContext();
    let location = useLocation();

    if (auth.loading) return <FullPageLoading />;

    return auth.user ? (
        auth.authUser?.isAdmin ? (
            <Navigate to={ROUTES.Vendors} state={{ from: location }} replace />
        ) : (
            <Navigate
                to={ROUTES.Dashboard}
                state={{ from: location }}
                replace
            />
        )
    ) : (
        children
    );
};

export default PublicRoute;
