import { DBCollection } from "enum/db-collection.enum";
import { dateTimeHelper } from "helpers/date-time.helper";
import { ActiveChatMessage, SubmitStatus } from "models/chat.model";
import { PaginationModel } from "models/pagination.model";
import {
    API_ENDPOINT_ฺCHAT,
    API_ENDPOINT_ฺINBOX,
} from "shared/constants/api.constant";
import ROLES from "shared/constants/roles.constant";
import { SHARED_DATE_TIME_CREATED } from "shared/constants/shared-db.constant";
import { defaultPaging } from "shared/constants/shared.constant";
import { CONSOLIDATE_STATUS } from "shared/constants/status";
import {
    collection,
    db,
    DocumentData,
    limit,
    onSnapshot,
    orderBy,
    OrderByDirection,
    query,
    QuerySnapshot,
    where,
} from "../firebaseSetup";
import { requestService } from "./request.service";

const subscribeUpdatedBookings = (
    snapShotLatestUpdatedItem: (param: QuerySnapshot<DocumentData>) => void,
    chatRoomId: string,
    consolidateStatus: string, //TODO: check data type
    orderByField: string,
    orderDirection: OrderByDirection = "desc",
) => {
    const path = `${DBCollection.Bookings}`;
    const queryRef = collection(db, path);
    const q = query(
        queryRef,
        where("consolidateStatus", "==", consolidateStatus),
        where("chatRoomId", "==", chatRoomId),
        orderBy(orderByField, orderDirection),
        limit(1),
    );

    return onSnapshot(
        q,
        {
            // Listen for document metadata changes
            includeMetadataChanges: true,
        },
        snapShotLatestUpdatedItem,
    );
};
const subscribeNewChatMessage = (
    snapShotLatestUpdatedItem: (param: QuerySnapshot<DocumentData>) => void,
    bookingId: string,
    orderByField: string,
    orderDirection: OrderByDirection = "desc",
) => {
    const path = `${DBCollection.Bookings}/${bookingId}/${DBCollection.Messages}`;
    const queryRef = collection(db, path);
    const q = query(
        queryRef,
        where("sendToUserRoles", "array-contains", ROLES.Vendor),
        orderBy(orderByField, orderDirection),
        limit(1),
    );

    return onSnapshot(q, snapShotLatestUpdatedItem);
};

const fetchChatMessages = async (
    bookingId: string,
    amount: number,
    lastId: string | null,
): Promise<PaginationModel<ActiveChatMessage[]>> => {
    try {
        //TODO: move endpoint name to a constant (endpoints.constant)
        const result = (await requestService.onCall(
            API_ENDPOINT_ฺCHAT.getChatMessagesByBookingId,
            {
                ...defaultPaging,
                amount: amount,
                lastId: lastId,
                orderByKey: SHARED_DATE_TIME_CREATED,
                bookingId,
                role: ROLES.Vendor,
            },
        )) as PaginationModel<any[]>;
        if (result.lists) {
            result.lists = result.lists.map((item) => {
                if (item.dateTimeCreated)
                    item.dateTimeCreated =
                        dateTimeHelper.convertFirestoreTimestampToDate(
                            item.dateTimeCreated._seconds,
                            item.dateTimeCreated._nanoseconds,
                        );
                if (item.dateTimeUpdated)
                    item.dateTimeUpdated =
                        dateTimeHelper.convertFirestoreTimestampToDate(
                            item.dateTimeUpdated._seconds,
                            item.dateTimeUpdated._nanoseconds,
                        );

                item.submitStatus = SubmitStatus.success;
                return item as ActiveChatMessage;
            });
        }
        return result;
    } catch (exception) {
        throw exception;
    }
};
const sendChatMessage = async (
    bookingId: string,
    text: string,
): Promise<string> => {
    try {
        const result = (await requestService.onCall(
            API_ENDPOINT_ฺCHAT.addChatMessage,
            {
                bookingId,
                text,
            },
        )) as string;

        return result;
    } catch (exception) {
        throw exception;
    }
};
const updateLastVisitBookingId = async (bookingId: string): Promise<string> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺINBOX.updateLastVisitBookingId,
        {
            bookingId,
        },
    )) as Promise<string>;
};

const subscribeUnreadOnConsolidateStatusTab = (
    snapShotLatestUpdatedItem: (param: QuerySnapshot<DocumentData>) => void,
    chatRoomId: string,
    consolidateStatus: CONSOLIDATE_STATUS,
) => {
    const path = `${DBCollection.Bookings}`;
    const queryRef = collection(db, path);
    const q = query(
        queryRef,
        where("chatRoomId", "==", chatRoomId),
        where("consolidateStatus", "==", consolidateStatus),
        where("vendorRead", "==", false),
        limit(1),
    );

    return onSnapshot(q, snapShotLatestUpdatedItem);
};
export const chatService = {
    subscribeUpdatedBookings,
    subscribeNewChatMessage,
    updateLastVisitBookingId,
    fetchChatMessages,
    sendChatMessage,
    subscribeUnreadOnConsolidateStatusTab,
};
