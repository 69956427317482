import { Divider } from "@mui/material";
import { NotificationSkeleton } from "components/Notification";

const NotificationOverviewSkeleton = () => {
    return (
        <>
            <NotificationSkeleton />
            <Divider />
            <NotificationSkeleton />
            <Divider />
            <NotificationSkeleton />
            <Divider />
            <NotificationSkeleton />
        </>
    );
};

export default NotificationOverviewSkeleton;
