import { Divider, Grid, Skeleton } from "@mui/material";

export const ReservationDetailSkeleton = () => {
    return (
        <>
            <Divider className="mt-5 mb-6" />
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="space-y-3 md:space-y-0 mb-3 md:mb-7"
            >
                <Grid item xs={12} md={12}>
                    <div className="flex justify-between flex-col sm:flex-row">
                        <div>
                            <Skeleton variant="text" className="w-20" />
                            <Skeleton variant="text" className="w-40" />
                        </div>
                        <div className="mt-4 sm:mt-0">
                            <Skeleton variant="text" className="w-20" />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="space-y-3 md:space-y-0 mb-3 md:mb-7"
            >
                <Grid item xs={12} md={8}>
                    <Skeleton variant="text" className="w-20" />
                    <Skeleton variant="text" className="w-40" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton variant="text" className="w-20" />
                    <Skeleton variant="text" className="w-40" />
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="space-y-3 md:space-y-0 mb-3 md:mb-7"
            >
                <Grid item xs={12} md={8}>
                    <Skeleton variant="text" className="w-20" />
                    <Skeleton variant="text" className="w-40" />
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="space-y-3 md:space-y-0 mb-3 md:mb-7"
            >
                <Grid item xs={12} md={4}>
                    <Skeleton variant="text" className="w-20" />
                    <Skeleton variant="text" className="w-40" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton variant="text" className="w-20" />
                    <Skeleton variant="text" className="w-10" />
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="space-y-3 md:space-y-0 mb-3 md:mb-7"
            >
                <Grid item xs={12} md={4}>
                    <Skeleton variant="text" className="w-20" />
                    <Skeleton variant="text" className="w-40" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton variant="text" className="w-20" />
                    <Skeleton variant="text" className="w-40" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton variant="text" className="w-20" />
                    <Skeleton variant="text" className="w-40" />
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="space-y-3 md:space-y-0 mb-3 md:mb-7"
            >
                <Grid item xs={12} md={12}>
                    <Skeleton variant="text" className="w-20" />
                    <Skeleton variant="text" className="w-40" />
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="space-y-3 md:space-y-0 mb-3 md:mb-7"
            >
                <Grid item xs={12} md={12}>
                    <Skeleton variant="text" className="w-40" />
                    <div className="space-y-2">
                        <Skeleton variant="text" className="w-full" />
                        <Skeleton variant="text" className="w-full" />
                        <Skeleton variant="text" className="w-full" />
                        <Skeleton variant="text" className="w-full" />
                        <Skeleton variant="text" className="w-full" />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};
