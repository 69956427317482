import React from "react";
import { ImageProps } from "types";

export const Logo: React.FC<ImageProps> = (props) => {
    return (
        <img
            data-test="icon"
            src={props.src ? props.src : "/images/jc-logo-tagline.svg"}
            alt={props.alt}
            width={props.width}
            height={props.height}
            className={`object-contain max-w-[150px] md:max-w-none ${props.className}`}
        />
    );
};
