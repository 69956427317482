import { FormControl, FormHelperText, Grid, Typography } from "@mui/material";
import { TextInput } from "components/UI";
import { FormikProps } from "formik";
import MESSAGES from "shared/constants/messages.constant";
import { AccountInformationModel } from "../../../models/account-information.model";

export interface GeneralAccountInformationProps {
    formik: FormikProps<AccountInformationModel>;
    isReadOnly: boolean;
}

export const GeneralAccountInformation = (
    props: GeneralAccountInformationProps,
) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
    } = props.formik;
    const isReadOnly = props.isReadOnly;

    return (
        <>
            <Typography
                variant="h3"
                color="initial"
                className="mb-5 font-medium"
            >
                General
            </Typography>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.firstName && touched.firstName}
                    >
                        <TextInput
                            label="First name"
                            placeholder=""
                            id="firstName"
                            name="firstName"
                            aria-describedby="firstname-help-text"
                            value={values.firstName}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.firstName && errors.firstName && (
                            <FormHelperText
                                id="firstName-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.firstName}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.lastName && touched.lastName}
                    >
                        <TextInput
                            label="Last name"
                            placeholder=""
                            id="lastName"
                            name="lastName"
                            aria-describedby="lastName-help-text"
                            value={values.lastName}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.lastName && errors.lastName && (
                            <FormHelperText
                                id="lastName-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.lastName}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.company && touched.company}
                    >
                        <TextInput
                            label="Company (Optional)"
                            placeholder=""
                            id="company"
                            name="company"
                            aria-describedby="company-help-text"
                            value={values.company}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.company && errors.company && (
                            <FormHelperText
                                id="company-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.company}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.vatNumber && touched.vatNumber}
                    >
                        <TextInput
                            label="VAT Registration number (Optional)"
                            placeholder=""
                            id="vatNumber"
                            name="vatNumber"
                            aria-describedby="vat-help-text"
                            value={values.vatNumber}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.vatNumber && errors.vatNumber && (
                            <FormHelperText
                                id="vatNumber-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.vatNumber}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.email && touched.email}
                    >
                        <TextInput
                            label="Email address"
                            placeholder=""
                            id="email"
                            name="email"
                            aria-describedby="email-help-text"
                            value={values.email}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            hasTooltip
                            tooltipContent={
                                MESSAGES.ACCOUNT_INFORMATION_EMAIL_HELP_TEXT
                            }
                            showRequiredSymbol
                        />
                        {touched.email && errors.email && (
                            <FormHelperText
                                id="email-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.email}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.phoneNumber && touched.phoneNumber}
                    >
                        <TextInput
                            label="Phone number"
                            placeholder=""
                            id="phoneNumber"
                            name="phoneNumber"
                            aria-describedby="phone-help-text"
                            value={values.phoneNumber}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        <FormHelperText
                            id="phoneNumber-help-text"
                            className="mx-0 my-1 !text-grey-500"
                        >
                            {MESSAGES.ACCOUNT_INFORMATION_PHONENUMBER_HELP_TEXT}
                        </FormHelperText>
                        {touched.phoneNumber && errors.phoneNumber && (
                            <FormHelperText
                                id="phoneNumber-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.phoneNumber}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <FormControl
                variant="standard"
                className="mb-4 md:mb-8"
                error={!!errors.addressLine1 && touched.addressLine1}
            >
                <TextInput
                    label="Address line 1"
                    placeholder=""
                    id="addressLine1"
                    name="addressLine1"
                    aria-describedby="address-help-text"
                    value={values.addressLine1}
                    disabled={isReadOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showRequiredSymbol
                />
                {touched.addressLine1 && errors.addressLine1 && (
                    <FormHelperText
                        id="addressLine1-help-text"
                        className="mx-0 my-1"
                    >
                        {errors.addressLine1}
                    </FormHelperText>
                )}
            </FormControl>
            <FormControl
                variant="standard"
                className="mb-4 md:mb-8"
                error={!!errors.addressLine2 && touched.addressLine2}
            >
                <TextInput
                    label="Address line 2 (Optional)"
                    placeholder=""
                    id="addressLine2"
                    name="addressLine2"
                    aria-describedby="address-help-text"
                    value={values.addressLine2}
                    disabled={isReadOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.addressLine2 && errors.addressLine2 && (
                    <FormHelperText
                        id="addressLine2-help-text"
                        className="mx-0 my-1"
                    >
                        {errors.addressLine2}
                    </FormHelperText>
                )}
            </FormControl>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.city && touched.city}
                    >
                        <TextInput
                            label="City"
                            placeholder=""
                            id="city"
                            name="city"
                            aria-describedby="city-help-text"
                            value={values.city}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.city && errors.city && (
                            <FormHelperText
                                id="city-help-text"
                                className="mx-0 my-1"
                            >
                                {touched.city && errors.city}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.postalCode && touched.postalCode}
                    >
                        <TextInput
                            label="Postal code"
                            placeholder=""
                            id="postalCode"
                            name="postalCode"
                            aria-describedby="postalCode-help-text"
                            value={values.postalCode}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.postalCode && errors.postalCode && (
                            <FormHelperText
                                id="postalCode-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.postalCode}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.country && touched.country}
                    >
                        <TextInput
                            label="Country"
                            placeholder=""
                            id="country"
                            name="country"
                            aria-describedby="country-help-text"
                            value={values.country}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.country && errors.country && (
                            <FormHelperText
                                id="country-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.country}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export * from "./GeneralAccountInformationSkeleton";
