import { API_ENDPOINT_ฺMOOD } from "shared/constants/api.constant";
import { Mood } from "models";
import { requestService } from "./request.service";

const getMoods = async (): Promise<Mood[]> => {
    return (await requestService.onCall(API_ENDPOINT_ฺMOOD.getMoods)) as Mood[];
};

export const moodService = {
    getMoods,
};
