import { SectionTitle } from "components/UI/SectionTitle";
import { OpeningHour } from "components/UI/Vendors/Listing";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import MESSAGES from "shared/constants/messages.constant";

export const OpeningHours = (props: FormikProps<MeetingRoom>) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
    } = props;
    return (
        <>
            <SectionTitle
                title="Opening Hours"
                helpText={MESSAGES.LISTING_OPENING_HOURS_TOOLTIP}
                hasTooltip
                tooltipPosition="right"
                showRequiredSymbol
            />
            <OpeningHour {...props} />
        </>
    );
};
