import {
    Box,
    // DialogProps,
    Divider,
    FormGroup,
} from "@mui/material";
import { FormikProps } from "formik";
import { MeetingRoom } from "models";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { amenityService } from "services/amenity.service";
import SimpleBarReact from "simplebar-react";
import { AmenityItem } from "./AmenityItem";

export interface AmenityProps {
    formik: FormikProps<MeetingRoom>;
}

export const AmenitiesList = (props: AmenityProps) => {
    const { values, setFieldValue, setFieldTouched, touched, errors } =
        props.formik;

    const { data: initAmenitiesList, isFetching } = useQuery(
        "initAmenitiesList",
        async () => await amenityService.getMeeingRoomAmenities(),
        {
            refetchOnWindowFocus: false,
        },
    );

    const handleCheckedChange = (e) => {
        if (e.event.target.checked === true) {
            const item = initAmenitiesList.filter((x) => x.id === e.item.id)[0];
            setFieldValue("meetingRoomAmenities", [
                ...values.meetingRoomAmenities,
                item,
            ]);
        } else {
            setFieldValue(
                "meetingRoomAmenities",
                values.meetingRoomAmenities.filter((x) => x.id !== e.item.id),
            );
        }
        setFieldTouched("meetingRoomAmenities", true, false);
    };
    const itemIsSelected = (id) => {
        return (
            (values.meetingRoomAmenities.length > 0 &&
                values.meetingRoomAmenities.map((x) => x.id).indexOf(id) !==
                    -1) ||
            false
        );
    };

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <FormGroup
                    sx={{
                        width: "auto",
                    }}
                >
                    <SimpleBarReact style={{ maxHeight: 180 }}>
                        {initAmenitiesList &&
                            initAmenitiesList.map((data, index) => (
                                <AmenityItem
                                    formik={props.formik}
                                    data={data}
                                    handleCheckedChange={handleCheckedChange}
                                    itemIsSelected={itemIsSelected(data.id)}
                                    key={index + "amenity"}
                                ></AmenityItem>
                            ))}
                    </SimpleBarReact>
                </FormGroup>
                <Divider className="border-b-dashed" />
            </Box>
        </>
    );
};
