import { Box, Button, IconButton, Popover } from "@mui/material";
import { TextInput } from "components";
import { FormikProps } from "formik";
import { Amenity } from "models";
import React, { useEffect, useState } from "react";
import SimpleBarReact from "simplebar-react";
import palette from "styles/theme/palette";
import {
    DynamicIcon,
    Icons,
    modifyIconNameToKebabCase,
    modifyIconNameToPascal,
} from "../DynamicIcon";
export interface IconPickerProps {
    icon?: string;
    formik?: FormikProps<Amenity>;
}

const IconPicker = (iconPickerProps?: IconPickerProps) => {
    const [filter, setFilter] = useState("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectIcon, setSelectIcon] = useState(["", ""]);
    const availableIcons = [
        "IoAddCircleOutline",
        "IoAddOutline",
        "IoAirplaneOutline",
        "IoAlarmOutline",
        "IoAlbumsOutline",
        "IoAlertCircleOutline",
        "IoAlertOutline",
        "IoAmericanFootballOutline",
        "IoAnalyticsOutline",
        "IoApertureOutline",
        "IoAppsOutline",
        "IoArchiveOutline",
        "IoArrowBackCircleOutline",
        "IoArrowBackOutline",
        "IoArrowDownCircleOutline",
        "IoArrowDownOutline",
        "IoArrowForwardCircleOutline",
        "IoArrowForwardOutline",
        "IoArrowRedoCircleOutline",
        "IoArrowRedoOutline",
        "IoArrowUndoCircleOutline",
        "IoArrowUndoOutline",
        "IoArrowUpCircleOutline",
        "IoArrowUpOutline",
        "IoAtCircleOutline",
        "IoAtOutline",
        "IoAttachOutline",
        "IoBackspaceOutline",
        "IoBandageOutline",
        "IoBarChartOutline",
        "IoBarbellOutline",
        "IoBarcodeOutline",
        "IoBaseballOutline",
        "IoBasketOutline",
        "IoBasketballOutline",
        "IoBatteryChargingOutline",
        "IoBatteryDeadOutline",
        "IoBatteryFullOutline",
        "IoBatteryHalfOutline",
        "IoBeakerOutline",
        "IoBedOutline",
        "IoBeerOutline",
        "IoBicycleOutline",
        "IoBluetoothOutline",
        "IoBoatOutline",
        "IoBodyOutline",
        "IoBonfireOutline",
        "IoBookOutline",
        "IoBookmarkOutline",
        "IoBookmarksOutline",
        "IoBriefcaseOutline",
        "IoBrowsersOutline",
        "IoBrushOutline",
        "IoBugOutline",
        "IoBuildOutline",
        "IoBulbOutline",
        "IoBusOutline",
        "IoBusinessOutline",
        "IoCafeOutline",
        "IoCalculatorOutline",
        "IoCalendarOutline",
        "IoCallOutline",
        "IoCameraOutline",
        "IoCameraReverseOutline",
        "IoCarOutline",
        "IoCarSportOutline",
        "IoCardOutline",
        "IoCaretBackCircleOutline",
        "IoCaretBackOutline",
        "IoCaretDownCircleOutline",
        "IoCaretDownOutline",
        "IoCaretForwardCircleOutline",
        "IoCaretForwardOutline",
        "IoCaretUpCircleOutline",
        "IoCaretUpOutline",
        "IoCartOutline",
        "IoCashOutline",
        "IoCellularOutline",
        "IoChatboxEllipsesOutline",
        "IoChatboxOutline",
        "IoChatbubbleEllipsesOutline",
        "IoChatbubbleOutline",
        "IoChatbubblesOutline",
        "IoCheckboxOutline",
        "IoCheckmarkCircleOutline",
        "IoCheckmarkDoneCircleOutline",
        "IoCheckmarkDoneOutline",
        "IoCheckmarkOutline",
        "IoChevronBackCircleOutline",
        "IoChevronBackOutline",
        "IoChevronDownCircleOutline",
        "IoChevronDownOutline",
        "IoChevronForwardCircleOutline",
        "IoChevronForwardOutline",
        "IoChevronUpCircleOutline",
        "IoChevronUpOutline",
        "IoClipboardOutline",
        "IoCloseCircleOutline",
        "IoCloseOutline",
        "IoCloudCircleOutline",
        "IoCloudDoneOutline",
        "IoCloudDownloadOutline",
        "IoCloudOfflineOutline",
        "IoCloudOutline",
        "IoCloudUploadOutline",
        "IoCloudyNightOutline",
        "IoCloudyOutline",
        "IoCodeDownloadOutline",
        "IoCodeOutline",
        "IoCodeSlashOutline",
        "IoCodeWorkingOutline",
        "IoCogOutline",
        "IoColorFillOutline",
        "IoColorFilterOutline",
        "IoColorPaletteOutline",
        "IoColorWandOutline",
        "IoCompassOutline",
        "IoConstructOutline",
        "IoContractOutline",
        "IoContrastOutline",
        "IoCopyOutline",
        "IoCreateOutline",
        "IoCropOutline",
        "IoCubeOutline",
        "IoCutOutline",
        "IoDesktopOutline",
        "IoDiscOutline",
        "IoDocumentAttachOutline",
        "IoDocumentOutline",
        "IoDocumentTextOutline",
        "IoDocumentsOutline",
        "IoDownloadOutline",
        "IoDuplicateOutline",
        "IoEarOutline",
        "IoEarthOutline",
        "IoEaselOutline",
        "IoEggOutline",
        "IoEllipseOutline",
        "IoEllipsisHorizontalCircleOutline",
        "IoEllipsisHorizontalOutline",
        "IoEllipsisVerticalCircleOutline",
        "IoEllipsisVerticalOutline",
        "IoEnterOutline",
        "IoExitOutline",
        "IoExpandOutline",
        "IoEyeOffOutline",
        "IoEyeOutline",
        "IoEyedropOutline",
        "IoFastFoodOutline",
        "IoFemaleOutline",
        "IoFileTrayFullOutline",
        "IoFileTrayOutline",
        "IoFileTrayStackedOutline",
        "IoFilmOutline",
        "IoFilterOutline",
        "IoFingerPrintOutline",
        "IoFitnessOutline",
        "IoFlagOutline",
        "IoFlameOutline",
        "IoFlashOffOutline",
        "IoFlashOutline",
        "IoFlashlightOutline",
        "IoFlaskOutline",
        "IoFlowerOutline",
        "IoFolderOpenOutline",
        "IoFolderOutline",
        "IoFootballOutline",
        "IoFunnelOutline",
        "IoGameControllerOutline",
        "IoGiftOutline",
        "IoGitBranchOutline",
        "IoGitCommitOutline",
        "IoGitCompareOutline",
        "IoGitMergeOutline",
        "IoGitNetworkOutline",
        "IoGitPullRequestOutline",
        "IoGlassesOutline",
        "IoGlobeOutline",
        "IoGolfOutline",
        "IoGridOutline",
        "IoHammerOutline",
        "IoHandLeftOutline",
        "IoHandRightOutline",
        "IoHappyOutline",
        "IoHardwareChipOutline",
        "IoHeadsetOutline",
        "IoHeartCircleOutline",
        "IoHeartDislikeCircleOutline",
        "IoHeartDislikeOutline",
        "IoHeartHalfOutline",
        "IoHeartOutline",
        "IoHelpBuoyOutline",
        "IoHelpCircleOutline",
        "IoHelpOutline",
        "IoHomeOutline",
        "IoHourglassOutline",
        "IoIceCreamOutline",
        "IoImageOutline",
        "IoImagesOutline",
        "IoInfiniteOutline",
        "IoInformationCircleOutline",
        "IoInformationOutline",
        "IoJournalOutline",
        "IoKeyOutline",
        "IoKeypadOutline",
        "IoLanguageOutline",
        "IoLaptopOutline",
        "IoLayersOutline",
        "IoLeafOutline",
        "IoLibraryOutline",
        "IoLinkOutline",
        "IoListCircleOutline",
        "IoListOutline",
        "IoLocateOutline",
        "IoLocationOutline",
        "IoLockClosedOutline",
        "IoLockOpenOutline",
        "IoLogInOutline",
        "IoLogOutOutline",
        "IoMagnetOutline",
        "IoMailOpenOutline",
        "IoMailOutline",
        "IoMailUnreadOutline",
        "IoMaleFemaleOutline",
        "IoMaleOutline",
        "IoManOutline",
        "IoMapOutline",
        "IoMedalOutline",
        "IoMedicalOutline",
        "IoMedkitOutline",
        "IoMegaphoneOutline",
        "IoMenuOutline",
        "IoMicCircleOutline",
        "IoMicOffCircleOutline",
        "IoMicOffOutline",
        "IoMicOutline",
        "IoMoonOutline",
        "IoMoveOutline",
        "IoMusicalNoteOutline",
        "IoMusicalNotesOutline",
        "IoNavigateCircleOutline",
        "IoNavigateOutline",
        "IoNewspaperOutline",
        "IoNotificationsCircleOutline",
        "IoNotificationsOffCircleOutline",
        "IoNotificationsOffOutline",
        "IoNotificationsOutline",
        "IoNuclearOutline",
        "IoNutritionOutline",
        "IoOpenOutline",
        "IoOptionsOutline",
        "IoPaperPlaneOutline",
        "IoPartlySunnyOutline",
        "IoPauseCircleOutline",
        "IoPauseOutline",
        "IoPawOutline",
        "IoPencilOutline",
        "IoPeopleCircleOutline",
        "IoPeopleOutline",
        "IoPersonAddOutline",
        "IoPersonCircleOutline",
        "IoPersonOutline",
        "IoPersonRemoveOutline",
        "IoPhoneLandscapeOutline",
        "IoPhonePortraitOutline",
        "IoPieChartOutline",
        "IoPinOutline",
        "IoPintOutline",
        "IoPizzaOutline",
        "IoPlanetOutline",
        "IoPlayBackCircleOutline",
        "IoPlayBackOutline",
        "IoPlayCircleOutline",
        "IoPlayForwardCircleOutline",
        "IoPlayForwardOutline",
        "IoPlayOutline",
        "IoPlaySkipBackCircleOutline",
        "IoPlaySkipBackOutline",
        "IoPlaySkipForwardCircleOutline",
        "IoPlaySkipForwardOutline",
        "IoPodiumOutline",
        "IoPowerOutline",
        "IoPricetagOutline",
        "IoPricetagsOutline",
        "IoPrintOutline",
        "IoPulseOutline",
        "IoPushOutline",
        "IoQrCodeOutline",
        "IoRadioButtonOffOutline",
        "IoRadioButtonOnOutline",
        "IoRadioOutline",
        "IoRainyOutline",
        "IoReaderOutline",
        "IoReceiptOutline",
        "IoRecordingOutline",
        "IoRefreshCircleOutline",
        "IoRefreshOutline",
        "IoReloadCircleOutline",
        "IoReloadOutline",
        "IoRemoveCircleOutline",
        "IoRemoveOutline",
        "IoReorderFourOutline",
        "IoReorderThreeOutline",
        "IoReorderTwoOutline",
        "IoRepeatOutline",
        "IoResizeOutline",
        "IoRestaurantOutline",
        "IoReturnDownBackOutline",
        "IoReturnDownForwardOutline",
        "IoReturnUpBackOutline",
        "IoReturnUpForwardOutline",
        "IoRibbonOutline",
        "IoRocketOutline",
        "IoRoseOutline",
        "IoSadOutline",
        "IoSaveOutline",
        "IoScanCircleOutline",
        "IoScanOutline",
        "IoSchoolOutline",
        "IoSearchCircleOutline",
        "IoSearchOutline",
        "IoSendOutline",
        "IoServerOutline",
        "IoSettingsOutline",
        "IoShapesOutline",
        "IoShareOutline",
        "IoShareSocialOutline",
        "IoShieldCheckmarkOutline",
        "IoShieldOutline",
        "IoShirtOutline",
        "IoShuffleOutline",
        "IoSkullOutline",
        "IoSnowOutline",
        "IoSpeedometerOutline",
        "IoSquareOutline",
        "IoStarHalfOutline",
        "IoStarOutline",
        "IoStatsChartOutline",
        "IoStopCircleOutline",
        "IoStopOutline",
        "IoStopwatchOutline",
        "IoSubwayOutline",
        "IoSunnyOutline",
        "IoSwapHorizontalOutline",
        "IoSwapVerticalOutline",
        "IoSyncCircleOutline",
        "IoSyncOutline",
        "IoTabletLandscapeOutline",
        "IoTabletPortraitOutline",
        "IoTennisballOutline",
        "IoTerminalOutline",
        "IoTextOutline",
        "IoThermometerOutline",
        "IoThumbsDownOutline",
        "IoThumbsUpOutline",
        "IoThunderstormOutline",
        "IoTimeOutline",
        "IoTimerOutline",
        "IoTodayOutline",
        "IoToggleOutline",
        "IoTrailSignOutline",
        "IoTrainOutline",
        "IoTransgenderOutline",
        "IoTrashBinOutline",
        "IoTrashOutline",
        "IoTrendingDownOutline",
        "IoTrendingUpOutline",
        "IoTriangleOutline",
        "IoTrophyOutline",
        "IoTvOutline",
        "IoUmbrellaOutline",
        "IoVideocamOutline",
        "IoVolumeHighOutline",
        "IoVolumeLowOutline",
        "IoVolumeMediumOutline",
        "IoVolumeMuteOutline",
        "IoVolumeOffOutline",
        "IoWalkOutline",
        "IoWalletOutline",
        "IoWarningOutline",
        "IoWatchOutline",
        "IoWaterOutline",
        "IoWifiOutline",
        "IoWineOutline",
        "IoWomanOutline",
        "IoIosAddCircleOutline",
        "IoIosAddOutline",
        "IoIosAirplaneOutline",
        "IoIosAlarmOutline",
        "IoIosAlbumsOutline",
        "IoIosAlertCircleOutline",
        "IoIosAlertOutline",
        "IoIosAmericanFootballOutline",
        "IoIosAnalyticsOutline",
        "IoIosApertureOutline",
        "IoIosAppsOutline",
        "IoIosArchiveOutline",
        "IoIosArrowBackCircleOutline",
        "IoIosArrowBackOutline",
        "IoIosArrowDownCircleOutline",
        "IoIosArrowDownOutline",
        "IoIosArrowForwardCircleOutline",
        "IoIosArrowForwardOutline",
        "IoIosArrowRedoCircleOutline",
        "IoIosArrowRedoOutline",
        "IoIosArrowUndoCircleOutline",
        "IoIosArrowUndoOutline",
        "IoIosArrowUpCircleOutline",
        "IoIosArrowUpOutline",
        "IoIosAtCircleOutline",
        "IoIosAtOutline",
        "IoIosAttachOutline",
        "IoIosBackspaceOutline",
        "IoIosBandageOutline",
        "IoIosBarChartOutline",
        "IoIosBarbellOutline",
        "IoIosBarcodeOutline",
        "IoIosBaseballOutline",
        "IoIosBasketOutline",
        "IoIosBasketballOutline",
        "IoIosBatteryChargingOutline",
        "IoIosBatteryDeadOutline",
        "IoIosBatteryFullOutline",
        "IoIosBatteryHalfOutline",
        "IoIosBeakerOutline",
        "IoIosBedOutline",
        "IoIosBeerOutline",
        "IoIosBicycleOutline",
        "IoIosBluetoothOutline",
        "IoIosBoatOutline",
        "IoIosBodyOutline",
        "IoIosBonfireOutline",
        "IoIosBookOutline",
        "IoIosBookmarkOutline",
        "IoIosBookmarksOutline",
        "IoIosBriefcaseOutline",
        "IoIosBrowsersOutline",
        "IoIosBrushOutline",
        "IoIosBugOutline",
        "IoIosBuildOutline",
        "IoIosBulbOutline",
        "IoIosBusOutline",
        "IoIosBusinessOutline",
        "IoIosCafeOutline",
        "IoIosCalculatorOutline",
        "IoIosCalendarOutline",
        "IoIosCallOutline",
        "IoIosCameraOutline",
        "IoIosCameraReverseOutline",
        "IoIosCarOutline",
        "IoIosCarSportOutline",
        "IoIosCardOutline",
        "IoIosCaretBackCircleOutline",
        "IoIosCaretBackOutline",
        "IoIosCaretDownCircleOutline",
        "IoIosCaretDownOutline",
        "IoIosCaretForwardCircleOutline",
        "IoIosCaretForwardOutline",
        "IoIosCaretUpCircleOutline",
        "IoIosCaretUpOutline",
        "IoIosCartOutline",
        "IoIosCashOutline",
        "IoIosCellularOutline",
        "IoIosChatboxEllipsesOutline",
        "IoIosChatboxOutline",
        "IoIosChatbubbleEllipsesOutline",
        "IoIosChatbubbleOutline",
        "IoIosChatbubblesOutline",
        "IoIosCheckboxOutline",
        "IoIosCheckmarkCircleOutline",
        "IoIosCheckmarkDoneCircleOutline",
        "IoIosCheckmarkDoneOutline",
        "IoIosCheckmarkOutline",
        "IoIosChevronBackCircleOutline",
        "IoIosChevronBackOutline",
        "IoIosChevronDownCircleOutline",
        "IoIosChevronDownOutline",
        "IoIosChevronForwardCircleOutline",
        "IoIosChevronForwardOutline",
        "IoIosChevronUpCircleOutline",
        "IoIosChevronUpOutline",
        "IoIosClipboardOutline",
        "IoIosCloseCircleOutline",
        "IoIosCloseOutline",
        "IoIosCloudCircleOutline",
        "IoIosCloudDoneOutline",
        "IoIosCloudDownloadOutline",
        "IoIosCloudOfflineOutline",
        "IoIosCloudOutline",
        "IoIosCloudUploadOutline",
        "IoIosCloudyNightOutline",
        "IoIosCloudyOutline",
        "IoIosCodeDownloadOutline",
        "IoIosCodeOutline",
        "IoIosCodeSlashOutline",
        "IoIosCodeWorkingOutline",
        "IoIosCogOutline",
        "IoIosColorFillOutline",
        "IoIosColorFilterOutline",
        "IoIosColorPaletteOutline",
        "IoIosColorWandOutline",
        "IoIosCompassOutline",
        "IoIosConstructOutline",
        "IoIosContractOutline",
        "IoIosContrastOutline",
        "IoIosCopyOutline",
        "IoIosCreateOutline",
        "IoIosCropOutline",
        "IoIosCubeOutline",
        "IoIosCutOutline",
        "IoIosDesktopOutline",
        "IoIosDiscOutline",
        "IoIosDocumentAttachOutline",
        "IoIosDocumentOutline",
        "IoIosDocumentTextOutline",
        "IoIosDocumentsOutline",
        "IoIosDownloadOutline",
        "IoIosDuplicateOutline",
        "IoIosEarOutline",
        "IoIosEarthOutline",
        "IoIosEaselOutline",
        "IoIosEggOutline",
        "IoIosEllipseOutline",
        "IoIosEllipsisHorizontalCircleOutline",
        "IoIosEllipsisHorizontalOutline",
        "IoIosEllipsisVerticalCircleOutline",
        "IoIosEllipsisVerticalOutline",
        "IoIosEnterOutline",
        "IoIosExitOutline",
        "IoIosExpandOutline",
        "IoIosEyeOffOutline",
        "IoIosEyeOutline",
        "IoIosEyedropOutline",
        "IoIosFastFoodOutline",
        "IoIosFemaleOutline",
        "IoIosFileTrayFullOutline",
        "IoIosFileTrayOutline",
        "IoIosFileTrayStackedOutline",
        "IoIosFilmOutline",
        "IoIosFilterOutline",
        "IoIosFingerPrintOutline",
        "IoIosFitnessOutline",
        "IoIosFlagOutline",
        "IoIosFlameOutline",
        "IoIosFlashOffOutline",
        "IoIosFlashOutline",
        "IoIosFlashlightOutline",
        "IoIosFlaskOutline",
        "IoIosFlowerOutline",
        "IoIosFolderOpenOutline",
        "IoIosFolderOutline",
        "IoIosFootballOutline",
        "IoIosFunnelOutline",
        "IoIosGameControllerOutline",
        "IoIosGiftOutline",
        "IoIosGitBranchOutline",
        "IoIosGitCommitOutline",
        "IoIosGitCompareOutline",
        "IoIosGitMergeOutline",
        "IoIosGitNetworkOutline",
        "IoIosGitPullRequestOutline",
        "IoIosGlassesOutline",
        "IoIosGlobeOutline",
        "IoIosGolfOutline",
        "IoIosGridOutline",
        "IoIosHammerOutline",
        "IoIosHandLeftOutline",
        "IoIosHandRightOutline",
        "IoIosHappyOutline",
        "IoIosHardwareChipOutline",
        "IoIosHeadsetOutline",
        "IoIosHeartCircleOutline",
        "IoIosHeartDislikeCircleOutline",
        "IoIosHeartDislikeOutline",
        "IoIosHeartHalfOutline",
        "IoIosHeartOutline",
        "IoIosHelpBuoyOutline",
        "IoIosHelpCircleOutline",
        "IoIosHelpOutline",
        "IoIosHomeOutline",
        "IoIosHourglassOutline",
        "IoIosIceCreamOutline",
        "IoIosImageOutline",
        "IoIosImagesOutline",
        "IoIosInfiniteOutline",
        "IoIosInformationCircleOutline",
        "IoIosInformationOutline",
        "IoIosJournalOutline",
        "IoIosKeyOutline",
        "IoIosKeypadOutline",
        "IoIosLanguageOutline",
        "IoIosLaptopOutline",
        "IoIosLayersOutline",
        "IoIosLeafOutline",
        "IoIosLibraryOutline",
        "IoIosLinkOutline",
        "IoIosListCircleOutline",
        "IoIosListOutline",
        "IoIosLocateOutline",
        "IoIosLocationOutline",
        "IoIosLockClosedOutline",
        "IoIosLockOpenOutline",
        "IoIosLogInOutline",
        "IoIosLogOutOutline",
        "IoIosMagnetOutline",
        "IoIosMailOpenOutline",
        "IoIosMailOutline",
        "IoIosMailUnreadOutline",
        "IoIosMaleFemaleOutline",
        "IoIosMaleOutline",
        "IoIosManOutline",
        "IoIosMapOutline",
        "IoIosMedalOutline",
        "IoIosMedicalOutline",
        "IoIosMedkitOutline",
        "IoIosMegaphoneOutline",
        "IoIosMenuOutline",
        "IoIosMicCircleOutline",
        "IoIosMicOffCircleOutline",
        "IoIosMicOffOutline",
        "IoIosMicOutline",
        "IoIosMoonOutline",
        "IoIosMoveOutline",
        "IoIosMusicalNoteOutline",
        "IoIosMusicalNotesOutline",
        "IoIosNavigateCircleOutline",
        "IoIosNavigateOutline",
        "IoIosNewspaperOutline",
        "IoIosNotificationsCircleOutline",
        "IoIosNotificationsOffCircleOutline",
        "IoIosNotificationsOffOutline",
        "IoIosNotificationsOutline",
        "IoIosNuclearOutline",
        "IoIosNutritionOutline",
        "IoIosOpenOutline",
        "IoIosOptionsOutline",
        "IoIosPaperPlaneOutline",
        "IoIosPartlySunnyOutline",
        "IoIosPauseCircleOutline",
        "IoIosPauseOutline",
        "IoIosPawOutline",
        "IoIosPencilOutline",
        "IoIosPeopleCircleOutline",
        "IoIosPeopleOutline",
        "IoIosPersonAddOutline",
        "IoIosPersonCircleOutline",
        "IoIosPersonOutline",
        "IoIosPersonRemoveOutline",
        "IoIosPhoneLandscapeOutline",
        "IoIosPhonePortraitOutline",
        "IoIosPieChartOutline",
        "IoIosPinOutline",
        "IoIosPintOutline",
        "IoIosPizzaOutline",
        "IoIosPlanetOutline",
        "IoIosPlayBackCircleOutline",
        "IoIosPlayBackOutline",
        "IoIosPlayCircleOutline",
        "IoIosPlayForwardCircleOutline",
        "IoIosPlayForwardOutline",
        "IoIosPlayOutline",
        "IoIosPlaySkipBackCircleOutline",
        "IoIosPlaySkipBackOutline",
        "IoIosPlaySkipForwardCircleOutline",
        "IoIosPlaySkipForwardOutline",
        "IoIosPodiumOutline",
        "IoIosPowerOutline",
        "IoIosPricetagOutline",
        "IoIosPricetagsOutline",
        "IoIosPrintOutline",
        "IoIosPulseOutline",
        "IoIosPushOutline",
        "IoIosQrCodeOutline",
        "IoIosRadioButtonOffOutline",
        "IoIosRadioButtonOnOutline",
        "IoIosRadioOutline",
        "IoIosRainyOutline",
        "IoIosReaderOutline",
        "IoIosReceiptOutline",
        "IoIosRecordingOutline",
        "IoIosRefreshCircleOutline",
        "IoIosRefreshOutline",
        "IoIosReloadCircleOutline",
        "IoIosReloadOutline",
        "IoIosRemoveCircleOutline",
        "IoIosRemoveOutline",
        "IoIosReorderFourOutline",
        "IoIosReorderThreeOutline",
        "IoIosReorderTwoOutline",
        "IoIosRepeatOutline",
        "IoIosResizeOutline",
        "IoIosRestaurantOutline",
        "IoIosReturnDownBackOutline",
        "IoIosReturnDownForwardOutline",
        "IoIosReturnUpBackOutline",
        "IoIosReturnUpForwardOutline",
        "IoIosRibbonOutline",
        "IoIosRocketOutline",
        "IoIosRoseOutline",
        "IoIosSadOutline",
        "IoIosSaveOutline",
        "IoIosScanCircleOutline",
        "IoIosScanOutline",
        "IoIosSchoolOutline",
        "IoIosSearchCircleOutline",
        "IoIosSearchOutline",
        "IoIosSendOutline",
        "IoIosServerOutline",
        "IoIosSettingsOutline",
        "IoIosShapesOutline",
        "IoIosShareOutline",
        "IoIosShareSocialOutline",
        "IoIosShieldCheckmarkOutline",
        "IoIosShieldOutline",
        "IoIosShirtOutline",
        "IoIosShuffleOutline",
        "IoIosSkullOutline",
        "IoIosSnowOutline",
        "IoIosSpeedometerOutline",
        "IoIosSquareOutline",
        "IoIosStarHalfOutline",
        "IoIosStarOutline",
        "IoIosStatsChartOutline",
        "IoIosStopCircleOutline",
        "IoIosStopOutline",
        "IoIosStopwatchOutline",
        "IoIosSubwayOutline",
        "IoIosSunnyOutline",
        "IoIosSwapHorizontalOutline",
        "IoIosSwapVerticalOutline",
        "IoIosSyncCircleOutline",
        "IoIosSyncOutline",
        "IoIosTabletLandscapeOutline",
        "IoIosTabletPortraitOutline",
        "IoIosTennisballOutline",
        "IoIosTerminalOutline",
        "IoIosTextOutline",
        "IoIosThermometerOutline",
        "IoIosThumbsDownOutline",
        "IoIosThumbsUpOutline",
        "IoIosThunderstormOutline",
        "IoIosTimeOutline",
        "IoIosTimerOutline",
        "IoIosTodayOutline",
        "IoIosToggleOutline",
        "IoIosTrailSignOutline",
        "IoIosTrainOutline",
        "IoIosTransgenderOutline",
        "IoIosTrashBinOutline",
        "IoIosTrashOutline",
        "IoIosTrendingDownOutline",
        "IoIosTrendingUpOutline",
        "IoIosTriangleOutline",
        "IoIosTrophyOutline",
        "IoIosTvOutline",
        "IoIosUmbrellaOutline",
        "IoIosVideocamOutline",
        "IoIosVolumeHighOutline",
        "IoIosVolumeLowOutline",
        "IoIosVolumeMediumOutline",
        "IoIosVolumeMuteOutline",
        "IoIosVolumeOffOutline",
        "IoIosWalkOutline",
        "IoIosWalletOutline",
        "IoIosWarningOutline",
        "IoIosWatchOutline",
        "IoIosWaterOutline",
        "IoIosWifiOutline",
        "IoIosWineOutline",
        "IoIosWomanOutline",
        "IoMdAddCircleOutline",
        "IoMdAddOutline",
        "IoMdAirplaneOutline",
        "IoMdAlarmOutline",
        "IoMdAlbumsOutline",
        "IoMdAlertCircleOutline",
        "IoMdAlertOutline",
        "IoMdAmericanFootballOutline",
        "IoMdAnalyticsOutline",
        "IoMdApertureOutline",
        "IoMdAppsOutline",
        "IoMdArchiveOutline",
        "IoMdArrowBackCircleOutline",
        "IoMdArrowBackOutline",
        "IoMdArrowDownCircleOutline",
        "IoMdArrowDownOutline",
        "IoMdArrowForwardCircleOutline",
        "IoMdArrowForwardOutline",
        "IoMdArrowRedoCircleOutline",
        "IoMdArrowRedoOutline",
        "IoMdArrowUndoCircleOutline",
        "IoMdArrowUndoOutline",
        "IoMdArrowUpCircleOutline",
        "IoMdArrowUpOutline",
        "IoMdAtCircleOutline",
        "IoMdAtOutline",
        "IoMdAttachOutline",
        "IoMdBackspaceOutline",
        "IoMdBandageOutline",
        "IoMdBarChartOutline",
        "IoMdBarbellOutline",
        "IoMdBarcodeOutline",
        "IoMdBaseballOutline",
        "IoMdBasketOutline",
        "IoMdBasketballOutline",
        "IoMdBatteryChargingOutline",
        "IoMdBatteryDeadOutline",
        "IoMdBatteryFullOutline",
        "IoMdBatteryHalfOutline",
        "IoMdBeakerOutline",
        "IoMdBedOutline",
        "IoMdBeerOutline",
        "IoMdBicycleOutline",
        "IoMdBluetoothOutline",
        "IoMdBoatOutline",
        "IoMdBodyOutline",
        "IoMdBonfireOutline",
        "IoMdBookOutline",
        "IoMdBookmarkOutline",
        "IoMdBookmarksOutline",
        "IoMdBriefcaseOutline",
        "IoMdBrowsersOutline",
        "IoMdBrushOutline",
        "IoMdBugOutline",
        "IoMdBuildOutline",
        "IoMdBulbOutline",
        "IoMdBusOutline",
        "IoMdBusinessOutline",
        "IoMdCafeOutline",
        "IoMdCalculatorOutline",
        "IoMdCalendarOutline",
        "IoMdCallOutline",
        "IoMdCameraOutline",
        "IoMdCameraReverseOutline",
        "IoMdCarOutline",
        "IoMdCarSportOutline",
        "IoMdCardOutline",
        "IoMdCaretBackCircleOutline",
        "IoMdCaretBackOutline",
        "IoMdCaretDownCircleOutline",
        "IoMdCaretDownOutline",
        "IoMdCaretForwardCircleOutline",
        "IoMdCaretForwardOutline",
        "IoMdCaretUpCircleOutline",
        "IoMdCaretUpOutline",
        "IoMdCartOutline",
        "IoMdCashOutline",
        "IoMdCellularOutline",
        "IoMdChatboxEllipsesOutline",
        "IoMdChatboxOutline",
        "IoMdChatbubbleEllipsesOutline",
        "IoMdChatbubbleOutline",
        "IoMdChatbubblesOutline",
        "IoMdCheckboxOutline",
        "IoMdCheckmarkCircleOutline",
        "IoMdCheckmarkDoneCircleOutline",
        "IoMdCheckmarkDoneOutline",
        "IoMdCheckmarkOutline",
        "IoMdChevronBackCircleOutline",
        "IoMdChevronBackOutline",
        "IoMdChevronDownCircleOutline",
        "IoMdChevronDownOutline",
        "IoMdChevronForwardCircleOutline",
        "IoMdChevronForwardOutline",
        "IoMdChevronUpCircleOutline",
        "IoMdChevronUpOutline",
        "IoMdClipboardOutline",
        "IoMdCloseCircleOutline",
        "IoMdCloseOutline",
        "IoMdCloudCircleOutline",
        "IoMdCloudDoneOutline",
        "IoMdCloudDownloadOutline",
        "IoMdCloudOfflineOutline",
        "IoMdCloudOutline",
        "IoMdCloudUploadOutline",
        "IoMdCloudyNightOutline",
        "IoMdCloudyOutline",
        "IoMdCodeDownloadOutline",
        "IoMdCodeOutline",
        "IoMdCodeSlashOutline",
        "IoMdCodeWorkingOutline",
        "IoMdCogOutline",
        "IoMdColorFillOutline",
        "IoMdColorFilterOutline",
        "IoMdColorPaletteOutline",
        "IoMdColorWandOutline",
        "IoMdCompassOutline",
        "IoMdConstructOutline",
        "IoMdContractOutline",
        "IoMdContrastOutline",
        "IoMdCopyOutline",
        "IoMdCreateOutline",
        "IoMdCropOutline",
        "IoMdCubeOutline",
        "IoMdCutOutline",
        "IoMdDesktopOutline",
        "IoMdDiscOutline",
        "IoMdDocumentAttachOutline",
        "IoMdDocumentOutline",
        "IoMdDocumentTextOutline",
        "IoMdDocumentsOutline",
        "IoMdDownloadOutline",
        "IoMdDuplicateOutline",
        "IoMdEarOutline",
        "IoMdEarthOutline",
        "IoMdEaselOutline",
        "IoMdEggOutline",
        "IoMdEllipseOutline",
        "IoMdEllipsisHorizontalCircleOutline",
        "IoMdEllipsisHorizontalOutline",
        "IoMdEllipsisVerticalCircleOutline",
        "IoMdEllipsisVerticalOutline",
        "IoMdEnterOutline",
        "IoMdExitOutline",
        "IoMdExpandOutline",
        "IoMdEyeOffOutline",
        "IoMdEyeOutline",
        "IoMdEyedropOutline",
        "IoMdFastFoodOutline",
        "IoMdFemaleOutline",
        "IoMdFileTrayFullOutline",
        "IoMdFileTrayOutline",
        "IoMdFileTrayStackedOutline",
        "IoMdFilmOutline",
        "IoMdFilterOutline",
        "IoMdFingerPrintOutline",
        "IoMdFitnessOutline",
        "IoMdFlagOutline",
        "IoMdFlameOutline",
        "IoMdFlashOffOutline",
        "IoMdFlashOutline",
        "IoMdFlashlightOutline",
        "IoMdFlaskOutline",
        "IoMdFlowerOutline",
        "IoMdFolderOpenOutline",
        "IoMdFolderOutline",
        "IoMdFootballOutline",
        "IoMdFunnelOutline",
        "IoMdGameControllerOutline",
        "IoMdGiftOutline",
        "IoMdGitBranchOutline",
        "IoMdGitCommitOutline",
        "IoMdGitCompareOutline",
        "IoMdGitMergeOutline",
        "IoMdGitNetworkOutline",
        "IoMdGitPullRequestOutline",
        "IoMdGlassesOutline",
        "IoMdGlobeOutline",
        "IoMdGolfOutline",
        "IoMdGridOutline",
        "IoMdHammerOutline",
        "IoMdHandLeftOutline",
        "IoMdHandRightOutline",
        "IoMdHappyOutline",
        "IoMdHardwareChipOutline",
        "IoMdHeadsetOutline",
        "IoMdHeartCircleOutline",
        "IoMdHeartDislikeCircleOutline",
        "IoMdHeartDislikeOutline",
        "IoMdHeartHalfOutline",
        "IoMdHeartOutline",
        "IoMdHelpBuoyOutline",
        "IoMdHelpCircleOutline",
        "IoMdHelpOutline",
        "IoMdHomeOutline",
        "IoMdHourglassOutline",
        "IoMdIceCreamOutline",
        "IoMdImageOutline",
        "IoMdImagesOutline",
        "IoMdInfiniteOutline",
        "IoMdInformationCircleOutline",
        "IoMdInformationOutline",
        "IoMdJournalOutline",
        "IoMdKeyOutline",
        "IoMdKeypadOutline",
        "IoMdLanguageOutline",
        "IoMdLaptopOutline",
        "IoMdLayersOutline",
        "IoMdLeafOutline",
        "IoMdLibraryOutline",
        "IoMdLinkOutline",
        "IoMdListCircleOutline",
        "IoMdListOutline",
        "IoMdLocateOutline",
        "IoMdLocationOutline",
        "IoMdLockClosedOutline",
        "IoMdLockOpenOutline",
        "IoMdLogInOutline",
        "IoMdLogOutOutline",
        "IoMdMagnetOutline",
        "IoMdMailOpenOutline",
        "IoMdMailOutline",
        "IoMdMailUnreadOutline",
        "IoMdMaleFemaleOutline",
        "IoMdMaleOutline",
        "IoMdManOutline",
        "IoMdMapOutline",
        "IoMdMedalOutline",
        "IoMdMedicalOutline",
        "IoMdMedkitOutline",
        "IoMdMegaphoneOutline",
        "IoMdMenuOutline",
        "IoMdMicCircleOutline",
        "IoMdMicOffCircleOutline",
        "IoMdMicOffOutline",
        "IoMdMicOutline",
        "IoMdMoonOutline",
        "IoMdMoveOutline",
        "IoMdMusicalNoteOutline",
        "IoMdMusicalNotesOutline",
        "IoMdNavigateCircleOutline",
        "IoMdNavigateOutline",
        "IoMdNewspaperOutline",
        "IoMdNotificationsCircleOutline",
        "IoMdNotificationsOffCircleOutline",
        "IoMdNotificationsOffOutline",
        "IoMdNotificationsOutline",
        "IoMdNuclearOutline",
        "IoMdNutritionOutline",
        "IoMdOpenOutline",
        "IoMdOptionsOutline",
        "IoMdPaperPlaneOutline",
        "IoMdPartlySunnyOutline",
        "IoMdPauseCircleOutline",
        "IoMdPauseOutline",
        "IoMdPawOutline",
        "IoMdPencilOutline",
        "IoMdPeopleCircleOutline",
        "IoMdPeopleOutline",
        "IoMdPersonAddOutline",
        "IoMdPersonCircleOutline",
        "IoMdPersonOutline",
        "IoMdPersonRemoveOutline",
        "IoMdPhoneLandscapeOutline",
        "IoMdPhonePortraitOutline",
        "IoMdPieChartOutline",
        "IoMdPinOutline",
        "IoMdPintOutline",
        "IoMdPizzaOutline",
        "IoMdPlanetOutline",
        "IoMdPlayBackCircleOutline",
        "IoMdPlayBackOutline",
        "IoMdPlayCircleOutline",
        "IoMdPlayForwardCircleOutline",
        "IoMdPlayForwardOutline",
        "IoMdPlayOutline",
        "IoMdPlaySkipBackCircleOutline",
        "IoMdPlaySkipBackOutline",
        "IoMdPlaySkipForwardCircleOutline",
        "IoMdPlaySkipForwardOutline",
        "IoMdPodiumOutline",
        "IoMdPowerOutline",
        "IoMdPricetagOutline",
        "IoMdPricetagsOutline",
        "IoMdPrintOutline",
        "IoMdPulseOutline",
        "IoMdPushOutline",
        "IoMdQrCodeOutline",
        "IoMdRadioButtonOffOutline",
        "IoMdRadioButtonOnOutline",
        "IoMdRadioOutline",
        "IoMdRainyOutline",
        "IoMdReaderOutline",
        "IoMdReceiptOutline",
        "IoMdRecordingOutline",
        "IoMdRefreshCircleOutline",
        "IoMdRefreshOutline",
        "IoMdReloadCircleOutline",
        "IoMdReloadOutline",
        "IoMdRemoveCircleOutline",
        "IoMdRemoveOutline",
        "IoMdReorderFourOutline",
        "IoMdReorderThreeOutline",
        "IoMdReorderTwoOutline",
        "IoMdRepeatOutline",
        "IoMdResizeOutline",
        "IoMdRestaurantOutline",
        "IoMdReturnDownBackOutline",
        "IoMdReturnDownForwardOutline",
        "IoMdReturnUpBackOutline",
        "IoMdReturnUpForwardOutline",
        "IoMdRibbonOutline",
        "IoMdRocketOutline",
        "IoMdRoseOutline",
        "IoMdSadOutline",
        "IoMdSaveOutline",
        "IoMdScanCircleOutline",
        "IoMdScanOutline",
        "IoMdSchoolOutline",
        "IoMdSearchCircleOutline",
        "IoMdSearchOutline",
        "IoMdSendOutline",
        "IoMdServerOutline",
        "IoMdSettingsOutline",
        "IoMdShapesOutline",
        "IoMdShareOutline",
        "IoMdShareSocialOutline",
        "IoMdShieldCheckmarkOutline",
        "IoMdShieldOutline",
        "IoMdShirtOutline",
        "IoMdShuffleOutline",
        "IoMdSkullOutline",
        "IoMdSnowOutline",
        "IoMdSpeedometerOutline",
        "IoMdSquareOutline",
        "IoMdStarHalfOutline",
        "IoMdStarOutline",
        "IoMdStatsChartOutline",
        "IoMdStopCircleOutline",
        "IoMdStopOutline",
        "IoMdStopwatchOutline",
        "IoMdSubwayOutline",
        "IoMdSunnyOutline",
        "IoMdSwapHorizontalOutline",
        "IoMdSwapVerticalOutline",
        "IoMdSyncCircleOutline",
        "IoMdSyncOutline",
        "IoMdTabletLandscapeOutline",
        "IoMdTabletPortraitOutline",
        "IoMdTennisballOutline",
        "IoMdTerminalOutline",
        "IoMdTextOutline",
        "IoMdThermometerOutline",
        "IoMdThumbsDownOutline",
        "IoMdThumbsUpOutline",
        "IoMdThunderstormOutline",
        "IoMdTimeOutline",
        "IoMdTimerOutline",
        "IoMdTodayOutline",
        "IoMdToggleOutline",
        "IoMdTrailSignOutline",
        "IoMdTrainOutline",
        "IoMdTransgenderOutline",
        "IoMdTrashBinOutline",
        "IoMdTrashOutline",
        "IoMdTrendingDownOutline",
        "IoMdTrendingUpOutline",
        "IoMdTriangleOutline",
        "IoMdTrophyOutline",
        "IoMdTvOutline",
        "IoMdUmbrellaOutline",
        "IoMdVideocamOutline",
        "IoMdVolumeHighOutline",
        "IoMdVolumeLowOutline",
        "IoMdVolumeMediumOutline",
        "IoMdVolumeMuteOutline",
        "IoMdVolumeOffOutline",
        "IoMdWalkOutline",
        "IoMdWalletOutline",
        "IoMdWarningOutline",
        "IoMdWatchOutline",
        "IoMdWaterOutline",
        "IoMdWifiOutline",
        "IoMdWineOutline",
        "IoMdWomanOutline",
    ];

    let outLineReactIcon = Object.entries(Icons)
        .filter((icon) => icon[0].includes("Outline"))
        .filter((icon) => availableIcons.includes(icon[0]));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        iconPickerProps.formik.setFieldTouched("icon", true, true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (iconPickerProps?.icon) {
            setSelectIcon([
                modifyIconNameToPascal(iconPickerProps?.icon),
                iconPickerProps?.icon,
            ]);
        }
    }, [iconPickerProps?.icon]);

    const handleSelectIcon = (e) => {
        const icon = [
            e.target.getAttribute("aria-label"),
            modifyIconNameToKebabCase(
                e.target.getAttribute("aria-label").split("Io")[1],
            ),
        ];
        setSelectIcon(icon);
        iconPickerProps.formik.setFieldValue("icon", icon[1]);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <>
            <Button
                aria-describedby={id}
                variant="outlined"
                onClick={handleClick}
                sx={{
                    height: 60,
                    backgroundColor: "transparent !important",
                    color: "#222 !important",
                    maxWidth: 180,
                    borderColor: palette.border,
                }}
                className="justify-between w-full text-3xl"
            >
                <DynamicIcon name={selectIcon[0]} mobilename={selectIcon[1]} />
                <Icons.IoChevronDown className="text-base" />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box
                    sx={{ maxHeight: 300, width: "100%", overflow: "hidden" }}
                    className="mb-4"
                >
                    <div className="p-4">
                        <TextInput
                            id="search-icon"
                            value={filter}
                            onChange={(event) => setFilter(event.target.value)}
                            autoComplete="off"
                            placeholder="Search icons"
                        />
                    </div>
                    <div className="px-2">
                        <SimpleBarReact style={{ maxHeight: "300px" }}>
                            {outLineReactIcon
                                .filter(
                                    (icon) =>
                                        modifyIconNameToKebabCase(
                                            icon[0]
                                                .split("Io")[1]
                                                .split("Outline")[0],
                                        ).includes(filter.toLowerCase()) ||
                                        filter === "",
                                )
                                .map((icon) => (
                                    //  Object.entries return name in Array[0] ex. 0: "IoAccessibilityOutline", 1: function
                                    <React.Fragment key={icon[0]}>
                                        <IconButton
                                            aria-label={icon[0]}
                                            onClick={handleSelectIcon}
                                            tabIndex={0}
                                            className="inline-flex"
                                            color="primary"
                                            name={icon[0]}
                                            value={icon[0]}
                                            id={modifyIconNameToKebabCase(
                                                icon[0].split("Io")[1],
                                            )}
                                            title={modifyIconNameToKebabCase(
                                                icon[0]
                                                    .split("Io")[1]
                                                    .split("Outline")[0],
                                            )}
                                        >
                                            <DynamicIcon
                                                name={icon[0]}
                                                className="text-xl pointer-events-none"
                                            />
                                        </IconButton>
                                    </React.Fragment>
                                ))}
                        </SimpleBarReact>
                    </div>
                </Box>
            </Popover>
        </>
    );
};

export default IconPicker;
