import {
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    RadioGroup,
    Typography,
} from "@mui/material";
import { BaseRadio, RadioBoxCardView } from "components/UI/Radio";
import { SectionTitle } from "components/UI/SectionTitle";
import { CalculationStyle, PricingStyle } from "enum/meeting-room.enum";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import MESSAGES from "shared/constants/messages.constant";
import AddTimeBlock from "./AddTimeBlock";
import HalfFullDayPassed from "./HalfFullDayPassed";
import Hourly from "./Hourly";
export interface PricingProps {
    isAddNewListing: boolean;
    formik: FormikProps<MeetingRoom>;
}
export const Pricing = (props: PricingProps) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
        setFieldValue,
    } = props.formik;
    return (
        <>
            <Grid
                container
                rowSpacing={0}
                className=""
                columnSpacing={{ xs: 0, md: 6 }}
            >
                <Grid item xs={12}>
                    <div className="md:mb-4">
                        <SectionTitle
                            title="Pricing"
                            className="!mb-0"
                            helpText={MESSAGES.LISTING_PRICING_TOOLTIP}
                            hasTooltip
                            showRequiredSymbol
                        />
                        <Typography
                            variant="body2"
                            color="initial"
                            className="mb-2 font-bold"
                        >
                            Please select a pricing scheme for this meeting room
                        </Typography>
                    </div>
                    <FormControl
                        className="overflow-hidden"
                        error={!!errors.pricingStyle && touched.pricingStyle}
                    >
                        <RadioGroup
                            id="pricingStyle"
                            name="pricingStyle"
                            aria-labelledby="price-schema-radio-buttons-group-label"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.pricingStyle}
                        >
                            <div className="md:flex md:flex-row space-y-4 md:space-y-0 md:space-x-5 flex-wrap">
                                <RadioBoxCardView
                                    label={"Per meeting room"}
                                    cardMinHeight="60"
                                    cardClassName="w-auto min-w-[270px] !pl-2 md:flex"
                                    iconOnLeft
                                >
                                    <BaseRadio
                                        inputProps={{
                                            "aria-label":
                                                "Pricing per meeting room",
                                        }}
                                        value={PricingStyle.perMeetingRoom}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </RadioBoxCardView>
                                <RadioBoxCardView
                                    label={"Per person"}
                                    cardMinHeight="60"
                                    cardClassName="w-auto min-w-[270px] !pl-2 md:flex"
                                    iconOnLeft
                                >
                                    <BaseRadio
                                        inputProps={{
                                            "aria-label": "Pricing per person",
                                        }}
                                        value={PricingStyle.perPerson}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </RadioBoxCardView>
                            </div>
                        </RadioGroup>
                        {touched.pricingStyle && errors.pricingStyle && (
                            <FormHelperText
                                id="pricingStyle-help-text"
                                className="mx-0 my-2 w-full"
                            >
                                {errors.pricingStyle}
                            </FormHelperText>
                        )}
                    </FormControl>

                    {values.pricingStyle &&
                        (values.pricingStyle === PricingStyle.perMeetingRoom ||
                            values.pricingStyle === PricingStyle.perPerson) && (
                            <FormControl
                                className="overflow-hidden mb-6"
                                error={
                                    !!errors.calculationStyle &&
                                    touched.calculationStyle &&
                                    (touched.halfDayPricing?.isEnable ||
                                        touched.fullDayPricing?.isEnable)
                                }
                            >
                                <Divider className="my-6 border-dashed" />
                                <SectionTitle
                                    title="Select type"
                                    labelVariantInherit
                                    className="!mb-2"
                                    showRequiredSymbol
                                />
                                <RadioGroup
                                    id="calculationStyle"
                                    name="calculationStyle"
                                    aria-labelledby="type-price-radio-buttons-group-label"
                                    onChange={(event) => {
                                        handleChange(event);
                                        values.calculationStyle =
                                            event.target.value;
                                        setFieldValue(
                                            "calculationStyle",
                                            event.target.value,
                                            true,
                                        );
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.calculationStyle}
                                >
                                    <div className="md:flex md:flex-row space-y-4 md:space-y-0 md:space-x-5 flex-wrap">
                                        <RadioBoxCardView
                                            label={"Hourly"}
                                            cardMinHeight="60"
                                            cardClassName="w-auto min-w-[270px] !pl-2 md:flex"
                                            iconOnLeft
                                        >
                                            <BaseRadio
                                                inputProps={{
                                                    "aria-label": "Hourly",
                                                }}
                                                value={CalculationStyle.hourly}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </RadioBoxCardView>
                                        <RadioBoxCardView
                                            label={"Half / Full day"}
                                            cardMinHeight="60"
                                            cardClassName="w-auto min-w-[270px] !pl-2 md:flex"
                                            iconOnLeft
                                        >
                                            <BaseRadio
                                                inputProps={{
                                                    "aria-label":
                                                        "Half full day",
                                                }}
                                                value={
                                                    CalculationStyle.halfFullDayPassed
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </RadioBoxCardView>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        )}

                    <div className="mb-6">
                        {values.pricingStyle === PricingStyle.perMeetingRoom &&
                            values.calculationStyle ===
                                CalculationStyle.hourly && (
                                <Hourly {...props.formik} />
                            )}
                        {values.pricingStyle === PricingStyle.perMeetingRoom &&
                            values.calculationStyle ===
                                CalculationStyle.halfFullDayPassed && (
                                <HalfFullDayPassed {...props.formik} />
                            )}
                        {values.pricingStyle === PricingStyle.perPerson &&
                            values.calculationStyle ===
                                CalculationStyle.hourly && (
                                <Hourly {...props.formik} />
                            )}
                        {values.pricingStyle === PricingStyle.perPerson &&
                            values.calculationStyle ===
                                CalculationStyle.halfFullDayPassed && (
                                <HalfFullDayPassed {...props.formik} />
                            )}
                    </div>

                    {touched.calculationStyle &&
                        errors.calculationStyle &&
                        ((values.pricingStyle !== "" &&
                            values.calculationStyle === "") ||
                            (values.pricingStyle !== "" &&
                                values.calculationStyle ===
                                    CalculationStyle.halfFullDayPassed)) && (
                            <FormHelperText
                                id="calculationStyle-help-text"
                                className="mx-0 my-2 w-full"
                                error={
                                    errors.calculationStyle &&
                                    touched.calculationStyle &&
                                    (touched.halfDayPricing?.isEnable ||
                                        touched.fullDayPricing?.isEnable)
                                }
                            >
                                {(touched.halfDayPricing?.isEnable ||
                                    touched.fullDayPricing?.isEnable) &&
                                    errors.calculationStyle}
                            </FormHelperText>
                        )}

                    <Divider className="my-6" />
                    <AddTimeBlock {...props.formik} />
                </Grid>
            </Grid>

            {/* The old one */}
            {/* <Grid
                container
                rowSpacing={0}
                className=""
                columnSpacing={{ xs: 0, md: 6 }}
            >
                <Grid item xs={12} md={5}>
                    <SectionTitle
                        title="Pricing"
                        className="md:mb-5"
                        showRequiredSymbol
                    />
                    <FormControl
                        variant="standard"
                        className="mb-4 sm:flex flex-row justify-between items-baseline"
                        error={!!errors.pricePerHour && touched.pricePerHour}
                    >
                        <InputLabel
                            htmlFor="my-input"
                            className="static mb-1 sm:mb-0 !text-black"
                        >
                            Hourly
                        </InputLabel>
                        <div className="w-full sm:max-w-[160px]">
                            <div className="relative">
                                <IMaskInput
                                    mask={Number}
                                    scale={0} // digits after point, 0 for integers
                                    signed={false} // disallow negative
                                    value={String(values.pricePerHour) ?? "0"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="0"
                                    id="pricePerHour"
                                    name="pricePerHour"
                                    label="Hourly"
                                    aria-describedby="hourly-help-text"
                                    className={`maskInput ${
                                        !!errors.pricePerHour &&
                                        touched.pricePerHour
                                            ? "maskInput-error"
                                            : ""
                                    }`}
                                />
                                <div className="absolute right-3 top-2">
                                    DKK
                                </div>
                            </div>

                            <FormHelperText
                                id="hourly-help-text"
                                className="mx-0 my-2"
                            >
                                {touched.pricePerHour && errors.pricePerHour}
                            </FormHelperText>
                        </div>
                    </FormControl>
                </Grid>

                <Divider className="block md:hidden w-full my-8" />

                <Grid
                    item
                    xs={12}
                    md={7}
                    columnSpacing={{ xs: 0, md: 6 }}
                    className="!sm:pl-0 basis-auto relative"
                >
                    <Divider
                        orientation="vertical"
                        flexItem
                        className="hidden md:flex absolute left-6 top-0 h-full"
                    />
                    <SectionTitle title="Discount" className="md:mb-5" />
                    <FormGroup>
                        <FormControlLabel
                            className="align-top items-start sm:align-middle sm:items-center"
                            control={
                                <Checkbox
                                    id="discountFullDay.isEnable"
                                    name="discountFullDay.isEnable"
                                    checked={
                                        values.discountFullDay.isEnable ?? false
                                    }
                                    value={values.discountFullDay.isEnable}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="self-baseline pt-2"
                                />
                            }
                            label={
                                <FormControl
                                    className="flex flex-col sm:flex-row sm:items-center flex-wrap pt-2 sm:pt-0"
                                    error={
                                        !!errors.discountFullDay?.percent &&
                                        touched.discountFullDay?.percent
                                    }
                                >
                                    <Typography
                                        variant="body2"
                                        color="initial"
                                        className="whitespace-nowrap min-w-[125px]"
                                        component="span"
                                    >
                                        Full day
                                    </Typography>
                                    <div className="flex items-center mt-2 mr-2 sm:ml-2 sm:my-0">
                                        <div className="relative mr-2">
                                            <IMaskInput
                                                mask={Number}
                                                scale={1} // digits after point, 0 for integers
                                                signed={false} // disallow negative
                                                radix="."
                                                value={
                                                    String(
                                                        values.discountFullDay
                                                            .percent,
                                                    ) ?? "0"
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={
                                                    !values.discountFullDay
                                                        .isEnable
                                                }
                                                placeholder="0"
                                                id="discountFullDay.percent"
                                                name="discountFullDay.percent"
                                                className={`maskInput max-w-[120px] ${
                                                    !!errors.discountFullDay
                                                        ?.percent &&
                                                    touched.discountFullDay
                                                        ?.percent
                                                        ? "maskInput-error"
                                                        : ""
                                                }`}
                                            />
                                            <div className="absolute right-3 top-2.5">
                                                %
                                            </div>
                                        </div>
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            className="whitespace-nowrap"
                                            component="span"
                                        >
                                            for 8+ hours
                                        </Typography>
                                    </div>
                                    <FormHelperText
                                        id="discountFullDay-help-text"
                                        className="mx-0 my-2 w-full"
                                    >
                                        {touched.discountFullDay?.percent &&
                                            errors.discountFullDay?.percent}
                                    </FormHelperText>
                                </FormControl>
                            }
                        />

                        <FormControlLabel
                            className="align-top items-start sm:align-middle sm:items-center"
                            control={
                                <Checkbox
                                    id="discountFullDay.isEnable"
                                    name="discountFullDay.isEnable"
                                    checked={
                                        values.discountFullDay.isEnable ?? false
                                    }
                                    value={values.discountFullDay.isEnable}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="self-baseline pt-2"
                                />
                            }
                            label={
                                <FormControl
                                    className="flex flex-col sm:flex-row sm:items-center flex-wrap pt-2 sm:pt-0"
                                    error={
                                        !!errors.discountFullDay?.percent &&
                                        touched.discountFullDay?.percent
                                    }
                                >
                                    <Typography
                                        variant="body2"
                                        color="initial"
                                        className="whitespace-nowrap min-w-[125px]"
                                        component="span"
                                    >
                                        Full day
                                    </Typography>
                                    <div className="flex items-center mt-2 mr-2 sm:ml-2 sm:my-0">
                                        <div className="relative mr-2">
                                            <IMaskInput
                                                mask={Number}
                                                scale={1} // digits after point, 0 for integers
                                                signed={false} // disallow negative
                                                radix="."
                                                value={
                                                    String(
                                                        values.discountFullDay
                                                            .percent,
                                                    ) ?? "0"
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={
                                                    !values.discountFullDay
                                                        .isEnable
                                                }
                                                placeholder="0"
                                                id="discountFullDay.percent"
                                                name="discountFullDay.percent"
                                                className={`maskInput max-w-[120px] ${
                                                    !!errors.discountFullDay
                                                        ?.percent &&
                                                    touched.discountFullDay
                                                        ?.percent
                                                        ? "maskInput-error"
                                                        : ""
                                                }`}
                                            />
                                            <div className="absolute right-3 top-2.5">
                                                %
                                            </div>
                                        </div>
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            className="whitespace-nowrap"
                                            component="span"
                                        >
                                            for 8+ hours
                                        </Typography>
                                    </div>
                                    <FormHelperText
                                        id="discountFullDay-help-text"
                                        className="mx-0 my-2 w-full"
                                    >
                                        {touched.discountFullDay?.percent &&
                                            errors.discountFullDay?.percent}
                                    </FormHelperText>
                                </FormControl>
                            }
                        />
                    </FormGroup>
                </Grid>
            </Grid> */}
        </>
    );
};
