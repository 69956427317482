import { Divider } from "@mui/material";
import { AccountInformation } from "components/AccountInformation";
import {
    AdminContentContainer,
    AdminPageTitle,
    AdminSingleBlockLayout,
} from "components/Layouts";
import { FullPageLoading } from "components/UI/Loading/FullPageLoading";
import { useAuthContext } from "context/AuthContext";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { vendorService } from "services/vendor.service";
import ROUTES from "shared/constants/routes.constant";

const AccountPage = () => {
    const auth = useAuthContext();
    const vendorId = auth.authUser.userId ?? auth.getToken();
    const navigate = useNavigate();

    // API get initial data
    const {
        data: accountInformationData,
        isFetching,
        refetch,
    } = useQuery(
        "getVendorAccountInformationById",
        async () =>
            await vendorService.getVendorAccountInformationById(vendorId),
        {
            refetchOnWindowFocus: false,
            onError: async (err: any) => {
                navigate(ROUTES.PageNotFound);
            },
        },
    );

    if (isFetching) return <FullPageLoading />;
    return (
        <>
            <Helmet>
                <title>Joes & Cos | Account</title>
            </Helmet>
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <div className="max-w-[720px] mx-auto">
                        <AdminPageTitle pageTitle="Account Information" />
                        <Divider className="mb-8" />
                        <AccountInformation
                            accountInformationData={accountInformationData}
                            vendorId={vendorId}
                            refetch={refetch}
                            isReadOnly={false}
                        />
                    </div>
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};

export default AccountPage;
