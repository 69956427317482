import { useEffect } from "react";
import { FullPageLoading } from "../Loading";

export interface FullPageLoaderProps {
    isLoading: boolean;
}
export const FullPageLoader = (props: FullPageLoaderProps) => {
    useEffect(() => {
        if (props.isLoading) {
            document.getElementsByTagName("html")[0].style.overflow = "hidden";
        } else {
            document.getElementsByTagName("html")[0].style.overflow = "initial";
        }
    }, [props.isLoading]);
    if (!props.isLoading) return <></>;
    return (
        <>
            <div className="fixed inset-0 z-[1500] bg-white/60">
                <FullPageLoading />
            </div>
        </>
    );
};
