import firebase from "firebase/auth";
import React, { useContext } from "react";

export type AuthUser = {
    userId: string;
    name: string;
    email: string;
    //  profileImage: string;
    roles: string[];
    isAdmin: boolean;
    isVendor: boolean;
};
interface AuthContextType {
    user: firebase.User | null;
    // claims: any | null;
    authUser: AuthUser;
    loading: boolean;
    isLoginFail: boolean;
    login: (username: string, password: string, callback: VoidFunction) => void;
    logout: (callback: VoidFunction) => void;
    sendPasswordReset: (email: string) => void;
    createUser: (username: string, password: string) => void;
    updateUserProfile: (displayName: string, photoUrl: string) => void;
    hasPermission: (roles: string[]) => boolean;
    getToken: () => string | null;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export const useAuthContext = () => {
    return useContext(AuthContext);
};
