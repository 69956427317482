import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Typography,
} from "@mui/material";
import { SectionTitle } from "components/UI";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import { useEffect } from "react";
import { IMaskInput } from "react-imask";
import MESSAGES from "shared/constants/messages.constant";

export interface DiscountProps {
    formik: FormikProps<MeetingRoom>;
    handleHalfDayDiscountChange: any;
    handleFullDayDiscountChange: any;
    sampleHalfDayDiscount: string;
    sampleFullDayDiscount: string;
}

const Discount = (props: DiscountProps) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
        setFieldValue,
    } = props.formik;

    const {
        handleHalfDayDiscountChange,
        handleFullDayDiscountChange,
        sampleHalfDayDiscount,
        sampleFullDayDiscount,
    } = props;

    useEffect(() => {
        if (
            values.minimumHours &&
            values.minimumHours.isEnable &&
            Number(values.minimumHours?.value) > 7
        ) {
            setFieldValue("discountHalfDay.isEnable", false);
        }
    }, [values.minimumHours?.value]);

    return (
        <>
            <SectionTitle
                title="Discount"
                helpText={
                    MESSAGES.LISTING_PRICING_PER_MEETINGROOM_HOURLY_DISCOUNT_TOOLTIP
                }
                // className="md:mb-5"
                labelVariantInherit
                tooltipPosition="right"
                hasTooltip
            />
            <FormGroup className="space-y-4">
                <FormControlLabel
                    className="items-baseline align-top sm:align-middle sm:items-center"
                    control={
                        <Checkbox
                            id="discountHalfDay.isEnable"
                            name="discountHalfDay.isEnable"
                            checked={values.discountHalfDay.isEnable ?? false}
                            value={values.discountHalfDay.isEnable}
                            onChange={(e) => {
                                handleChange(e);
                                handleHalfDayDiscountChange(e.target.value);
                            }}
                            onBlur={handleBlur}
                            className="self-baseline pt-0 md:pt-2 relative top-[7px] md:top-auto"
                            disabled={
                                values.minimumHours?.isEnable &&
                                Number(values.minimumHours?.value) > 7
                            }
                        />
                    }
                    label={
                        <FormControl
                            className="flex flex-col flex-wrap pt-2 sm:flex-row sm:items-center sm:pt-0"
                            error={
                                !!errors.discountHalfDay?.percent &&
                                touched.discountHalfDay?.percent
                            }
                        >
                            <SectionTitle
                                title="Half day"
                                labelClassName="!mb-0 mr-2 font-normal min-w-[60px] label-clickable"
                                className="!mb-0 font-normal"
                                labelVariantInherit
                            />
                            <div className="flex items-center mt-2 mr-2 sm:ml-2 sm:my-0">
                                <div className="relative mr-2">
                                    <IMaskInput
                                        mask={Number}
                                        scale={1} // digits after point, 0 for integers
                                        signed={false} // disallow negative
                                        radix="."
                                        value={
                                            String(
                                                values.discountHalfDay?.percent,
                                            ) ?? "0"
                                        }
                                        min={0}
                                        max={100}
                                        //@ts-ignore
                                        onBlur={handleBlur}
                                        disabled={
                                            !values.discountHalfDay?.isEnable
                                        }
                                        placeholder="0"
                                        id="discountHalfDay.percent"
                                        name="discountHalfDay.percent"
                                        className={`maskInput max-w-[120px] pointer-events-auto ${
                                            !!errors.discountHalfDay?.percent &&
                                            touched.discountHalfDay?.percent
                                                ? "maskInput-error"
                                                : ""
                                        }`}
                                        normalizeZeros={true}
                                        padFractionalZeros={false}
                                        onAccept={(value, mask) => {
                                            const expectedValue =
                                                value === ""
                                                    ? null
                                                    : String(value).endsWith(
                                                          ".",
                                                      )
                                                    ? value
                                                    : Number(value);
                                            setFieldValue(
                                                "discountHalfDay.percent",
                                                expectedValue,
                                                true,
                                            );
                                            handleHalfDayDiscountChange(
                                                expectedValue,
                                            );
                                        }}
                                    />
                                    <div className="absolute right-3 top-2.5">
                                        %
                                    </div>
                                </div>
                                <Typography
                                    variant="body2"
                                    color="initial"
                                    className="whitespace-nowrap"
                                    component="span"
                                >
                                    for 4-7 hours
                                </Typography>
                            </div>
                            <Typography
                                variant="body2"
                                color="initial"
                                className="ml-4 font-bold whitespace-nowrap bold"
                                component="span"
                            >
                                {sampleHalfDayDiscount &&
                                values.discountHalfDay?.isEnable &&
                                Number(values.discountHalfDay?.percent) > 0
                                    ? sampleHalfDayDiscount
                                    : "-"}
                            </Typography>
                            {touched.discountHalfDay?.percent &&
                                errors.discountHalfDay?.percent && (
                                    <FormHelperText
                                        id="discountHalfDay-help-text"
                                        className="w-full mx-0 my-2"
                                    >
                                        {errors.discountHalfDay?.percent}
                                    </FormHelperText>
                                )}
                        </FormControl>
                    }
                />

                <FormControlLabel
                    className="items-baseline align-top sm:align-middle sm:items-center"
                    control={
                        <Checkbox
                            id="discountFullDay.isEnable"
                            name="discountFullDay.isEnable"
                            checked={values.discountFullDay.isEnable ?? false}
                            value={values.discountFullDay.isEnable}
                            onChange={(e) => {
                                handleChange(e);
                                handleFullDayDiscountChange(e.target.value);
                            }}
                            onBlur={handleBlur}
                            className="self-baseline pt-0 md:pt-2 relative top-[7px] md:top-auto"
                        />
                    }
                    label={
                        <FormControl
                            className="flex flex-col flex-wrap pt-2 sm:flex-row sm:items-center sm:pt-0"
                            error={
                                !!errors.discountFullDay?.percent &&
                                touched.discountFullDay?.percent
                            }
                        >
                            <SectionTitle
                                title="Full day"
                                labelClassName="!mb-0 mr-2 font-normal min-w-[60px] label-clickable"
                                className="!mb-0 font-normal"
                                labelVariantInherit
                            />
                            <div className="flex items-baseline mt-2 mr-2 sm:ml-2 sm:my-0">
                                <div className="relative mr-2">
                                    <IMaskInput
                                        mask={Number}
                                        scale={1} // digits after point, 0 for integers
                                        signed={false} // disallow negative
                                        radix="."
                                        value={
                                            String(
                                                values.discountFullDay?.percent,
                                            ) ?? "0"
                                        }
                                        min={0}
                                        max={100}
                                        //@ts-ignore
                                        onBlur={handleBlur}
                                        disabled={
                                            !values.discountFullDay?.isEnable
                                        }
                                        placeholder="0"
                                        id="discountFullDay.percent"
                                        name="discountFullDay.percent"
                                        className={`maskInput max-w-[120px] pointer-events-auto ${
                                            !!errors.discountFullDay?.percent &&
                                            touched.discountFullDay?.percent
                                                ? "maskInput-error"
                                                : ""
                                        }`}
                                        normalizeZeros={true}
                                        padFractionalZeros={false}
                                        onAccept={(value, mask) => {
                                            const expectedValue =
                                                value === ""
                                                    ? null
                                                    : String(value).endsWith(
                                                          ".",
                                                      )
                                                    ? value
                                                    : Number(value);

                                            setFieldValue(
                                                "discountFullDay.percent",
                                                expectedValue,
                                                true,
                                            );
                                            handleFullDayDiscountChange(
                                                expectedValue,
                                            );
                                        }}
                                    />
                                    <div className="absolute right-3 top-2.5">
                                        %
                                    </div>
                                </div>
                                <Typography
                                    variant="body2"
                                    color="initial"
                                    className="whitespace-nowrap"
                                    component="span"
                                >
                                    for 8+ hours
                                </Typography>
                            </div>
                            <Typography
                                variant="body2"
                                color="initial"
                                className="ml-4 font-bold whitespace-nowrap bold"
                                component="span"
                            >
                                {sampleFullDayDiscount &&
                                values.discountFullDay?.isEnable &&
                                Number(values.discountFullDay?.percent) > 0
                                    ? sampleFullDayDiscount
                                    : "-"}
                            </Typography>
                            {touched.discountFullDay?.percent &&
                                errors.discountFullDay?.percent && (
                                    <FormHelperText
                                        id="discountFullDay-help-text"
                                        className="w-full mx-0 my-2"
                                    >
                                        {errors.discountFullDay?.percent}
                                    </FormHelperText>
                                )}
                        </FormControl>
                    }
                />
            </FormGroup>
        </>
    );
};

export default Discount;
