import { Avatar, Box, Divider, Skeleton } from "@mui/material";
import theme from "styles/theme";
export const ChatBubblesSkeleton = () => {
    return (
        <>
            <div className="space-y-4">
                <Skeleton variant="text" width="30%" className="mx-auto" />
                <div className="flex space-x-4 items-center">
                    <Skeleton variant="circular">
                        <Avatar sx={{ width: 38, height: 38 }} />
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        width="30%"
                        height="30px"
                        className="rounded-lg"
                    />
                </div>

                <Skeleton
                    variant="rectangular"
                    width="30%"
                    height="30px"
                    className="rounded-lg ml-auto"
                />
                <Skeleton
                    variant="rectangular"
                    width="20%"
                    height="30px"
                    className="rounded-lg ml-auto"
                />
                <div className="flex space-x-4 items-center">
                    <Skeleton variant="circular">
                        <Avatar sx={{ width: 38, height: 38 }} />
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        width="30%"
                        height="30px"
                        className="rounded-lg"
                    />
                </div>
            </div>
        </>
    );
};
const MessagesSkeleton = () => {
    return (
        <Box
            className="absolute flex-1 h-full bg-white visibility-hidden lg:visibility-visible lg:relative"
            sx={{
                width: { xs: "100%", lg: "50%" },
                left: "100%",
                transition: "all ease-in-out .3s",
                zIndex: 11,
                // ...(props.openMobileMessageSection && {
                //     left: 0,
                //     visibility: "visible",
                // }),
                [theme.breakpoints.up("lg")]: {
                    left: "auto",
                },
            }}
        >
            <Divider
                orientation="vertical"
                className="absolute top-0 left-0 hidden lg:block"
            />
            <div className="flex flex-col w-full h-full">
                <div className="px-4 2xl:px-10 pt-6">
                    <Skeleton variant="text" width="40%" />
                </div>
                <div className="flex flex-wrap items-center justify-between px-4 py-2 border-b border-gray-300 2xl:px-10">
                    <div className="flex flex-wrap my-1 w-full space-x-1">
                        <Skeleton variant="text" width="30%" />
                        <Skeleton variant="text" width="15%" />
                        <Skeleton variant="text" width="15%" />
                        <Skeleton
                            variant="text"
                            width="15%"
                            className="!ml-auto"
                        />
                    </div>
                </div>
                <Divider />
                <div className="relative w-full pt-6 px-4 2xl:px-10 overflow-hidden flex-1 2xl:max-w-[85%] mx-auto">
                    <ChatBubblesSkeleton />
                </div>
                <div className="flex items-center justify-between w-full py-3 px-4 2xl:px-10 border-t border-gray-300 2xl:max-w-[85%] mx-auto">
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="30px"
                        className="rounded-lg"
                    />
                    {/* <form ref={myFormRef} style={{ width: "100%" }}>
                        <FormControl
                            variant="standard"
                            className="flex flex-col w-full 2xl:mb-7"
                        >
                            <div className="relative leading-none textarea-autosize-chat">
                                <TextareaAutosize
                                    aria-label="empty textarea"
                                    placeholder="Type message"
                                    minRows="1"
                                    id="message"
                                    name="message"
                                    className="w-full"
                                    value={chatMessage}
                                    onChange={(event) => handleChange(event)}
                                    onKeyDown={onEnterPress}
                                />
                                <IconButton
                                    className="absolute top-0 right-1"
                                >
                                    <IoPaperPlaneOutline className="text-2xl text-black" />
                                </IconButton>
                            </div>
                        </FormControl>
                    </form> */}
                </div>
            </div>
            <Divider
                orientation="vertical"
                className="absolute top-0 right-0 hidden lg:block"
            />
        </Box>
    );
};

export default MessagesSkeleton;
