import { DBCollection } from "enum/db-collection.enum";
import { Query } from "firebase/firestore";

import {
    defaultNotiPaginationLimit,
    defaultPaging,
} from "../shared/constants/shared.constant";
import {
    collection,
    db,
    DocumentData,
    limit,
    onSnapshot,
    orderBy,
    OrderByDirection,
    query,
    QuerySnapshot,
    Timestamp,
    where,
} from "../firebaseSetup";
import { requestService } from "./request.service";
import { PagingResult } from "models/pagination.model";
import { API_ENDPOINT_ฺNOTIFICATION } from "shared/constants/api.constant";

const FIELD_READ = "read";
const FIELD_DELETED = "deleted";
const FIELD_DATE_TIME_CREATED = "dateTimeCreated";

const subscribeHasNewNotification = (
    userId: string,
    setValues: (result: boolean) => void,
    orderByField: string,
    orderDirection: OrderByDirection = "asc",
) => {
    const path = `${DBCollection.Vendors}/${userId}/${DBCollection.VendorNotifications}`;
    const notificationRef = collection(db, path);
    const q = query(
        notificationRef,
        orderBy(orderByField, orderDirection),
        where(FIELD_READ, "==", false),
        where(FIELD_DELETED, "==", false),
        limit(1),
    );
    return onSnapshot(q, (docs) => {
        setValues(docs.size > 0);
    });
};

const subscribeNewNotifications = (
    userId: string,
    onlyUnread: boolean,
    snapShotFirstPage: (param: QuerySnapshot<DocumentData>) => void,
    orderByField: string,
    orderDirection: OrderByDirection = "asc",
) => {
    const path = `${DBCollection.Vendors}/${userId}/${DBCollection.VendorNotifications}`;
    const notificationRef = collection(db, path);
    const now = new Date();
    let q: Query<DocumentData>;
    if (onlyUnread) {
        q = query(
            notificationRef,
            where(FIELD_DELETED, "==", false),
            where(FIELD_DATE_TIME_CREATED, ">=", now),
            where("read", "==", false),
            orderBy(orderByField, orderDirection),
            limit(1),
        );
    } else {
        q = query(
            notificationRef,
            where(FIELD_DELETED, "==", false),
            where(FIELD_DATE_TIME_CREATED, ">=", now),
            orderBy(orderByField, orderDirection),
            limit(1),
        );
    }

    return onSnapshot(q, snapShotFirstPage);
};

const fetchNotifications = async (
    lastId: string,
    onlyUnread: boolean,
): Promise<PagingResult> => {
    try {
        const result = (await requestService.onCall(
            API_ENDPOINT_ฺNOTIFICATION.getVendorNotificationsByVendorId,
            {
                ...defaultPaging,
                amount: defaultNotiPaginationLimit,
                lastId: lastId,
                onlyUnread,
            },
        )) as any;
        result.lists = result.lists.map((item) => {
            item.dateTimeCreated = new Timestamp(
                item.dateTimeCreated._seconds,
                item.dateTimeCreated._nanoseconds,
            ).toDate();
            return item;
        }) as PagingResult;

        return result;
    } catch (exception) {
        throw exception;
    }
};

const deleteNotificationById = async (id: string): Promise<boolean> => {
    try {
        return (await requestService.onCall(
            API_ENDPOINT_ฺNOTIFICATION.deleteVendorNotificationById,
            {
                id: id,
            },
        )) as boolean;
    } catch (exception) {
        throw exception;
    }
};

const updateAsReadNotificationById = async (id: string): Promise<boolean> => {
    try {
        return (await requestService.onCall(
            API_ENDPOINT_ฺNOTIFICATION.updateAsReadById,
            {
                id: id,
            },
        )) as boolean;
    } catch (exception) {
        throw exception;
    }
};

const updateAsUnReadNotificationById = async (id: string): Promise<boolean> => {
    try {
        return (await requestService.onCall(
            API_ENDPOINT_ฺNOTIFICATION.updateAsUnReadById,
            {
                id: id,
            },
        )) as boolean;
    } catch (exception) {
        throw exception;
    }
};

export const vendorNotificationService = {
    subscribeHasNewNotification,
    subscribeNewNotifications,
    deleteNotificationById,
    updateAsReadNotificationById,
    updateAsUnReadNotificationById,
    fetchNotifications,
};
