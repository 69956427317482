import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
} from "@mui/material";
import { SectionTitle } from "components/UI";
import JCSelect from "components/UI/SelectDropdown";
import { PricingStyle } from "enum/meeting-room.enum";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import { useState } from "react";
import { IMaskInput } from "react-imask";
import MESSAGES from "shared/constants/messages.constant";
import BookingTimeIncrement from "./BookingTimeIncrement";
import Discount from "./Discount";

const Hourly = (props: FormikProps<MeetingRoom>) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        setErrors,
    } = props;

    const [minimumHours, setMinimumHours] = useState<Number[]>([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
    ]);

    const [sampleHalfDayDiscount, setSampleHalfDayDiscount] = useState(null);
    const [sampleFullDayDiscount, setSampleFullDayDiscount] = useState(null);

    function handleHalfDayDiscountChange(eventValue: number | string) {
        calculateSampleHalfDayDiscount(eventValue);
    }
    function handleFullDayDiscountChange(eventValue: number | string) {
        calculateSampleFullDayDiscount(eventValue);
    }
    function handlePricePerHourChange(eventValue: number) {
        calculateSampleHalfFullDayDiscount(eventValue);
    }

    function calculateSampleHalfDayDiscount(eventValue: number | string) {
        setSampleHalfDayDiscount(
            !isNaN(+eventValue)
                ? calculateSampleDiscount(null, Number(eventValue))
                : calculateSampleDiscount(
                      null,
                      values.discountHalfDay?.percent,
                  ),
        );
    }
    function calculateSampleFullDayDiscount(eventValue: number | string) {
        setSampleFullDayDiscount(
            !isNaN(+eventValue)
                ? calculateSampleDiscount(null, Number(eventValue))
                : calculateSampleDiscount(
                      null,
                      values.discountFullDay?.percent,
                  ),
        );
    }
    function calculateSampleHalfFullDayDiscount(eventValue: number) {
        setSampleHalfDayDiscount(
            calculateSampleDiscount(
                Number(eventValue),
                values.discountHalfDay?.percent,
            ),
        );
        setSampleFullDayDiscount(
            calculateSampleDiscount(
                Number(eventValue),
                values.discountFullDay?.percent,
            ),
        );
    }
    function calculateSampleDiscount(
        newPricePerHour: number,
        discountPercent: number,
    ) {
        const pricePerHour = newPricePerHour ?? values.pricePerHour;
        const totalSample = Number(
            pricePerHour - (pricePerHour * discountPercent) / 100,
        ).toFixed(2);
        return totalSample + " DKK / hour after discount";
    }

    function handleMinimumChargeHourlyChange(eventValue: number | string) {
        //TODO: not working yet -> find solution later for set error flag
        // setTimeout(() => {
        //     if (Number(eventValue) <= values.pricePerHour) {
        //         setFieldValue(
        //             "minimumChargeHourly.value",
        //             Number(eventValue),
        //             true,
        //         );
        //         setFieldTouched("minimumChargeHourly.value", true);
        //         setFieldError(
        //             "minimumChargeHourly.value",
        //             "Please add more minimum charge",
        //         );
        //     }
        // }, 200);
    }

    return (
        <>
            <SectionTitle
                title="Hourly rate"
                labelVariantInherit
                className="!mb-0"
                labelClassName="mb-0"
                showRequiredSymbol
            />
            <div className="items-baseline md:flex">
                <FormControl
                    variant="standard"
                    className="mb-2 mr-5"
                    error={!!errors.pricePerHour && touched.pricePerHour}
                    sx={{ flex: { md: "0 0 160px" } }}
                >
                    <div className="w-full max-w-[200px]">
                        <div className="relative">
                            <IMaskInput
                                mask={Number}
                                scale={0} // digits after point, 0 for integers
                                signed={false} // disallow negative
                                value={String(values.pricePerHour) ?? "0"}
                                //@ts-ignore
                                onBlur={handleBlur}
                                placeholder="0"
                                id="pricePerHour"
                                name="pricePerHour"
                                label="Hourly"
                                aria-describedby="hourly-help-text"
                                className={`max-w-none maskInput ${
                                    !!errors.pricePerHour &&
                                    touched.pricePerHour
                                        ? "maskInput-error"
                                        : ""
                                }`}
                                onAccept={(value, mask) => {
                                    value = value === "" ? null : Number(value);
                                    setFieldValue("pricePerHour", value, true);
                                    handlePricePerHourChange(Number(value));
                                }}
                            />
                            <div className="absolute right-3 top-2.5">DKK</div>
                        </div>
                    </div>
                    {touched.pricePerHour && errors.pricePerHour && (
                        <FormHelperText
                            id="pricePerHour-help-text"
                            className="w-full mx-0 my-2"
                        >
                            {errors.pricePerHour}
                        </FormHelperText>
                    )}
                </FormControl>

                <div className="flex flex-col space-y-3">
                    <FormControlLabel
                        className="items-baseline md:align-middle md:ml-0"
                        control={
                            <Checkbox
                                id="minimumHours.isEnable"
                                name="minimumHours.isEnable"
                                className="relative pt-0 self-baseline md:pt-2 top-2"
                                value={values.minimumHours?.isEnable ?? false}
                                checked={values.minimumHours?.isEnable ?? false}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        }
                        label={
                            <FormControl
                                className="flex flex-col flex-wrap items-baseline md:flex-row"
                                error={
                                    !!errors.minimumHours?.value &&
                                    touched.minimumHours?.value
                                }
                            >
                                <SectionTitle
                                    title="Minimum hours"
                                    labelClassName="m-0 mr-3 min-w-[140px] label-clickable"
                                    className="!mb-0"
                                    helpText={
                                        MESSAGES.LISTING_PRICING_PER_PERSON_HOURLY_MINIMUM_HOURS_TOOLTIP
                                    }
                                    tooltipPosition="bottom"
                                    hasTooltip
                                    labelVariantInherit
                                />
                                <div className="flex items-center">
                                    <JCSelect
                                        labelId="demo-simple-select-label-2"
                                        id="minimumHours.value"
                                        defaultValue=""
                                        name="minimumHours.value"
                                        className="w-[150px] pointer-events-auto"
                                        onChange={(e) =>
                                            setFieldValue(
                                                "minimumHours.value",
                                                Number(e.target.value),
                                            )
                                        }
                                        onBlur={handleBlur}
                                        value={
                                            values.minimumHours?.value ===
                                                undefined ||
                                            values.minimumHours?.value === 0
                                                ? ""
                                                : values.minimumHours?.value ??
                                                  ""
                                        }
                                        disabled={
                                            !values.minimumHours?.isEnable ??
                                            false
                                        }
                                    >
                                        {minimumHours &&
                                            minimumHours.map((hour) => (
                                                <MenuItem
                                                    value={String(hour)}
                                                    key={String(hour)}
                                                    sx={{
                                                        fontSize:
                                                            "14px !important;",
                                                    }}
                                                >
                                                    {hour} hour
                                                    {hour > 1 && "s"}
                                                </MenuItem>
                                            ))}
                                    </JCSelect>
                                </div>
                                {touched.minimumHours?.value &&
                                    errors.minimumHours?.value && (
                                        <FormHelperText
                                            id="minimumHours-help-text"
                                            className="w-full mx-0 my-2"
                                        >
                                            {errors.minimumHours?.value}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                        }
                    />
                    {values.pricingStyle === PricingStyle.perPerson && (
                        <FormControlLabel
                            className="items-baseline md:align-middle md:ml-0 "
                            control={
                                <Checkbox
                                    id="minimumChargeHourly.isEnable"
                                    name="minimumChargeHourly.isEnable"
                                    checked={
                                        values.minimumChargeHourly?.isEnable ??
                                        false
                                    }
                                    value={
                                        values.minimumChargeHourly?.isEnable ??
                                        false
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="relative pt-0 self-baseline md:pt-2 top-2"
                                />
                            }
                            label={
                                <FormControl
                                    className="flex flex-col flex-wrap items-baseline md:flex-row"
                                    error={
                                        !!errors.minimumChargeHourly?.value &&
                                        touched.minimumChargeHourly?.value
                                    }
                                >
                                    <SectionTitle
                                        title="Minimum charge"
                                        labelClassName="m-0 mr-3 w-full md:w-[140px] label-clickable"
                                        className="!mb-0"
                                        helpText={
                                            MESSAGES.LISTING_PRICING_PER_PERSON_HOURLY_MINIMUM_CHARGE_TOOLTIP
                                        }
                                        tooltipPosition="bottom"
                                        hasTooltip
                                        labelVariantInherit
                                    />
                                    <div className="flex items-center  w-[150px]">
                                        <div className="relative w-[150px]">
                                            <IMaskInput
                                                mask={Number}
                                                scale={0} // digits after point, 0 for integers
                                                signed={false} // disallow negative
                                                value={
                                                    String(
                                                        values
                                                            .minimumChargeHourly
                                                            ?.value,
                                                    ) ?? "0"
                                                }
                                                //@ts-ignore
                                                onBlur={handleBlur}
                                                placeholder="0"
                                                id="minimumChargeHourly.value"
                                                name="minimumChargeHourly.value"
                                                label="Hourly"
                                                aria-describedby="hourly-help-text"
                                                className={`w-[150px] max-w-none maskInput pointer-events-auto ${
                                                    !!errors.minimumChargeHourly
                                                        ?.value &&
                                                    touched.minimumChargeHourly
                                                        ?.value
                                                        ? "maskInput-error"
                                                        : ""
                                                }`}
                                                disabled={
                                                    !values.minimumChargeHourly
                                                        ?.isEnable
                                                }
                                                onAccept={(value, mask) => {
                                                    value =
                                                        value === ""
                                                            ? null
                                                            : Number(value);
                                                    setFieldValue(
                                                        "minimumChargeHourly.value",
                                                        value,
                                                    );
                                                    handleMinimumChargeHourlyChange(
                                                        Number(value),
                                                    );
                                                }}
                                            />
                                            <div className="absolute right-3 top-2.5">
                                                DKK
                                            </div>
                                        </div>
                                    </div>
                                    {touched.minimumChargeHourly?.value &&
                                        errors.minimumChargeHourly?.value && (
                                            <FormHelperText
                                                id="minimumChargeHourly-help-text"
                                                className="w-full mx-0 my-2"
                                            >
                                                {
                                                    errors.minimumChargeHourly
                                                        ?.value
                                                }
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            }
                        />
                    )}
                </div>
            </div>
            <Divider className="my-6 border-dashed" />
            <Discount
                formik={props}
                handleHalfDayDiscountChange={handleHalfDayDiscountChange}
                handleFullDayDiscountChange={handleFullDayDiscountChange}
                sampleHalfDayDiscount={sampleHalfDayDiscount}
                sampleFullDayDiscount={sampleFullDayDiscount}
            />
            <Divider className="my-6 border-dashed" />
            <BookingTimeIncrement {...props} />
        </>
    );
};

export default Hourly;
