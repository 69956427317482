import {
    Button,
    DialogProps,
    FormControl,
    FormHelperText,
    InputAdornment,
    Stack,
    TextareaAutosize,
    Typography,
} from "@mui/material";
import { TextInput } from "components";
import { BaseModal } from "components/UI/Modal";
import { Formik } from "formik";
import { AddOn } from "models/add-on.model";
import MESSAGES from "shared/constants/messages.constant";
import * as Yup from "yup";
export interface AddOnsModalProps extends DialogProps {
    closeFn?: () => void;
    setRtnValueAddOns: (addOn: AddOn) => void;
    initTextboxValue?: AddOn;
}

const inputValidation = Yup.object().shape({
    title: Yup.string().required(MESSAGES.ADD_ON_VALIDATION_TITLE_REQUIRED),
    description: Yup.string().required(
        MESSAGES.ADD_ON_VALIDATION_DESCRIPTION_REQUIRED,
    ),
    pricePerUnit: Yup.string()
        .typeError(MESSAGES.ADD_ON_VALIDATION_PER_UNIT_TYPE_ERROR)
        .required(MESSAGES.ADD_ON_VALIDATION_PER_UNIT_REQUIRED)
        .matches(
            /(?=,*?\d)^\$?(([1-9]\d{0,2}(.\d{3})*)|\d+)?(\,\d{1,2})?$/,
            MESSAGES.ADD_ON_VALIDATION_PER_UNIT_INVALID_FORMAT,
        )
        .min(0, MESSAGES.ADD_ON_VALIDATION_PER_UNIT_MIN),
});

export const AddOnsModal: React.FC<AddOnsModalProps> = (
    props: AddOnsModalProps,
) => {
    const { initTextboxValue } = props;
    const isEdit = !!initTextboxValue;

    return (
        <BaseModal title="Add-ons (Optional)" open={props.open}>
            <Formik
                initialValues={{
                    uid: initTextboxValue?.uid ?? "-99",
                    title: initTextboxValue?.title ?? "",
                    description: initTextboxValue?.description ?? "",
                    pricePerUnit: initTextboxValue?.pricePerUnit
                        ? initTextboxValue.pricePerUnit.toLocaleString("de-DE")
                        : 0,
                }}
                validationSchema={inputValidation}
                onSubmit={(values: AddOn) => {
                    values.pricePerUnit = values.pricePerUnit
                        .toString()
                        .includes(".")
                        ? Number(
                              values.pricePerUnit
                                  .toString()
                                  .replaceAll(".", "")
                                  .replace(",", "."),
                          )
                        : values.pricePerUnit.toString().includes(",")
                        ? Number(
                              values.pricePerUnit.toString().replace(",", "."),
                          )
                        : Number(values.pricePerUnit);
                    props.setRtnValueAddOns(values);
                }}
            >
                {({
                    errors,
                    touched,
                    handleSubmit,
                    dirty,
                    isValid,
                    handleChange,
                    handleBlur,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormControl
                            variant="standard"
                            error={!!errors.title && touched.title}
                            className="mb-4 md:mb-7"
                        >
                            <Typography
                                variant="body2"
                                className="mb-1 font-bold"
                            >
                                Add-on name
                            </Typography>
                            <TextInput
                                id="title"
                                name="title"
                                placeholder=""
                                style={{ width: "100%" }}
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.title && errors.title && (
                                <FormHelperText
                                    id="add-on-name-help-text"
                                    className="mx-0 my-1"
                                >
                                    {errors.title}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            variant="standard"
                            error={!!errors.description && touched.description}
                            className="mb-4 md:mb-5"
                        >
                            <Typography
                                variant="body2"
                                className="mb-1 font-bold"
                            >
                                Add-on description
                            </Typography>
                            <TextareaAutosize
                                id="description"
                                name="description"
                                placeholder=""
                                minRows={3}
                                maxRows={6}
                                style={{ width: "100%" }}
                                value={values.description}
                                className={
                                    !!errors.description && touched.description
                                        ? "error"
                                        : ""
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.description && errors.description && (
                                <FormHelperText
                                    id="add-on-description-help-text"
                                    className="mx-0 mt-0 mb-2"
                                >
                                    {errors.description}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            variant="standard"
                            error={
                                !!errors.pricePerUnit && touched.pricePerUnit
                            }
                            className="mb-4 md:mb-7"
                        >
                            <TextInput
                                id="pricePerUnit"
                                name="pricePerUnit"
                                label="Add-on price per person"
                                hasTooltip
                                tooltipContent={
                                    MESSAGES.LISTING_ADD_ONS_PRICE_PER_UNIT_TOOLTIP
                                }
                                placeholder=""
                                type="text"
                                style={{ width: "100%" }}
                                value={values.pricePerUnit}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{
                                    min: 0,
                                }}
                                endAdornment={
                                    <InputAdornment
                                        position="end"
                                        className="-mr-2"
                                    >
                                        DKK
                                    </InputAdornment>
                                }
                                sx={{ maxWidth: 260 }}
                            />
                            {touched.pricePerUnit && errors.pricePerUnit && (
                                <FormHelperText
                                    id="add-on-pricePerUnit-help-text"
                                    className="mx-0 my-1"
                                >
                                    {errors.pricePerUnit}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {/* Modal Footer */}
                        <Stack
                            className="mt-4"
                            spacing={2}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Button
                                className="min-w-[100px]"
                                type="submit"
                                variant="contained"
                                onClick={props.closeFn}
                                disabled={!dirty || !isValid}
                            >
                                {isEdit ? "Update" : "Add"}
                            </Button>
                            <Button
                                className="min-w-[100px]"
                                variant="outlined"
                                onClick={props.closeFn}
                            >
                                Close
                            </Button>
                        </Stack>
                    </form>
                )}
            </Formik>
        </BaseModal>
    );
};
