import { API_ENDPOINT_ฺINBOX } from "shared/constants/api.constant";
import { DBCollection } from "enum/db-collection.enum";
import { dateTimeHelper } from "helpers/date-time.helper";
import { VendorInbox } from "models/inbox.model";
import { PagingResult } from "models/pagination.model";
import {
    defaultInboxPaginationLimit,
    defaultPaging,
    fieldDateTimeUpdated,
} from "../shared/constants/shared.constant";
import {
    collection,
    db,
    DocumentData,
    limit,
    onSnapshot,
    orderBy,
    OrderByDirection,
    query,
    QuerySnapshot,
    where,
} from "../firebaseSetup";
import { requestService } from "./request.service";
const subscribeLatestInboxes = (
    vendorId: string,
    snapShotLatestUpdatedItem: (param: QuerySnapshot<DocumentData>) => void,
    orderByField: string,
    orderDirection: OrderByDirection = "asc",
) => {
    const path = `${DBCollection.Vendors}/${vendorId}/${DBCollection.InBoxes}`;
    const queryRef = collection(db, path);
    const q = query(queryRef, orderBy(orderByField, orderDirection), limit(1));
    return onSnapshot(q, snapShotLatestUpdatedItem);
};

const fetchVendorInboxes = async (
    lastId: string,
    vendorId: string,
): Promise<PagingResult> => {
    try {
        //TODO: move endpoint name to a constant (endpoints.constant)
        const result = (await requestService.onCall(
            API_ENDPOINT_ฺINBOX.getVendorInboxes,
            {
                ...defaultPaging,
                amount: defaultInboxPaginationLimit,
                lastId: lastId,
                orderByKey: fieldDateTimeUpdated,
                vendorId,
            },
        )) as any;
        result.lists = result.lists.map((item) => {
            item.dateTimeUpdated =
                dateTimeHelper.convertFirestoreTimestampToDate(
                    item.dateTimeUpdated._seconds,
                    item.dateTimeUpdated._nanoseconds,
                );
            if (item.lastMessage && item.lastMessage.dateTimeUpdated) {
                item.lastMessage.dateTimeUpdated =
                    dateTimeHelper.convertFirestoreTimestampToDate(
                        item.lastMessage.dateTimeUpdated._seconds,
                        item.lastMessage.dateTimeUpdated._nanoseconds,
                    );
            }
            return item;
        }) as PagingResult;

        return result;
    } catch (exception) {
        throw exception;
    }
};

const getLatestInbox = async (vendorId: string): Promise<VendorInbox> => {
    try {
        const result = (await requestService.onCall(
            API_ENDPOINT_ฺINBOX.getVendorInboxes,
            {
                ...defaultPaging,
                amount: 1,
                lastId: null,
                orderByKey: fieldDateTimeUpdated,
                vendorId,
            },
        )) as any;
        result.lists = result.lists.map((item) => {
            item.dateTimeCreated =
                dateTimeHelper.convertFirestoreTimestampToDate(
                    item.dateTimeCreated._seconds,
                    item.dateTimeCreated._nanoseconds,
                );
            return item as VendorInbox;
        }) as PagingResult;

        return result.lists.lenght === 0 ? null : result.lists[0];
    } catch (exception) {
        throw exception;
    }
};
const getUnreadInboxByVendorId = async (
    vebdorId: string,
): Promise<VendorInbox> => {
    try {
        const chatMessage = (await requestService.onCall(
            API_ENDPOINT_ฺINBOX.getUnreadInboxByVendorId,
            {
                vebdorId,
            },
        )) as any;
        if (chatMessage) {
            if (chatMessage.dateTimeCreated)
                chatMessage.dateTimeCreated =
                    dateTimeHelper.convertFirestoreTimestampToDate(
                        chatMessage.dateTimeCreated._seconds,
                        chatMessage.dateTimeCreated._nanoseconds,
                    );
            if (chatMessage.dateTimeUpdated)
                chatMessage.dateTimeUpdated =
                    dateTimeHelper.convertFirestoreTimestampToDate(
                        chatMessage.dateTimeUpdated._seconds,
                        chatMessage.dateTimeUpdated._nanoseconds,
                    );
            return chatMessage as VendorInbox;
        }
        return null;
    } catch (exception) {
        throw exception;
    }
};

const subscribeFirstUnreadInboxes = (
    userId: string,
    snapShotHasUnreadInboxes: (param: QuerySnapshot<DocumentData>) => void,
) => {
    const path = `${DBCollection.Vendors}/${userId}/${DBCollection.InBoxes}`;
    const queryRef = collection(db, path);
    const q = query(
        queryRef,
        where("unreadBookingIds", "!=", []),
        orderBy("unreadBookingIds", "asc"),
        orderBy("dateTimeUpdated", "desc"),
        limit(1),
    );
    return onSnapshot(q, snapShotHasUnreadInboxes);
};

export const chatInboxService = {
    subscribeLatestInboxes,
    fetchVendorInboxes,
    subscribeFirstUnreadInboxes,
    getLatestInbox,
    getUnreadInboxByVendorId,
};
