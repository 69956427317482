export const defaultDate: string = "1970-01-01";

export const defaultStartTime: string = "09:00";
export const defaultEndTime: string = "18:00";
export const defaultNotiPaginationLimit: number = 20;
export const defaultInboxPaginationLimit: number = 20;
export const fieldDateTimeCreated: string = "dateTimeCreated";
export const fieldDateTimeUpdated: string = "dateTimeUpdated";
export const defaultPaginationLimit: number = 10;
export const defaultVendorListPaginationLimit: number = 20;
export const defaultLat: number = 55.675758;
export const defaultLng: number = 12.56902;

export const dateStringFormat = "ddd, MMM DD YYYY";
export const dateTimeStringFormat = "ddd, MMM DD YYYY HH:mm";
export const timeStringFormat = "HH:mm";
export const timeStringHalfDayFormat = "hh:mm A";

export const defaultPaging = {
    sortingDirection: "desc",
    orderByKey: fieldDateTimeCreated,
    amount: 10,
    lastId: "",
};

export const phoneRegExp =
    /^[\+]?((\\+[1-9_ ]{1,4})|(\\([0-9_ ]{2,3}\\))|([0-9_ ]{2,4}))*?[0-9_ ]{3,4}?[0-9_ ]{3,4}?$/;
