import { TabPanel } from "@mui/lab";
import { List } from "@mui/material";
import { EmptyList } from "components/UI";
import { useAuthContext } from "context/AuthContext";
import { DocumentData, QuerySnapshot } from "firebaseSetup";
import { dateTimeHelper } from "helpers/date-time.helper";
import { getConsolidateStatusTextByStatus } from "helpers/status.helper";
import { SHARED_DATE_TIME_UPDATED } from "shared/constants/shared-db.constant";
import { defaultPaginationLimit } from "shared/constants/shared.constant";
// import Bookings from "mocks/bookings.mock";
import { ReservationDetail } from "models/bookings.model";
import { PaginationModel } from "models/pagination.model";
import React, { useLayoutEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "react-query";
import { bookingService } from "services/booking.service";
import { chatService } from "services/chat.service";
import { getNoBookingRequestMessage } from "shared/constants/messages.constant";
import { CONSOLIDATE_STATUS } from "shared/constants/status";
import BookingItem from "./BookingItems";
import { BookingItemsSkeleton } from "./BookingsSkeleton";
export interface ConsolidateStatusPanelTabProps {
    chatRoomId: string;
    bookingId: string;
    userId: string;
    consolidateStatus: CONSOLIDATE_STATUS;
    setActiveBookingItem: (item: ReservationDetail) => void;
}

export const ConsolidateStatusPanelTab = (
    props: ConsolidateStatusPanelTabProps,
) => {
    const { chatRoomId, bookingId, consolidateStatus } = props;
    const queryKey = `booking-${chatRoomId}-${consolidateStatus}`;
    const queryClient = useQueryClient();
    const auth = useAuthContext();

    const fetchBookings = async ({ pageParam = "" }) => {
        const result =
            await bookingService.getBookingsByChatRoomIdAndConsolidateStatus(
                chatRoomId,
                consolidateStatus,
                defaultPaginationLimit,
                pageParam,
            );
        result.lists = result.lists.map((booking) => {
            booking.meetingDate =
                dateTimeHelper.convertFirestoreTimestampToDate(
                    booking.meetingDate._seconds,
                    booking.meetingDate._nanoseconds,
                );
            return booking;
        });
        return result;
    };

    const {
        data: items,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery(queryKey, fetchBookings, {
        getNextPageParam: (
            lastPage: PaginationModel<ReservationDetail[]>,
            pages,
        ) => {
            return lastPage.page.isLastPage ? undefined : lastPage.page.lastId;
        },
        refetchOnWindowFocus: false,
    });

    useLayoutEffect(() => {
        const unSub = chatService.subscribeUpdatedBookings(
            snapShotFirstUpdatedBooking,
            chatRoomId,
            consolidateStatus,
            SHARED_DATE_TIME_UPDATED,
            "desc",
        );
        return () => {
            unSub();
        };
    }, [chatRoomId, consolidateStatus]);

    const snapShotFirstUpdatedBooking = async (
        querySnapshot: QuerySnapshot<DocumentData>,
    ) => {
        const bookingChange = await querySnapshot.docChanges().map((change) => {
            if (querySnapshot.metadata.fromCache) {
                return null;
            }

            const booking = change.doc.data();
            booking.meetingDate = booking.meetingDate.toDate();
            return booking as ReservationDetail;
        });
        const bookings = await querySnapshot.docs.map((doc) => {
            const booking = doc.data();
            booking.meetingDate = booking.meetingDate.toDate();
            return booking as ReservationDetail;
        });

        if (bookings.length > 0 && itemSize > 0 && bookingChange[0]) {
            queryClient.setQueryData(
                queryKey,
                (prev: InfiniteData<PaginationModel<ReservationDetail[]>>) => {
                    return prev
                        ? {
                              ...prev,
                              pages: prev.pages.map(
                                  (
                                      page: PaginationModel<
                                          ReservationDetail[]
                                      >,
                                  ) => {
                                      const lists = page.lists.filter(
                                          (booking) =>
                                              booking.id !== bookings[0].id,
                                      );

                                      return {
                                          page: page.page,
                                          lists: [bookings[0], ...lists],
                                      };
                                  },
                              ),
                          }
                        : prev;
                },
            );
        }
    };

    const markAsRead = (newItem: ReservationDetail) => {
        if (auth.authUser.isVendor)
            queryClient.setQueryData(
                queryKey,
                (prev: InfiniteData<PaginationModel<ReservationDetail[]>>) => {
                    return prev
                        ? {
                              ...prev,
                              pages: prev.pages.map(
                                  (
                                      page: PaginationModel<
                                          ReservationDetail[]
                                      >,
                                  ) => {
                                      const lists = page.lists.map(
                                          (booking) => {
                                              return booking.id === newItem.id
                                                  ? ({
                                                        ...newItem,
                                                        vendorRead: true,
                                                    } as ReservationDetail)
                                                  : booking;
                                          },
                                      );

                                      return { page: page.page, lists: lists };
                                  },
                              ),
                          }
                        : prev;
                },
            );

        props.setActiveBookingItem(newItem);
    };

    const itemSize = items?.pages?.reduce((x, y) => x + y.lists.length, 0) ?? 0;

    if (isFetching && !items)
        //  || !bookingDetail
        return (
            <>
                <BookingItemsSkeleton />
                <BookingItemsSkeleton />
                <BookingItemsSkeleton />
                <BookingItemsSkeleton />
            </>
        );

    return (
        <div className="border-r border-gray-300">
            <List
                sx={{
                    width: "100%",
                    minWidth: {
                        lg: "initial",
                    },
                    p: 0,
                }}
                component={"div"}
            >
                {itemSize === 0 && (
                    <TabPanel value={consolidateStatus} sx={{ p: 0 }}>
                        <EmptyList
                            label={getNoBookingRequestMessage(
                                getConsolidateStatusTextByStatus(
                                    consolidateStatus,
                                ),
                            )}
                            bgTransparent
                        />
                    </TabPanel>
                )}
                {itemSize > 0 && (
                    <TabPanel value={consolidateStatus} sx={{ p: 0 }}>
                        <div
                            id="scrollableBookings"
                            className="custom-scrollbar"
                            style={{
                                maxHeight: "calc(100vh - 180px)",
                                overflowY: "auto",
                            }}
                        >
                            <InfiniteScroll
                                dataLength={items?.pages ? itemSize : 0}
                                next={fetchNextPage}
                                hasMore={hasNextPage ?? false}
                                loader={
                                    (isFetching || isFetchingNextPage) && (
                                        <BookingItemsSkeleton />
                                    )
                                }
                                scrollableTarget="scrollableBookings"
                            >
                                {items &&
                                    items?.pages?.map((bookings, indexPage) =>
                                        bookings?.lists?.map(
                                            (
                                                bookingItem: ReservationDetail,
                                                index,
                                            ) => (
                                                <React.Fragment
                                                    key={bookingItem.id}
                                                >
                                                    <BookingItem
                                                        reservationDetail={
                                                            bookingItem
                                                        }
                                                        onClick={(item) =>
                                                            markAsRead(item)
                                                        }
                                                        isActive={
                                                            bookingId ===
                                                            bookingItem.id
                                                        }
                                                    />
                                                </React.Fragment>
                                            ),
                                        ),
                                    )}
                            </InfiniteScroll>
                        </div>
                    </TabPanel>
                )}
            </List>
        </div>
    );
};
