import { Button } from "@mui/material";
import { EmptyList, SectionTitle } from "components";
import { ItemsAddOns } from "components/UI/Vendors/Listing";
import { AddOnsModal } from "components/UI/Vendors/Listing/AddOnsModal";
import { FormikProps } from "formik";
import { MeetingRoom } from "models";
import { AddOn } from "models/add-on.model";
import { useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import MESSAGES from "shared/constants/messages.constant";

export interface AddOnsProps {
    formik: FormikProps<MeetingRoom>;
}

export const AddOns = (props: AddOnsProps) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
    } = props.formik;

    const [openAddOns, setOpenAddOns] = useState(false);
    const [selectedAddOn, setSelectedAddOn] = useState(null);

    const handleClickOpenAddOns = () => {
        setOpenAddOns(true);
    };
    const handleClickCloseAddOns = () => {
        setOpenAddOns(false);
        setSelectedAddOn(null);
    };

    const updateAddOn = (addOn: AddOn) => {
        // CASE ADD
        if (addOn.uid === "-99") {
            addOn.uid = (values.addOns.length + 1).toString();
            const newAddOns = [...values.addOns, addOn];
            setFieldValue("addOns", newAddOns);
        }
        // CASE EDIT
        else {
            const editAddOns = values.addOns.map((item) => {
                if (item.uid === addOn.uid) return { ...addOn };
                else return { ...item };
            });
            setFieldValue("addOns", [...editAddOns]);
        }
    };
    const openEditAddOnModal = (addOn: AddOn) => {
        setSelectedAddOn(addOn);
        setOpenAddOns(true);
    };

    const handleDeleteClicked = (addOn: AddOn) => {
        setSelectedAddOn(null);
        let indexCounter: string = "1";
        const deletedList = [
            ...values.addOns.filter((data, index) => {
                let newData: AddOn = data;
                if (newData.uid !== addOn.uid) {
                    newData.uid = indexCounter;
                    indexCounter = (Number(indexCounter) + 1).toString();
                    return newData;
                }
                return null;
            }),
        ];
        indexCounter = "0";
        setFieldValue("addOns", deletedList);
        setFieldTouched("addOns", true);
    };

    return (
        <>
            <SectionTitle
                title="Add-ons (Optional)"
                helpText={MESSAGES.LISTING_ADD_ONS_TOOLTIP}
                hasTooltip
                tooltipPosition="bottom"
            />
            <Button
                variant="outlined"
                startIcon={<IoAddOutline className="w-4 h-4" />}
                className="min-w-[140px]"
                onClick={handleClickOpenAddOns}
            >
                Create Add-on
            </Button>
            <AddOnsModal
                open={openAddOns}
                closeFn={() => handleClickCloseAddOns()}
                setRtnValueAddOns={(addOn: AddOn) => {
                    updateAddOn(addOn);
                }}
                initTextboxValue={selectedAddOn}
            />
            <div className="mt-4">
                {values.addOns.length > 0 ? (
                    values.addOns.map((addOn, index) => (
                        <ItemsAddOns
                            key={`add-ons-${index}`}
                            addOn={addOn}
                            onEditClick={(addOn: AddOn) =>
                                openEditAddOnModal(addOn)
                            }
                            onDeleteClick={(addOn: AddOn) =>
                                handleDeleteClicked(addOn)
                            }
                        />
                    ))
                ) : (
                    <EmptyList label={MESSAGES.LISTING_ADD_ONS_EMPTY_LIST} />
                )}
            </div>
        </>
    );
};
