import {
    API_ENDPOINT_ฺBOOKING,
    API_ENDPOINT_ฺCHAT,
} from "shared/constants/api.constant";
import { fieldDateTimeCreated } from "shared/constants/shared.constant";
import { Roles } from "enum/role.enum";
import { ReservationDetail } from "models/bookings.model";
import { PaginationModel } from "models/pagination.model";
import { CONSOLIDATE_STATUS, STATUS } from "shared/constants/status";
import { requestService } from "./request.service";

const getBookingByChatRoomIdAndBookingId = async (
    chatRoomId: string,
    bookingId: string,
    userId: string,
    selectedRole: string,
): Promise<ReservationDetail> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺBOOKING.getBookingByChatRoomIdAndBookingId,
        {
            chatRoomId: chatRoomId,
            bookingId: bookingId,
            userId: userId,
            selectedRole: selectedRole,
        },
    )) as ReservationDetail;
};

const getBookingById = async (
    bookingId: string,
    userId: string,
    role: Roles,
): Promise<ReservationDetail> => {
    return (await requestService.onCall(API_ENDPOINT_ฺBOOKING.getBookingById, {
        id: bookingId,
        userId,
        selectedRole: role,
    })) as ReservationDetail;
};

const getBookingByIdWithoutPayoutDetails = async (
    bookingId: string,
): Promise<ReservationDetail> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺBOOKING.getBookingByIdWithoutPayoutDetails,
        {
            id: bookingId,
        },
    )) as ReservationDetail;
};

const approveBookingById = async (id: string): Promise<boolean> => {
    if (id) {
        return (await requestService.onCall(
            API_ENDPOINT_ฺBOOKING.approveBookingById,
            {
                id: id,
            },
        )) as boolean;
    }
    return (await requestService.onCall(
        API_ENDPOINT_ฺBOOKING.approveBookingById,
    )) as boolean;
};

const declineBookingById = async (id: string): Promise<boolean> => {
    if (id) {
        return (await requestService.onCall(
            API_ENDPOINT_ฺBOOKING.declineBookingById,
            {
                id: id,
            },
        )) as boolean;
    }
    return (await requestService.onCall(
        API_ENDPOINT_ฺBOOKING.declineBookingById,
    )) as boolean;
};

const getBookingsByConsolidateStatus = async (
    vendorId: string,
    consolidateStatus: CONSOLIDATE_STATUS,
    amount: number,
    lastId: string | null,
): Promise<PaginationModel<ReservationDetail[]>> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺBOOKING.getBookingsByConsolidateStatus,
        {
            vendorId,
            consolidateStatus,
            amount,
            lastId,
        },
    )) as Promise<PaginationModel<ReservationDetail[]>>;
};

const getBookingsByStatuses = async (
    vendorId: string,
    statuses: STATUS[],
    amount: number,
    lastId: string | null,
    orderByField: string = fieldDateTimeCreated,
    sortingDirection: string = "asc",
): Promise<PaginationModel<ReservationDetail[]>> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺBOOKING.getBookingByStatuses,
        {
            vendorId,
            statuses,
            amount,
            lastId,
            orderByField,
            sortingDirection,
        },
    )) as Promise<PaginationModel<ReservationDetail[]>>;
};

const getBookingsByChatRoomIdAndConsolidateStatus = async (
    chatRoomId: string,
    consolidateStatus: CONSOLIDATE_STATUS,
    amount: number,
    lastId: string | null,
): Promise<PaginationModel<ReservationDetail[]>> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺBOOKING.getBookingsByChatRoomIdAndConsolidateStatus,
        {
            chatRoomId,
            consolidateStatus,
            amount,
            lastId,
        },
    )) as Promise<PaginationModel<ReservationDetail[]>>;
};

const updateReadStatusByBookingId = async (
    bookingId: string,
    selectedRole: Roles,
): Promise<string> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺCHAT.updateReadStatusByBookingId,
        {
            bookingId,
            selectedRole,
        },
    )) as Promise<string>;
};

export const bookingService = {
    getBookingByChatRoomIdAndBookingId,
    getBookingById,
    getBookingByIdWithoutPayoutDetails,
    approveBookingById,
    declineBookingById,
    getBookingsByConsolidateStatus,
    getBookingsByChatRoomIdAndConsolidateStatus,
    updateReadStatusByBookingId,
    getBookingsByStatuses,
};
