import {
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    Typography,
} from "@mui/material";
import { DynamicIcon, modifyIconNameToPascal } from "components/UI/DynamicIcon";
import { FormikProps } from "formik";
import { Amenity, MeetingRoom } from "models";
import React from "react";
import { IoCloseCircleOutline, IoCreateOutline } from "react-icons/io5";
export interface AmenityItemProps {
    formik: FormikProps<MeetingRoom>;
    handleCheckedChange: (e) => void;
    deleteAction?: (id: string) => void;
    updateAction?: (amenity: Amenity) => void;
    data: Amenity;
    showButtons?: boolean;
    itemIsSelected: boolean;
}

export const AmenityItem = (props: AmenityItemProps) => {
    const { handleBlur } = props.formik;

    const {
        data,
        handleCheckedChange,
        deleteAction,
        updateAction,
        itemIsSelected,
        showButtons = false,
    } = props;

    return (
        <React.Fragment key={data?.id + "frag"}>
            <FormControlLabel
                key={data?.id + "control-checkbox"}
                className="w-full mx-0 item-amenities"
                label={
                    <>
                        <div className="flex flex-col items-baseline py-3.5 ml-1 sm:flex-row -mt-0.5">
                            <Typography
                                variant="body2"
                                className="font-bold label-clickable"
                                sx={{ width: 135 }}
                            >
                                {data?.title}
                            </Typography>
                            <div className="relative flex items-baseline justify-center my-3 mr-5 text-xl sm:my-0 sm:w-20 sm:ml-1 top-1">
                                <DynamicIcon
                                    name={modifyIconNameToPascal(data?.icon)}
                                    className="-mt-1"
                                />
                            </div>
                            <Typography
                                variant="body2"
                                className="flex-wrap flex-1"
                            >
                                {data?.description}
                            </Typography>
                            {showButtons ? (
                                <div
                                    className="flex items-baseline relative top-0.5 pointer-events-auto"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <IconButton
                                        aria-label="edit"
                                        color="inherit"
                                        className="-ml-2 sm:ml-0 sm:py-0"
                                        onClick={async () => {
                                            updateAction(data);
                                        }}
                                    >
                                        <IoCreateOutline className="text-xl" />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        className="py-0 text-xl"
                                        color="primary"
                                        onClick={(e) => {
                                            deleteAction(data.id);
                                        }}
                                    >
                                        <IoCloseCircleOutline className="text-xl" />
                                    </IconButton>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                }
                control={
                    <Checkbox
                        checked={itemIsSelected}
                        value={data?.id}
                        id={data?.id}
                        onChange={(e) => {
                            handleCheckedChange({
                                item: data,
                                event: e,
                            });
                        }}
                        onBlur={handleBlur}
                        className={`self-baseline ${
                            showButtons ? "pt-3.5" : "pt-2.5"
                        }`}
                    />
                }
                labelPlacement="end"
            />
            <Divider
                key={data?.id + "divider"}
                className="border-0 border-t border-dashed"
            />
        </React.Fragment>
    );
};
