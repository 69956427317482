import { Skeleton } from "@mui/material";

export const VendorWorkspacesSkeleton = () => {
    return (
        <>
            <div className="mb-6 md:mb-8">
                <Skeleton variant="text" className="w-full"></Skeleton>
                <Skeleton variant="text" className="w-full"></Skeleton>
                <Skeleton variant="text" className="w-full"></Skeleton>
                <Skeleton variant="text" className="w-full"></Skeleton>
                <Skeleton variant="text" className="w-full"></Skeleton>
            </div>
        </>
    );
};
