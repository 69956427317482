import { Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { IoAddOutline } from "react-icons/io5";

const getClassName = (className, isActive) => {
    if (!isActive) return className;
    return `${className} ${className}-active`;
};

export const Dropzone = ({
    onDrop,
    accept,
    maxSize,
    maxFiles,
    children,
    onFileDialogCancel,
    rejectedFilesRef,
    customValidateMessages = null,
}) => {
    const { getRootProps, getInputProps, isDragActive, fileRejections } =
        useDropzone({
            onDrop,
            accept,
            maxSize,
            maxFiles,
            onFileDialogCancel,
        });
    const showErrors = () => {
        let errorMessages = [];
        if (rejectedFilesRef)
            rejectedFilesRef
                .map((x) => x.errors.map((error) => error.message))
                .forEach((error) => {
                    if (errorMessages.filter((x) => x !== error[0])) {
                        errorMessages.push(error[0]);
                    }
                });
        const distinctMessages = Array.from(
            new Set(
                errorMessages.map((x) =>
                    customValidateMessages ? customValidateMessages(x) : x,
                ),
            ),
        );
        return distinctMessages.join("<br>");
    };

    return (
        <div
            className={`${getClassName(
                "dropzone",
                isDragActive,
            )} top-0 left-0 w-full h-full`}
            {...getRootProps()}
        >
            <input className="dropzone-input" {...getInputProps()} />
            <Box className="flex flex-col items-center justify-center h-full py-2 text-center cursor-pointer">
                {isDragActive ? (
                    <Typography
                        variant="body2"
                        className="flex items-center justify-center h-6 p-2 my-3 text-center text-grey-500"
                    >
                        Release to drop the files here
                    </Typography>
                ) : (
                    <Typography
                        variant="body2"
                        className="flex items-center justify-center h-6 p-2 my-3 text-center text-grey-500"
                    >
                        <IoAddOutline className="hidden mr-1 text-2xl sm:block" />
                        Drop image here or click to upload
                    </Typography>
                )}
                {children}
            </Box>
            <div
                className="px-3 py-2 text-sm text-center text-error"
                dangerouslySetInnerHTML={{ __html: showErrors() }}
            ></div>
        </div>
    );
};
