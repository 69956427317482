import {
    CardActionArea,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
    Typography,
} from "@mui/material";
import StatusReservation from "components/Dashboard/StatusReservation";
import { useAuthContext } from "context/AuthContext";
import { Roles } from "enum/role.enum";
import { dateTimeHelper } from "helpers/date-time.helper";
import { ReservationDetail } from "models/bookings.model";
import { useEffect } from "react";
import { IoChevronBack, IoEllipse, IoManOutline } from "react-icons/io5";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { bookingService } from "services/booking.service";
import { chatService } from "services/chat.service";
import ROUTES from "shared/constants/routes.constant";
import theme from "styles/theme";

// import { LazyLoadImage } from "react-lazy-load-image-component";

interface BookingItemProps {
    reservationDetail: ReservationDetail;
    isActive: boolean;
    onClick?: (item: ReservationDetail) => void;
}
const BookingItem = (props: BookingItemProps) => {
    const auth = useAuthContext();
    const navigate = useNavigate();
    const reservationDetail = props.reservationDetail;
    const [searchParams, setSearchParams] = useSearchParams();
    const vendorIdSearchParam = searchParams.get("vendorId");
    const vendorNameSearchParam = searchParams.get("vendorName");
    const handleWhenClickBookingItem = () => {
        const bookingId = reservationDetail.id;
        const chatRoomId = reservationDetail.chatRoomId;
        let inboxUrl = generatePath(ROUTES.Messages, {
            chatRoomId,
            bookingId,
        });
        if (vendorIdSearchParam)
            inboxUrl +=
                "?vendorId=" +
                vendorIdSearchParam +
                "&vendorName=" +
                vendorNameSearchParam;
        navigate(inboxUrl);
        props.onClick(reservationDetail);
        if (auth.authUser.isVendor)
            chatService.updateLastVisitBookingId(bookingId);
    };

    useEffect(() => {
        if (props.isActive) {
            if (auth.authUser.isVendor)
                bookingService.updateReadStatusByBookingId(
                    reservationDetail.id,
                    Roles.Vendor,
                );
            props.onClick(reservationDetail);
        }
    }, [props.isActive]);

    return (
        <>
            <CardActionArea
                onClick={() => {
                    handleWhenClickBookingItem();
                }}
            >
                <ListItem
                    alignItems="flex-start"
                    className={`px-4 xl:py-5 2xl:px-10 ${
                        props.isActive && "bg-notification-light"
                    }`}
                    component={"div"}
                >
                    <ListItemAvatar
                        sx={{
                            width: "35%",
                            height: "auto",
                            maxWidth: 120,
                            [theme.breakpoints.up(1600)]: {
                                width: 130,
                                height: 80,
                                maxWidth: "none",
                            },
                        }}
                        className="mt-0 mr-3 relative leading-[0] aspect-[5/3]"
                    >
                        {props.isActive && (
                            <IoChevronBack className="absolute hidden -translate-y-1/2 2xl:text-2xl text-grey-500 lg:block -left-4 2xl:-left-9 top-1/2" />
                        )}

                        <Skeleton
                            variant="rectangular"
                            className="absolute inset-0 h-full"
                        />
                        <img
                            alt="Collaborative Meeting"
                            src={reservationDetail.meetingRoomImages[0].url}
                            className="object-cover w-full 2xl:w-[130px] 2xl:h-20 aspect-[5/3]"
                        />
                    </ListItemAvatar>
                    <ListItemText
                        className="my-0"
                        primary={
                            <div className="relative flex flex-col">
                                {(!reservationDetail?.vendorRead ?? false) && (
                                    <IoEllipse className="text-notification text-[10px] xl:text-sm absolute right-0 top-0" />
                                )}
                                <Typography
                                    variant="body2"
                                    color="text.primary"
                                    className="pr-3.5 font-bold"
                                >
                                    {reservationDetail?.meetingRoomTitle}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.primary"
                                    className="text-sm 2xl:text-base"
                                >
                                    <span className="font-semibold">
                                        {dateTimeHelper.convertDateTimeToString(
                                            reservationDetail.meetingDate,
                                        ) ?? "-"}
                                    </span>

                                    <span className="mx-2">|</span>
                                    <span className="font-semibold">
                                        {
                                            reservationDetail?.meetingStartTimeString
                                        }
                                        -
                                        {
                                            reservationDetail?.meetingEndTimeString
                                        }
                                    </span>
                                </Typography>
                                <div className="flex flex-wrap items-center justify-between mt-2">
                                    <Typography
                                        variant="body2"
                                        color="initial"
                                        className="flex items-center mb-0 text-sm font-semibold 2xl:text-base"
                                    >
                                        <IoManOutline className="mr-1 -ml-1 text-xl font-semibold" />
                                        {reservationDetail?.totalPeople}
                                    </Typography>
                                    <StatusReservation
                                        consolidateStatus={
                                            reservationDetail?.consolidateStatus
                                        }
                                        status={reservationDetail?.status}
                                    />
                                </div>
                            </div>
                        }
                    />
                </ListItem>
            </CardActionArea>
            <Divider component="div" />
        </>
    );
};

export default BookingItem;
