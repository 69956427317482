import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ModalConfirmation } from "components/UI/Modal";
import { AddOn } from "models/add-on.model";
import { useState } from "react";
import { IoCloseCircleOutline, IoCreateOutline } from "react-icons/io5";
import { MdCircle } from "react-icons/md";
import MESSAGES from "shared/constants/messages.constant";

interface ItemsAddOnsProps {
    addOn: AddOn;
    onEditClick: (addOn: AddOn) => void;
    onDeleteClick: (addOn: AddOn) => void;
}

export const ItemsAddOns: React.FC<ItemsAddOnsProps> = (props) => {
    const { addOn } = props;
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    let addOnPricePerUnitStr = "";

    let isFree: boolean;
    if (addOn.pricePerUnit === 0) {
        isFree = true;
    } else {
        isFree = false;
        addOnPricePerUnitStr = addOn.pricePerUnit.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
        });
    }
    return (
        <>
            <div className="pt-2 pb-4 border-0 border-t border-dashed last:border-b border-divider">
                <div className="flex flex-col font-bold sm:flex-row sm:items-baseline">
                    <div className="flex flex-col w-full sm:w-[160px] sm:mr-4">
                        <div className="text-base">{addOn.title}</div>
                        <div className="flex items-center text-xl">
                            <MdCircle className="text-[8px] mr-2" />
                            <div>
                                {isFree
                                    ? "FREE"
                                    : addOnPricePerUnitStr + " DKK"}
                            </div>
                        </div>
                    </div>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="flex-1 sm:mr-3"
                    >
                        {addOn.description}
                    </Typography>
                    <div className="flex items-baseline relative top-0.5">
                        <IconButton
                            aria-label="edit"
                            color="inherit"
                            className="-ml-2 sm:ml-0"
                            onClick={() => props.onEditClick(addOn)}
                        >
                            <IoCreateOutline className="text-xl" />
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            className="text-xl"
                            color="primary"
                            onClick={() => setOpenConfirmDelete(true)}
                        >
                            <IoCloseCircleOutline />
                        </IconButton>
                    </div>
                </div>
            </div>
            <ModalConfirmation
                severity="primary"
                title={MESSAGES.ADDON_DELETE_MODAL_CONFIRMATION_TITLE}
                btnCancelText={MESSAGES.NO}
                btnText={MESSAGES.YES}
                open={openConfirmDelete}
                closeFn={() => setOpenConfirmDelete(false)}
                confirmFn={() => {
                    props.onDeleteClick(addOn);
                    setOpenConfirmDelete(false);
                }}
            >
                {MESSAGES.ADDON_DELETE_MODAL_CONFIRMATION_BODY}
            </ModalConfirmation>
        </>
    );
};
