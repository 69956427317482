import { Button, Typography } from "@mui/material";
import { JCTooltip } from "components";
import { useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";

interface SectionTitleProps {
    title: string;
    helpText?: string;
    className?: string;
    labelClassName?: string;
    labelVariantInherit?: boolean;
    hasTooltip?: boolean;
    tooltipPosition?:
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left-start"
        | "left"
        | "right-end"
        | "right-start"
        | "right"
        | "top-end"
        | "top-start"
        | "top";
    showRequiredSymbol?: boolean;
}
export const SectionTitle: React.FC<SectionTitleProps> = (props) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    return (
        <div
            className={`mb-2 ${!props.hasTooltip && "md:mb-4"} ${
                props.className
            }`}
        >
            <Typography
                variant={props.labelVariantInherit ? "inherit" : "h3"}
                color="initial"
                className={`mb-1 ${
                    props.labelClassName !== undefined && props.labelClassName
                } ${props.labelVariantInherit && "text-base font-bold"}`}
                component="div"
            >
                {props.title}

                {/* REVIEW: Tooltip title > Use <span> instead <div> because the console show the error when <p> wrap <div>. Normally, tooltip content renders to <p> tag we cannot use <p> or <div> inside <p> */}
                {props.hasTooltip && (
                    <JCTooltip
                        title={
                            <span
                                className="whitespace-normal inline-block"
                                dangerouslySetInnerHTML={{
                                    __html: props.helpText,
                                }}
                            />
                        }
                        placement={props.tooltipPosition}
                        tooltipOpen={openTooltip}
                        onClose={() => setOpenTooltip(false)}
                    >
                        <Button
                            className="min-w-0 py-0 text-xl relative top-0 px-1"
                            onClick={() => setOpenTooltip(true)}
                        >
                            <IoInformationCircleOutline />
                        </Button>
                    </JCTooltip>
                )}
                {props.showRequiredSymbol && (
                    <span className="text-black ml-0.5">*</span>
                )}
                {/* Add-ons (Optional) */}
            </Typography>
            {props.helpText !== undefined && !props.hasTooltip && (
                <Typography
                    variant="body1"
                    color="initial"
                    className="text-grey-500"
                >
                    {props.helpText}
                </Typography>
            )}
        </div>
    );
};
