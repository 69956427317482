import { SortDirection, TableCell } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { HeadCellSortable } from "./HeadCellSortable";
import { HeadCellProps } from "./type";
export interface CustomHeadCellProps extends HeadCellProps {
    updateActiveColumn?: (activeColumn: HeadCellProps) => void;
}

export const TableHeadCell = (props: CustomHeadCellProps) => {
    const [sortingDirection, setSortingDirection] = useState<SortDirection>(
        props.sortingDirection,
    );
    const [activeColumn, setActiveColumn] = useState<HeadCellProps>(props);
    const updateSortingDirection = (direction: SortDirection) => {
        setSortingDirection(direction);
        setActiveColumn((prev) => updateActiveColumn(prev, direction));
    };
    const updateActiveColumn = (
        activeColumn: HeadCellProps,
        direction: SortDirection,
    ) => {
        activeColumn = {
            ...activeColumn,
            sortingActive: true,
            sortingDirection: direction,
        };
        return activeColumn;
    };
    useEffect(() => {
        if (activeColumn !== props) props.updateActiveColumn(activeColumn);
    }, [activeColumn]);
    useLayoutEffect(() => {
        if (!props.sortingActive) setSortingDirection(props.sortingDirection);
    });
    return (
        <TableCell
            key={props.id}
            align={props.numeric === true ? "right" : "left"}
            padding={props.disablePadding ? "none" : "normal"}
            className={`font-bold text-bold text-black ${
                props.disabledSortColumn ? "pointer-events-none" : ""
            } ${props.className}`}
        >
            {props.disabledSortColumn ? (
                props.label
            ) : (
                <HeadCellSortable
                    label={props.label}
                    disabledSortColumn={props.disabledSortColumn}
                    sortingActive={props.sortingActive}
                    sortingDirection={sortingDirection}
                    updateSortingDirection={updateSortingDirection}
                />
            )}

            {props.children && props.children}
        </TableCell>
    );
};
