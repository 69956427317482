import { Container, Grid } from "@mui/material";
import { AdminLayoutProps } from "../types";

export const AdminSingleBlockLayout: React.FunctionComponent<
  AdminLayoutProps
> = (props) => {
  const { children } = props;
  return (
    <Container maxWidth={false} className="px-4 lg:px-10">
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 3 }}
        className="mt-4 mb-6"
      >
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};
