import { Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import { Workspace } from "models/workspace.model";
import React from "react";
interface WorkspaceWithCheckboxProps {
    data: Workspace;
    itemIsSelected: boolean;
    selectItem: (selected: boolean) => void;
}
export const WorkspaceWithCheckbox = ({
    data,
    itemIsSelected,
    selectItem,
}: WorkspaceWithCheckboxProps) => {
    const onChange = (event: any) => {
        selectItem(event.target.checked);
    };
    return (
        <React.Fragment>
            <FormControlLabel
                className="w-full mx-0 item-amenities"
                label={
                    <>
                        <div className="flex flex-col items-baseline py-3.5 ml-1 sm:flex-row -mt-0.5">
                            <Typography variant="body2" className="font-bold">
                                {data.title}
                            </Typography>
                        </div>
                    </>
                }
                control={
                    <Checkbox
                        checked={itemIsSelected}
                        value={data?.id}
                        // id={data?.id}
                        className="self-baseline pt-2.5"
                        onChange={onChange}
                    />
                }
                labelPlacement="end"
            />
            <Divider className="border-0 border-t border-dashed" />
        </React.Fragment>
    );
};
