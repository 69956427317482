import { Typography } from "@mui/material";
import { memo } from "react";
import { ChatProps } from "./chat";

const ChatSystemNotice = (props: ChatProps) => {
    return (
        <div className="space-y-2 text-center">
            <Typography
                variant="body1"
                color="secondary"
                className="text-grey-500 text-[12px]"
            >
                {props.date}
            </Typography>
            {props.message && (
                <Typography
                    variant="body1"
                    color="secondary"
                    className="inline-block px-4 py-1 text-sm whitespace-pre-line rounded-full text-grey-600 bg-grey-100"
                >
                    {props.message}
                </Typography>
            )}
        </div>
    );
};

export default memo(ChatSystemNotice);
