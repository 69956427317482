import { Button, FormHelperText, Typography } from "@mui/material";
import {
    AmenitiesList,
    AmenityProps,
} from "components/ListingForm/Amenities/AmenitiesList";
import { ModalConfirmation, SectionTitle } from "components/UI";
import AddAmenitiesModal, {
    AmenityModalMode,
    AmenityModalSetup,
} from "components/UI/Vendors/Listing/AddAmenitiesModal";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { Amenity, CustomAmenity } from "models/amenity.model";
import { useEffect, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { amenityService } from "services/amenity.service";
import MESSAGES from "shared/constants/messages.constant";
import { CustomAmenitiesList } from "./CustomAmenitiesList";

let modalSetup: AmenityModalSetup;
let deleteFunction: () => void;
export const Amenities = (props: AmenityProps) => {
    const [openAddAmenitiesModal, setOpenAddAmenitiesModal] = useState(false);
    const [refetchCustomAmenity, setRefetchCustomAmenity] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const fullPageLoaderContext = useFullPageLoadingContext();
    const openEditItem = (amenityModalSetup: AmenityModalSetup) => {
        modalSetup = amenityModalSetup;
        setOpenAddAmenitiesModal(true);
    };
    const postActions = () => {
        setRefetchCustomAmenity(true);
        setRefetchCustomAmenity(false);
    };

    const addCustomAmenity = async (amenity: Amenity) => {
        amenityService.addNewVendorAmenity(amenity).then(() => {
            postActions();
            setOpenAddAmenitiesModal(false);
            fullPageLoaderContext.hide();
        });
    };
    const updateCustomAmenity = async (amenity: Amenity) => {
        amenityService.updateVendorAmenityByAmenityId(amenity).then(() => {
            postActions();
            setOpenAddAmenitiesModal(false);
            fullPageLoaderContext.hide();
        });

        // Update the amenity in the current form state
        const foundAmenities =
            props.formik.values.customMeetingRoomAmenities.filter(
                (x) => x.uid === amenity.id,
            );
        if (foundAmenities && foundAmenities.length > 0) {
            const editedAmenity: CustomAmenity = {
                uid: amenity.id,
                title: amenity.title,
                description: amenity.description,
                icon: amenity.icon,
            };

            const updatedAmenitiesList =
                props.formik.values.customMeetingRoomAmenities.map((u) =>
                    u.uid !== editedAmenity.uid ? u : editedAmenity,
                );

            props.formik.setFieldValue(
                "customMeetingRoomAmenities",
                updatedAmenitiesList,
            );
            props.formik.setFieldTouched(
                "customMeetingRoomAmenities",
                true,
                false,
            );
        }
    };
    const deleteCustomAmenity = async (id: string) => {
        deleteFunction = () => {
            setIsLoading(true);
            amenityService.deleteVendorAmenityByAmenityId(id).then(() => {
                setOpenConfirmDelete(false);
                postActions();
                setIsLoading(false);
                //Remove deleted item from current form state
                props.formik.setFieldValue(
                    "customMeetingRoomAmenities",
                    props.formik.values.customMeetingRoomAmenities.filter(
                        (x) => x.uid !== id,
                    ),
                );
                props.formik.setFieldTouched(
                    "customMeetingRoomAmenities",
                    true,
                    false,
                );
            });
        };
        setOpenConfirmDelete(true);
    };

    const addNewItemModalSetup = {
        initValue: { id: "", icon: "", title: "", description: "" } as Amenity,
        action: addCustomAmenity,
        mode: AmenityModalMode.addNew,
    } as AmenityModalSetup;

    useEffect(() => {
        if (isLoading) fullPageLoaderContext.show();
        else fullPageLoaderContext.hide();
    }, [isLoading]);
    return (
        <>
            <SectionTitle
                title="Amenities"
                helpText={MESSAGES.LISTING_AMENITIES_TOOLTIP}
                hasTooltip
                tooltipPosition="right"
                showRequiredSymbol
            />
            <Typography
                variant="body2"
                color="initial"
                className="mb-2 font-bold"
            >
                Select amenities
            </Typography>
            <AmenitiesList formik={props.formik}></AmenitiesList>
            <SectionTitle
                title="Custom Amenity"
                helpText={MESSAGES.LISTING_CUSTOM_AMENITIES_TOOLTIP}
                className="pt-7"
                hasTooltip
                tooltipPosition="bottom"
            />
            <Button
                variant="outlined"
                startIcon={<IoAddOutline className="w-4 h-4" />}
                className="min-w-[140px]"
                onClick={() => {
                    modalSetup = addNewItemModalSetup;
                    setOpenAddAmenitiesModal(true);
                }}
            >
                Create Custom Amenity
            </Button>
            <AddAmenitiesModal
                open={openAddAmenitiesModal}
                closeFn={() => setOpenAddAmenitiesModal(false)}
                modalSetup={modalSetup}
            />
            <div className="mt-4">
                <CustomAmenitiesList
                    formik={props.formik}
                    deleteAction={deleteCustomAmenity}
                    updateAction={async (amenity: Amenity) => {
                        const modalSetup: AmenityModalSetup = {
                            initValue: amenity,
                            action: updateCustomAmenity,
                            mode: AmenityModalMode.edit,
                        };
                        openEditItem(modalSetup);
                    }}
                    refetch={refetchCustomAmenity}
                ></CustomAmenitiesList>

                {((props.formik.touched.customMeetingRoomAmenities &&
                    !props.formik.touched.meetingRoomAmenities &&
                    props.formik.errors.customMeetingRoomAmenities) ||
                    (props.formik.touched.meetingRoomAmenities &&
                        props.formik.errors.meetingRoomAmenities)) && (
                    <FormHelperText
                        id="amenities-help-text"
                        className={`mx-0 my-2 w-full text-error`}
                        style={{ fontSize: "13px" }}
                    >
                        {props.formik.touched.customMeetingRoomAmenities &&
                            !props.formik.touched.meetingRoomAmenities &&
                            props.formik.errors.customMeetingRoomAmenities}

                        {props.formik.touched.meetingRoomAmenities &&
                            props.formik.errors.meetingRoomAmenities}
                    </FormHelperText>
                )}
            </div>

            <ModalConfirmation
                severity="primary"
                title={MESSAGES.AMENITIES_DELETE_MODAL_CONFIRMATION_TITLE}
                btnCancelText={MESSAGES.NO}
                btnText={MESSAGES.YES}
                open={openConfirmDelete}
                closeFn={() => setOpenConfirmDelete(false)}
                confirmFn={() => {
                    deleteFunction();
                    setOpenConfirmDelete(false);
                }}
            >
                {MESSAGES.AMENITIES_DELETE_MODAL_CONFIRMATION_BODY}
            </ModalConfirmation>
        </>
    );
};
