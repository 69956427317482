import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {
    CustomHeadCellProps,
    EmptyList,
    ModalConfirmation,
    TableHeadCell,
} from "components";
import TableSkeleton from "components/UI/Table/TableSkeleton";
import { HeadCellProps } from "components/UI/Table/type";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { useNotificationContext } from "context/NotificationContext";
import { Timestamp } from "firebase/firestore";
import { displayAsDecimal } from "helpers/currency.helper";
import { dateTimeHelper } from "helpers/date-time.helper";
import { PaginationModel } from "models/pagination.model";
import { VendorList } from "models/vendor.model";
import React, { useEffect, useState } from "react";
import {
    IoChatboxEllipsesOutline,
    IoDocumentTextOutline,
    IoTrashOutline,
} from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, useMutation } from "react-query";
import {
    generatePath,
    NavLink,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { chatInboxService } from "services/chatInbox.service";
import { vendorService } from "services/vendor.service";
import MESSAGES from "shared/constants/messages.constant";
import ROUTES from "shared/constants/routes.constant";
import { defaultVendorListPaginationLimit } from "shared/constants/shared.constant";
import TableVendorsSkeletonLoading from "./TableVendorsSkeletonLoading";
export const tableVendorsHeadCells: readonly HeadCellProps[] = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Vendor",
        // sortingActive: true,
        sortingDirection: "asc",
    },
    {
        id: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created date",
        sortingDirection: "asc",
    },
    {
        id: "ownerName",
        numeric: false,
        disablePadding: false,
        label: "Owner",
        // disabledSortColumn: true,
        sortingDirection: "asc",
    },
    {
        id: "ownerEmail",
        numeric: false,
        disablePadding: false,
        label: "Email",
        sortingDirection: "asc",
    },
    {
        id: "location",
        numeric: false,
        disablePadding: false,
        label: "Location",
        sortingDirection: "asc",
    },
    {
        id: "totalBooking",
        numeric: true,
        disablePadding: false,
        label: "Completed bookings",
        sortingDirection: "asc",
    },
    {
        id: "revenue",
        numeric: true,
        disablePadding: false,
        label: "Total revenue",
        sortingDirection: "asc",
    },
];

export interface VendorListProps {
    userId: string;
}
let currentColumn: HeadCellProps = tableVendorsHeadCells[0];
export function EnhancedTableHeadVendors(props) {
    const [headers, setHeaders] = useState<HeadCellProps[]>(props.data);
    const [activeColumn, setActiveColumn] = useState<HeadCellProps>(
        props.data[0],
    );
    const updateActiveColumn = (activeColumn: HeadCellProps) => {
        setHeaders((prev) => [
            ...prev.map((header: HeadCellProps) => {
                if (header.id === activeColumn.id) {
                    header.sortingActive = true;
                    header.sortingDirection = activeColumn.sortingDirection;
                    setActiveColumn(header);
                } else {
                    header.sortingActive = false;
                    header.sortingDirection = "asc";
                }
                return header;
            }),
        ]);
        props.updateActiveColumn(activeColumn);
    };
    useEffect(() => {
        const defaultSortingColumn = props.data[0];
        setHeaders([
            ...headers.map((header: HeadCellProps) => {
                if (header.id === defaultSortingColumn.id) {
                    header.sortingActive = true;
                } else {
                    header.sortingActive = false;
                }
                header.sortingDirection = "asc";
                return header;
            }),
        ]);
    }, []);

    useEffect(() => {
        setHeaders([...headers]);
    }, [activeColumn]);
    return (
        <>
            <TableHead
                className="sticky top-0 z-10 bg-white"
                style={{ position: "sticky", zIndex: "2" }}
            >
                <TableRow>
                    {headers.map((headCell: CustomHeadCellProps) => (
                        <TableHeadCell
                            className="sticky top-0 bg-white"
                            key={headCell.id}
                            {...headCell}
                            updateActiveColumn={updateActiveColumn}
                        />
                    ))}
                    <TableCell className="sticky top-0 right-0 bg-white">
                        <span className="sr-only">Actions</span>
                    </TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

const TableVendors: React.FC<VendorListProps> = (props: VendorListProps) => {
    const notificationContext = useNotificationContext();
    const fullPageLoaderContext = useFullPageLoadingContext();
    let [searchParams] = useSearchParams();
    const searchQuery = searchParams.get("query");
    const userId = props.userId;
    const navigate = useNavigate();

    const [, setActiveColumn] = useState<HeadCellProps>(
        tableVendorsHeadCells[0],
    );
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [isFullPageLoading, setIsFullPageLoading] = useState<boolean>(false);
    const [deletingVendorId, setdeletingVendorId] = useState<string>(null);
    const fetchVendors = async ({ pageParam = "" }) => {
        const result = await vendorService.getVendors(
            (searchQuery as string) ?? "",
            defaultVendorListPaginationLimit,
            pageParam,
            currentColumn.id,
            typeof currentColumn.sortingDirection === "boolean"
                ? "desc"
                : currentColumn.sortingDirection.valueOf(),
        );
        result.lists = result.lists?.map((item) => {
            if (item.dateTimeCreated)
                item.dateTimeCreated =
                    dateTimeHelper.convertFirestoreTimestampToDate(
                        (item.dateTimeCreated as any)._seconds,
                        (item.dateTimeCreated as any)._nanoseconds,
                    );
            if (item.dateTimeUpdated)
                item.dateTimeUpdated =
                    dateTimeHelper.convertFirestoreTimestampToDate(
                        (item.dateTimeUpdated as any)._seconds,
                        (item.dateTimeUpdated as any)._nanoseconds,
                    );
            return item;
        });

        return result;
    };

    const {
        data: items,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        isLoading,
        refetch,
        isRefetching,
    } = useInfiniteQuery(`vendor-${userId}-listing`, fetchVendors, {
        getNextPageParam: (lastPage: PaginationModel<VendorList[]>, pages) =>
            lastPage.page.isLastPage ? undefined : lastPage.page.lastId,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    useEffect(() => {
        refetch();
    }, [searchParams.get("query")]);
    const itemSize = items?.pages?.reduce((x, y) => x + y.lists.length, 0) ?? 0;

    const updateActiveColumn = async (activeColumn: CustomHeadCellProps) => {
        setActiveColumn({ ...activeColumn });
        currentColumn = activeColumn;
        try {
            await refetch();
        } catch (ex) {
            console.error(ex);
        }
    };
    const { mutate: mutateDeleteVendor, isLoading: isLoadingDeleteVendor } =
        useMutation(
            async (vendorId: string) => {
                return await vendorService.deleteVendor(vendorId);
            },
            {
                onSuccess: async (res) => {
                    notificationContext.showSuccess(
                        MESSAGES.VENDOR_DELETE_SUCCESSFULLY,
                    );

                    setdeletingVendorId(null);
                    await refetch();
                },
                onError: async (err: any) => {
                    notificationContext.showError(err.message);
                },
            },
        );
    const goToVendorChat = async (vendorId, vendorName) => {
        setIsFullPageLoading(true);
        const latestInbox = await chatInboxService.getLatestInbox(vendorId);
        if (latestInbox)
            navigate(
                generatePath(ROUTES.Messages, {
                    chatRoomId: latestInbox.id,
                    bookingId: latestInbox.lastMessage?.bookingId,
                }) +
                    "?vendorId=" +
                    vendorId +
                    "&vendorName=" +
                    vendorName,
            );
        else
            navigate(
                ROUTES.Messages +
                    "?vendorId=" +
                    vendorId +
                    "&vendorName=" +
                    vendorName,
            );

        setIsFullPageLoading(false);
    };

    useEffect(() => {
        if (isLoadingDeleteVendor || isFullPageLoading)
            fullPageLoaderContext.show();
        else fullPageLoaderContext.hide();
    }, [isLoadingDeleteVendor, isFullPageLoading]);

    return (
        <>
            {isLoading && <TableSkeleton />}
            {!isFetching && itemSize === 0 && (
                <EmptyList
                    label={MESSAGES.VENDOR_LIST_EMPTY_LIST}
                    bgTransparent
                />
            )}

            <TableContainer component={Paper} sx={{ border: 0 }}>
                <div
                    id="scrollableVendors"
                    className="custom-scrollbar"
                    style={{
                        maxHeight: "calc(100vh - 255px)",
                        overflowY: "auto",
                    }}
                >
                    {itemSize > 0 && (
                        <InfiniteScroll
                            dataLength={items?.pages ? itemSize : 0}
                            next={fetchNextPage}
                            hasMore={hasNextPage ?? false}
                            loader={null}
                            scrollableTarget="scrollableVendors"
                            className="custom-scrollbar"
                            style={{ height: "auto", overflow: "inherite" }}
                        >
                            <Table
                                aria-label="Vendors table"
                                sx={{
                                    "& .MuiTableRow-root": {
                                        "&.disabled": {
                                            "& .MuiTableCell-root, & .MuiIconButton-root":
                                                {
                                                    color: "#8e8e8e",
                                                    pointerEvents: "none",
                                                },
                                            "& .MuiIconButton-root.icon-delete":
                                                {
                                                    color: "#2e2e2e",
                                                    pointerEvents: "auto",
                                                },
                                        },
                                    },
                                }}
                            >
                                <EnhancedTableHeadVendors
                                    data={tableVendorsHeadCells}
                                    updateActiveColumn={updateActiveColumn}
                                />

                                <TableBody>
                                    {!isLoading &&
                                        !isFetchingNextPage &&
                                        isRefetching && (
                                            <>
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                                <TableVendorsSkeletonLoading />
                                            </>
                                        )}

                                    {(isFetchingNextPage || !isRefetching) &&
                                        items &&
                                        items?.pages?.map(
                                            (vendors, indexPage) =>
                                                vendors?.lists?.map(
                                                    (
                                                        vendor: VendorList,
                                                        index,
                                                    ) => (
                                                        <React.Fragment
                                                            key={vendor.id}
                                                        >
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                className={
                                                                    vendor.active !==
                                                                        undefined &&
                                                                    vendor.active ===
                                                                        false
                                                                        ? "disabled"
                                                                        : ""
                                                                }
                                                            >
                                                                <TableCell
                                                                    data-th={
                                                                        tableVendorsHeadCells[0]
                                                                            .label
                                                                    }
                                                                >
                                                                    <span className="whitespace-nowrap">
                                                                        {vendor.name ??
                                                                            "-"}
                                                                        {vendor.active !==
                                                                            undefined &&
                                                                            vendor.active ===
                                                                                false &&
                                                                            " (Inactive)"}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell
                                                                    data-th={
                                                                        tableVendorsHeadCells[1]
                                                                            .label
                                                                    }
                                                                >
                                                                    <span className="whitespace-nowrap">
                                                                        {dateTimeHelper.convertTimeStampToString(
                                                                            vendor.dateTimeCreated as Timestamp,
                                                                        ) ??
                                                                            "-"}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell
                                                                    data-th={
                                                                        tableVendorsHeadCells[2]
                                                                            .label
                                                                    }
                                                                >
                                                                    <span className="line-clamp-1">
                                                                        {vendor.ownerName ??
                                                                            "-"}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell
                                                                    data-th={
                                                                        tableVendorsHeadCells[3]
                                                                            .label
                                                                    }
                                                                >
                                                                    <span className="line-clamp-1">
                                                                        {vendor.ownerEmail ??
                                                                            "-"}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell
                                                                    data-th={
                                                                        tableVendorsHeadCells[4]
                                                                            .label
                                                                    }
                                                                >
                                                                    <span className="line-clamp-none sm:line-clamp-1">
                                                                        {vendor.location ??
                                                                            "-"}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    data-th={
                                                                        tableVendorsHeadCells[5]
                                                                            .label
                                                                    }
                                                                >
                                                                    {vendor.totalBooking ??
                                                                        0}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    data-th={
                                                                        tableVendorsHeadCells[6]
                                                                            .label
                                                                    }
                                                                >
                                                                    {vendor.revenue
                                                                        ? displayAsDecimal(
                                                                              vendor.revenue,
                                                                              2,
                                                                              true,
                                                                          )
                                                                        : 0}{" "}
                                                                    DKK
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    data-th="Action"
                                                                    className="sticky right-0 bg-white"
                                                                >
                                                                    <div className="flex justify-end">
                                                                        <NavLink
                                                                            to=""
                                                                            onClick={() =>
                                                                                goToVendorChat(
                                                                                    vendor.id,
                                                                                    vendor.name,
                                                                                )
                                                                            }
                                                                        >
                                                                            <IconButton
                                                                                color="primary"
                                                                                aria-label="message"
                                                                                edge="start"
                                                                                className="text-[22px]"
                                                                            >
                                                                                <IoChatboxEllipsesOutline />
                                                                            </IconButton>
                                                                        </NavLink>
                                                                        <div className="mx-4">
                                                                            <NavLink
                                                                                to={generatePath(
                                                                                    ROUTES.EditVendorProfile,
                                                                                    {
                                                                                        id: vendor.id,
                                                                                    },
                                                                                )}
                                                                            >
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    aria-label="profile"
                                                                                    edge="start"
                                                                                    className="text-[22px]"
                                                                                >
                                                                                    <IoDocumentTextOutline />
                                                                                </IconButton>
                                                                            </NavLink>
                                                                        </div>

                                                                        <IconButton
                                                                            color="primary"
                                                                            aria-label="delete"
                                                                            edge="start"
                                                                            className="text-[22px] icon-delete"
                                                                            onClick={() => {
                                                                                setdeletingVendorId(
                                                                                    vendor.id,
                                                                                );
                                                                                setOpenConfirmation(
                                                                                    true,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <IoTrashOutline />
                                                                        </IconButton>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    ),
                                                ),
                                        )}
                                    {isFetchingNextPage && (
                                        <TableVendorsSkeletonLoading />
                                    )}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    )}
                </div>
            </TableContainer>
            {/* CONFIRMATION MODAL */}
            <ModalConfirmation
                severity="primary"
                title={MESSAGES.VENDOR_DELETE_CONFIRMATION}
                btnCancelText={MESSAGES.VENDOR_DELETE_CONFIRMATION_BUTTON}
                btnText={MESSAGES.VENDOR_DELETE_CANCEL_BUTTON}
                open={openConfirmation}
                closeFn={async () => {
                    await mutateDeleteVendor(deletingVendorId);
                    setOpenConfirmation(false);
                }}
                confirmFn={async () => {
                    setOpenConfirmation(false);
                }}
            >
                <Typography variant="body2" color="initial">
                    {MESSAGES.VENDOR_DELETE_CONFIRMATION_BODY}
                </Typography>
            </ModalConfirmation>
        </>
    );
};

export default TableVendors;
