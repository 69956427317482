import { AuthProvider } from "provider/AuthProvider";
import { FullPageLoaderProvider } from "provider/FullPageLoaderProvider";
import { NotificationProvider } from "provider/NotificationProvider";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <NotificationProvider>
                <FullPageLoaderProvider>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </FullPageLoaderProvider>
            </NotificationProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
