import { Typography } from "@mui/material";
import { useQuery } from "react-query";

/**
 * This component is generated as an example for useQuery hook
 *
 * To learn more about React Query and data fetching,
 * please visit https://react-query.tanstack.com/
 */

// const API_URL =
//   "https://official-joke-api.appspot.com/jokes/programming/random";

const API_URL = "https://pokeapi.co/api/v2/pokemon/ditto";

export const ReactQueryExample = () => {
  const { data, refetch } = useQuery(
    "abilities",
    () => fetch(API_URL).then((res) => res.json()),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleClick = () => {
    // manually refetch
    refetch();
  };

  if (data) {
    const forms = data.forms;
    return (
      <div>
        <Typography variant="h2">React Query Data Fetching Example</Typography>
        {/* <code>Data : {JSON.stringify(forms)}</code> */}

        <main>
          {/* <p>Programmer Jokes {`#${data[0].name}`}</p>
          <p>{data[0].setup}</p>
          <p>{data[0].punchline}</p> */}
          <p>
            <strong>Name:</strong> {forms[0].name}
          </p>
          <p>
            <strong>URL:</strong> {forms[0].url}
          </p>
          <p>
            <button onClick={handleClick}>Give me another</button>
          </p>
        </main>
        <footer>
          <a
            href="https://react-query.tanstack.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go To Documentation
          </a>
        </footer>
      </div>
    );
  }
  return null;
};
