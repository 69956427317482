import {
    Avatar,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import { dateTimeStringFormat } from "shared/constants/shared.constant";
import { useAuthContext } from "context/AuthContext";
import { dateTimeHelper } from "helpers/date-time.helper";
import { getSystemMessageBySystemMessageType } from "helpers/system-message.helper";
import { MessageType } from "models/chat.model";
import { VendorInbox } from "models/inbox.model";
import { IoEllipse } from "react-icons/io5";

interface InboxItemProps {
    onClick?: () => void;
    inboxItem: VendorInbox;
    isActive: boolean;
}

const InboxItem = (props: InboxItemProps) => {
    let auth = useAuthContext();
    const displayDate = (date: Date) => {
        if (!date) return "";
        const isDifferentDate = dateTimeHelper.isDifferentDate(
            new Date(),
            date,
        );
        if (!isDifferentDate)
            return "Today, " + dateTimeHelper.convertDateToTimeString(date);
        else
            return dateTimeHelper.convertDateTimeToString(
                date,
                dateTimeStringFormat,
            );
    };
    const displayUserMessage = (message: string, senderId: string) => {
        return senderId === auth?.authUser?.userId
            ? `You: ${message}`
            : message;
    };
    const displayMessage = (inbox: VendorInbox) => {
        if (!inbox) return "";
        return inbox.lastMessage.type === MessageType.system
            ? getSystemMessageBySystemMessageType(
                  inbox.lastMessage.systemMessageType,
                  inbox.userName,
              )
            : displayUserMessage(
                  inbox.lastMessage?.text,
                  inbox.lastMessage?.senderId,
              );
    };
    return (
        <>
            <ListItem
                alignItems="flex-start"
                className={`relative py-[15px] px-4 xl:px-10 cursor-pointer ${
                    props.isActive && "bg-notification-light"
                }`}
                onClick={props.onClick}
                component={"div"}
            >
                <ListItemAvatar className="mt-0">
                    <Avatar
                        alt={`${props.inboxItem.userName} image`}
                        src={props.inboxItem.userImageURL}
                        className="w-12 h-12"
                    />
                </ListItemAvatar>
                <ListItemText>
                    {props.inboxItem.unreadBookingIds &&
                        props.inboxItem.unreadBookingIds.length > 0 && (
                            <IoEllipse className="text-notification text-[10px] absolute right-1.5 xl:right-2 top-1.5 xl:top-2" />
                        )}
                    <div className="flex flex-wrap items-center justify-between">
                        <Typography
                            variant="body2"
                            color="text.primary"
                            className={`text-lg mb-[3px] ${
                                props.inboxItem.unreadBookingIds &&
                                props.inboxItem.unreadBookingIds.length > 0 &&
                                "font-bold"
                            }`}
                        >
                            {props.inboxItem.userName}
                        </Typography>
                        <span className="text-xs text-grey-900">
                            {props.inboxItem.lastMessage &&
                            props.inboxItem.lastMessage
                                ? displayDate(
                                      props.inboxItem.lastMessage
                                          .dateTimeUpdated as Date,
                                  )
                                : displayDate(
                                      props.inboxItem.dateTimeUpdated as Date,
                                  )}
                        </span>
                    </div>

                    <Typography
                        variant="body1"
                        color="text.primary"
                        className={`truncate ${
                            props.inboxItem.unreadBookingIds &&
                            props.inboxItem.unreadBookingIds.length > 0 &&
                            "font-bold"
                        }`}
                    >
                        {props.inboxItem.lastMessage
                            ? displayMessage(props.inboxItem)
                            : ""}
                    </Typography>
                </ListItemText>
            </ListItem>
            <Divider component="div" />
        </>
    );
};

export default InboxItem;
