import { API_ENDPOINT_ฺUSER_PROFILE } from "shared/constants/api.constant";
import { UserDetails } from "models/user.model";
import { requestService } from "./request.service";

const getUserProfileById = async (userId: string): Promise<UserDetails> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺUSER_PROFILE.getUserProfileById,
        {
            userId: userId,
        },
    )) as UserDetails;
};

export const userService = {
    getUserProfileById,
};
