export const StatusListing = ["publish", "inactive"];
export const StatusNotification = [
    "bookingRequest",
    "bookingChangeRequest",
    "paymentReceived",
];
export enum CONSOLIDATE_STATUS {
    Pending = "Pending",
    Upcoming = "Upcoming",
    Past = "Past",
}
export enum STATUS {
    AwaitingResponse = "AwaitingResponse",
    AwaitingPayment = "AwaitingPayment",
    RequestDeclined = "RequestDeclined",
    Confirmed = "Confirmed",
    Completed = "Completed",
    CanceledByGuest = "CanceledByGuest",
    RequestExpired = "RequestExpired",
}
export const SUB_STATUS = {
    WAITING_RESPONSE: "AwaitingResponse",
    PAYMENT_PENDING: "AwaitingPayment",
    DECLINED: "RequestDeclined",
    PAID: "Confirmed",
    COMPLETED: "Completed",
    CANCELED: "CanceledByGuest",
    EXPIRED: "RequestExpired",
};
export const SUB_STATUS_TEXT = {
    AwaitingResponse: "Awaiting response",
    AwaitingPayment: "Awaiting Payment",
    RequestDeclined: "Request declined",
    Confirmed: "Confirmed",
    Completed: "Completed",
    CanceledByGuest: "CanceledByGuest",
    RequestExpired: "Request expired",
};
export const CONSOLIDATE_STATUS_TEXT = {
    Upcoming: "Upcoming",
    Pending: "Pending",
    Past: "Past",
};
export const TEXT_COLOR = {
    PENDING_ORANGE: "text-status-pending",
    WAITING_RESPONSE_ORANGE: "text-status-waitingResponse",
    PAYMENT_PENDING_PURPLE: "text-status-paymentPending",
    DECLINED_RED: "text-status-declined",
    UPCOMING_GREEN: "text-status-upcoming",
    PAID_GREEN: "text-status-paid",
    PAST_BLACK: "text-status-past",
    COMPLETED_BLACK: "text-status-completed",
    DEFAULT_BLACK: "text-status-DEFAULT",
    CANCELED_RED: "text-status-canceled",
    EXPIRED_RED: "text-status-expired",
};

export const BG_COLOR = {
    PENDING_ORANGE: "bg-status-pending",
    WAITING_RESPONSE_ORANGE: "bg-status-waitingResponse",
    PAYMENT_PENDING_PURPLE: "bg-status-paymentPending",
    DECLINED_RED: "bg-status-declined",
    UPCOMING_GREEN: "bg-status-upcoming",
    PAID_GREEN: "bg-status-paid",
    PAST_BLACK: "bg-status-past",
    COMPLETED_BLACK: "bg-status-completed",
    DEFAULT_BLACK: "bg-status-DEFAULT",
    CANCELED_RED: "bg-status-canceled",
    EXPIRED_RED: "bg-status-expired",
};

// NOTE textColorClass reference from Tailwind.config
export const StatusReservations = [
    {
        title: CONSOLIDATE_STATUS.Pending,
        textColorClass: TEXT_COLOR.PENDING_ORANGE,
        subStatus: [
            {
                title: SUB_STATUS.WAITING_RESPONSE,
                textColorClass: TEXT_COLOR.WAITING_RESPONSE_ORANGE,
            },
            {
                title: SUB_STATUS.PAYMENT_PENDING,
                textColorClass: TEXT_COLOR.PAYMENT_PENDING_PURPLE,
            },
            {
                title: SUB_STATUS.DECLINED,
                textColorClass: TEXT_COLOR.PENDING_ORANGE,
            },
        ],
    },
    {
        title: CONSOLIDATE_STATUS.Upcoming,
        textColorClass: TEXT_COLOR.UPCOMING_GREEN,
        subStatus: [
            {
                title: SUB_STATUS.PAID,
                textColorClass: TEXT_COLOR.PAID_GREEN,
            },
        ],
    },
    {
        title: CONSOLIDATE_STATUS.Past,
        textColorClass: TEXT_COLOR.PAST_BLACK,
        subStatus: [
            {
                title: SUB_STATUS.COMPLETED,
                textColorClass: TEXT_COLOR.COMPLETED_BLACK,
            },
            {
                title: SUB_STATUS.CANCELED,
                textColorClass: TEXT_COLOR.CANCELED_RED,
            },
            {
                title: SUB_STATUS.EXPIRED,
                textColorClass: TEXT_COLOR.EXPIRED_RED,
            },
        ],
    },
];
