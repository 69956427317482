import { Grid, Skeleton, Typography } from "@mui/material";

export const PaymentAccountInformationSkeleton = () => {
    return (
        <>
            <Skeleton variant="text" className="mb-5">
                <Typography>Payment Method</Typography>
            </Skeleton>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Reg. number</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Bank account number</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
            </Grid>
        </>
    );
};
