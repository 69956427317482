export const MonthlyOverviewData = [
  {
    totalBooking: 12,
    revenue: 8345,
    averageRating: 4.5,
    dateTimeCreated: new Date("2021-01-30T18:00:00.000Z"),
  },
];

export default MonthlyOverviewData;
