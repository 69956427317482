import { Button, SortDirection } from "@mui/material";
import React from "react";
import { BsArrowDown } from "react-icons/bs";
import { theme } from "styles/theme";

export const HeadCellSortable = (props) => {
    const [sortingDirection, setSortingDirection] =
        React.useState<SortDirection>(props.sortingDirection);
    const isActive = props.sortingActive;
    return (
        <Button
            variant="text"
            onClick={() => {
                if (props.disabledSortColumn) return;
                const isAsc = sortingDirection === "asc";
                const direction = isAsc ? "desc" : "asc";
                if (!isActive) {
                    props.updateSortingDirection(props.sortingDirection);
                } else {
                    setSortingDirection(direction);
                    props.updateSortingDirection(direction);
                }
            }}
            className={`group font-bold text-bold text-black p-0 hover:text-black btn-transition}`}
            sx={{
                "&.MuiButton-text:hover": {
                    color: (theme.palette.primary.main + " !important") as any,
                },
                ...(isActive && {
                    color: (theme.palette.primary.main + " !important") as any,
                }),
            }}
        >
            <span className="whitespace-nowrap">{props.label}</span>

            {!props.disabledSortColumn && (
                <BsArrowDown
                    className={`w-4 h-4 min-w-[16px] text-grey-300 group-hover:text-black btn-transition ${
                        isActive && "!text-black"
                    } ${props.sortingDirection === "desc" && "rotate-180"}`}
                />
            )}
        </Button>
    );
};
