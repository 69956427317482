import { Divider } from "@mui/material";
import {
    ItemNotification,
    NotificationSkeleton,
} from "components/Notification";
import { EmptyList } from "components/UI/EmptyList";
import { useAuthContext } from "context/AuthContext";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { useNotificationContext } from "context/NotificationContext";
import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { PagingResult, VendorNotification } from "models";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { vendorNotificationService } from "services/vendor-notification.service";
import MESSAGES from "shared/constants/messages.constant";
import ROUTES from "shared/constants/routes.constant";

interface NotificationProps {
    onlyUnread: boolean;
}

let lastId: string = "";
let isLastPage: boolean = false;
let loadedFirstPage: boolean = false;

export const Notification = (props: NotificationProps) => {
    const auth = useAuthContext();
    const [notifications, setNotifications] = useState<VendorNotification[]>(
        [],
    );
    const [pagingResult, setPagingResult] = useState<PagingResult>(null);
    const [isNotificationListLoading, setNotificationListLoading] =
        useState<boolean>(true);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const notificationContext = useNotificationContext();
    const fullPageLoaderContext = useFullPageLoadingContext();

    //This effect is used for subscribe and unsubscribe the new created notification
    useEffect(() => {
        const unsub = vendorNotificationService.subscribeNewNotifications(
            auth.authUser.userId,
            props.onlyUnread,
            snapShotNewNotification,
            "dateTimeCreated",
            "desc",
        );
        return unsub;
    }, []);

    //This effect is used for reset some flags and control the page scroll event
    useEffect(() => {
        fetchNotifications(lastId);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            lastId = "";
            isLastPage = false;
            loadedFirstPage = false;
        };
    }, []);

    //This effect is used for setting some flags and append the next page notifications to the main state
    useEffect(() => {
        if (pagingResult) {
            setNotifications((prev) => [
                ...prev,
                ...pagingResult.lists.filter(
                    (x) => prev.map((p) => p.id).indexOf(x.id) === -1,
                ),
            ]);
            lastId = pagingResult.page.lastId;
            isLastPage = pagingResult.page.isLastPage;
        }
    }, [pagingResult]);

    const handleScroll = () => {
        let userScrollHeight = window.innerHeight + window.scrollY;
        let windowBottomHeight = document.documentElement.offsetHeight;

        if (userScrollHeight >= windowBottomHeight && !isLastPage) {
            fetchNotifications(lastId);
        }
    };
    const snapShotNewNotification = async (
        querySnapshot: QuerySnapshot<DocumentData>,
    ) => {
        const vendorNotifications = await querySnapshot.docs.map((doc) => {
            const notification = doc.data();
            notification.dateTimeCreated =
                notification.dateTimeCreated.toDate();
            return notification as VendorNotification;
        });
        setNotifications((prev) => [
            ...vendorNotifications.filter(
                (x) => prev.map((p) => p.id).indexOf(x.id) === -1,
            ),
            ...prev,
        ]);
    };

    const fetchNotifications = async (nextId: string) => {
        setIsPageLoading(true);
        const pagingResult = await vendorNotificationService.fetchNotifications(
            nextId,
            props.onlyUnread,
        );
        setPagingResult(pagingResult);
        setNotificationListLoading(false);
        setIsPageLoading(false);
        loadedFirstPage = true;
    };

    const updateNotificationAsRead = async (id: string) => {
        await vendorNotificationService.updateAsReadNotificationById(id);
    };

    const removeNotification = async (id: string) => {
        try {
            fullPageLoaderContext.show();
            await vendorNotificationService.deleteNotificationById(id);

            setNotifications((prev) => prev.filter((item) => item.id !== id));
            fullPageLoaderContext.hide();
            notificationContext.showSuccess(
                MESSAGES.NOTIFICATION_DELETE_SUCCESSFULLY,
            );
        } catch (exception) {
            console.log(exception);
            fullPageLoaderContext.hide();
            notificationContext.showError(exception.message);
        }
    };

    return (
        <>
            {!isPageLoading && (!notifications || notifications.length === 0) && (
                <div className="mt-1.5">
                    <EmptyList
                        bgTransparent
                        label={MESSAGES.NOTIFICATION_EMPTY_LIST}
                    />
                </div>
            )}
            {notifications &&
                notifications?.map((data: VendorNotification, index) => (
                    <React.Fragment key={index}>
                        <Link
                            to={`${ROUTES.ReservationDetail.replace(
                                ":bookingId",
                                data.bookingId,
                            )}`}
                            onClick={async () =>
                                updateNotificationAsRead(data.id)
                            }
                        >
                            <ItemNotification
                                notification={data}
                                {...data}
                                removeNotificationFn={removeNotification}
                            />
                        </Link>
                        <Divider />
                    </React.Fragment>
                ))}
            {isNotificationListLoading && (
                <>
                    <NotificationSkeleton />
                    <Divider />
                    <NotificationSkeleton />
                    <Divider />
                    <NotificationSkeleton />
                    <Divider />
                    <NotificationSkeleton />
                    <Divider />
                </>
            )}
            {isPageLoading && loadedFirstPage && (
                <>
                    <NotificationSkeleton />
                    <Divider />
                </>
            )}
        </>
    );
};
export * from "./ItemNotification";
export * from "./NotificationSkeleton";
