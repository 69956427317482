import {
    Button,
    InputBase,
    InputBaseProps,
    InputLabel,
    styled,
} from "@mui/material";
import { JCTooltip } from "components";
import React, { useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";

interface TextInputProps extends InputBaseProps, InputBaseProps {
    className?: string;
    label?: string;
    labelClassName?: string;
    placeholder?: string;
    id: string;
    horizontal?: boolean;
    hasTooltip?: boolean;
    tooltipContent?: string;
    tooltipPosition?:
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left-start"
        | "left"
        | "right-end"
        | "right-start"
        | "right"
        | "top-end"
        | "top-start"
        | "top";
    showRequiredSymbol?: boolean;
}

const CustomInput = styled(InputBase)(({ theme }) => ({
    "&.Mui-error": {
        "& .MuiInputBase-input": {
            borderColor: theme.palette.error.main,
        },
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
        border: "1px solid #cecece",
        fontSize: 14,
        padding: "10px 12px",
        height: "100%",
        boxSizing: "border-box",
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
        },
        "&[type=number]": {
            MozAppearance: "textfield",
        },

        "& +.MuiInputAdornment-root": {
            "& p": {
                color: theme.palette.primary.main,
                fontSize: 14,
            },
        },
        "&.Mui-disabled": {
            backgroundColor: "#eee",
            color: theme.palette.primary.main,
            "&::-webkit-input-placeholder, &::placeholder": {
                color: theme.palette.primary.main,
                WebkitTextFillColor: theme.palette.primary.main,
            },
        },
    },
}));
export const TextInput: React.FC<TextInputProps> = ({
    label,
    labelClassName,
    className,
    id,
    horizontal,
    hasTooltip,
    tooltipContent: textTooltip,
    tooltipPosition,
    showRequiredSymbol,
    ...props
}) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    return (
        <>
            {horizontal ? (
                <>
                    <div className="flex flex-col sm:flex-row sm:justify-start md:justify-between sm:items-center">
                        <InputLabel
                            shrink
                            disableAnimation
                            className={`font-title font-bold text-sm md:text-base static transform-none mb-1 ${labelClassName}`}
                            sx={{
                                "&.Mui-focused, &.Mui-error": {
                                    color: "#2e2e2e",
                                },
                            }}
                            htmlFor={id}
                        >
                            {label}

                            {/* REVIEW: Tooltip title > Use <span> instead <div> because the console show the error when <p> wrap <div>. Normally, tooltip content renders to <p> tag we cannot use <p> or <div> inside <p> */}
                            {hasTooltip && (
                                <JCTooltip
                                    title={
                                        <span
                                            className="whitespace-normal inline-block"
                                            dangerouslySetInnerHTML={{
                                                __html: textTooltip,
                                            }}
                                        />
                                    }
                                    placement={tooltipPosition}
                                    tooltipOpen={openTooltip}
                                    onClose={() => setOpenTooltip(false)}
                                >
                                    <Button
                                        className="min-w-0 py-0 text-xl relative -top-0.5 px-1"
                                        onClick={() => setOpenTooltip(true)}
                                    >
                                        <IoInformationCircleOutline />
                                    </Button>
                                </JCTooltip>
                            )}
                            {showRequiredSymbol && (
                                <span className="text-black ml-0.5">*</span>
                            )}
                        </InputLabel>
                        <CustomInput className={className} id={id} {...props} />
                    </div>
                </>
            ) : (
                <>
                    <InputLabel
                        shrink
                        disableAnimation
                        className={`font-title font-bold text-sm md:text-base static transform-none mb-1 ${labelClassName}`}
                        sx={{
                            "&.Mui-focused, &.Mui-error": {
                                color: "#2e2e2e",
                            },
                        }}
                        htmlFor={id}
                    >
                        {label}

                        {hasTooltip && (
                            <JCTooltip
                                title={
                                    <span
                                        className="whitespace-normal inline-block"
                                        dangerouslySetInnerHTML={{
                                            __html: textTooltip,
                                        }}
                                    />
                                }
                                placement={tooltipPosition}
                                tooltipOpen={openTooltip}
                                onClose={() => setOpenTooltip(false)}
                            >
                                <Button
                                    className="min-w-0 py-0 text-xl relative -top-0.5 px-1"
                                    onClick={() => setOpenTooltip(true)}
                                >
                                    <IoInformationCircleOutline />
                                </Button>
                            </JCTooltip>
                        )}
                        {showRequiredSymbol && (
                            <span className="text-black ml-0.5">*</span>
                        )}
                    </InputLabel>
                    <CustomInput className={className} id={id} {...props} />
                </>
            )}
        </>
    );
};

TextInput.propTypes = {} as any;
