import { Box, List, Typography } from "@mui/material";
import { EmptyList } from "components/UI";
import { fieldDateTimeUpdated } from "shared/constants/shared.constant";
import { useAuthContext } from "context/AuthContext";
import { Roles } from "enum/role.enum";
import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { ReservationDetail } from "models/bookings.model";
import { ChatMessage } from "models/chat.model";
import { VendorInbox } from "models/inbox.model";
import { PagingResult } from "models/pagination.model";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { bookingService } from "services/booking.service";
import SimpleBarReact from "simplebar-react";
import { chatInboxService } from "../../../services/chatInbox.service";
import { InboxItemSkeleton } from "./InboxesSkeleton";
import InboxItem from "./InboxItem";
interface InboxProps {
    setIsPageLoading: (isPageLoading: boolean) => void;
    activeInboxId: string;
    setActiveInboxItem: (item: VendorInbox) => void;
    openMobileRequestList: (open: boolean) => void;
    activeBookingId: string;
    resetActiveBookingId: () => void;
    activeBookingItem: ReservationDetail;
    lastMessage: ChatMessage;
}

let lastId: string = "";
let isLastPage: boolean = false;
let activeInbox: VendorInbox = null;
let activeBookingId: string = null;

export const Inboxes = (props: InboxProps) => {
    const auth = useAuthContext();
    const [inboxes, setInboxes] = useState<VendorInbox[]>([]);
    const [pagingResult, setPagingResult] = useState<PagingResult>(null);
    const [loadedFirstPage, setLoadedFirstPage] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    let { chatRoomId, bookingId } = useParams();
    const [searchParams] = useSearchParams();
    let vendorId = auth.authUser.userId;
    const vendorIdSearchParam = searchParams.get("vendorId");
    if (vendorIdSearchParam) vendorId = vendorIdSearchParam;
    //This effect is used for subscribe and unsubscribe the new created notification
    useEffect(() => {
        if (!loadedFirstPage) return;
        const unsub = chatInboxService.subscribeLatestInboxes(
            vendorId,
            snapShotNewInboxes,
            fieldDateTimeUpdated,
            "desc",
        );
        return unsub;
    }, [loadedFirstPage]);

    //This effect is used for reset some flags and control the page scroll event
    useEffect(() => {
        fetchNextPage(lastId);
        const inboxContainer = document.querySelector(
            ".simplebar-content-wrapper",
        );
        inboxContainer.addEventListener("scroll", handleScroll);
        return () => {
            inboxContainer.removeEventListener("scroll", handleScroll);
            lastId = "";
            isLastPage = false;
            activeInbox = null;
        };
    }, []);
    //This effect is used for setting some flags and append the next page notifications to the main state
    useEffect(() => {
        if (pagingResult) {
            setInboxes((prev) => [
                ...prev,
                ...pagingResult.lists.filter(
                    (x) => prev.map((p) => p.id).indexOf(x.id) === -1,
                ),
            ]);
            lastId = pagingResult.page.lastId;
            isLastPage = pagingResult.page.isLastPage;
        }
    }, [pagingResult]);
    //Update the id of the last booking to the inbox
    useEffect(() => {
        if (props.activeBookingId !== "" && activeInbox) {
            setInboxes((prev) => updateLastBookingId(prev));
        }
    }, [props, activeInbox]);
    useEffect(() => {
        if (bookingId) activeBookingId = bookingId;
    }, [bookingId]);
    useEffect(() => {
        if (props.lastMessage) {
            var tempItems = inboxes.filter((item) => item.id === chatRoomId);
            if (tempItems.length > 0) {
                const tempInbox = { ...tempItems[0] };
                tempInbox.lastMessage = props.lastMessage;
                tempInbox.dateTimeCreated = new Date();
                tempInbox.dateTimeUpdated = tempInbox.dateTimeCreated;
                updateInboxList([tempInbox]);
            }
        }
    }, [props.lastMessage]);

    const getActiveBookingId = () => {
        return activeBookingId;
    };

    const snapShotNewInboxes = async (
        querySnapshot: QuerySnapshot<DocumentData>,
    ) => {
        const latestInboxes = await querySnapshot.docs.map((doc) => {
            const inbox = doc.data();
            inbox.dateTimeUpdated = inbox.dateTimeUpdated.toDate();
            if (inbox.lastMessage && inbox.lastMessage.dateTimeUpdated) {
                inbox.lastMessage.dateTimeUpdated =
                    inbox.lastMessage.dateTimeUpdated.toDate();
            }
            return inbox as VendorInbox;
        });
        if (latestInboxes && latestInboxes.length > 0) {
            if (inboxes.length === 0) {
                setActiveInbox(latestInboxes[0]);
                props.setActiveInboxItem(latestInboxes[0]);
            }
            updateInboxList(latestInboxes);
        }
    };
    const updateInboxList = (latestInboxes: VendorInbox[]) => {
        if (
            latestInboxes[0].unreadBookingIds[
                latestInboxes[0].unreadBookingIds.length - 1
            ] === getActiveBookingId()
        ) {
            if (auth.authUser.isVendor)
                bookingService.updateReadStatusByBookingId(
                    getActiveBookingId(),
                    Roles.Vendor,
                );
            setInboxes((prevInboxes) =>
                updateNewMessageToMainList(prevInboxes, latestInboxes, true),
            );
        } else
            setInboxes((prevInboxes) =>
                updateNewMessageToMainList(prevInboxes, latestInboxes),
            );
    };

    const updateNewMessageToMainList = (
        prevMessages: VendorInbox[],
        newMessages: VendorInbox[],
        useOldUnreadBookingIds: boolean = false,
    ) => {
        newMessages.reverse().forEach((newItem) => {
            let existingItems = prevMessages.filter(
                (item) => item.id === newItem.id,
            );
            if (existingItems.length > 0) {
                const existingIndex = prevMessages.indexOf(existingItems[0]);
                //only replace the existing item with the new item in the case all bookings are visited
                if (
                    existingItems[0].dateTimeUpdated < newItem.dateTimeUpdated
                ) {
                    if (useOldUnreadBookingIds)
                        newItem.unreadBookingIds =
                            existingItems[0].unreadBookingIds;
                    prevMessages.splice(existingIndex, 1);
                    prevMessages = [...[newItem], ...prevMessages];
                } else {
                    prevMessages[existingIndex] = newItem;
                    prevMessages = [...prevMessages];
                }
                //push new item to the top of the list
            } else {
                prevMessages = [...[newItem], ...prevMessages];
            }
        });
        return prevMessages;
    };
    const updateLastBookingId = (inboxes: VendorInbox[]) => {
        let existingItems = inboxes.filter(
            (item) => item.id === activeInbox.id,
        );
        if (existingItems.length > 0) {
            const existingIndex = inboxes.indexOf(existingItems[0]);
            existingItems[0].lastVisitBookingId = props.activeBookingId;
            props.resetActiveBookingId();
            inboxes[existingIndex] = existingItems[0];
            inboxes = [...inboxes];
        }
        return inboxes;
    };

    const fetchNextPage = async (nextId: string) => {
        try {
            setIsFetching(true);
            const pagingResult = await chatInboxService.fetchVendorInboxes(
                nextId,
                vendorId ? vendorId : auth.authUser.userId,
            );
            setPagingResult(pagingResult);
            setIsFetching(false);
            if (!loadedFirstPage) setLoadedFirstPage(true);
        } catch (ex) {
            setIsFetching(false);
        }
    };
    const handleScroll = (e) => {
        const bottom =
            e.target.scrollHeight - e.target.scrollTop ===
            e.target.clientHeight;
        if (bottom && !isLastPage) {
            fetchNextPage(lastId);
        }
    };
    const setActiveInbox = (inbox: VendorInbox) => {
        activeInbox = inbox;
    };

    useEffect(() => {
        if (inboxes.length > 0) {
            const defaultInboxes = inboxes.filter(
                (ib) => ib.id === props.activeInboxId,
            );
            if (defaultInboxes.length > 0) {
                if (defaultInboxes[0].lastVisitBookingId !== bookingId) {
                    defaultInboxes[0].lastVisitBookingId = bookingId;
                }
                props.setActiveInboxItem(defaultInboxes[0]);
                setActiveInbox(defaultInboxes[0]);
            }
        }
    }, [inboxes]);

    return (
        <>
            {loadedFirstPage && !isFetching && inboxes.length === 0 && (
                <>
                    <div className="fixed inset-0 top-16 z-[12] bg-white">
                        <Typography
                            variant="h2"
                            color="initial"
                            className="px-4 pt-6 mb-0 text-xl xl:text-2xl xl:px-10"
                        >
                            Inbox
                        </Typography>
                        <div className="mt-[8%]">
                            <EmptyList
                                label="Your inbox is empty"
                                bgTransparent
                            />
                        </div>
                    </div>
                </>
            )}
            <Box
                className="relative z-10 bg-white"
                sx={{
                    maxWidth: { xs: "100%", lg: 460 },
                    width: { xs: "100%", lg: "25%" },
                }}
            >
                <Typography
                    variant="h2"
                    color="initial"
                    className="px-4 pt-6 mb-0 text-2xl xl:text-3xl xl:px-10"
                >
                    Inbox
                </Typography>
                <div className="border-r border-gray-300">
                    <List
                        sx={{
                            width: "100%",
                        }}
                        component={"div"}
                    >
                        <SimpleBarReact
                            style={{
                                maxHeight: "calc(100vh - 127px)",
                            }}
                            onScroll={handleScroll}
                        >
                            {inboxes.map((inbox) => (
                                <React.Fragment key={inbox.id}>
                                    <InboxItem
                                        onClick={() => {
                                            setActiveInbox(inbox);
                                            props.setActiveInboxItem(inbox);
                                            props.openMobileRequestList(true);
                                        }}
                                        isActive={
                                            props.activeInboxId === inbox.id
                                        }
                                        inboxItem={inbox}
                                    />
                                </React.Fragment>
                            ))}
                            {isFetching && (
                                <>
                                    <InboxItemSkeleton />
                                    <InboxItemSkeleton />
                                    <InboxItemSkeleton />
                                    <InboxItemSkeleton />
                                </>
                            )}
                        </SimpleBarReact>
                    </List>
                </div>
            </Box>
        </>
    );
};
