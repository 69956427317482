import { functions, httpsCallable } from "firebaseSetup";

const onCall = async (path: string, data?: any) => {
    try {
        const request = httpsCallable(functions, `api/${path}`);
        const response = await request(data);
        const result = response.data;
        // const sanitizedMessage = result?.text;
        return result;
    } catch (exception: any) {
        console.error(exception?.message ?? exception);
        throw new Error(exception?.message ?? exception);
    }
};

export const requestService = {
    onCall,
};
