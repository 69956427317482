import { Skeleton } from "@mui/material";

const ListingItemSkeleton = () => {
    return (
        <div className="flex flex-col sm:flex-row">
            {/* props {JSON.stringify(props.listing)} */}
            <div className="max-w-full max-h-[300px] aspect-[5/3] sm:aspect-auto sm:w-[260px] sm:h-[160px] mb-4 sm:mb-0 relative overflow-hidden leading-[0]">
                <Skeleton
                    variant="rectangular"
                    className="object-cover w-full h-full aspect-[5/3] sm:aspect-auto sm:w-[260px] sm:h-[160px] bg-grey-300"
                />
            </div>
            <div className="flex flex-col sm:ml-7 flex-1">
                <Skeleton
                    variant="text"
                    width={300}
                    height={30}
                    className="bg-grey-300"
                />
                <Skeleton
                    variant="text"
                    width={100}
                    height={30}
                    className="bg-grey-300"
                />
                <Skeleton
                    variant="text"
                    width={120}
                    height={30}
                    className="bg-grey-300"
                />
                <Skeleton
                    variant="text"
                    width={100}
                    height={30}
                    className="bg-grey-300"
                />
                <Skeleton
                    variant="text"
                    width={60}
                    height={32}
                    className="bg-grey-300"
                />
            </div>
        </div>
    );
};

export default ListingItemSkeleton;
