import { MenuItem, MenuItemProps } from "@mui/material";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface DropdownItemProps extends MenuItemProps {
    label: string;
    icon?: ReactNode;
    url?: string;
}
export const DropdownItem: React.FC<DropdownItemProps> = (props) => {
    return (
        <>
            <MenuItem className="p-0" sx={{ minWidth: "165px" }} {...props}>
                {props.url ? (
                    <NavLink
                        to={props.url}
                        className={({ isActive }) =>
                            isActive
                                ? "text-grey-500 w-full px-4 h-[38px]"
                                : "text-primary w-full px-4 h-[38px]"
                        }
                    >
                        <div className="flex items-center h-full">
                            {props.icon && (
                                <span className="flex mr-4 text-xl text-center">
                                    {props.icon}
                                </span>
                            )}
                            <span className="font-bold">{props.label}</span>
                        </div>
                    </NavLink>
                ) : (
                    <div className="flex items-center px-4 h-[38px]">
                        {props.icon && (
                            <span className="flex mr-4 text-xl text-center">
                                {props.icon}
                            </span>
                        )}
                        <span className="font-bold">{props.label}</span>
                    </div>
                )}
            </MenuItem>
        </>
    );
};
