import { FormControl, Typography } from "@mui/material";
import {
    Autocomplete,
    GoogleMap,
    Marker,
    useLoadScript,
} from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/src/utils/make-load-script-url";
import { TextInput } from "components";
import { MeetingRoom } from "models/meeting-room.model";
import { useEffect, useRef, useState } from "react";
import MESSAGES from "shared/constants/messages.constant";

const mapContainerStyle = {
    height: "400px",
    width: "800px",
};

const libraries: Libraries = ["places"];
const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export interface Addresses {
    address1: string;
    city: string;
    postalCode: string;
}

export interface GoogleMapProps {
    values: MeetingRoom;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => void;
    setRtnValueAddresses: (address: Addresses) => void;
    searchAddress: string;
}

export const getAddressFromMapAddress = (place) => {
    let formattedAddress1 = "";
    let route =
        place?.address_components?.find((x) =>
            x.types.find((y) => y === "route"),
        )?.long_name ?? "";
    formattedAddress1 = route ?? "";
    let streetNumber = place?.address_components?.find((x) =>
        x.types.find((y) => y === "street_number"),
    )?.long_name;
    formattedAddress1 = streetNumber
        ? formattedAddress1.trim() + " " + streetNumber
        : "";
    const newAddresses = {
        address1: formattedAddress1,
        city:
            place?.address_components?.find((x) =>
                x.types.find((y) => y === "locality"),
            )?.long_name ?? "",
        postalCode:
            place?.address_components?.find((x) =>
                x.types.find((y) => y === "postal_code"),
            )?.long_name ?? "",
    } as Addresses;
    return newAddresses;
};

export const ReactGoogleMaps = (props: GoogleMapProps) => {
    const { values, setFieldValue } = props;

    const [autocomplete, setAutocomplete] =
        useState<google.maps.places.Autocomplete | null>(null);
    const searchAddressRef = useRef<HTMLInputElement>(null);
    const [searchAddress, setSearchAddress] = useState<string>("");
    const [center, setCenter] = useState({
        lat: Number(values.location?.lat) ?? 0,
        lng: Number(values.location?.lng) ?? 0,
    });

    const [marker, setMarker] = useState({
        position: {
            lat: Number(values.location?.lat) ?? 0,
            lng: Number(values.location?.lng) ?? 0,
        },
        address: "unknown",
    });

    const [zoomSize, setZoomSize] = useState(12);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    useEffect(() => {
        if (values.location) {
            const latLng = {
                lat: values.location?.lat,
                lng: values.location?.lng,
            };
            setMarker({
                position: {
                    lat: Number(values.location?.lat) ?? 0,
                    lng: Number(values.location?.lng) ?? 0,
                },
                address: "unknown",
            });

            setCenter(latLng);
            setZoomSize(17);
        }
    }, [values.location]);
    useEffect(() => {
        if (searchAddressRef.current) {
            setSearchAddress(values.location.address);
            searchAddressRef.current.value = props.searchAddress;
        }
    }, [props.searchAddress]);

    const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(autocomplete);
    };
    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            const location = place?.geometry?.location;
            const latLng = { lat: location?.lat(), lng: location?.lng() };
            const name = place?.name;
            setMarker({ position: latLng, address: name });

            setCenter(latLng);
            setZoomSize(17);
            updateAddress(name, latLng.lat, latLng.lng);
            const newAddresses = getAddressFromMapAddress(place);
            props.setRtnValueAddresses(newAddresses);
        } else {
            console.log("Autocomplete is not loaded yet!");
        }
    };

    const handleClickMap = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();

        setMarker({ position: { lat: lat, lng: lng }, address: "unknown" });

        updateAddress("unknown", lat, lng);
    };
    const updateAddress = (updateAddress: string, lat: number, lng: number) => {
        setFieldValue("location.address", updateAddress);
        setFieldValue("location.lat", lat);
        setFieldValue("location.lng", lng);
    };

    if (!isLoaded) return <></>;
    return (
        <div>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                <>
                    <FormControl variant="standard" className="mb-4 md:mb-8">
                        <TextInput
                            label="Find my address"
                            placeholder="Begin typing an address"
                            id="room_location"
                            name="placeName"
                            aria-describedby="room-location-help-text"
                            type="text"
                            hasTooltip
                            tooltipContent={
                                MESSAGES.LISTING_FIND_MY_ADDRESS_TOOLTIP
                            }
                            tooltipPosition="bottom-start"
                            inputRef={searchAddressRef}
                            onChange={(e) => setSearchAddress(e.target.value)}
                        />

                        {/* <FormHelperText id="room-location-help-text" className="mx-0 my-1">
              location is require
            </FormHelperText> */}
                        <Typography variant="body2" className="mt-2 mb-5">
                            <span className="inline-block mr-3">
                                Latitude: {marker?.position?.lat}
                            </span>
                            <span className="inline-block">
                                Longitude: {marker?.position?.lng}
                            </span>
                        </Typography>
                        <div className="max-w-[750px] h-[270px] overflow-hidden relative">
                            <GoogleMap
                                id="google-map"
                                mapContainerStyle={mapContainerStyle}
                                zoom={zoomSize}
                                center={center}
                                onClick={handleClickMap}
                            >
                                <Marker
                                    position={marker.position}
                                    title={marker.address}
                                    draggable
                                />
                            </GoogleMap>
                        </div>
                    </FormControl>
                </>
            </Autocomplete>
        </div>
    );
};
