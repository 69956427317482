import { API_ENDPOINT_ฺNEIGHBORHOOD } from "shared/constants/api.constant";
import { Neighborhood } from "models/neighborhood.model";
import { requestService } from "./request.service";

const getNeighborhoods = async (): Promise<Neighborhood[]> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺNEIGHBORHOOD.getNeighborhoods,
        {},
    )) as Neighborhood[];
};

export const neighborhoodService = {
    getNeighborhoods,
};
