import { Grid, Skeleton } from "@mui/material";

const MonthlyOverviewSkeleton = () => {
    return (
        <Grid
            container
            rowSpacing={0}
            columnSpacing={{ xs: 3 }}
            className="mt-4 mb-6 space-y-5 sm:space-y-0"
        >
            <Grid item xs={12} sm={4}>
                <div className="p-7 lg:p-4 xl:p-7 bg-grey-200 rounded-[20px]">
                    <div className="flex flex-col min-h-[145px] justify-between">
                        <div>
                            <Skeleton variant="text" className="h-10" />
                            <Skeleton variant="text" className="h-8 w-20" />
                        </div>
                        <Skeleton
                            variant="rectangular"
                            className="h-10 w-10 rounded-lg"
                        />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={4}>
                <div className="p-7 lg:p-4 xl:p-7 bg-grey-200 rounded-[20px]">
                    <div className="flex flex-col min-h-[145px] justify-between">
                        <div>
                            <Skeleton variant="text" className="h-10" />
                            <Skeleton variant="text" className="h-6 w-20" />
                        </div>
                        <Skeleton
                            variant="rectangular"
                            className="h-10 w-10 rounded-lg"
                        />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={4}>
                <div className="p-7 lg:p-4 xl:p-7 bg-grey-200 rounded-[20px]">
                    <div className="flex flex-col min-h-[145px] justify-between">
                        <div>
                            <Skeleton variant="text" className="h-10" />
                            <Skeleton variant="text" className="h-6 w-20" />
                        </div>
                        <Skeleton
                            variant="rectangular"
                            className="h-10 w-10 rounded-lg"
                        />
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default MonthlyOverviewSkeleton;
