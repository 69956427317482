import { Grid, Skeleton, Typography } from "@mui/material";

export const GeneralAccountInformationSkeleton = () => {
    return (
        <>
            <Skeleton variant="text" className="mb-5">
                <Typography className="w-20">General</Typography>
            </Skeleton>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>First name</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Last name</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Company</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>VAT Registration number</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Email</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Phone</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
            </Grid>
            <div className="mb-4 md:mb-8">
                <Skeleton variant="text">
                    <Typography>Address line 1</Typography>
                </Skeleton>
                <Skeleton
                    variant="rectangular"
                    className="mt-1 mb-5 w-full h-7 rounded"
                ></Skeleton>
            </div>
            <div className="mb-4 md:mb-8">
                <Skeleton variant="text">
                    <Typography>Address line 2 (Optional)</Typography>
                </Skeleton>
                <Skeleton
                    variant="rectangular"
                    className="mt-1 mb-5 w-full h-7 rounded"
                ></Skeleton>
            </div>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>City</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Postal code</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Country</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
            </Grid>
        </>
    );
};
