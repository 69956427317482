import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { ImageModel } from "models";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { IoClose } from "react-icons/io5";
interface DragImageProps {
    type: string;
    id: string;
    index: number;
}
const type = "Image"; // Need to pass which type element can be draggable

const Image = ({ image, index, moveImage, deleteImage }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: type,
        hover(item: DragImageProps) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Move the content
            moveImage(dragIndex, hoverIndex);
            // Update the index for dragged item directly to avoid flickering when half dragged
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type,
        item: { type, id: image.id, index } as DragImageProps,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    // initialize drag and drop into the element
    drag(drop(ref));

    return (
        <div
            ref={ref}
            style={{ opacity: isDragging ? 0 : 1 }}
            className="flex items-center border border-solid file-item border-grey-300"
        >
            {image.url ? (
                <div className="relative">
                    <img
                        alt={`img - ${image.title}`}
                        src={image.url}
                        className="block object-cover file-img"
                        style={{
                            height: "98px",
                            width: "160px",
                            cursor: "move",
                        }}
                    />
                    <IconButton
                        color="primary"
                        className="absolute z-10 p-0 right-1 top-1 hover:bg-transparent"
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteImage(image);
                        }}
                    >
                        <div className="rounded-full text-xl bg-black hover:bg-opacity-90 w-[22px] h-[22px] flex items-center justify-center">
                            <IoClose className="text-xl text-white" />
                        </div>
                    </IconButton>
                </div>
            ) : (
                <Box
                    sx={{ width: 160, height: 98 }}
                    className="flex items-center justify-center p-4 bg-black bg-opacity-30"
                >
                    <CircularProgress className="text-white" />
                </Box>
            )}
        </div>
    );
};

const ImageList = ({ images, moveImage, deleteImage }) => {
    const renderImage = (image: ImageModel, index: number) => {
        return (
            <div className="m-2" key={`${index}-image`}>
                <Image
                    image={image}
                    index={index}
                    moveImage={moveImage}
                    deleteImage={deleteImage}
                />
            </div>
        );
    };

    return (
        <section className="file-list relative z-10 flex flex-wrap justify-start px-0.5 w-full">
            {images.map(renderImage)}
        </section>
    );
};

export default ImageList;
