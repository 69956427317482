import { FullPageLoading } from "components/UI";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import ROUTES from "../shared/constants/routes.constant";

export const ProtectedRoute: React.FC<{
    children: JSX.Element;
    allowRoles: string[];
}> = ({ children, allowRoles }) => {
    let auth = useAuthContext();
    let location = useLocation();

    if (auth.loading) return <FullPageLoading />;

    return auth.authUser?.roles.find((role) => allowRoles?.includes(role)) ? (
        children
    ) : auth.user ? (
        <Navigate to={ROUTES.Unauthorized} state={{ from: location }} replace />
    ) : (
        <Navigate to={ROUTES.SignIn} state={{ from: location }} replace />
    );
};

export default ProtectedRoute;
