import { Button, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CalculationStyle, PricingStyle } from "enum/meeting-room.enum";
import { MeetingRoom } from "models";
import {
    IoLocationOutline,
    IoManOutline,
    IoPricetagsOutline,
} from "react-icons/io5";
import { generatePath, Link } from "react-router-dom";
import ROUTES from "shared/constants/routes.constant";
import { ListingStatus } from "./ListingStatus";

type ListingItemProps = {
    listing: MeetingRoom;
    className?: string;
    setOpenConfirmation?: React.Dispatch<React.SetStateAction<boolean>>;
    getCurrentIndex?: (pageIndex: number, dataIndex: number) => void;
    pageIndex?: number;
    dataIndex?: number;
};

const ListingItem: React.FC<ListingItemProps> = (props) => {
    const { setOpenConfirmation, getCurrentIndex, pageIndex, dataIndex } =
        props;

    return (
        <>
            <div className="flex flex-col sm:flex-row">
                {/* props {JSON.stringify(props.listing)} */}
                <div className="max-w-full max-h-[300px] sm:w-[260px] sm:h-[160px] leading-[0] aspect-[5/3] mb-4 sm:mb-0 relative overflow-hidden">
                    <Skeleton
                        variant="rectangular"
                        className="absolute inset-0 h-full sm:w-[260px] sm:h-[160px]"
                    />
                    <Link
                        to={generatePath(ROUTES.ListEdit, {
                            id: props.listing.id,
                        })}
                    >
                        <img
                            src={
                                props.listing.images[0]?.url ??
                                "/images/no-image.jpg"
                            }
                            alt={props.listing.title + ` image`}
                            className="object-cover w-full sm:w-[260px] sm:h-[160px] relative z-10"
                        />
                    </Link>
                </div>
                <div className="relative flex flex-col flex-1 sm:ml-7">
                    <ListingStatus
                        status={props.listing.status}
                        className="absolute right-0 -top-2"
                    />
                    <Typography
                        variant="h4"
                        color="initial"
                        className="pr-20 mb-2"
                    >
                        {props.listing.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="flex items-center mb-2 font-semibold"
                    >
                        <IoLocationOutline className="mr-4" />
                        {props.listing.city}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="flex items-center mb-2 font-semibold"
                    >
                        <IoManOutline className="mr-4" />
                        {props.listing.capacity}&nbsp;people
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="flex items-center mb-2 font-semibold"
                    >
                        <IoPricetagsOutline className="mr-4" />
                        {/* Hourly Rate */}
                        {(props.listing.calculationStyle ===
                            CalculationStyle.hourly ||
                            !props.listing.calculationStyle) && (
                            <>
                                {props.listing.pricePerHour}&nbsp;DKK / hour{" "}
                                {props.listing.pricingStyle &&
                                props.listing.pricingStyle ===
                                    PricingStyle.perPerson
                                    ? "per person"
                                    : "per venue"}
                            </>
                        )}
                        {/* Half/Full Day Passed Rates */}
                        {props.listing.calculationStyle ===
                            CalculationStyle.halfFullDayPassed && (
                            <>
                                {/* Half Day and Full Day */}
                                {props.listing.fullDayPricing &&
                                    props.listing.fullDayPricing.isEnable &&
                                    props.listing.halfDayPricing &&
                                    props.listing.halfDayPricing.isEnable && (
                                        <>
                                            Half Day{" "}
                                            {props.listing.halfDayPricing.value}{" "}
                                            DKK / Full Day{" "}
                                            {props.listing.fullDayPricing.value}{" "}
                                            DKK{" "}
                                            {props.listing.pricingStyle &&
                                            props.listing.pricingStyle ===
                                                PricingStyle.perPerson
                                                ? "per person"
                                                : "per venue"}
                                        </>
                                    )}
                                {/* Only Half Day */}
                                {props.listing.halfDayPricing &&
                                    props.listing.halfDayPricing.isEnable &&
                                    (!props.listing.fullDayPricing ||
                                        (props.listing.fullDayPricing &&
                                            !props.listing.fullDayPricing
                                                .isEnable)) && (
                                        <>
                                            Half Day{" "}
                                            {props.listing.halfDayPricing.value}{" "}
                                            DKK{" "}
                                            {props.listing.pricingStyle &&
                                            props.listing.pricingStyle ===
                                                PricingStyle.perPerson
                                                ? "per person"
                                                : "per venue"}
                                        </>
                                    )}
                                {/* Only Full Day */}
                                {props.listing.fullDayPricing &&
                                    props.listing.fullDayPricing.isEnable &&
                                    (!props.listing.halfDayPricing ||
                                        (props.listing.halfDayPricing &&
                                            !props.listing.halfDayPricing
                                                .isEnable)) && (
                                        <>
                                            Full Day{" "}
                                            {props.listing.fullDayPricing.value}{" "}
                                            DKK{" "}
                                            {props.listing.pricingStyle &&
                                            props.listing.pricingStyle ===
                                                PricingStyle.perPerson
                                                ? "per person"
                                                : "per venue"}
                                        </>
                                    )}
                            </>
                        )}
                    </Typography>
                    <div className="flex space-x-2">
                        <Link
                            to={generatePath(ROUTES.ListEdit, {
                                id: props.listing.id,
                            })}
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                className="mt-1"
                            >
                                Edit
                            </Button>
                        </Link>
                        <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            className="mt-1"
                            onClick={() => {
                                setOpenConfirmation(true);
                                getCurrentIndex(pageIndex, dataIndex);
                            }}
                            sx={{
                                borderColor: "rgba(255,0,0,0.6)",
                                color: "rgba(255,0,0,0.6)",
                                "&:hover, &:active": {
                                    background: "rgba(255,0,0,0.6)",
                                    color: "#fff",
                                    borderColor: "rgba(255,0,0,0.6)",
                                },
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListingItem;
