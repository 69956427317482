import * as Yup from "yup";
import MESSAGES from "../shared/constants/messages.constant";
import { phoneRegExp } from "../shared/constants/shared.constant";

export const AccountInformationValidation = Yup.object().shape({
    email: Yup.string()
        .email("Email is invalid format")
        .required(MESSAGES.ACCOUNT_INFORMATION_VALIDATION_EMAIL_REQUIRE),
    firstName: Yup.string().required(
        MESSAGES.ACCOUNT_INFORMATION_VALIDATION_FIRSTNAME_REQUIRE,
    ),
    lastName: Yup.string().required(
        MESSAGES.ACCOUNT_INFORMATION_VALIDATION_LASTNAME_REQUIRE,
    ),
    company: Yup.string(),
    vatNumber: Yup.number().typeError(
        "VAT Registration number should be number",
    ),
    phoneNumber: Yup.string()
        .matches(phoneRegExp, "Phone number is invalid")
        .required(MESSAGES.ACCOUNT_INFORMATION_VALIDATION_PHONENUMBER_REQUIRE),
    addressLine1: Yup.string().required(
        MESSAGES.ACCOUNT_INFORMATION_VALIDATION_ADDRESSLINE1_REQUIRE,
    ),
    addressLine2: Yup.string(),
    city: Yup.string().required(
        MESSAGES.ACCOUNT_INFORMATION_VALIDATION_CITY_REQUIRE,
    ),
    country: Yup.string().required(
        MESSAGES.ACCOUNT_INFORMATION_VALIDATION_COUNTRY_REQUIRE,
    ),
    postalCode: Yup.string().required(
        MESSAGES.ACCOUNT_INFORMATION_VALIDATION_POSTALCODE_REQUIRE,
    ),
    payment: Yup.object().shape({
        regNumber: Yup.number()
            .typeError("Reg. number should be number")
            .required(
                MESSAGES.ACCOUNT_INFORMATION_VALIDATION_REGNUMBER_REQUIRE,
            ),
        bankAccountNumber: Yup.number()
            .typeError("Bank account number should be number")
            .required(
                MESSAGES.ACCOUNT_INFORMATION_VALIDATION_BANKACCOUNTNUMBER_REQUIRE,
            ),
    }),
});
