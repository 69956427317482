import * as IoIcons from "react-icons/io5";

interface DynamicIconProps {
    name: string;
    className?: string;
    mobilename?: string;
}

export const Icons = IoIcons;
export const DynamicIcon: React.FC<DynamicIconProps> = ({
    name,
    className,
    ...props
}) => {
    const IconComponent = IoIcons[name];
    if (!IconComponent) {
        // Return a default one
        return <span className="text-base font-normal">Please select</span>;
    }
    return <IconComponent className={className} {...props} />;
};

export function modifyIconNameToKebabCase(str: string) {
    if (!str.includes("-")) {
        return (str = str
            .replace(/([a-z])([A-Z])/g, "$1-$2")
            .replace(/[\s_]+/g, "-")
            .toLowerCase());
    } else {
        return str;
    }
}

export function modifyIconNameToPascal(str: string) {
    if (!str) return "";
    if (!str.startsWith("Io")) {
        return "Io" + toPascalCase(str);
    } else {
        return str;
    }
}
function toPascalCase(str: string) {
    return str.replace(/(^\w|-\w)/g, clearAndUpper);
}
function clearAndUpper(str: string) {
    return str.replace(/-/, "").toUpperCase();
}
