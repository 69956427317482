import {
    Alert,
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    TextareaAutosize,
    Typography,
    useTheme,
} from "@mui/material";
import { useAuthContext } from "context/AuthContext";
import { Roles } from "enum/role.enum";
import { DocumentData, QuerySnapshot } from "firebaseSetup";
import { dateTimeHelper } from "helpers/date-time.helper";
import { getSystemMessageBySystemMessageType } from "helpers/system-message.helper";
import { ReservationDetail as BookingModel } from "models/bookings.model";
import {
    ActiveChatMessage,
    ChatMessage,
    MessageType,
    SubmitStatus,
} from "models/chat.model";
import { VendorInbox } from "models/inbox.model";
import { PaginationModel } from "models/pagination.model";
import React, { useLayoutEffect, useRef, useState } from "react";
import {
    IoChatbubblesOutline,
    IoFileTrayFullOutline,
    IoInformationCircleOutline,
    IoPaperPlaneOutline,
} from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "react-query";
import { chatService } from "services/chat.service";
import { SHARED_DATE_TIME_CREATED } from "shared/constants/shared-db.constant";
import {
    dateStringFormat,
    defaultPaginationLimit,
    timeStringHalfDayFormat,
} from "shared/constants/shared.constant";
import ChatBubbleLeft from "./ChatBubbleLeft";
import ChatBubbleRight from "./ChatBubbleRight";
import ChatSystemNotice from "./ChatSystemNotice";
import MessagesSkeleton, { ChatBubblesSkeleton } from "./MessagesSkeleton";
interface MessageProps {
    activeBookingItem: BookingModel;
    activeInboxItem: VendorInbox;
    openMobileMessageSection: boolean;
    closeMobileMessageSection: () => void;
    setMobileBookingSection: (open: boolean) => void;
    setOpenReservationDetial: (open: boolean) => void;
    setLastMessage: (lastMessage: ChatMessage) => void;
}

export const Messages = (props: MessageProps) => {
    let auth = useAuthContext();
    const scrollThreshold = 0.8;
    const theme = useTheme();
    const queryClient = useQueryClient();
    const queryKey = `chat-booking-${props.activeBookingItem?.id}`;
    const [chatMessage, setChatMessage] = useState<string>("");
    const [activeBookingItem, setActiveBookingItem] =
        useState<BookingModel>(null);

    const fetchChatMessage = async ({ pageParam = "" }) => {
        const messages = await chatService.fetchChatMessages(
            props.activeBookingItem?.id,
            defaultPaginationLimit,
            pageParam,
        );
        messages.lists = await messages.lists.reverse();
        if (!messages.page.isLastPage) scrollToBack();
        return messages;
    };
    const sendChatMessage = async () => {
        if (chatMessage) {
            const now = new Date();
            const message = {
                id: Math.floor(Date.now() / 1000).toString(),
                senderId: auth.authUser.userId,
                text: chatMessage,
                type: MessageType.text,
                systemMessageType: null,
                sendToUserRoles: [Roles.Vendor, Roles.User],
                status: "",
                consolidateStatus: "",
                bookingId: props.activeBookingItem.id,
                dateTimeCreated: now,
                dateTimeUpdated: now,
                submitStatus: SubmitStatus.sending,
            } as ActiveChatMessage;
            props.setLastMessage(message);
            setChatMessage("");
            chatService
                .sendChatMessage(props.activeBookingItem.id, chatMessage)
                .then(() => {
                    updateMessageStatus(message, SubmitStatus.success);
                })
                .catch((ex) => {
                    console.log(ex);
                    updateMessageStatus(message, SubmitStatus.failed);
                });
            appendNewMessageToChats([message]);
        }
    };
    const handleChange = (event) => {
        setChatMessage(event.target.value);
    };
    const {
        data: items,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery(queryKey, fetchChatMessage, {
        select: (data) => ({
            pages: [...data.pages].reverse(),
            pageParams: [...data.pageParams].reverse(),
        }),
        getNextPageParam: (lastPage: PaginationModel<ActiveChatMessage[]>) => {
            return lastPage.page.isLastPage ? undefined : lastPage.page.lastId;
        },
        refetchOnWindowFocus: false,
    });
    useLayoutEffect(() => {
        const unsub = chatService.subscribeNewChatMessage(
            snapShotNewChatMessage,
            props.activeBookingItem?.id,
            SHARED_DATE_TIME_CREATED,
            "desc",
        );
        return () => {
            unsub();
        };
    }, [props.activeBookingItem]);

    useLayoutEffect(() => {
        if (props.activeBookingItem)
            setActiveBookingItem(props.activeBookingItem);
    }, [props.activeBookingItem]);
    const snapShotNewChatMessage = async (
        querySnapshot: QuerySnapshot<DocumentData>,
    ) => {
        const chatMessages = await querySnapshot.docs.map((doc) => {
            return doc.data();
        });
        const newMessages = chatMessages
            .map((message) => {
                message.dateTimeCreated = message.dateTimeCreated.toDate();
                message.submitStatus = SubmitStatus.success;
                return message as ActiveChatMessage;
            })
            .filter((message) => message.senderId !== auth.authUser.userId);

        if (newMessages.length > 0) await appendNewMessageToChats(newMessages);
    };
    const appendNewMessageToChats = async (
        chatMessages: ActiveChatMessage[],
    ) => {
        await queryClient.setQueryData(
            queryKey,
            (prev: InfiniteData<PaginationModel<ActiveChatMessage[]>>) => {
                return prev
                    ? {
                          ...prev,
                          pages: prev.pages.map(
                              (page: PaginationModel<ActiveChatMessage[]>) => {
                                  const lists = page.lists.filter(
                                      (booking) =>
                                          booking.id !==
                                          chatMessages[chatMessages.length - 1]
                                              .id,
                                  );

                                  return {
                                      page: page.page,
                                      lists: [
                                          ...lists,
                                          chatMessages[chatMessages.length - 1],
                                      ],
                                  };
                              },
                          ),
                      }
                    : prev;
            },
        );
    };
    const updateMessageStatus = async (
        chatMessage: ActiveChatMessage,
        submitStatus: SubmitStatus,
    ) => {
        await queryClient.setQueryData(
            queryKey,
            (prev: InfiniteData<PaginationModel<ActiveChatMessage[]>>) => {
                return prev
                    ? {
                          ...prev,
                          pages: prev.pages.map(
                              (page: PaginationModel<ActiveChatMessage[]>) => {
                                  const updatedMessage = page.lists.find(
                                      (message) => message === chatMessage,
                                  );
                                  if (updatedMessage)
                                      updatedMessage.submitStatus =
                                          submitStatus;

                                  return {
                                      page: page.page,
                                      lists: page.lists,
                                  };
                              },
                          ),
                      }
                    : prev;
            },
        );
    };
    const messageContainerRef = useRef<null | HTMLDivElement>(null);
    const myFormRef = useRef<null | HTMLFormElement>(null);
    const scrollToBack = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scroll(
                0,
                messageContainerRef.current.scrollTop + 100,
            );
        }
    };
    const itemSize = items?.pages?.reduce((x, y) => x + y.lists.length, 0) ?? 0;

    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendChatMessage();
        }
    };
    const displayChatMessage = (
        message: ActiveChatMessage,
        messages: ActiveChatMessage[],
        index: number,
    ) => {
        //Map system messages
        const prevMessage = messages[index - 1];
        if (message.systemMessageType && message.type === MessageType.system) {
            const systemMessageText = getSystemMessageBySystemMessageType(
                message.systemMessageType,
                props.activeInboxItem?.userName,
            );
            message.text = systemMessageText;
        }
        return (
            <React.Fragment key={message.id}>
                {index === 0 && message.type === MessageType.text && (
                    <div className="space-y-4">
                        <ChatSystemNotice
                            date={dateTimeHelper.convertDateTimeToString(
                                message.dateTimeCreated as Date,
                                dateStringFormat,
                            )}
                        />
                    </div>
                )}
                {message &&
                    message.dateTimeCreated &&
                    dateTimeHelper.isDifferentDate(
                        message.dateTimeCreated as Date,
                        prevMessage?.dateTimeCreated as Date,
                    ) && (
                        <div className="space-y-4">
                            <ChatSystemNotice
                                date={dateTimeHelper.convertDateTimeToString(
                                    message.dateTimeCreated as Date,
                                    dateStringFormat,
                                )}
                            />
                        </div>
                    )}
                {index === 0 && message.type === MessageType.system && (
                    <ChatSystemNotice
                        date={dateTimeHelper.convertDateTimeToString(
                            message.dateTimeCreated as Date,
                            dateStringFormat,
                        )}
                    />
                )}
                {index === 0 && message.type === MessageType.system && (
                    <ChatSystemNotice
                        message={message.text}
                        date={dateTimeHelper.convertDateTimeToString(
                            message.dateTimeCreated as Date,
                            timeStringHalfDayFormat,
                        )}
                    />
                )}
                {index !== 0 && message.type === MessageType.system && (
                    <ChatSystemNotice
                        message={message.text}
                        date={dateTimeHelper.convertDateTimeToString(
                            message.dateTimeCreated as Date,
                            timeStringHalfDayFormat,
                        )}
                    />
                )}
                {(message.type.toString() === MessageType.text.toString() &&
                    auth.authUser.userId) === message.senderId && (
                    <ChatBubbleRight
                        message={message.text}
                        date={dateTimeHelper.convertDateTimeToString(
                            message.dateTimeCreated as Date,
                            timeStringHalfDayFormat,
                        )}
                        status={message.submitStatus}
                    />
                )}
                {message.type.toString() === MessageType.text.toString() &&
                    auth.authUser.userId !== message.senderId && (
                        <ChatBubbleLeft
                            avatarImage={
                                props.activeInboxItem &&
                                props.activeInboxItem.userImageURL
                                    ? props.activeInboxItem.userImageURL
                                    : null
                            }
                            message={message.text}
                            date={dateTimeHelper.convertDateTimeToString(
                                message.dateTimeCreated as Date,
                                timeStringHalfDayFormat,
                            )}
                        />
                    )}
            </React.Fragment>
        );
    };
    if (isFetching && !items) return <MessagesSkeleton />;
    return (
        <Box
            className="absolute flex-1 h-full bg-white visibility-hidden lg:visibility-visible lg:relative"
            sx={{
                width: { xs: "100%", lg: "50%" },
                left: "100%",
                transition: "all ease-in-out .3s",
                zIndex: 11,
                ...(props.openMobileMessageSection && {
                    left: 0,
                    visibility: "visible",
                }),
                [theme.breakpoints.up("lg")]: {
                    left: "auto",
                },
            }}
        >
            <Divider
                orientation="vertical"
                className="absolute top-0 left-0 hidden lg:block"
            />
            <div className="flex flex-col w-full h-full">
                <Typography
                    variant="h2"
                    color="initial"
                    className="flex flex-wrap items-center justify-between px-4 pt-6 mb-0 space-x-3 text-2xl xl:text-3xl 2xl:px-10 lg:space-x-0"
                >
                    <span>{activeBookingItem?.userName}</span>
                    <div className="flex flex-row">
                        <IconButton
                            color="primary"
                            aria-label="open drawer"
                            onClick={() => {
                                props.closeMobileMessageSection();
                                props.setMobileBookingSection(false);
                            }}
                            edge="start"
                            className="inline-block lg:hidden"
                        >
                            <IoChatbubblesOutline />
                        </IconButton>
                        <IconButton
                            color="primary"
                            aria-label="open drawer"
                            onClick={() => {
                                props.closeMobileMessageSection();
                                props.setMobileBookingSection(true);
                            }}
                            edge="start"
                            className="inline-block lg:hidden"
                        >
                            <IoFileTrayFullOutline />
                        </IconButton>
                    </div>
                </Typography>

                <div className="flex flex-wrap items-center justify-between px-4 py-2 border-b border-gray-300 2xl:px-10">
                    <div className="flex flex-wrap my-1">
                        <Typography variant="body2" color="initial">
                            <span className="font-bold">
                                {props.activeBookingItem?.meetingRoomTitle}
                            </span>
                            <span className="mx-2">|</span>
                            <span className="font-semibold">
                                {dateTimeHelper.convertDateTimeToString(
                                    props.activeBookingItem.meetingDate,
                                ) ?? "-"}
                            </span>
                            <span className="mx-2">|</span>
                            <span className="font-semibold">
                                {
                                    props.activeBookingItem
                                        ?.meetingStartTimeString
                                }
                                -{props.activeBookingItem?.meetingEndTimeString}
                            </span>
                        </Typography>
                    </div>
                    <Button
                        variant="outlined"
                        size="small"
                        className="mt-4 sm:my-1"
                        sx={{ minWidth: "120px !important" }}
                        onClick={() => {
                            props.setOpenReservationDetial(true);
                        }}
                    >
                        View Details
                    </Button>
                </div>
                <Divider />
                <div className="relative w-full pt-6 px-4 2xl:px-10 overflow-hidden flex-1 2xl:max-w-[85%] mx-auto">
                    <div
                        className="flex flex-col-reverse max-h-full pr-2 -mr-2 overflow-y-auto custom-scrollbar space-y-7"
                        ref={messageContainerRef}
                        id="scrollableMessage"
                    >
                        <InfiniteScroll
                            dataLength={items?.pages ? itemSize : 0}
                            next={fetchNextPage}
                            hasMore={hasNextPage ?? false}
                            style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                            }}
                            loader={
                                (isFetching || isFetchingNextPage) && (
                                    <ChatBubblesSkeleton />
                                )
                            }
                            inverse={true}
                            scrollableTarget="scrollableMessage"
                            scrollThreshold={scrollThreshold}
                        >
                            <div className="space-y-4">
                                {items &&
                                    items?.pages?.map((messages, indexPage) =>
                                        messages?.lists?.map(
                                            (
                                                message: ActiveChatMessage,
                                                index,
                                            ) =>
                                                displayChatMessage(
                                                    message,
                                                    messages.lists,
                                                    index,
                                                ),
                                        ),
                                    )}
                            </div>
                        </InfiniteScroll>
                    </div>
                    {/* </SimpleBarReact> */}
                </div>
                {props.activeInboxItem.active !== undefined &&
                    props.activeInboxItem.active === false && (
                        <div className="flex items-center justify-between w-full mb-2 px-4 2xl:px-10 border-t border-gray-300 2xl:max-w-[85%] mx-auto">
                            <Alert
                                severity="info"
                                className="w-full bg-grey-500 rounded-none text-white items-center py-1.5"
                                icon={
                                    <IoInformationCircleOutline className="text-white !p-0 mr-1.5" />
                                }
                                sx={{
                                    ".MuiAlert-icon, .MuiAlert-message": {
                                        padding: 0,
                                        marginRight: 0,
                                    },
                                }}
                            >
                                The user is no longer available
                            </Alert>
                        </div>
                    )}
                {auth.authUser.isVendor && (
                    <div className="flex items-center justify-between w-full py-3 px-4 2xl:px-10 border-t border-gray-300 2xl:max-w-[85%] mx-auto">
                        <form ref={myFormRef} style={{ width: "100%" }}>
                            <FormControl
                                variant="standard"
                                className="flex flex-col w-full 2xl:mb-7"
                            >
                                <div className="relative leading-none textarea-autosize-chat">
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Type message"
                                        minRows="1"
                                        id="message"
                                        name="message"
                                        className="w-full"
                                        value={chatMessage}
                                        onChange={(event) =>
                                            handleChange(event)
                                        }
                                        onKeyDown={onEnterPress}
                                        style={{ paddingRight: 44 }}
                                        disabled={
                                            props.activeInboxItem.active !==
                                                undefined &&
                                            props.activeInboxItem.active ===
                                                false
                                        }
                                    />
                                    <IconButton
                                        className="absolute top-0 right-1"
                                        onClick={() => sendChatMessage()}
                                    >
                                        <IoPaperPlaneOutline className="text-2xl text-black" />
                                    </IconButton>
                                </div>
                            </FormControl>
                        </form>
                    </div>
                )}
            </div>
            <Divider
                orientation="vertical"
                className="absolute top-0 right-0 hidden lg:block"
            />
        </Box>
    );
};
export * from "./ChatBubbleLeft";
export * from "./ChatBubbleRight";
export * from "./ChatSystemNotice";
