import { Button, Card, Grid, Typography } from "@mui/material";
import { AxiosExample } from "components/examples/axios";
import { ReactQueryExample } from "components/examples/react-query";
import {
    AdminContentContainer,
    AdminPageTitle,
    AdminSingleBlockLayout,
} from "components/Layouts";
import { JCSnackbar, Loading } from "components/UI";
import IconPicker from "components/UI/IconPicker";
import { ModalConfirmation } from "components/UI/Modal";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { requestService } from "services/request.service";

export const ComponentsPage = () => {
    const [openWarning, setOpenWarning] = useState(false);
    const [openSnackbarError, setOpenSnackbarError] = useState(false);
    const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);

    return (
        <>
            <Helmet>
                <title>Joes & Cos | Components</title>
            </Helmet>

            {/* https://europe-west1-joescos.cloudfunctions.net/searchConnections?searchText=sfsdf */}
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <AdminPageTitle pageTitle="Components" />
                    {/* <code>{JSON.stringify(Vendors)}</code> */}
                    <Card sx={{ my: 4, p: 4 }}>
                        <Typography variant="h3">Loading</Typography>
                        <Loading />
                    </Card>
                    <Card sx={{ my: 4, p: 4 }}>
                        <Typography variant="h3" className="mb-3">
                            Confirmation Modal
                        </Typography>

                        <Button
                            variant="outlined"
                            color="error"
                            className="w-full sm:max-w-[175px]"
                            fullWidth
                            onClick={async () =>
                                await requestService.onCall("getAllUsers")
                            }
                        >
                            onCall
                        </Button>
                        {/*  */}
                        <Button
                            variant="outlined"
                            color="error"
                            className="w-full sm:max-w-[175px]"
                            fullWidth
                            onClick={() => setOpenWarning(true)}
                        >
                            Inactive
                        </Button>
                        <ModalConfirmation
                            severity="primary"
                            title="Are you sure you want to inactive this listing?"
                            btnCancelText="No"
                            btnText="Yes"
                            open={openWarning}
                            closeFn={() => setOpenWarning(false)}
                        >
                            <Typography variant="body2" color="initial">
                                Lorem ipsum dolor sit amet, consectetur elit.
                                Nulla ullam corper consequ dolor sit ame at
                                nisl.
                            </Typography>
                        </ModalConfirmation>
                    </Card>
                    <Card sx={{ my: 4, p: 4 }}>
                        <Typography variant="h3" className="mb-3">
                            Snackbar (Toast)
                        </Typography>
                        <div className="flex space-x-3">
                            <Button
                                variant="outlined"
                                color="error"
                                className="w-full sm:max-w-[175px]"
                                fullWidth
                                onClick={() => setOpenSnackbarError(true)}
                            >
                                Error
                            </Button>
                            <Button
                                variant="outlined"
                                color="success"
                                className="w-full sm:max-w-[175px]"
                                fullWidth
                                onClick={() => setOpenSnackbarSuccess(true)}
                            >
                                Success
                            </Button>
                        </div>

                        <JCSnackbar
                            open={openSnackbarError}
                            onClose={() => setOpenSnackbarError(false)}
                            vertical={"bottom"}
                            horizontal={"right"}
                            severity="error"
                            description="Example error snackbar"
                        ></JCSnackbar>
                        <JCSnackbar
                            open={openSnackbarSuccess}
                            onClose={() => setOpenSnackbarSuccess(false)}
                            vertical={"bottom"}
                            horizontal={"right"}
                            severity="success"
                            description="Example success Macaroon cake powder dessert I love. Jujubes marshmallow caramels jelly-o dragée icing jelly. "
                        ></JCSnackbar>
                    </Card>
                    <Card sx={{ my: 4, p: 4 }}>
                        <Typography variant="h3" className="mb-3">
                            Dropzone
                        </Typography>
                    </Card>
                    <Card sx={{ my: 4, p: 4 }}>
                        <Typography variant="h3" className="mb-3">
                            IconPicker
                        </Typography>
                        <IconPicker />
                    </Card>

                    {/* <Card sx={{ my: 4, p: 4 }}>
            <Typography variant="h2">Chat UI</Typography>
            <Chat />
          </Card> */}
                    <Card sx={{ my: 4, p: 4 }}>
                        <AxiosExample />
                    </Card>
                    <Card sx={{ my: 4, p: 4 }}>
                        <ReactQueryExample />
                    </Card>
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 3 }}
                        className="mt-4 mb-6"
                    >
                        <Grid item xs={12} lg={12}></Grid>
                    </Grid>
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};
