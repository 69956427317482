import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    MenuItem,
} from "@mui/material";
import { SectionTitle } from "components/UI";
import JCSelect from "components/UI/SelectDropdown";
import { PricingStyle } from "enum/meeting-room.enum";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import MESSAGES from "shared/constants/messages.constant";

const HalfFullDayPassed = (props: FormikProps<MeetingRoom>) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        setFieldError,
    } = props;

    const [halfDayHourLengths, setHalfDayHourLengths] = useState<Number[]>([
        3, 4, 5, 6, 7,
    ]);
    const [fullDayHourLengths, setFullDayHourLengths] = useState<Number[]>([
        6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
    ]);
    const [fullDayHourLength, setFullDayHourLength] = useState<Number>(
        values.fullDayPricing?.value && values.fullDayPricing.value > 5
            ? values.fullDayPricing.value
            : 0,
    );
    const [halfDayHourLength, setHalfDayHourLength] = useState<Number>(
        values.halfDayPricing?.value && values.halfDayPricing.value > 5
            ? values.halfDayPricing.value
            : 0,
    );

    useEffect(() => {
        if (
            values.halfDayPricing &&
            values.fullDayPricing &&
            values.halfDayPricing.length &&
            values.fullDayPricing.length
        ) {
            if (
                values.fullDayPricing.isEnable &&
                values.halfDayPricing.isEnable &&
                Number(values.halfDayPricing.length) >=
                    Number(values.fullDayPricing.length)
            ) {
                setFieldValue("halfDayPricing.length", 0);
                setFieldTouched("halfDayPricing.length", true);
                setFieldError("halfDayPricing.length", "Required");
            }
            if (
                values.fullDayPricing.isEnable &&
                values.halfDayPricing.isEnable &&
                Number(values.halfDayPricing.length) >=
                    Number(values.fullDayPricing.length)
            ) {
                setFieldValue("halfDayPricing.length", 0);
                setFieldTouched("halfDayPricing.length", true);
                setFieldError("halfDayPricing.length", "Required");
            }
        }
        setFullDayHourLength(
            values.fullDayPricing && values.fullDayPricing?.length
                ? values.fullDayPricing.length
                : 0,
        );
        setHalfDayHourLength(
            values.halfDayPricing && values.halfDayPricing?.length
                ? values.halfDayPricing.length
                : 0,
        );
    }, [values?.halfDayPricing?.length, values?.fullDayPricing?.length]);

    return (
        <>
            <FormGroup className="space-y-5 md:space-y-0 md:mt-14">
                <FormControlLabel
                    className="items-baseline md:align-middle md:items-baseline md:mb-14"
                    control={
                        <Checkbox
                            id="halfDayPricing.isEnable"
                            name="halfDayPricing.isEnable"
                            checked={values.halfDayPricing?.isEnable ?? false}
                            value={values.halfDayPricing?.isEnable ?? false}
                            onChange={(event) => {
                                handleChange(event);
                                setFieldTouched(
                                    "halfDayPricing.isEnable",
                                    true,
                                    false,
                                );
                            }}
                            onBlur={handleBlur}
                            className="self-baseline pt-0 relative top-[7px]"
                        />
                    }
                    label={
                        <FormControl className="flex flex-col flex-wrap pt-2 space-y-5 md:flex-row md:items-baseline md:pt-0 md:space-y-0">
                            <SectionTitle
                                title="Half day"
                                labelClassName="!mb-0 mr-2 font-normal min-w-[80px] label-clickable"
                                className="!mb-0"
                                helpText={
                                    MESSAGES.LISTING_PRICING_PER_PERSON_HALFFULLDAY_HALFDAY_TOOLTIP
                                }
                                hasTooltip
                                labelVariantInherit
                            />
                            <div className="flex flex-col items-baseline mr-2 md:ml-2 md:my-0">
                                <div className="flex flex-col items-baseline mr-2 space-y-5 md:flex-row md:ml-2 md:my-0 md:space-y-0">
                                    <FormControl
                                        className="w-full min-w-none md:max-w-[200px] mr-4"
                                        sx={{
                                            "& .MuiInputLabel-root": {
                                                position: {
                                                    xs: "static",
                                                    md: "absolute",
                                                },
                                                top: "-25px",
                                            },
                                        }}
                                        error={
                                            !!errors.halfDayPricing?.length &&
                                            touched.halfDayPricing?.length
                                        }
                                    >
                                        <JCSelect
                                            labelId="length-select-label"
                                            id="halfDayPricing.length"
                                            name="halfDayPricing.length"
                                            label="Select length"
                                            onChange={(e) =>
                                                setFieldValue(
                                                    "halfDayPricing.length",
                                                    Number(e.target.value),
                                                )
                                            }
                                            onBlur={handleBlur}
                                            defaultValue={
                                                String(
                                                    props.values?.halfDayPricing
                                                        ?.length !==
                                                        undefined &&
                                                        props.values
                                                            ?.halfDayPricing
                                                            ?.length === 0
                                                        ? ""
                                                        : props.values
                                                              ?.halfDayPricing
                                                              ?.length,
                                                ) ?? ""
                                            }
                                            value={
                                                String(
                                                    props.values?.halfDayPricing
                                                        ?.length !==
                                                        undefined &&
                                                        props.values
                                                            ?.halfDayPricing
                                                            ?.length === 0
                                                        ? ""
                                                        : props.values
                                                              ?.halfDayPricing
                                                              ?.length,
                                                ) ?? ""
                                            }
                                            className="w-full pointer-events-auto max-w-none"
                                            disabled={
                                                !values.halfDayPricing
                                                    ?.isEnable ?? false
                                            }
                                        >
                                            {halfDayHourLengths &&
                                                halfDayHourLengths
                                                    .filter(
                                                        (x) =>
                                                            x <
                                                                fullDayHourLength ||
                                                            fullDayHourLength ===
                                                                0 ||
                                                            !values
                                                                .fullDayPricing
                                                                ?.isEnable,
                                                    )
                                                    .map((hourLength) => (
                                                        <MenuItem
                                                            value={String(
                                                                hourLength,
                                                            )}
                                                            key={String(
                                                                hourLength,
                                                            )}
                                                            sx={{
                                                                fontSize:
                                                                    "14px !important;",
                                                            }}
                                                        >
                                                            {hourLength} hours
                                                        </MenuItem>
                                                    ))}
                                        </JCSelect>
                                        {touched.halfDayPricing?.length &&
                                            errors.halfDayPricing?.length && (
                                                <FormHelperText
                                                    id="halfDayPricingLength-help-text"
                                                    className="m-0 mt-2"
                                                >
                                                    {
                                                        errors.halfDayPricing
                                                            ?.length
                                                    }
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                    <FormControl
                                        className="w-full md:max-w-[200px]"
                                        error={
                                            !!errors.halfDayPricing?.value &&
                                            touched.halfDayPricing?.value
                                        }
                                    >
                                        <SectionTitle
                                            title="Rate"
                                            className="!mb-0 md:absolute -top-6"
                                            labelClassName="leading-4"
                                            labelVariantInherit
                                        />
                                        <div className="w-full">
                                            <div className="relative">
                                                <IMaskInput
                                                    mask={Number}
                                                    scale={0} // digits after point, 0 for integers
                                                    signed={false} // disallow negative
                                                    value={
                                                        String(
                                                            values
                                                                .halfDayPricing
                                                                ?.value,
                                                        ) ?? "0"
                                                    }
                                                    //@ts-ignore
                                                    onBlur={handleBlur}
                                                    placeholder="0"
                                                    id="halfDayPricing.value"
                                                    name="halfDayPricing.value"
                                                    label="Hourly"
                                                    aria-describedby="hourly-help-text"
                                                    className={`w-full max-w-none maskInput pointer-events-auto ${
                                                        touched?.halfDayPricing
                                                            ?.value &&
                                                        !!errors?.halfDayPricing
                                                            ?.value
                                                            ? "maskInput-error"
                                                            : ""
                                                    }`}
                                                    disabled={
                                                        !values.halfDayPricing
                                                            ?.isEnable
                                                    }
                                                    onAccept={(value, mask) => {
                                                        value =
                                                            value === ""
                                                                ? null
                                                                : Number(value);
                                                        setFieldValue(
                                                            "halfDayPricing.value",
                                                            value,
                                                        );
                                                    }}
                                                />
                                                <div className="absolute right-3 top-2.5">
                                                    DKK
                                                </div>
                                            </div>
                                        </div>
                                        {touched.halfDayPricing?.value &&
                                            errors.halfDayPricing?.value && (
                                                <FormHelperText
                                                    id="halfDayPricingValue-help-text"
                                                    className="m-0 mt-2"
                                                >
                                                    {
                                                        errors.halfDayPricing
                                                            ?.value
                                                    }
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                                {values.pricingStyle ===
                                    PricingStyle.perPerson && (
                                    <div className="flex items-baseline mt-3 mr-2 md:ml-2">
                                        <FormControlLabel
                                            className="items-baseline md:align-middle"
                                            control={
                                                <Checkbox
                                                    id="minimumChargeHalfDay.isEnable"
                                                    name="minimumChargeHalfDay.isEnable"
                                                    checked={
                                                        values
                                                            .minimumChargeHalfDay
                                                            ?.isEnable ?? false
                                                    }
                                                    value={
                                                        values
                                                            .minimumChargeHalfDay
                                                            ?.isEnable ?? false
                                                    }
                                                    disabled={
                                                        !values?.halfDayPricing
                                                            ?.isEnable
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="relative pt-2 self-baseline top-2"
                                                />
                                            }
                                            label={
                                                <FormControl
                                                    className="flex flex-col flex-wrap items-baseline md:flex-row"
                                                    error={
                                                        !!errors
                                                            .minimumChargeHalfDay
                                                            ?.value &&
                                                        touched
                                                            .minimumChargeHalfDay
                                                            ?.value
                                                    }
                                                >
                                                    <SectionTitle
                                                        title="Minimum charge"
                                                        labelClassName="m-0 mr-4 w-full md:w-[170px] label-clickable"
                                                        className="!mb-0"
                                                        helpText={
                                                            MESSAGES.LISTING_PRICING_PER_PERSON_HALFFULLDAY_HALFDAY_MINIMUM_CHARGE_TOOLTIP
                                                        }
                                                        tooltipPosition="bottom"
                                                        hasTooltip
                                                        labelVariantInherit
                                                    />
                                                    <div className="flex items-center mt-2 md:my-0 w-[200px]">
                                                        <div className="block">
                                                            <div className="relative w-[200px]">
                                                                <IMaskInput
                                                                    mask={
                                                                        Number
                                                                    }
                                                                    scale={0} // digits after point, 0 for integers
                                                                    signed={
                                                                        false
                                                                    } // disallow negative
                                                                    value={
                                                                        String(
                                                                            values
                                                                                .minimumChargeHalfDay
                                                                                ?.value,
                                                                        ) ?? "0"
                                                                    }
                                                                    //@ts-ignore
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    placeholder="0"
                                                                    id="minimumChargeHalfDay.value"
                                                                    name="minimumChargeHalfDay.value"
                                                                    label="Hourly"
                                                                    aria-describedby="hourly-help-text"
                                                                    className={`max-w-none maskInput pointer-events-auto ${
                                                                        touched
                                                                            .minimumChargeHalfDay
                                                                            ?.value &&
                                                                        errors
                                                                            .minimumChargeHalfDay
                                                                            ?.value
                                                                            ? "maskInput-error"
                                                                            : ""
                                                                    }`}
                                                                    disabled={
                                                                        !values
                                                                            .minimumChargeHalfDay
                                                                            ?.isEnable ||
                                                                        !values
                                                                            .halfDayPricing
                                                                            ?.isEnable
                                                                    }
                                                                    onAccept={(
                                                                        value,
                                                                        mask,
                                                                    ) => {
                                                                        value =
                                                                            value ===
                                                                            ""
                                                                                ? null
                                                                                : Number(
                                                                                      value,
                                                                                  );
                                                                        setFieldValue(
                                                                            "minimumChargeHalfDay.value",
                                                                            value,
                                                                            true,
                                                                        );
                                                                    }}
                                                                />
                                                                <div className="absolute right-3 top-2.5">
                                                                    DKK
                                                                </div>
                                                            </div>
                                                            {touched
                                                                .minimumChargeHalfDay
                                                                ?.value &&
                                                                errors
                                                                    .minimumChargeHalfDay
                                                                    ?.value && (
                                                                    <FormHelperText
                                                                        id="minimumChargeHalfDay-help-text"
                                                                        className="m-0 mt-2"
                                                                    >
                                                                        {
                                                                            errors
                                                                                .minimumChargeHalfDay
                                                                                ?.value
                                                                        }
                                                                    </FormHelperText>
                                                                )}
                                                        </div>
                                                    </div>
                                                </FormControl>
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </FormControl>
                    }
                />
                <FormControlLabel
                    className="items-baseline md:align-middle md:items-baseline"
                    control={
                        <Checkbox
                            id="fullDayPricing.isEnable"
                            name="fullDayPricing.isEnable"
                            checked={values.fullDayPricing?.isEnable ?? false}
                            value={values.fullDayPricing?.isEnable ?? false}
                            onChange={(event) => {
                                handleChange(event);
                                setFieldTouched(
                                    "fullDayPricing.isEnable",
                                    true,
                                    false,
                                );
                            }}
                            onBlur={handleBlur}
                            className="self-baseline pt-0 relative top-[7px]"
                        />
                    }
                    label={
                        <FormControl
                            className="flex flex-col flex-wrap pt-2 space-y-5 md:flex-row md:items-baseline md:pt-0 md:space-y-0"
                            sx={{
                                "& .MuiInputLabel-root": {
                                    position: {
                                        xs: "static",
                                        md: "absolute",
                                    },
                                    top: "-25px",
                                },
                            }}
                        >
                            <SectionTitle
                                title="Full day"
                                labelClassName="!mb-0 mr-2 font-normal min-w-[80px] label-clickable"
                                className="!mb-0"
                                helpText={
                                    MESSAGES.LISTING_PRICING_PER_PERSON_HALFFULLDAY_FULLDAY_TOOLTIP
                                }
                                labelVariantInherit
                                hasTooltip
                            />
                            <div className="flex flex-col items-baseline mr-2 md:ml-2 md:my-0">
                                <div className="flex flex-col items-baseline mr-2 space-y-5 md:flex-row md:ml-2 md:my-0 md:space-y-0">
                                    <FormControl
                                        className="w-full min-w-none md:max-w-[200px] mr-4"
                                        error={
                                            !!errors.fullDayPricing?.length &&
                                            touched.fullDayPricing?.length
                                        }
                                    >
                                        <JCSelect
                                            labelId="length-select-label"
                                            id="fullDayPricing.length"
                                            name="fullDayPricing.length"
                                            label="Select length"
                                            onChange={(e) =>
                                                setFieldValue(
                                                    "fullDayPricing.length",
                                                    Number(e.target.value),
                                                )
                                            }
                                            onBlur={handleBlur}
                                            defaultValue={
                                                String(
                                                    props.values?.fullDayPricing
                                                        ?.length !==
                                                        undefined &&
                                                        props.values
                                                            ?.fullDayPricing
                                                            ?.length === 0
                                                        ? ""
                                                        : props.values
                                                              ?.fullDayPricing
                                                              ?.length,
                                                ) ?? ""
                                            }
                                            value={
                                                String(
                                                    props.values?.fullDayPricing
                                                        ?.length !==
                                                        undefined &&
                                                        props.values
                                                            ?.fullDayPricing
                                                            ?.length === 0
                                                        ? ""
                                                        : props.values
                                                              ?.fullDayPricing
                                                              ?.length,
                                                ) ?? ""
                                            }
                                            className="w-full pointer-events-auto max-w-none"
                                            disabled={
                                                !values.fullDayPricing
                                                    ?.isEnable ?? false
                                            }
                                        >
                                            {fullDayHourLengths &&
                                                fullDayHourLengths
                                                    .filter(
                                                        (x) =>
                                                            x >
                                                                halfDayHourLength ||
                                                            halfDayHourLength ===
                                                                0 ||
                                                            !values
                                                                .halfDayPricing
                                                                ?.isEnable,
                                                    )
                                                    .map((hourLength) => (
                                                        <MenuItem
                                                            value={String(
                                                                hourLength,
                                                            )}
                                                            key={String(
                                                                hourLength,
                                                            )}
                                                            sx={{
                                                                fontSize:
                                                                    "14px !important;",
                                                            }}
                                                        >
                                                            {hourLength} hours
                                                        </MenuItem>
                                                    ))}
                                        </JCSelect>
                                        {touched.fullDayPricing?.length &&
                                            errors.halfDayPricing?.length && (
                                                <FormHelperText
                                                    id="fullDayPricingLength-help-text"
                                                    className="m-0 mt-2"
                                                >
                                                    {
                                                        errors.fullDayPricing
                                                            ?.length
                                                    }
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                    <FormControl
                                        className="w-full md:max-w-[200px]"
                                        error={
                                            !!errors.fullDayPricing?.value &&
                                            touched.fullDayPricing?.value
                                        }
                                    >
                                        <SectionTitle
                                            title="Rate"
                                            className="!mb-0 md:absolute -top-6"
                                            labelClassName="leading-4"
                                            labelVariantInherit
                                        />
                                        <div className="w-full">
                                            <div className="relative">
                                                <IMaskInput
                                                    mask={Number}
                                                    scale={0} // digits after point, 0 for integers
                                                    signed={false} // disallow negative
                                                    value={
                                                        String(
                                                            values
                                                                .fullDayPricing
                                                                ?.value,
                                                        ) ?? "0"
                                                    }
                                                    //@ts-ignore
                                                    onBlur={handleBlur}
                                                    placeholder="0"
                                                    id="fullDayPricing.value"
                                                    name="fullDayPricing.value"
                                                    label="Hourly"
                                                    aria-describedby="hourly-help-text"
                                                    className={`w-full max-w-none maskInput pointer-events-auto ${
                                                        touched.fullDayPricing
                                                            ?.value &&
                                                        errors.fullDayPricing
                                                            ?.value
                                                            ? "maskInput-error"
                                                            : ""
                                                    }`}
                                                    disabled={
                                                        !values.fullDayPricing
                                                            ?.isEnable
                                                    }
                                                    onAccept={(value, mask) => {
                                                        value =
                                                            value === ""
                                                                ? null
                                                                : Number(value);
                                                        setFieldValue(
                                                            "fullDayPricing.value",
                                                            value,
                                                            true,
                                                        );
                                                    }}
                                                />
                                                <div className="absolute right-3 top-2.5">
                                                    DKK
                                                </div>
                                            </div>
                                        </div>
                                        {touched.fullDayPricing?.value &&
                                            errors.fullDayPricing?.value && (
                                                <FormHelperText
                                                    id="fullDayPricingValue-help-text"
                                                    className="m-0 mt-2"
                                                >
                                                    {
                                                        errors.fullDayPricing
                                                            ?.value
                                                    }
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                                {values.pricingStyle ===
                                    PricingStyle.perPerson && (
                                    <div className="flex items-baseline mt-3 mr-2 md:ml-2">
                                        <FormControlLabel
                                            className="items-baseline md:align-middle"
                                            control={
                                                <Checkbox
                                                    id="minimumChargeFullDay.isEnable"
                                                    name="minimumChargeFullDay.isEnable"
                                                    checked={
                                                        values
                                                            ?.minimumChargeFullDay
                                                            ?.isEnable ?? false
                                                    }
                                                    value={
                                                        values
                                                            ?.minimumChargeFullDay
                                                            ?.isEnable ?? false
                                                    }
                                                    disabled={
                                                        !values?.fullDayPricing
                                                            ?.isEnable
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="relative pt-2 self-baseline top-2"
                                                />
                                            }
                                            label={
                                                <FormControl
                                                    className="flex flex-col flex-wrap items-baseline md:flex-row"
                                                    error={
                                                        !!errors
                                                            .minimumChargeFullDay
                                                            ?.value &&
                                                        touched
                                                            .minimumChargeFullDay
                                                            ?.value
                                                    }
                                                >
                                                    <SectionTitle
                                                        title="Minimum charge"
                                                        labelClassName="m-0 mr-4 w-full md:w-[170px] label-clickable"
                                                        className="!mb-0"
                                                        helpText={
                                                            MESSAGES.LISTING_PRICING_PER_PERSON_HALFFULLDAY_FULLDAY_MINIMUM_CHARGE_TOOLTIP
                                                        }
                                                        tooltipPosition="bottom"
                                                        hasTooltip
                                                        labelVariantInherit
                                                    />
                                                    <div className="flex items-center mt-2 md:my-0 w-[200px]">
                                                        <div className="block">
                                                            <div className="relative w-[200px]">
                                                                <IMaskInput
                                                                    mask={
                                                                        Number
                                                                    }
                                                                    scale={0} // digits after point, 0 for integers
                                                                    signed={
                                                                        false
                                                                    } // disallow negative
                                                                    value={
                                                                        String(
                                                                            values
                                                                                .minimumChargeFullDay
                                                                                ?.value,
                                                                        ) ?? "0"
                                                                    }
                                                                    //@ts-ignore
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    placeholder="0"
                                                                    id="minimumChargeFullDay.value"
                                                                    name="minimumChargeFullDay.value"
                                                                    label="Hourly"
                                                                    aria-describedby="hourly-help-text"
                                                                    className={`max-w-none maskInput pointer-events-auto ${
                                                                        errors
                                                                            .minimumChargeFullDay
                                                                            ?.value &&
                                                                        touched
                                                                            .minimumChargeFullDay
                                                                            ?.value
                                                                            ? "maskInput-error"
                                                                            : ""
                                                                    }`}
                                                                    disabled={
                                                                        !values
                                                                            .minimumChargeFullDay
                                                                            ?.isEnable ||
                                                                        !values
                                                                            ?.fullDayPricing
                                                                            ?.isEnable
                                                                    }
                                                                    onAccept={(
                                                                        value,
                                                                        mask,
                                                                    ) => {
                                                                        value =
                                                                            value ===
                                                                            ""
                                                                                ? null
                                                                                : Number(
                                                                                      value,
                                                                                  );
                                                                        setFieldValue(
                                                                            "minimumChargeFullDay.value",
                                                                            value,
                                                                            true,
                                                                        );
                                                                    }}
                                                                />
                                                                <div className="absolute right-3 top-2.5">
                                                                    DKK
                                                                </div>
                                                            </div>
                                                            {touched
                                                                .minimumChargeFullDay
                                                                ?.value &&
                                                                errors
                                                                    .minimumChargeFullDay
                                                                    ?.value && (
                                                                    <FormHelperText
                                                                        id="minimumChargeFullDay-help-text"
                                                                        className="m-0 mt-2"
                                                                    >
                                                                        {
                                                                            errors
                                                                                .minimumChargeFullDay
                                                                                ?.value
                                                                        }
                                                                    </FormHelperText>
                                                                )}
                                                        </div>
                                                    </div>
                                                </FormControl>
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </FormControl>
                    }
                />
            </FormGroup>
        </>
    );
};

export default HalfFullDayPassed;
