import { Button, Divider, Typography } from "@mui/material";
import FileUpload from "components/FileUpload";
import { AdminPageTitle } from "components/Layouts";
import { ModalConfirmation } from "components/UI";
import { ListingStatus } from "components/UI/Vendors/Listing/ListingStatus";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { useNotificationContext } from "context/NotificationContext";
import { PublishStatus } from "enum/publish-status.enum";
import { FormikProps, useFormik } from "formik";
import usePrompt from "helpers/prompt.helper";
import { ImageModel } from "models/image.model";
import { defaultMeetingRoom, MeetingRoom } from "models/meeting-room.model";
import { useEffect, useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import { generatePath, useNavigate } from "react-router-dom";
import { firebaseService } from "services/firebase.service";
import { listingService } from "services/listing.service";
import MESSAGES from "shared/constants/messages.constant";
import ROUTES from "shared/constants/routes.constant";
import { listingValidation } from "validation-schema/listing.validation";
import { AddOns } from "./AddOns";
import { Amenities } from "./Amenities";
import { CancellationPolicy } from "./CancellationPolicy";
import { MeetingRoomDescription } from "./MeetingRoomDescription";
import { MeetingRoomDetail } from "./MeetingRoomDetail";
import { Mood } from "./Mood";
import { OpeningHours } from "./OpeningHours";
import { Pricing } from "./Pricing";

export interface ListingProps {
    listing: MeetingRoom | null;
    isAddNewListing: boolean;
    pageTitle?: string;
    isFetchingInitialData: boolean;
}

const validate = (formik: FormikProps<MeetingRoom>) => {
    const values = formik.values;
    if (!values.title) {
        formik.setFieldTouched("title", true);
        throw new Error(formik.errors.title);
    }
    if (formik.errors && formik.errors.openingHours?.length >= 1) {
        throw new Error("Please input the correct opening time");
    }
};
export const ListingForm = (props: ListingProps) => {
    const navigate = useNavigate();
    const { listing, isAddNewListing, isFetchingInitialData } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false);
    const fullPageLoaderContext = useFullPageLoadingContext();

    usePrompt(MESSAGES.LISTING_NAVIGATE_AWAY_CONFIRMATION, isBlocking);
    const notificationContext = useNotificationContext();

    // Check  full page loading
    useEffect(() => {
        if (isFetchingInitialData) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [isFetchingInitialData]);

    const defaultLoadedImages = listing
        ? listing.images
        : defaultMeetingRoom.images;
    const deleteInactiveImages = async (images: ImageModel[]) => {
        if (defaultLoadedImages && defaultLoadedImages.length === 0) return;

        // inactivedItems: the items that disappeared from edited images
        const inactivedItems = defaultLoadedImages.filter(
            (x) => images.map((img) => img.uid).indexOf(x.uid) === -1,
        );
        inactivedItems.map(async (image) => {
            try {
                await firebaseService.deleteImage(
                    "meetingRooms",
                    image.reference,
                );
                await firebaseService.deleteImage(
                    "meetingRooms",
                    `${image.thumbReference}`,
                );
            } catch (ex) {
                console.log(ex);
            }
        });
    };
    const storageContainer = "meetingRooms";
    const uploadImages = (images: ImageModel[]) => {
        return images
            .filter((x) => x.url?.startsWith("data:")) // only upload new images where the url starts with 'data:'
            .map((item) => {
                return new Promise((resolve, reject) => {
                    try {
                        firebaseService
                            .uploadImage(storageContainer, item)
                            .then((downloadURL) => {
                                //Update image url
                                item.url = downloadURL;
                                const urlSplitted = downloadURL.split(
                                    `${storageContainer}%2F`,
                                );

                                item.thumbUrl = `${urlSplitted[0]}${storageContainer}%2Fthumb_${urlSplitted[1]}`;
                                item.reference = `${
                                    urlSplitted[1].split("?")[0]
                                }`;
                                item.thumbReference = `thumb_${
                                    urlSplitted[1].split("?")[0]
                                }`;
                                //Remove unwanted field
                                delete item.file;
                                resolve(item);
                            });
                    } catch (error) {
                        console.log(error);
                        images = [...images.filter((x) => x !== item)];
                    }
                });
            });
    };
    const handleSaveData = async (formik: FormikProps<MeetingRoom>) => {
        setIsBlocking(false);
        try {
            validate(formik);
            const status =
                formik.values.status === PublishStatus.Unpublish
                    ? PublishStatus.Unpublish
                    : PublishStatus.Draft;

            await saveListingDetail(formik.values, status, false);
        } catch (ex) {
            setIsLoading(false);
            notificationContext.showError(ex.message);
        }
    };

    const handleUnpublishListing = async (formik: FormikProps<MeetingRoom>) => {
        setIsBlocking(false);
        try {
            validate(formik);
            await saveListingDetail(
                formik.values,
                PublishStatus.Unpublish,
                true,
            );
        } catch (ex) {
            setIsLoading(false);
            notificationContext.showError(ex.message);
        }
    };

    const saveListingDetail = async (
        values: MeetingRoom,
        status: PublishStatus,
        redirectToListing: boolean = true,
    ) => {
        try {
            setIsLoading(true);
            //Upload images
            await deleteInactiveImages(values.images);
            await Promise.all(uploadImages(values.images));
            const response = await listingService.addOrUpdateListing(
                values,
                status,
            );
            setIsLoading(false);

            if (redirectToListing) {
                navigate({
                    pathname: ROUTES.Listings,
                    search: "?refetchList=true",
                });
                if (status === PublishStatus.Unpublish) {
                    notificationContext.showSuccess(
                        MESSAGES.LISTING_UNLISTING_SUCCESSFULLY,
                    );
                } else {
                    notificationContext.showSuccess(
                        props.isAddNewListing
                            ? MESSAGES.LISTING_ADD_SUCCESSFULLY
                            : MESSAGES.LISTING_UPDATED_SUCCESSFULLY,
                    );
                }
            } else {
                navigate({
                    pathname: generatePath(ROUTES.ListEdit, {
                        id: response.id,
                    }),
                });
                notificationContext.showSuccess(
                    status === PublishStatus.Unpublish
                        ? MESSAGES.LISTING_UPDATED_SUCCESSFULLY
                        : MESSAGES.LISTING_DRAFT_SAVE_SUCCESSFULLY,
                );
            }
        } catch (ex) {
            setIsLoading(false);
            notificationContext.showError(ex.message);
        }
    };

    const formik = useFormik({
        initialValues: listing ?? defaultMeetingRoom,
        enableReinitialize: true,
        validationSchema: listingValidation,
        onSubmit: async (values: MeetingRoom) => {
            try {
                setIsBlocking(false);
                await saveListingDetail(values, PublishStatus.Publish, true);
            } catch (ex) {
                setIsLoading(false);
                notificationContext.showError(ex.message);
            }
        },
    });

    useEffect(() => {
        setIsBlocking(formik.dirty);
    }, [formik.dirty]);

    useEffect(() => {
        if (isLoading) fullPageLoaderContext.show();
        else fullPageLoaderContext.hide();
    }, [isLoading]);

    useEffect(() => {
        if (isAddNewListing && listing)
            listing.openingHours = defaultMeetingRoom.openingHours;
    }, [isAddNewListing]);

    useEffect(() => {
        if (
            listing
            // && isAddNewListing
        ) {
            formik.validateForm();
        }
    }, [listing]);

    return (
        <>
            <AdminPageTitle
                pageTitle={props.pageTitle}
                icon={<IoChevronBack />}
                iconURL={ROUTES.Listings + "?refetchList=true"}
                className="sm:pr-20"
            />
            <Divider className="my-6 mt-6" />
            {!props.isAddNewListing && (
                <ListingStatus
                    status={props.listing?.status}
                    className="absolute right-0 top-1 sm:top-14"
                />
            )}
            <form onSubmit={formik.handleSubmit} className="rounded-lg">
                <MeetingRoomDetail {...formik} />
                <Divider className="my-6 md:mt-0" />
                <OpeningHours {...formik} />
                <Divider className="my-6" />
                <Pricing
                    isAddNewListing={props.isAddNewListing}
                    formik={formik}
                />
                <Divider className="my-6" />
                <Amenities formik={formik} />
                <Divider className="my-6" />
                <AddOns formik={formik} />
                <Divider className="my-6" />
                <Mood formik={formik} />
                <Divider className="mt-1 mb-6" />
                <MeetingRoomDescription {...formik} />
                <Divider className="my-6" />
                <FileUpload {...formik} />
                <Divider className="my-6" />
                <CancellationPolicy formik={formik} />
                {/* FOOTER BUTTON */}
                <div className="sticky bottom-0 z-50 pb-6 bg-white">
                    <Divider className="my-6" />
                    <div className="flex flex-col justify-between space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3">
                        <div className="flex flex-col flex-grow space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3">
                            {formik.values.status !== PublishStatus.Publish && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="w-full sm:max-w-[175px]"
                                    fullWidth
                                    onClick={() => handleSaveData(formik)}
                                >
                                    Save
                                </Button>
                            )}

                            <Button
                                variant="contained"
                                type="submit"
                                color="success"
                                className="w-full sm:max-w-[175px]"
                                fullWidth
                                disabled={
                                    (!formik.dirty &&
                                        formik.values.status ===
                                            PublishStatus.Publish) ||
                                    !formik.isValid ||
                                    formik.isSubmitting
                                }
                            >
                                Save & List
                            </Button>
                        </div>
                        {formik.values.status === PublishStatus.Publish && (
                            <Button
                                variant="outlined"
                                color="primary"
                                className="w-full sm:max-w-[175px]"
                                fullWidth
                                onClick={() => setOpenConfirmation(true)}
                            >
                                Unlist
                            </Button>
                        )}
                    </div>
                </div>
                {/* CONFIRMATION MODAL */}
                <ModalConfirmation
                    severity="primary"
                    title={MESSAGES.LISTING_UNLISTING_CONFIRMATION}
                    btnCancelText={MESSAGES.LISTING_UNLISTING_CANCEL_BUTTON}
                    btnText={MESSAGES.LISTING_UNLISTING_CONFIRMATION_BUTTON}
                    open={openConfirmation}
                    closeFn={() => setOpenConfirmation(false)}
                    confirmFn={() => {
                        handleUnpublishListing(formik);
                        setOpenConfirmation(false);
                    }}
                >
                    <Typography variant="body2" color="initial">
                        {MESSAGES.LISTING_UNLISTING_BODY_CONFIRMATION}
                    </Typography>
                </ModalConfirmation>
            </form>
        </>
    );
};
