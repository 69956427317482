import { IoEllipse, IoEllipseOutline } from "react-icons/io5";
import { StatusListingProps } from "types/listings";

export const ListingStatus: React.FC<StatusListingProps> = (props) => {
    let iconColor;
    let textColor;
    let textStatus;
    (() => {
        switch (props.status) {
            case "publish":
                textColor = "text-success";
                iconColor = "text-success";
                textStatus = "Listed";
                return;
            case "unpublish":
                textColor = "text-inactive";
                iconColor = "text-error";
                textStatus = "Unlisted";
                return;
            case "draft":
                textColor = "text-black";
                iconColor = "text-black";
                textStatus = "Draft";
                return;
            default:
                textColor = "text-black";
        }
    })();
    return (
        <span
            className={`flex items-center font-normal text-base capitalize ${props.className}`}
        >
            {(props.status === "publish" || props.status === "unpublish") && (
                <IoEllipse className={`${iconColor} text-xs mr-1`} />
            )}
            {props.status === "draft" && (
                <IoEllipseOutline className={`${iconColor} text-xs mr-1`} />
            )}
            {textStatus}
        </span>
    );
};
