import {
    Avatar,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    RadioGroup,
    styled,
    TextareaAutosize,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as appConfigs from "appSettings";
import { TextInput } from "components";
import { JCSnackbar } from "components/UI";
import { BaseRadio } from "components/UI/Radio";
import { useAuthContext } from "context/AuthContext";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { VendorType } from "enum/vendor.enum";
import { useFormik } from "formik";
import { ImageModel } from "models/image.model";
import {
    defaultVendorProfile,
    VendorProfile,
} from "models/vendor-profile.model";
import { useEffect, useState } from "react";
import {
    IoAddOutline,
    IoCameraOutline,
    IoCreateOutline,
} from "react-icons/io5";
import { firebaseService } from "services/firebase.service";
import { vendorService } from "services/vendor.service";
import MESSAGES from "shared/constants/messages.constant";
import { vendorValidation } from "validation-schema/vendor-profile.validation";

const Input = styled("input")({
    display: "none",
});

const ImgProfile = (props: VendorProfileProps) => {
    const { vendor, setFieldValue, setErrors } = props;
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [openSnackbarError, setOpenSnackbarError] = useState(false);
    const [message, setMessage] = useState("");
    const handleImageChange = (event) => {
        let fileList = Array.from(event.target.files);
        fileList.forEach((files: any) => {
            if (
                appConfigs.SUPPORTED_IMAGE_TYPES.includes(files.type) &&
                files.type !== ""
            ) {
                if (appConfigs.MAX_FILE_SIZE_KB >= files.size / 1024) {
                    vendor.image.file = files;
                    setFieldValue("image", {}); // set value for dirty flag
                    setFieldValue("image.file", vendor.image.file);
                    setFieldValue(
                        "image.imageReference",
                        vendor.image.imageReference,
                    );
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        setFiles([files]);
                        setImages([reader.result]);
                    };
                    reader.readAsDataURL(files as Blob);
                } else {
                    const errorMsg = `${
                        MESSAGES.PROFILE_IMAGE_VALIDATION_FILE_SIZE
                    } ${String(appConfigs.MAX_FILE_SIZE_KB)} Kb`;
                    setErrors({ image: errorMsg });
                    setMessage(errorMsg);
                    setOpenSnackbarError(true);
                }
            } else {
                setErrors({
                    image: MESSAGES.PROFILE_IMAGE_VALIDATION_FILE_TYPE,
                });
                setMessage(MESSAGES.PROFILE_IMAGE_VALIDATION_FILE_TYPE);
                setOpenSnackbarError(true);
            }
        });
    };
    if (imagesUrl.length === 0 && vendor.image.imageUrl) {
        setImagesUrl([vendor.image.imageUrl]);
    }

    return (
        <div className="flex items-center">
            {files.length > 0 ? (
                images.map((images, i) => {
                    return (
                        <div key={i} className="relative mr-4 md:mr-8">
                            <Avatar
                                className="bg-grey-400"
                                sx={{ width: 110, height: 110 }}
                                src={images}
                            ></Avatar>
                            <label htmlFor="contained-button-file">
                                <Input
                                    accept={appConfigs.SUPPORTED_IMAGE_TYPES}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleImageChange}
                                />
                                <IconButton
                                    color="primary"
                                    aria-label="edit picture"
                                    component="span"
                                    sx={{ width: 30, height: 30 }}
                                    className="absolute bottom-0 right-0 p-0 bg-black rounded-full hover:bg-black"
                                >
                                    <IoCreateOutline className="text-white relative text-lg left-[1px]" />
                                </IconButton>
                            </label>
                        </div>
                    );
                })
            ) : imagesUrl.length > 0 ? (
                imagesUrl.map((imagesUrl, i) => {
                    return (
                        <div key={i} className="relative mr-4 md:mr-8">
                            <Avatar
                                className="bg-grey-400"
                                sx={{ width: 110, height: 110 }}
                                src={imagesUrl}
                            ></Avatar>
                            <label htmlFor="contained-button-file">
                                <Input
                                    accept={appConfigs.SUPPORTED_IMAGE_TYPES}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleImageChange}
                                />
                                <IconButton
                                    color="primary"
                                    aria-label="edit picture"
                                    component="span"
                                    sx={{ width: 30, height: 30 }}
                                    className="absolute bottom-0 right-0 p-0 bg-black rounded-full hover:bg-black"
                                >
                                    <IoCreateOutline className="text-white relative text-lg left-[1px]" />
                                </IconButton>
                            </label>
                        </div>
                    );
                })
            ) : (
                <label htmlFor="icon-button-file" className="mr-4 md:mr-8">
                    <Avatar
                        className="overflow-visible bg-grey-400"
                        sx={{ width: 110, height: 110 }}
                    >
                        <Input
                            accept={appConfigs.SUPPORTED_IMAGE_TYPES}
                            id="icon-button-file"
                            type="file"
                            onChange={handleImageChange}
                        />
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            disableRipple
                        >
                            <IoCameraOutline className="text-5xl text-white" />
                        </IconButton>
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            sx={{ width: 30, height: 30 }}
                            className="absolute bottom-0 right-0 p-0 bg-black rounded-full hover:bg-black"
                        >
                            <IoAddOutline className="relative left-0 text-2xl text-white" />
                        </IconButton>
                    </Avatar>
                </label>
            )}
            <div className="flex flex-col">
                <Typography
                    variant="body2"
                    color="initial"
                    className="mb-1 font-bold"
                >
                    Profile photo
                </Typography>
                {/* <Typography variant="body2" color="initial">
                    The image should be 1:1 ratio
                </Typography> */}
            </div>
            <JCSnackbar
                open={openSnackbarError}
                onClose={() => setOpenSnackbarError(false)}
                vertical={"bottom"}
                horizontal={"right"}
                severity="error"
                description={message}
            ></JCSnackbar>
        </div>
    );
};

const storageContainer = "userPhotos";
const deleteImages = async (imageReference) => {
    try {
        await firebaseService.deleteImage(
            `${storageContainer}`,
            `${imageReference}`,
        );
    } catch (ex) {
        console.log(ex);
    }
};
export interface VendorProfileProps {
    vendor: VendorProfile | null;
    setFieldValue?: any;
    setErrors?: any;
}
export const VendorProfileForm = (props: VendorProfileProps) => {
    let vendor = props.vendor ?? defaultVendorProfile;
    if (!vendor.vendorType) {
        vendor = defaultVendorProfile;
    }
    const auth = useAuthContext();
    const vendorId = auth.authUser.userId;
    const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
    const fullPageLoaderContext = useFullPageLoadingContext();
    const formik = useFormik({
        initialValues: vendor,
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: vendorValidation,
        onSubmit: async (values: VendorProfile) => {
            if (values.image?.file) {
                if (values.image.imageReference)
                    deleteImages(values.image.imageReference);
                const downloadURL = await firebaseService.uploadImage(
                    `${storageContainer}`,
                    {
                        title: values.image.file.name,
                        file: values.image.file,
                    } as ImageModel,
                );
                values.image.imageUrl = downloadURL;
                values.image.imageReference = downloadURL
                    .split(`${storageContainer}%2F`)[1]
                    .split("?")[0];
                delete values.image.file;
            }
            values.name =
                values.vendorType === VendorType.Company
                    ? values.company
                    : `${values.firstName} ${values.lastName}`;
            await vendorService.updateVendorById(vendorId, values);
            setOpenSnackbarSuccess(true);
        },
    });
    const {
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
        values,
        isValid,
        dirty,
        setFieldValue,
        setErrors,
    } = formik;
    useEffect(() => {
        if (isSubmitting) fullPageLoaderContext.show();
        else fullPageLoaderContext.hide();
    }, [isSubmitting]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-5">
                <ImgProfile
                    vendor={vendor}
                    setFieldValue={setFieldValue}
                    setErrors={setErrors}
                />
            </div>
            <Typography variant="body2" color="initial" className="font-bold">
                List as…
            </Typography>
            <Grid container rowSpacing={0} columnSpacing={0}>
                <Grid item xs={12} md={6}>
                    <FormControl className="mt-2">
                        <RadioGroup
                            aria-labelledby="type-group-label"
                            name="vendorType"
                            id="vendorType"
                            className="flex flex-row space-x-6"
                            value={values.vendorType ?? VendorType.Company}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <FormControlLabel
                                value={VendorType.Company}
                                control={<BaseRadio />}
                                label="Company"
                                className="font-bold"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <FormControlLabel
                                value="individual"
                                control={<BaseRadio />}
                                label="Individual"
                                className="font-bold"
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <FormHelperText error={false} className="mb-3">
                Choose whether to show your profile as a company or an
                individual on your meeting room description page. You can choose
                to share your name, your company or both in your vendor profile.
            </FormHelperText>

            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.company && touched.company}
                    >
                        <TextInput
                            label={
                                values.vendorType === VendorType.Company
                                    ? "Company name"
                                    : "Company name (Optional)"
                            }
                            placeholder=""
                            id="company"
                            name="company"
                            aria-describedby="company-help-text"
                            value={values.company ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol={
                                values.vendorType === VendorType.Company
                            }
                        />
                        {touched.company && errors.company && (
                            <FormHelperText
                                id="company-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.company}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.firstName && touched.firstName}
                    >
                        <TextInput
                            label={
                                values.vendorType === VendorType.Individual
                                    ? "First name"
                                    : "First name (Optional)"
                            }
                            placeholder=""
                            id="firstName"
                            name="firstName"
                            aria-describedby="firstName-help-text"
                            value={values.firstName ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol={
                                values.vendorType === VendorType.Individual
                            }
                        />
                        {touched.firstName && errors.firstName && (
                            <FormHelperText
                                id="firstName-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.firstName}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.lastName && touched.lastName}
                    >
                        <TextInput
                            label={
                                values.vendorType === VendorType.Individual
                                    ? "Last name"
                                    : "Last name (Optional)"
                            }
                            placeholder=""
                            id="lastName"
                            name="lastName"
                            value={values.lastName ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-describedby="lastName-help-text"
                            showRequiredSymbol={
                                values.vendorType === VendorType.Individual
                            }
                        />
                        {touched.lastName && errors.lastName && (
                            <FormHelperText
                                id="lastName-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.lastName}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={12}>
                    <FormControl
                        variant="standard"
                        className="flex flex-col"
                        error={!!errors.bio && touched.bio}
                    >
                        <label htmlFor="profile_desc" className="label">
                            About *
                        </label>
                        <TextareaAutosize
                            aria-label="Profile desacription"
                            placeholder="Profile description"
                            minRows="4"
                            id="bio"
                            name="bio"
                            value={values.bio}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.bio && errors.bio && (
                            <FormHelperText
                                id="bio-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.bio}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <div className="flex flex-col justify-between mt-8 space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3">
                <Button
                    variant="contained"
                    color="primary"
                    className="w-full sm:max-w-[175px]"
                    fullWidth
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting}
                >
                    Save
                </Button>
            </div>
            <JCSnackbar
                open={openSnackbarSuccess}
                onClose={() => setOpenSnackbarSuccess(false)}
                vertical={"bottom"}
                horizontal={"right"}
                severity="success"
                description={MESSAGES.PROFILE_UPDATE_SUCCESSFULLY}
            ></JCSnackbar>
        </form>
    );
};
