import { FullPageLoaderProps } from "components/UI/FullPageLoader";
import { FullPageLoadingContext } from "context/FullPageLoadingContext";
import React, { useState } from "react";

export const FullPageLoaderProvider: React.FC = ({ children }) => {
    const [fullPageLoader, setFullPageLoader] = useState(
        {} as FullPageLoaderProps,
    );

    const show = () => {
        setFullPageLoader({
            isLoading: true,
        } as FullPageLoaderProps);
    };

    const hide = () => {
        setFullPageLoader({
            isLoading: false,
        } as FullPageLoaderProps);
    };

    const fullPageLoaderContext = {
        show,
        hide,
        fullPageLoader,
    };

    return (
        <FullPageLoadingContext.Provider value={fullPageLoaderContext}>
            {children}
        </FullPageLoadingContext.Provider>
    );
};
