import {
    API_ENDPOINT_ฺVENDOR_ACCOUNT_INFORMATION,
    API_ENDPOINT_ฺVENDOR_PROFILE,
} from "shared/constants/api.constant";
import { DBCollection } from "enum/db-collection.enum";
import { doc } from "firebase/firestore";
import { db, DocumentData, onSnapshot } from "firebaseSetup";
import { AccountInformationModel } from "models/account-information.model";
import { PaginationModel } from "models/pagination.model";
import { VendorProfile } from "models/vendor-profile.model";
import { VendorSettingsModel } from "models/vendor-settings.model";
import { VendorList, VendorWorkspaces } from "models/vendor.model";
import { requestService } from "./request.service";

const getVendorProfileById = async (
    vendorId: string,
): Promise<VendorProfile> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_PROFILE.getVendorProfileById,
        {
            vendorId: vendorId,
        },
    )) as VendorProfile;
};

const updateVendorById = async (
    vendorId: string,
    data: VendorProfile,
): Promise<VendorProfile> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_PROFILE.updateVendorProfileById,
        {
            vendorId: vendorId,
            vendor: data,
        },
    )) as VendorProfile;
};

const getVendorAccountInformationById = async (
    vendorId: string,
): Promise<AccountInformationModel> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_ACCOUNT_INFORMATION.getVendorAccountInformationById,
        {
            vendorId: vendorId,
        },
    )) as AccountInformationModel;
};
const getVendorDetailById = async (
    vendorId: string,
): Promise<AccountInformationModel> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_PROFILE.getVendorDetailById,
        {
            vendorId: vendorId,
        },
    )) as AccountInformationModel;
};

const updateVendorAccountInformation = async (
    vendorId: string,
    data: AccountInformationModel,
): Promise<AccountInformationModel> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_ACCOUNT_INFORMATION.updateVendorAccountInformation,
        {
            vendorId: vendorId,
            accountInformation: data,
        },
    )) as AccountInformationModel;
};

const subScribeMonthlyReport = (
    userId: string,
    year: number,
    month: number,
    snapShotData: (doc: DocumentData) => void,
) => {
    const path = `${DBCollection.Vendors}/${userId}/${DBCollection.MonthlyReports}`;
    const docId = `${year}_${month.toString().padStart(2, "0")}`;
    const ref = doc(db, path, docId);
    return onSnapshot(ref, snapShotData);
};

const subScribeVendorInactive = (
    userId: string,
    snapShotData: (doc: DocumentData) => void,
) => {
    const path = `${DBCollection.Vendors}`;
    const ref = doc(db, path, userId);
    return onSnapshot(ref, snapShotData);
};

const addVendor = async (
    data: VendorSettingsModel,
): Promise<VendorSettingsModel> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_PROFILE.addVendor,
        data,
    )) as VendorSettingsModel;
};

const updateVendor = async (
    data: VendorSettingsModel,
): Promise<VendorSettingsModel> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_PROFILE.updateVendorByVendorId,
        data,
    )) as VendorSettingsModel;
};

const deleteVendor = async (vendorId: string) => {
    return await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_PROFILE.deleteVendorById,
        {
            vendorId: vendorId,
        },
    );
};

const getVendors = async (
    queryText: string,
    amount: number,
    lastId: string,
    orderByKey: string,
    sortingDirection: string = "asc",
): Promise<PaginationModel<VendorList[]>> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_PROFILE.getVendors,
        {
            queryText,
            amount,
            lastId,
            orderByKey,
            sortingDirection,
        },
    )) as Promise<PaginationModel<VendorList[]>>;
};

const getVendorWorkspaces = async (
    vendorId: string,
): Promise<VendorWorkspaces[]> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_PROFILE.getVendorAvailableSpacesByVendorId,
        {
            vendorId,
        },
    )) as Promise<VendorWorkspaces[]>;
};
export const vendorService = {
    getVendorProfileById,
    updateVendorById,
    getVendorAccountInformationById,
    updateVendorAccountInformation,
    subScribeMonthlyReport,
    subScribeVendorInactive,
    addVendor,
    getVendors,
    getVendorDetailById,
    updateVendor,
    deleteVendor,
    getVendorWorkspaces,
};
