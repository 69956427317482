import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useEffect, useState } from "react";

interface JCTooltipProps extends TooltipProps {
    tooltipOpen?: boolean;
    onClose?: () => void;
}
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        color: "#000",
        maxWidth: 600,
        fontSize: 14,
        border: "1px solid #dadde9",
        boxShadow:
            "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
        padding: "12px 16px",
        // "& .MuiTooltip-arrow": {
        //     color: "#fff",
        // },
    },
}));

export const JCTooltip: React.FC<JCTooltipProps> = ({
    children,
    title,
    tooltipOpen,
    onClose,
    ...props
}) => {
    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
    const [touchDevice, setTouchDevice] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            window.addEventListener("resize", function () {
                const touchDevice = matchMedia("(pointer:coarse)");
                setTouchDevice(touchDevice.matches);
            });
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {mdScreen && !touchDevice ? (
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">{title}</Typography>
                        </React.Fragment>
                    }
                    {...props}
                    // arrow
                    // placement="right"
                >
                    {children}
                </HtmlTooltip>
            ) : (
                <ClickAwayListener onClickAway={onClose}>
                    <div className="inline-block">
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">
                                        {title}
                                    </Typography>
                                </React.Fragment>
                            }
                            // arrow
                            // enterTouchDelay={2000}
                            open={tooltipOpen}
                            onClose={onClose}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            PopperProps={{
                                disablePortal: true,
                            }}
                        >
                            {children}
                        </HtmlTooltip>
                    </div>
                </ClickAwayListener>
            )}
        </>
    );
};
