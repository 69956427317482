import {
    AdminContentContainer,
    AdminSingleBlockLayout,
} from "components/Layouts";
import { ListingForm } from "components/ListingForm";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { listingService } from "services/listing.service";

const EditListingPage = () => {
    let { id } = useParams();
    //let listingForm: MeetingRoom = defaultMeetingRoom;

    const { data: listing, isFetching } = useQuery(
        `edit-listing-${id}`,
        async () => await listingService.getListingById(id),
        {
            refetchOnWindowFocus: false,
        },
    );
    return (
        <>
            <Helmet>
                <title>Joes & Cos | Add New Listing</title>
            </Helmet>
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <div className="max-w-[720px] mx-auto relative">
                        <ListingForm
                            listing={listing}
                            isAddNewListing={false}
                            pageTitle={listing?.title ?? ""}
                            isFetchingInitialData={isFetching}
                        />
                    </div>
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};

export default EditListingPage;
