import ROLES from "shared/constants/roles.constant";
import ROUTES from "shared/constants/routes.constant";
export const nav = [
    {
        name: "Dashboard",
        path: ROUTES.Dashboard,
        allowRoles: [ROLES.Admin, ROLES.Vendor],
    },
    {
        name: "Listings",
        path: ROUTES.Listings + "?refetchList=true",
        allowRoles: [ROLES.Admin, ROLES.Vendor],
    },
    {
        name: "Vendors",
        path: "/vendors",
        allowRoles: [ROLES.Admin],
    },
    // {
    //   name: "Vendors Profile",
    //   path: "/vendor-profile",
    // },
    // {
    //   name: "Components",
    //   path: "/components",
    // },
    // {
    //   name: "Sign in",
    //   path: "/sign-in",
    // },
];
