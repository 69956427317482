import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography } from "@mui/material";

/**
 * This component is generated as an example for axios
 *
 * To learn more about axios and data fetching,
 * please visit https://github.com/axios/axios
 */

// const BASE_API_URL = "https://official-joke-api.appspot.com/"; // This path is not working ==> server error
const BASE_API_URL = "https://pokeapi.co/";

const API_URL = "api/v2/pokemon/ditto";

const jokesApi = axios.create({
  baseURL: BASE_API_URL,
});

export const AxiosExample = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    jokesApi({
      method: "get",
      url: API_URL,
    })
      .then((res) => res.data.forms)
      .then(
        (result) => {
          setData(result);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Typography variant="h2">Axios Fetching Example</Typography>
      <code>Data : {JSON.stringify(data)}</code>
      <main data-testid="joke-container">
        {/* {data.length > 0 && (
          <>
            <p>Programmer Jokes {`#${data[0].id}`}</p>
            <p>{data[0].setup}</p>
            <p>{data[0].punchline}</p>
          </>
        )} */}

        {data.length > 0 && (
          <>
            <p>
              <strong>Name:</strong> {`#${data[0].name}`}
            </p>
            <p>
              <strong>url:</strong> {data[0].url}
            </p>
            {/* <p>{data[0].setup}</p>
            <p>{data[0].punchline}</p> */}
          </>
        )}
      </main>
      <div>
        <a
          href="https://github.com/axios/axios"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go To Documentation
        </a>
      </div>
    </div>
  );
};
