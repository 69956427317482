export const API_ENDPOINT_MEETING_ROOM = {
    getMeetingRooms: "getMeetingRooms",
    getMeetingRoomById: "getMeetingRoomById",
    getPrefillMeetingRoom: "getPrefillMeetingRoom",
    getMeetingRoomsBySpaceId: "getMeetingRoomsBySpaceId",
    addMeetingRoom: "addMeetingRoom",
    getLatestCompletedBooking: "getLatestCompletedBooking",
    getAReviewByMeetingRoomId: "getAReviewByMeetingRoomId",
    addMeetingRoomReview: "addMeetingRoomReview",
    updateMeetingRoom: "updateMeetingRoom",
    removeMeetingRoom: "removeMeetingRoom",
    getMeetingRoomsByVendorId: "getMeetingRoomsByVendorId",
    publishMeetingRoomById: "publishMeetingRoomById",
    unpublishMeetingRoomById: "unpublishMeetingRoomById",
    updateSortingOrderToAllMeetingRooms: "updateSortingOrderToAllMeetingRooms",
};

export const API_ENDPOINT_ฺBASE = {
    awake: "awake",
    getENV: "getENV",
};

export const API_ENDPOINT_ฺCANCELLATION_POLICY = {
    getAllCancellationPolicies: "getAllCancellationPolicies",
    getCancellationPolicyById: "getCancellationPolicyById",
};

export const API_ENDPOINT_ฺNEIGHBORHOOD = {
    getNeighborhoods: "getNeighborhoods",
    getNeighborhoodById: "getNeighborhoodById",
};

export const API_ENDPOINT_ฺMOOD = {
    getMoods: "getMoods",
    getMoodById: "getMoodById",
};

export const API_ENDPOINT_ฺBOOKING = {
    getBookingById: "getBookingById",
    getBookingByIdWithoutPayoutDetails: "getBookingByIdWithoutPayoutDetails",
    getBookingsByConsolidateStatus: "getBookingsByConsolidateStatus",
    getBookingByStatuses: "getBookingByStatuses",
    getBookingsByChatRoomIdAndConsolidateStatus:
        "getBookingsByChatRoomIdAndConsolidateStatus",
    getBookingByChatRoomIdAndBookingId: "getBookingByChatRoomIdAndBookingId",
    getAllBookingsByUserId: "getAllBookingsByUserId",
    addBooking: "addBooking",
    updateBooking: "updateBooking",
    getBookingAvailableTimeslots: "getBookingAvailableTimeslots",
    validateBookingRequestTime: "validateBookingRequestTime",
    getPricingSummary: "getPricingSummary",
    approveBookingById: "approveBookingById",
    declineBookingById: "declineBookingById",
    cancelBookingById: "cancelBookingById",
    markBookingPaymentAsPaidByBookingId: "markBookingPaymentAsPaidByBookingId",
    completeBookingById: "completeBookingById",
    bookingScheduleTestAPI: "bookingScheduleTestAPI",
    payBookingById: "payBookingById",
};

export const API_ENDPOINT_AUTH_USER = {
    getAllUsers: "getAllUsers",
    getAllUserByRole: "getAllUserByRole",
    updateUserRoleToAllUsers: "updateUserRoleToAllUsers",
    setUserRoles: "setUserRoles",
    addVendorRole: "addVendorRole",
    removeVendorRole: "removeVendorRole",
    removeUserRole: "removeUserRole",
    checkIsVendorExisting: "checkIsVendorExisting",
    checkIsUserExisting: "checkIsUserExisting",
};

export const API_ENDPOINT_ฺCACHE = {
    getCaheByKey: "getCaheByKey",
    deleteCaheByKey: "deleteCaheByKey",
};

export const API_ENDPOINT_ฺCHAT = {
    getAllChatRooms: "getAllChatRooms",
    getChatMessagesByBookingId: "getChatMessagesByBookingId",
    getUserChatRoomIdByVendor: "getUserChatRoomIdByVendor",
    addChatMessage: "addChatMessage",
    updateReadStatusByBookingId: "updateReadStatusByBookingId",
};

export const API_ENDPOINT_ฺINBOX = {
    getVendorInboxes: "getVendorInboxes",
    getUnreadInboxByVendorId: "getUnreadInboxByVendorId",
    getUserInboxes: "getUserInboxes",
    updateLastVisitBookingId: "updateLastVisitBookingId",
};

export const API_ENDPOINT_ฺNOTIFICATION = {
    getVendorNotificationsByVendorId: "getVendorNotificationsByVendorId",
    getUserNotificationsByUserId: "getUserNotificationsByUserId",
    getNotificationById: "getNotificationById",
    updateAsReadById: "updateAsReadById",
    updateAsUnReadById: "updateAsUnReadById",
    updateUserNotificationAsReadById: "updateUserNotificationAsReadById",
    updateUserMessageAsReadById: "updateUserMessageAsReadById",
    deleteUserNotificationById: "deleteUserNotificationById",
    deleteVendorNotificationById: "deleteVendorNotificationById",
};

export const API_ENDPOINT_ฺMEETING_ROOM_AMENITIES = {
    getMeetingRoomAmenities: "getMeetingRoomAmenities",
    getMeetingRoomAmenityById: "getMeetingRoomAmenityById",
};

export const API_ENDPOINT_ฺSPACE = {
    getSpaces: "getSpaces",
    getSpaceById: "getSpaceById",
};

export const API_ENDPOINT_ฺUSER_PROFILE = {
    getUserProfileById: "getUserProfileById",
};

export const API_ENDPOINT_ฺVENDOR_ACCOUNT_INFORMATION = {
    getVendorAccountInformationById: "getVendorAccountInformationById",
    updateVendorAccountInformation: "updateVendorAccountInformation",
};

export const API_ENDPOINT_ฺVENDOR_AMENITY = {
    getVendorAmenitiesByVendorId: "getVendorAmenitiesByVendorId",
    getVendorAmenityByAmenityId: "getVendorAmenityByAmenityId",
    addNewVendorAmenity: "addNewVendorAmenity",
    updateVendorAmenityByAmenityId: "updateVendorAmenityByAmenityId",
    deleteVendorAmenityByAmenityId: "deleteVendorAmenityByAmenityId",
};

export const API_ENDPOINT_ฺVENDOR_PROFILE = {
    getVendorProfile: "getVendorProfile",
    getVendorProfileById: "getVendorProfileById",
    updateVendorProfileById: "updateVendorProfileById",
    getVendorProfileByOwnerId: "getVendorProfileByOwnerId",
    getVendors: "getVendors",
    addVendor: "addVendor",
    updateVendorByVendorId: "updateVendorByVendorId",
    getVendorDetailById: "getVendorDetailById",
    deleteVendorById: "deleteVendorById",
    getVendorAvailableSpacesByVendorId: "getVendorAvailableSpacesByVendorId",
};
