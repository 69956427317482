import { Dashboard } from "components/Dashboard";
import { AdminContentContainer } from "components/Layouts";
import { Helmet } from "react-helmet-async";

export const DashboardPage = () => {
    return (
        <>
            <Helmet>
                <title>Joes & Cos | Dashboard</title>
            </Helmet>
            <AdminContentContainer>
                <Dashboard />
            </AdminContentContainer>
        </>
    );
};
