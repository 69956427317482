import {
    deleteObject,
    getDownloadURL,
    ref,
    storage,
    uploadBytesResumable
} from "firebaseSetup";
import { ImageModel } from "models/image.model";

const uploadImage = async (path: string, file: ImageModel): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        const metadata = {
            contentType: file.file.type,
        };
        const fileName = `${new Date().getTime()}-${file.title}`;
        const reference = `/${path}/${fileName}`;
        const storageRef = ref(storage, reference);
        file.reference = reference;
        const uploadTask = uploadBytesResumable(
            storageRef,
            file.file,
            metadata,
        );
        uploadTask.on("state_changed", (snapshot) => {
            //etc
            //     firebase.storage.TaskEvent.STATE_CHANGED,
            uploadTask.on(
                "state_changed",
                () => {},
                (error) => {
                    console.error(error);
                    reject();
                },
                async () => {
                    // ref.getDownloadURL().then((url) => {
                    //     return url;
                    // });
                    // ref.getMetadata();

                    const downloadUrl = await getDownloadURL(storageRef);
                    resolve(downloadUrl);
                },
            );
        });
    });
};

const deleteImage = async (path: string, url: string): Promise<void> => {
    const storageRef = ref(storage, `/${path}/${url}`);
    await deleteObject(storageRef);
};

export const firebaseService = {
    uploadImage,
    deleteImage,
};
