import { Skeleton } from "@mui/material";

export const NotificationSkeleton = () => {
    return (
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between flex-wrap relative p-3 pl-7 pr-14">
            <Skeleton
                variant="text"
                width="200"
                className="mr-1 text-base my-0.5 w-[150px] sm:w-[375px]"
            />

            <Skeleton variant="text" className="text-xs w-[100px] my-0.5" />
        </div>
    );
};
