import { CalculationStyle } from "enum/meeting-room.enum";
import { dateTimeHelper } from "helpers/date-time.helper";
import moment from "moment";
import MESSAGES from "shared/constants/messages.constant";
import * as Yup from "yup";
import { AnyObject } from "yup/lib/types";

export const listingValidation = Yup.object().shape(
    {
        title: Yup.string().required(
            MESSAGES.LISTING_VALIDATION_TITLE_REQUIRED,
        ),
        address1: Yup.string().required(
            MESSAGES.LISTING_VALIDATION_ADDRESS1_REQUIRED,
        ),
        address2: Yup.string(),
        location: Yup.object().shape({
            address: Yup.string().required(
                MESSAGES.LISTING_VALIDATION_LOCATION_ADDRESS_REQUIRED,
            ),
            lat: Yup.string().required(
                MESSAGES.LISTING_VALIDATION_LOCATION_LAT_REQUIRED,
            ),
            lng: Yup.string().required(
                MESSAGES.LISTING_VALIDATION_LOCATION_LNG_REQUIRED,
            ),
        }),
        city: Yup.string().required(MESSAGES.LISTING_VALIDATION_CITY_REQUIRED),
        // .matches(
        //     /^([^0-9 `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]*)$/,
        //     "City must be alphabet",
        // ),
        neighborhood: Yup.object().shape({
            title: Yup.string(),
            id: Yup.string()
                .typeError(MESSAGES.LISTING_VALIDATION_NEIGHBORHOOD_REQUIRED)
                .required(MESSAGES.LISTING_VALIDATION_NEIGHBORHOOD_REQUIRED)
                .min(1, MESSAGES.LISTING_VALIDATION_NEIGHBORHOOD_REQUIRED),
        }),
        postalCode: Yup.string().required(
            MESSAGES.LISTING_VALIDATION_POSTAL_REQUIRED,
        ),
        capacity: Yup.number()
            .typeError(MESSAGES.LISTING_VALIDATION_CAPACITY_TYPE_ERROR)
            .required(MESSAGES.LISTING_VALIDATION_CAPACITY_REQUIRED)
            .min(1, MESSAGES.LISTING_VALIDATION_CAPACITY_MIN),
        openingHours: Yup.array()
            .of(
                Yup.object().shape({
                    uid: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_OPENING_HOURS_ID_REQUIRED,
                    ),
                    dayOfWeek: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_OPENING_HOURS_DAY_OF_WEEK_REQUIRED,
                    ),
                    openingTime: Yup.string()
                        .required(
                            MESSAGES.LISTING_VALIDATION_OPENING_HOURS_OPENING_TIME_REQUIRED,
                        )
                        .test(
                            "startTime",
                            MESSAGES.LISTING_VALIDATION_OPENING_HOURS_TIME_FORMAT,
                            (value) => {
                                return moment(
                                    dateTimeHelper.convertTimeStringToDate(
                                        value,
                                    ),
                                ).isValid();
                            },
                        )

                        .test(
                            "OpeningTimeLaterThanClosingTime",
                            MESSAGES.LISTING_VALIDATION_OPENING_HOURS_OPENING_TIME_LATER_THAN_CLOSING_TIME,
                            (
                                openingTime: string,
                                context: Yup.TestContext<AnyObject>,
                            ) =>
                                dateTimeHelper.isBeforeClosingTimeAndAfterMidNight(
                                    openingTime,
                                    context.parent.closingTime,
                                ),
                        ),
                    closingTime: Yup.string()
                        .required(
                            MESSAGES.LISTING_VALIDATION_OPENING_HOURS_CLOSING_TIME_REQUIRED,
                        )
                        .test(
                            "endTime",
                            MESSAGES.LISTING_VALIDATION_OPENING_HOURS_TIME_FORMAT,
                            (value) => {
                                return moment(
                                    dateTimeHelper.convertTimeStringToDate(
                                        value,
                                    ),
                                ).isValid();
                            },
                        )
                        .test(
                            "ClosingTimeEarlierThanOpeningTime",
                            MESSAGES.LISTING_VALIDATION_OPENING_HOURS_CLOSING_TIME_EARLIER_THAN_OPENING_TIME,
                            (
                                closingTime: string,
                                context: Yup.TestContext<AnyObject>,
                            ) =>
                                dateTimeHelper.isAfterOpeningTimeAndBeforeMidNight(
                                    context.parent.openingTime,
                                    closingTime,
                                ),
                        ),
                    isOpen: Yup.boolean().required(
                        MESSAGES.LISTING_VALIDATION_OPENING_HOURS_REQUIRED,
                    ),
                }),
            )
            .min(7)
            .max(8),
        pricePerHour: Yup.number()
            .typeError(MESSAGES.LISTING_VALIDATION_PRICING_HOURLY_RATE_REQUIRED)
            .required(MESSAGES.LISTING_VALIDATION_PRICING_HOURLY_TYPE_ERROR)
            .min(0, MESSAGES.LISTING_VALIDATION_PRICING_HOURLY_MIN),
        discountHalfDay: Yup.object().shape({
            isEnable: Yup.boolean(),
            percent: Yup.string()
                .when("isEnable", {
                    is: true,
                    then: Yup.string()
                        .test(
                            "Max100Percent",
                            "Must be lesser than 100 percent",
                            (val) => Number(val) <= 100,
                        )
                        .required(
                            MESSAGES.LISTING_VALIDATION_DISCOUNT_HALF_DAY_REQUIRED,
                        )
                        .typeError(
                            MESSAGES.LISTING_VALIDATION_DISCOUNT_HALF_DAY_TYPE_ERROR,
                        )
                        .min(
                            1,
                            MESSAGES.LISTING_VALIDATION_DISCOUNT_HALF_DAY_MIN,
                        ),
                })
                .when("isEnable", {
                    is: false,
                    then: Yup.string().typeError(
                        MESSAGES.LISTING_VALIDATION_DISCOUNT_HALF_DAY_CHECK_AND_FILL,
                    ),
                }),
        }),
        discountFullDay: Yup.object().shape({
            isEnable: Yup.boolean(),
            percent: Yup.string()
                .when("isEnable", {
                    is: true,
                    then: Yup.string()
                        .test(
                            "Max100Percent",
                            "Must be lesser than 100 percent",
                            (val) => Number(val) <= 100,
                        )
                        .required(
                            MESSAGES.LISTING_VALIDATION_DISCOUNT_FULL_DAY_REQUIRED,
                        )
                        .typeError(
                            MESSAGES.LISTING_VALIDATION_DISCOUNT_FULL_DAY_TYPE_ERROR,
                        )
                        .min(
                            1,
                            MESSAGES.LISTING_VALIDATION_DISCOUNT_FULL_DAY_MIN,
                        ),
                })
                .when("isEnable", {
                    is: false,
                    then: Yup.string().typeError(
                        MESSAGES.LISTING_VALIDATION_DISCOUNT_FULL_DAY_CHECK_AND_FILL,
                    ),
                }),
        }),
        meetingRoomAmenities: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_MEETING_ROOM_AMENITIES_ID_REQUIRED,
                    ),
                    title: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_MEETING_ROOM_AMENITIES_TITLE_REQUIRED,
                    ),
                    icon: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_MEETING_ROOM_AMENITIES_ICON_REQUIRED,
                    ),
                    description: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_MEETING_ROOM_AMENITIES_DESCRIPTION_REQUIRED,
                    ),
                }),
            )
            .when("customMeetingRoomAmenities", {
                is: (customMeetingRoomAmenities) =>
                    !customMeetingRoomAmenities ||
                    customMeetingRoomAmenities.length === 0,
                then: Yup.array().min(
                    1,
                    MESSAGES.LISTING_VALIDATION_MEETING_ROOM_AMENITY_MIN,
                ),
                otherwise: Yup.array(),
            }),
        customMeetingRoomAmenities: Yup.array()
            .of(
                Yup.object().shape({
                    uid: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_CUSTOM_MEETING_ROOM_AMENITIES_ID_REQUIRED,
                    ),
                    title: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_CUSTOM_MEETING_ROOM_AMENITIES_TITLE_REQUIRED,
                    ),
                    icon: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_CUSTOM_MEETING_ROOM_AMENITIES_ICON_REQUIRED,
                    ),
                    description: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_CUSTOM_MEETING_ROOM_AMENITIES_DESCRIPTION_REQUIRED,
                    ),
                }),
            )
            .when("meetingRoomAmenities", {
                is: (meetingRoomAmenities) =>
                    !meetingRoomAmenities || meetingRoomAmenities.length === 0,
                then: Yup.array().min(
                    1,
                    MESSAGES.LISTING_VALIDATION_MEETING_ROOM_AMENITY_MIN,
                ),
                otherwise: Yup.array(),
            }),
        mood: Yup.object().shape({
            id: Yup.string()
                .typeError(MESSAGES.LISTING_VALIDATION_MOOD_REQUIRED)
                .required(MESSAGES.LISTING_VALIDATION_MOOD_REQUIRED),
        }),
        description: Yup.string()
            .typeError(MESSAGES.LISTING_VALIDATION_DESCRIPTION_REQUIRED)
            .required(MESSAGES.LISTING_VALIDATION_DESCRIPTION_REQUIRED),
        images: Yup.array()
            .of(
                Yup.object().shape({
                    title: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_IMAGES_TITLE_REQUIRED,
                    ),
                    url: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_IMAGES_URL_REQUIRED,
                    ),
                    reference: Yup.string().required(
                        MESSAGES.LISTING_VALIDATION_IMAGES_REFERENCE_REQUIRED,
                    ),
                }),
            )
            .min(1, MESSAGES.LISTING_VALIDATION_IMAGES_MIN),
        cancellationPolicyId: Yup.string()
            .typeError(MESSAGES.LISTING_VALIDATION_CANCELLATION_POLICY_REQUIRED)
            .required(MESSAGES.LISTING_VALIDATION_CANCELLATION_POLICY_REQUIRED),
        pricingStyle: Yup.string().required(
            MESSAGES.LISTING_VALIDATION_PRICING_REQUIRED,
        ), // perMeetingRoom | perPerson
        calculationStyle: Yup.string().when(
            ["halfDayPricing", "fullDayPricing"],
            {
                is: (
                    halfDayPricingIsEnable: any,
                    fullDayPricingIsEnable: any,
                ) =>
                    !halfDayPricingIsEnable.isEnable &&
                    !fullDayPricingIsEnable.isEnable,
                then: Yup.string()
                    .test(
                        "SelectedCalculationStyle",
                        "Please select at least 1 pricing rate",
                        (val) => val !== CalculationStyle.halfFullDayPassed,
                    )
                    .required(
                        MESSAGES.LISTING_VALIDATION_PRICING_SELECT_TYPE_REQUIRED,
                    ),
            },
        ), // hourly | halfFullDayPassed
        halfDayPricing: Yup.object().shape({
            isEnable: Yup.boolean(),
            value: Yup.number()
                .when("isEnable", {
                    is: true,
                    then: Yup.number()
                        .test(
                            "ValuePositive",
                            "Please fill positive value",
                            (val) => Number(val) >= 0,
                        )
                        .required(
                            MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_RATE_REQUIRED,
                        )
                        .typeError(
                            MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_RATE_TYPE_ERROR,
                        )
                        .min(
                            0,
                            MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_RATE_MIN,
                        ),
                })
                .when("isEnable", {
                    is: false,
                    then: Yup.number().typeError(
                        MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_HALF_RATE_CHECK_AND_FILL,
                    ),
                }),
            length: Yup.number().when("isEnable", {
                is: true,
                then: Yup.number()
                    .test(
                        "ValuePositive",
                        "Please fill positive value",
                        (val) => Number(val) >= 1,
                    )
                    .required(
                        MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_SELECT_LENGTH_REQUIRED,
                    )
                    .typeError(
                        MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_SELECT_LENGTH_TYPE_ERROR,
                    )
                    .min(
                        1,
                        MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_SELECT_LENGTH_MIN,
                    ),
            }),
        }),
        fullDayPricing: Yup.object().shape({
            isEnable: Yup.boolean(),
            value: Yup.number()
                .when("isEnable", {
                    is: true,
                    then: Yup.number()
                        .test(
                            "ValuePositive",
                            "Please fill positive value",
                            (val) => Number(val) >= 0,
                        )
                        .required(
                            MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_RATE_REQUIRED,
                        )
                        .typeError(
                            MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_RATE_TYPE_ERROR,
                        )
                        .min(
                            0,
                            MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_RATE_MIN,
                        ),
                })
                .when("isEnable", {
                    is: false,
                    then: Yup.number().typeError(
                        MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_FULL_RATE_CHECK_AND_FILL,
                    ),
                }),
            length: Yup.number().when("isEnable", {
                is: true,
                then: Yup.number()
                    .test(
                        "ValuePositive",
                        "Please fill positive value",
                        (val) => Number(val) >= 1,
                    )
                    .required(
                        MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_SELECT_LENGTH_REQUIRED,
                    )
                    .typeError(
                        MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_SELECT_LENGTH_TYPE_ERROR,
                    )
                    .min(
                        1,
                        MESSAGES.LISTING_VALIDATION_PRICING_HALFFULL_SELECT_LENGTH_MIN,
                    ),
            }),
        }),
        minimumHours: Yup.object().shape({
            isEnable: Yup.boolean(),
            value: Yup.number().when("isEnable", {
                is: true,
                then: Yup.number()
                    .test(
                        "ValuePositive",
                        "Please fill positive value",
                        (val) => Number(val) >= 1,
                    )
                    .required(
                        MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_HOURS_REQUIRED,
                    )
                    .typeError(
                        MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_HOURS_TYPE_ERROR,
                    )
                    .min(
                        1,
                        MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_HOURS_MIN,
                    ),
            }),
        }),
        minimumChargeHourly: Yup.object().shape({
            isEnable: Yup.boolean(),
            value: Yup.number()
                .when("isEnable", {
                    is: true,
                    then: Yup.number()
                        .test(
                            "ValuePositive",
                            "Please fill positive value",
                            (val) => Number(val) >= 1,
                        )
                        .required(
                            MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_REQUIRED,
                        )
                        .typeError(
                            MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_TYPE_ERROR,
                        )
                        .min(
                            1,
                            MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_MIN,
                        ),
                })
                .when("isEnable", {
                    is: false,
                    then: Yup.number().typeError(
                        MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_CHECK_AND_FILL,
                    ),
                }),
        }),
        minimumChargeHalfDay: Yup.object().shape({
            isEnable: Yup.boolean(),
            value: Yup.number()
                .when("isEnable", {
                    is: true,
                    then: Yup.number()
                        .test(
                            "ValuePositive",
                            "Please fill positive value",
                            (val) => Number(val) >= 1,
                        )
                        .required(
                            MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_REQUIRED,
                        )
                        .typeError(
                            MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_TYPE_ERROR,
                        )
                        .min(
                            1,
                            MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_MIN,
                        ),
                })
                .when("isEnable", {
                    is: false,
                    then: Yup.number().typeError(
                        MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_CHECK_AND_FILL,
                    ),
                }),
        }),
        minimumChargeFullDay: Yup.object().shape({
            isEnable: Yup.boolean(),
            value: Yup.number()
                .when("isEnable", {
                    is: true,
                    then: Yup.number()
                        .test(
                            "ValuePositive",
                            "Please fill positive value",
                            (val) => Number(val) >= 1,
                        )
                        .required(
                            MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_REQUIRED,
                        )
                        .typeError(
                            MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_TYPE_ERROR,
                        )
                        .min(
                            1,
                            MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_MIN,
                        ),
                })
                .when("isEnable", {
                    is: false,
                    then: Yup.number().typeError(
                        MESSAGES.LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_CHECK_AND_FILL,
                    ),
                }),
        }),
        timeBlock: Yup.object().shape({
            isEnable: Yup.boolean(),
            value: Yup.number().when("isEnable", {
                is: true,
                then: Yup.number()
                    .test(
                        "ValuePositive",
                        "Please fill positive value",
                        (val) => Number(val) >= 15,
                    )
                    .required(
                        MESSAGES.LISTING_VALIDATION_PRICING_TIME_BLOCK_REQUIRED,
                    )
                    .typeError(
                        MESSAGES.LISTING_VALIDATION_PRICING_TIME_BLOCK_TYPE_ERROR,
                    )
                    .min(1, MESSAGES.LISTING_VALIDATION_PRICING_TIME_BLOCK_MIN),
            }),
        }),
        bookingTimeIncrements: Yup.number().when("calculationStyle", {
            is: "hourly",
            then: Yup.number().required(
                MESSAGES.LISTING_VALIDATION_PRICING_BOOKING_TIME_INCREMENTS_REQUIRED,
            ),
        }),
    },
    [["meetingRoomAmenities", "customMeetingRoomAmenities"]],
);
