import { Dialog, DialogTitle, IconButton, ModalProps } from "@mui/material";
import * as React from "react";
import { IoCloseOutline } from "react-icons/io5";
import SimpleBarReact from "simplebar-react";
import theme from "styles/theme";

export interface JCModalProps extends ModalProps {
    inputData?: string;
    title?: string;
    hasCloseBtn?: boolean;
    titleClassName?: string;
    wrapperClassName?: string;
    onClose?: () => void;
    closeFn?: () => void;
}

function BaseModalHead(props: any) {
    const { title, titleClassName } = props;
    return (
        <DialogTitle
            sx={{ typography: "h3" }}
            color="initial"
            className={`mb-5 p-0 ${
                titleClassName !== "undefined" ? titleClassName : ""
            }`}
        >
            {title}
        </DialogTitle>
    );
}

export const BaseModal: React.FC<JCModalProps> = (props) => {
    return (
        <>
            <Dialog
                open={props.open}
                fullWidth
                maxWidth={"md"}
                sx={{
                    "& .MuiPaper-root": {
                        overflow: "hidden",
                    },
                    "& .simplebar-modal": {
                        maxHeight: "calc(100vh - 150px)",
                        [theme.breakpoints.up("md")]: {
                            maxHeight: "calc(100vh - 64px)",
                        },
                    },
                }}
            >
                <SimpleBarReact className="simplebar-modal">
                    <div
                        className={`p-6 sm:p-10 relative ${
                            props.wrapperClassName !== "undefined"
                                ? props.wrapperClassName
                                : ""
                        }`}
                    >
                        {props.hasCloseBtn && (
                            <IconButton
                                className="text-4xl p-0 sticky float-right -mt-8 -mr-6 sm:-mr-8 top-0"
                                aria-label="close button"
                                onClick={props.closeFn}
                                color="primary"
                            >
                                <IoCloseOutline />
                            </IconButton>
                        )}
                        {props.title && (
                            <BaseModalHead
                                title={props.title}
                                titleClassName={props.titleClassName}
                            />
                        )}
                        {props.children}
                    </div>
                </SimpleBarReact>
            </Dialog>
        </>
    );
};
