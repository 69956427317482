import { FullPageLoaderProps } from "components/UI/FullPageLoader";
import React, { useContext } from "react";

interface FullPageLoadingContextType {
    fullPageLoader: FullPageLoaderProps;
    show: () => void;
    hide: () => void;
}

export const FullPageLoadingContext =
    React.createContext<FullPageLoadingContextType>(null!);

export const useFullPageLoadingContext = () => {
    return useContext(FullPageLoadingContext);
};
