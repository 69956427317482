import * as Yup from "yup";
import MESSAGES from "../shared/constants/messages.constant";

export const VendorSettingsValidation = Yup.object().shape({
    name: Yup.string().required(
        MESSAGES.VENDOR_PROFILE_VALIDATION_NAME_REQUIRE,
    ), // vendor name
    ownerEmail: Yup.string()
        .email("Email is invalid format")
        .required(MESSAGES.VENDOR_PROFILE_VALIDATION_EMAIL_REQUIRE),
    vendorSettings: Yup.object().shape({
        serviceFeePercent: Yup.number().required(
            MESSAGES.VENDOR_PROFILE_VALIDATION_SERVICE_FEE_REQUIRE,
        ),
        vatType: Yup.string().required(
            MESSAGES.VENDOR_PROFILE_VALIDATION_VAT_TYPE_REQUIRE,
        ),
        availableSpaceIds: Yup.array()
            .min(0)
            .required(MESSAGES.VENDOR_PROFILE_VALIDATION_WORKSPACE_REQUIRE),
    }),
});
