import { Divider, Typography } from "@mui/material";
import { EmptyList } from "components";
import {
    ItemNotification,
    NotificationSkeleton,
} from "components/Notification";
import { useAuthContext } from "context/AuthContext";
import { PaginationModel, VendorNotification } from "models";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { generatePath, Link } from "react-router-dom";
import { vendorNotificationService } from "services/vendor-notification.service";
import MESSAGES from "shared/constants/messages.constant";
import ROUTES from "shared/constants/routes.constant";
import NotificationOverviewSkeleton from "./NotificationOverviewSkeleton";
export const NotificationOverview = () => {
    const auth = useAuthContext();
    const queryKey: string = `vendor-${auth.authUser.userId}-dashboard-notifications`;
    const fetchNotifications = async ({ pageParam = "" }) => {
        const pagingResult = await vendorNotificationService.fetchNotifications(
            pageParam,
            true,
        );
        return pagingResult as PaginationModel<VendorNotification[]>;
    };
    const {
        data: items,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery(queryKey, fetchNotifications, {
        getNextPageParam: (
            lastPage: PaginationModel<VendorNotification[]>,
            pages,
        ) => {
            return lastPage.page.isLastPage ? undefined : lastPage.page.lastId;
        },
        refetchOnWindowFocus: false,
    });
    const itemSize = items?.pages?.reduce((x, y) => x + y.lists.length, 0) ?? 0;

    return (
        <>
            <Typography variant="h3" className="mb-2">
                Notifications
            </Typography>
            {!items && <NotificationOverviewSkeleton />}

            <div
                id="scrollableNotifications"
                style={{ maxHeight: 205, overflowY: "auto" }}
                className="custom-scrollbar"
            >
                <InfiniteScroll
                    dataLength={items?.pages ? itemSize : 0}
                    next={fetchNextPage}
                    hasMore={hasNextPage ?? false}
                    loader={
                        (isFetching || isFetchingNextPage) && (
                            <>
                                <NotificationSkeleton />
                                <Divider />
                            </>
                        )
                    }
                    scrollableTarget="scrollableNotifications"
                >
                    {items &&
                        items?.pages?.map((notifications, indexPage) =>
                            notifications?.lists?.map(
                                (notification: VendorNotification, index) => (
                                    <React.Fragment key={notification.id}>
                                        <Link
                                            to={generatePath(
                                                ROUTES.ReservationDetail,
                                                {
                                                    bookingId:
                                                        notification.bookingId,
                                                },
                                            )}
                                            onClick={async () =>
                                                await vendorNotificationService.updateAsReadNotificationById(
                                                    notification.id,
                                                )
                                            }
                                        >
                                            <ItemNotification
                                                notification={notification}
                                                {...notification}
                                            />
                                        </Link>
                                        <Divider />
                                    </React.Fragment>
                                ),
                            ),
                        )}

                    {!isFetching && itemSize === 0 && (
                        <div className="mt-1.5">
                            <EmptyList
                                bgTransparent
                                hasBorder
                                label={MESSAGES.NOTIFICATION_EMPTY_LIST}
                            />
                        </div>
                    )}
                </InfiniteScroll>
            </div>
        </>
    );
};
