import { FormControl, FormHelperText, Grid, MenuItem } from "@mui/material";
import { SectionTitle, TextInput } from "components";
import {
    Addresses,
    getAddressFromMapAddress,
    ReactGoogleMaps
} from "components/ListingForm/ReactGoogleMaps";
import JCSelect from "components/UI/SelectDropdown";
import { useAuthContext } from "context/AuthContext";
import { FormikProps } from "formik";
import { VendorWorkspaces } from "models";
import { MeetingRoom } from "models/meeting-room.model";
import { Neighborhood } from "models/neighborhood.model";
import { useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import { IMaskInput } from "react-imask";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { neighborhoodService } from "services/neighborhood.service";
import { vendorService } from "services/vendor.service";
import MESSAGES from "shared/constants/messages.constant";

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
let isFirstLoad = true;
const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            func();
        } else {
            didMount.current = true;
        }
    }, deps);
};
export const MeetingRoomDetail = (props: FormikProps<MeetingRoom>) => {
    const auth = useAuthContext();
    Geocode.setApiKey(apiKey);
    Geocode.enableDebug();
    const {
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
        setFieldValue,
        setFieldTouched,
        dirty,
        isValid,
        isSubmitting,
    } = props;

    const [hasWorkspace, setHasWorkspaces] = useState<boolean>(false);
    const [hasNeighborhood, setHasNeighborhood] = useState<boolean>(false);
    const [workspaces, setWorkspaces] = useState<VendorWorkspaces[]>([]);
    const [neighborhoods, setNeighborhoods] = useState<Neighborhood[]>([]);
    const [initialMeetingRoomData, setInitialMeetingRoomData] =
        useState<MeetingRoom>(values);
    const [searchAddress, setSearchAddress] = useState<string>("");
    let { id } = useParams();
    const isEditMode = id && id !== "";

    const {
        data: vendorWorkspacesData,
        isFetching: isFetchingVendorWorkspaces,
    } = useQuery(
        `vendor-${auth.authUser.userId}-workspaces`,
        async () =>
            await vendorService.getVendorWorkspaces(auth.authUser.userId),
        {
            refetchOnWindowFocus: false,
        },
    );

    const { data: neighborhoodsData, isFetching: isFetchingNeighborhoods } =
        useQuery(
            `neighborhoods`,
            async () => await neighborhoodService.getNeighborhoods(),
            {
                refetchOnWindowFocus: false,
            },
        );

    useEffect(() => {
        if (neighborhoodsData) {
            setHasNeighborhood(neighborhoodsData.length > 0);
            setNeighborhoods(neighborhoodsData);
        }
    }, [neighborhoodsData]);

    useEffect(() => {
        if (vendorWorkspacesData) {
            setHasWorkspaces(vendorWorkspacesData.length > 0);
            setWorkspaces(vendorWorkspacesData);
        }
    }, [vendorWorkspacesData]);

    useDidMountEffect(() => {
        if (isFirstLoad) {
            isFirstLoad = false;
            return;
        }
        const updateMeetingRoomAddress = async () => {
            if (values.workspace && values.workspace.id !== "") {
                const selectedWorkspace = workspaces.find(
                    (x) => x.id === values.workspace.id,
                );
                if (
                    selectedWorkspace &&
                    selectedWorkspace.location &&
                    selectedWorkspace.location.lat &&
                    selectedWorkspace.location.lng
                ) {
                    setFieldValue("location", selectedWorkspace.location);
                    setSearchAddress(selectedWorkspace.location.address);
                    const address = await getAddressByLatLng(
                        selectedWorkspace.location.lat,
                        selectedWorkspace.location.lng,
                    );
                    if (address) {
                        const customAddress = getAddressFromMapAddress(address);
                        updateAddress(customAddress);
                    }
                }
            }
        };
        updateMeetingRoomAddress();
    }, [values.workspace]);

    const getAddressByLatLng = async (lat: number, lng: number) => {
        const response = await Geocode.fromLatLng(lat, lng);
        if (
            response.status !== "OK" ||
            !response.results ||
            response.results.lenght === 0
        )
            return null;
        return response.results[0];
    };

    const updateAddress = (addresses: Addresses) => {
        setFieldValue("address1", addresses.address1);
        setFieldValue("city", addresses.city);
        setFieldValue("postalCode", addresses.postalCode);
    };

    const handleNeighborhoodChange = (e) => {
        const updatedNeighborhood = neighborhoods.find(
            (neighborhood) => neighborhood.id === e.target.value,
        );
        if (updatedNeighborhood) {
            setFieldValue("neighborhood", { ...updatedNeighborhood });
        } else {
            const defaultNeighborhood = {
                title: "",
                id: "",
            };
            setFieldValue("neighborhood", { ...defaultNeighborhood });
        }
        setFieldTouched("neighborhood", true, false);
        setFieldTouched("neighborhood.id", true, false);
    };
    const handleWorkspaceChange = (e) => {
        if (e.target.value === "") {
            setSearchAddress("");
            if (isEditMode) {
                setFieldValue("workspace", null);
                const initialAddress = {
                    address1: "",
                    city: "",
                    postalCode: "",
                } as Addresses;
                updateAddress(initialAddress);
            } else {
                setFieldValue("workspace", initialMeetingRoomData.workspace);
                const initialAddress = {
                    address1: initialMeetingRoomData.address1,
                    city: initialMeetingRoomData.city,
                    postalCode: initialMeetingRoomData.postalCode,
                } as Addresses;
                updateAddress(initialAddress);
            }
            return;
        }
        const selectedWorkspace = workspaces.find(
            (x) => x.id === e.target.value,
        );
        setFieldValue("workspace", selectedWorkspace);
        setFieldTouched("workspace", true);
    };

    return (
        <>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={hasWorkspace ? 6 : 12}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.title && touched.title}
                    >
                        <TextInput
                            id="title"
                            name="title"
                            label="Meeting room name"
                            placeholder="Enter meeting room name"
                            aria-describedby="room-name-help-text"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.title && errors.title && (
                            <FormHelperText
                                id="room-name-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.title}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                {hasWorkspace && (
                    <Grid item xs={12} md={6}>
                        <FormControl className="mb-4 md:mb-8">
                            <JCSelect
                                labelId="demo-simple-select-label"
                                id="workspace"
                                name="workspace"
                                label="Workspace (Optional)"
                                onChange={handleWorkspaceChange}
                                defaultValue={values.workspace?.id ?? ""}
                                value={values.workspace?.id ?? ""}
                                title="Workspace (Optional)"
                                hasTooltip
                                tooltipContent={
                                    MESSAGES.LISTING_WORKSPACE_TOOLTIP
                                }
                                tooltipPosition="bottom"
                            >
                                <MenuItem
                                    value=""
                                    sx={{ fontSize: "14px !important;" }}
                                >
                                    Please select
                                </MenuItem>
                                {vendorWorkspacesData.map((vendorWorkspace) => (
                                    <MenuItem
                                        value={vendorWorkspace.id}
                                        key={vendorWorkspace.id}
                                        sx={{ fontSize: "14px !important;" }}
                                    >
                                        {vendorWorkspace.title}
                                    </MenuItem>
                                ))}
                            </JCSelect>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <ReactGoogleMaps
                {...props}
                setRtnValueAddresses={updateAddress}
                searchAddress={searchAddress}
            />
            <FormControl
                variant="standard"
                className="mb-4 md:mb-8"
                error={!!errors.address1 && touched.address1}
            >
                <TextInput
                    id="address1"
                    name="address1"
                    label="Address line 1"
                    placeholder="Enter address line 1"
                    aria-describedby="room-address1-help-text"
                    value={values.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showRequiredSymbol
                />
                {touched.address1 && errors.address1 && (
                    <FormHelperText
                        id="room-address1-help-text"
                        className="mx-0 my-1"
                    >
                        {touched.address1 && errors.address1}
                    </FormHelperText>
                )}
            </FormControl>
            <FormControl
                variant="standard"
                className="mb-4 md:mb-8"
                error={!!errors.address2 && touched.address2}
            >
                <TextInput
                    id="address2"
                    name="address2"
                    label="Address line 2 (Optional)"
                    placeholder="Enter address line 2 (Optional)"
                    aria-describedby="room-address2-help-text"
                    value={values.address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.address2 && errors.address2 && (
                    <FormHelperText
                        id="room-address2-help-text"
                        className="mx-0 my-1"
                    >
                        {errors.address2}
                    </FormHelperText>
                )}
            </FormControl>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.city && touched.city}
                    >
                        <TextInput
                            id="city"
                            name="city"
                            label="City"
                            placeholder="Enter city"
                            aria-describedby="room-city-help-text"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.city && errors.city && (
                            <FormHelperText
                                id="room-city-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.city}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={!!errors.postalCode && touched.postalCode}
                    >
                        <TextInput
                            id="postalCode"
                            name="postalCode"
                            label="Postal code"
                            placeholder="Enter postal code"
                            aria-describedby="room-postalCode-help-text"
                            value={values.postalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.postalCode && errors.postalCode && (
                            <FormHelperText
                                id="room-postalCode-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.postalCode}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                {hasNeighborhood && (
                    <Grid item xs={12} md={6}>
                        <FormControl
                            variant="standard"
                            className="mb-4 md:mb-8"
                            error={
                                !!errors.neighborhood?.id &&
                                touched.neighborhood?.id
                            }
                        >
                            <JCSelect
                                labelId="demo-simple-select-label-2"
                                id="neighborhood"
                                label="Neighborhood"
                                onChange={handleNeighborhoodChange}
                                defaultValue=""
                                value={values.neighborhood?.id ?? ""}
                                name="neighborhood"
                                title="Neighborhood"
                                hasTooltip
                                tooltipContent={
                                    MESSAGES.LISTING_NEIGHBORHOOD_TOOLTIP
                                }
                                tooltipPosition="bottom"
                                showRequiredSymbol
                                error={
                                    !!errors.neighborhood?.id &&
                                    touched.neighborhood?.id
                                }
                            >
                                <MenuItem
                                    key=""
                                    value=""
                                    sx={{ fontSize: "14px !important;" }}
                                >
                                    Please select
                                </MenuItem>
                                {neighborhoodsData.map((neighborhood) => (
                                    <MenuItem
                                        key={neighborhood.id}
                                        value={neighborhood.id}
                                        sx={{ fontSize: "14px !important;" }}
                                    >
                                        {neighborhood.title}
                                    </MenuItem>
                                ))}
                            </JCSelect>
                            {touched.neighborhood?.id &&
                                errors.neighborhood?.id && (
                                    <FormHelperText
                                        id="neighborhood-help-text"
                                        className="mx-0 my-1"
                                    >
                                        {errors.neighborhood?.id}
                                    </FormHelperText>
                                )}
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-2"
                        error={!!errors.capacity && touched.capacity}
                    >
                        <SectionTitle
                            title="Meeting room capacity"
                            labelClassName="m-0 mr-3 w-full label-clickable"
                            className="!mb-0"
                            showRequiredSymbol
                            labelVariantInherit
                        />
                        <IMaskInput
                            mask={Number}
                            scale={0} // digits after point, 0 for integers
                            signed={false} // disallow negative
                            value={String(values.capacity) ?? "0"}
                            //@ts-ignore
                            onBlur={handleBlur}
                            placeholder="0"
                            id="capacity"
                            name="capacity"
                            label="Capacity"
                            aria-describedby="capacity-help-text"
                            className={`max-w-none maskInput pointer-events-auto ${
                                !!errors.capacity && touched.capacity
                                    ? "maskInput-error"
                                    : ""
                            }`}
                            onAccept={(value, mask) => {
                                value = value === "" ? null : Number(value);
                                setFieldValue("capacity", value, true);
                            }}
                        />
                        {touched.capacity && errors.capacity && (
                            <FormHelperText
                                id="capacity-help-text"
                                className="mx-0 my-1"
                            >
                                {errors.capacity}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};
