import { FormControl, FormHelperText, MenuItem } from "@mui/material";
import { SectionTitle } from "components/UI";
import JCSelect from "components/UI/SelectDropdown";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import { useState } from "react";
import MESSAGES from "shared/constants/messages.constant";

const BookingTimeIncrement = (props: FormikProps<MeetingRoom>) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
    } = props;
    const [timeIncrements, setTimeIncrements] = useState<Number[]>([
        15, 30, 45, 60,
    ]);
    return (
        <>
            <FormControl
                className="flex flex-col flex-wrap pt-2 sm:flex-row sm:items-baseline sm:pt-0"
                error={
                    !!errors.bookingTimeIncrements &&
                    touched.bookingTimeIncrements
                }
            >
                <SectionTitle
                    title="Booking time increments"
                    labelClassName="mr-3"
                    className="!mb-0"
                    helpText={
                        MESSAGES.LISTING_PRICING_PER_MEETINGROOM_HOURLY_BOOKING_TIME_INCREMENTS_TOOLTIP
                    }
                    tooltipPosition="right"
                    hasTooltip
                    labelVariantInherit
                    showRequiredSymbol
                />
                <div className="flex items-center">
                    <JCSelect
                        labelId="demo-simple-select-label-2"
                        id="bookingTimeIncrements"
                        defaultValue=""
                        name="bookingTimeIncrements"
                        onChange={(e) =>
                            props.setFieldValue(
                                "bookingTimeIncrements",
                                Number(e.target.value),
                            )
                        }
                        onBlur={handleBlur}
                        value={values.bookingTimeIncrements}
                        className="pointer-events-auto"
                    >
                        {timeIncrements &&
                            timeIncrements.map((timeIncrement) => (
                                <MenuItem
                                    value={String(timeIncrement)}
                                    key={String(timeIncrement)}
                                    sx={{
                                        fontSize: "14px !important;",
                                    }}
                                >
                                    {timeIncrement} mins
                                </MenuItem>
                            ))}
                    </JCSelect>
                </div>
                {touched.bookingTimeIncrements && errors.bookingTimeIncrements && (
                    <FormHelperText
                        id="bookingTimeIncrements-help-text"
                        className="w-full mx-0 my-2"
                    >
                        {errors.bookingTimeIncrements}
                    </FormHelperText>
                )}
            </FormControl>
        </>
    );
};

export default BookingTimeIncrement;
