import { useNotificationContext } from "context/NotificationContext";
import { Formik } from "formik";
import { useMutation } from "react-query";

import { Button, Divider } from "@mui/material";

import { AccountInformationForm } from "components/AccountInformationForm";
import { GeneralAccountInformationSkeleton } from "components/AccountInformationForm/GeneralAccountInformation";
import { PaymentAccountInformationSkeleton } from "components/AccountInformationForm/PaymentAccountInformation";

import {
    AccountInformationModel,
    defaultAccountInformation,
} from "models/account-information.model";
import { vendorService } from "services/vendor.service";
import MESSAGES from "shared/constants/messages.constant";
import { AccountInformationValidation } from "validation-schema/account-information.validation";

export interface AccountInformationProps {
    accountInformationData: AccountInformationModel | null;
    vendorId: string | null;
    refetch: any;
    isReadOnly: boolean;
}

export const AccountInformation = (props: AccountInformationProps) => {
    const { accountInformationData, vendorId, refetch, isReadOnly } = props;

    const notificationContext = useNotificationContext();

    // API submit data
    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
        async (data: AccountInformationModel) => {
            return await vendorService.updateVendorAccountInformation(
                vendorId,
                data,
            );
        },
        {
            onSuccess: async (res) => {
                notificationContext.showSuccess(
                    MESSAGES.ACCOUNT_INFORMATION_UPDATE_SUCCESSFULLY,
                );
                await refetch();
            },
            onError: async (err: any) => {
                notificationContext.showError(err.message);
            },
        },
    );

    const saveAccountInformation = async (values: AccountInformationModel) => {
        try {
            values ? mutateUpdate(values) : mutateUpdate(null);
        } catch (ex) {
            notificationContext.showError(ex.message);
        }
    };

    if (isLoadingUpdate)
        return (
            <>
                <GeneralAccountInformationSkeleton />
                <Divider className="mt-2 mb-5 sm:mb-6" />
                <PaymentAccountInformationSkeleton />
            </>
        );

    return (
        <>
            <Formik
                initialValues={
                    accountInformationData ?? defaultAccountInformation
                }
                enableReinitialize
                validationSchema={AccountInformationValidation}
                onSubmit={async (values: AccountInformationModel) => {
                    try {
                        await saveAccountInformation(values);
                    } catch (ex) {
                        notificationContext.showError(ex.message);
                    }
                }}
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit} className="rounded-lg">
                        <AccountInformationForm
                            formik={formik}
                            isReadOnly={isReadOnly}
                        />
                        <div
                            className={
                                isReadOnly
                                    ? "hidden"
                                    : "flex flex-col sm:flex-row justify-between space-y-3 sm:space-y-0 sm:space-x-3"
                            }
                        >
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                className="w-full sm:max-w-[175px]"
                                fullWidth
                                disabled={
                                    !formik.dirty ||
                                    !formik.isValid ||
                                    formik.isSubmitting
                                }
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};
