import { CONSOLIDATE_STATUS } from "./status";

const MESSAGES = {
    LISTING_VALIDATION_TITLE_REQUIRED: "Meeting room name is required",
    LISTING_VALIDATION_ADDRESS1_REQUIRED: "Address line 1 is required",
    LISTING_VALIDATION_LOCATION_ADDRESS_REQUIRED:
        "Location address is required",
    LISTING_VALIDATION_LOCATION_LAT_REQUIRED:
        "Location coordinates are required",
    LISTING_VALIDATION_LOCATION_LNG_REQUIRED:
        "Location coordinates are required",
    LISTING_VALIDATION_CITY_REQUIRED: "City is required",
    LISTING_VALIDATION_NEIGHBORHOOD_REQUIRED: "Neighborhood is required",
    LISTING_VALIDATION_POSTAL_REQUIRED: "Postal code is required",
    LISTING_VALIDATION_CAPACITY_TYPE_ERROR:
        "Meeting room capacity should be a number",
    LISTING_VALIDATION_CAPACITY_REQUIRED: "Meeting room capacity is required",
    LISTING_VALIDATION_CAPACITY_MIN:
        "Meeting room capacity must be greater than 0",
    LISTING_VALIDATION_OPENING_HOURS_ID_REQUIRED: "Opening hour id is required",
    LISTING_VALIDATION_OPENING_HOURS_DAY_OF_WEEK_REQUIRED:
        "Day of week required for opening hours",
    LISTING_VALIDATION_OPENING_HOURS_OPENING_TIME_REQUIRED:
        "Opening hour is required",
    LISTING_VALIDATION_OPENING_HOURS_TIME_FORMAT: "Incorrect time format",
    LISTING_VALIDATION_OPENING_HOURS_OPENING_TIME_LATER_THAN_CLOSING_TIME:
        "Opening time cannot be later than closing time",
    LISTING_VALIDATION_OPENING_HOURS_CLOSING_TIME_REQUIRED:
        "Closing hour is required",
    LISTING_VALIDATION_OPENING_HOURS_CLOSING_TIME_EARLIER_THAN_OPENING_TIME:
        "Closing time cannot be earlier than opening time",
    LISTING_VALIDATION_OPENING_HOURS_REQUIRED: "Opening hour is required",

    //Old
    LISTING_VALIDATION_PRICE_PER_HOUR_TYPE_ERROR:
        "Hourly price should be number",
    LISTING_VALIDATION_PRICE_PER_HOUR_REQUIRED: "Hourly price is required",
    LISTING_VALIDATION_PRICE_PER_HOUR_MIN:
        "Hourly price must be greater than or equal to 0",
    LISTING_VALIDATION_DISCOUNT_HALF_DAY_REQUIRED:
        "Half day discount value required",
    LISTING_VALIDATION_DISCOUNT_HALF_DAY_TYPE_ERROR:
        "Half day discount value should be a number",
    LISTING_VALIDATION_DISCOUNT_HALF_DAY_MIN: "Discount must be greater than 0",
    LISTING_VALIDATION_DISCOUNT_HALF_DAY_CHECK_AND_FILL:
        "Please check discount half day and fill number",

    LISTING_VALIDATION_DISCOUNT_FULL_DAY_REQUIRED:
        "Full day discount value required",
    LISTING_VALIDATION_DISCOUNT_FULL_DAY_TYPE_ERROR:
        "Full day discount value should be a number",
    LISTING_VALIDATION_DISCOUNT_FULL_DAY_MIN:
        "Discount must be greater than zero",
    LISTING_VALIDATION_DISCOUNT_FULL_DAY_CHECK_AND_FILL:
        "Please check discount full day and fill number",

    // New
    LISTING_VALIDATION_PRICING_REQUIRED: "Pricing is required",
    LISTING_VALIDATION_PRICING_SELECT_TYPE_REQUIRED: "Select type is required",
    LISTING_VALIDATION_PRICING_HOURLY_RATE_REQUIRED: "Hourly rate is required",
    LISTING_VALIDATION_PRICING_HOURLY_TYPE_ERROR:
        "Hourly rate must be a number",
    LISTING_VALIDATION_PRICING_HOURLY_MIN: "Hourly rate must be a number",
    LISTING_VALIDATION_PRICING_MINIMUM_HOURS_REQUIRED:
        "Minimum hours is required",
    LISTING_VALIDATION_PRICING_MINIMUM_HOURS_TYPE_ERROR:
        "Minimum hours must be a number",
    LISTING_VALIDATION_PRICING_MINIMUM_HOURS_MIN:
        "Minimum hours must be greater than 0",
    LISTING_VALIDATION_PRICING_DISCOUNT_HALFDAY_REQUIRED:
        "Half day discount value required",
    LISTING_VALIDATION_PRICING_DISCOUNT_HALFDAY_TYPE_ERROR:
        "Half day discount value should be a number",
    LISTING_VALIDATION_PRICING_DISCOUNT_MIN: "Discount must be greater than 0",
    LISTING_VALIDATION_PRICING_DISCOUNT_FULLDAY_REQUIRED:
        "Full day discount value is required",
    LISTING_VALIDATION_PRICING_DISCOUNT_FULLDAY_TYPE_ERROR:
        "Full day discount value should be a number",
    LISTING_VALIDATION_PRICING_HALFFULL_SELECT_LENGTH_REQUIRED:
        "Select length is required",
    LISTING_VALIDATION_PRICING_HALFFULL_SELECT_LENGTH_TYPE_ERROR:
        "Select length must be a number",
    LISTING_VALIDATION_PRICING_HALFFULL_SELECT_LENGTH_MIN:
        "Select length must be greater than 0",
    LISTING_VALIDATION_PRICING_HALFFULL_RATE_REQUIRED: "Rate is required",
    LISTING_VALIDATION_PRICING_HALFFULL_RATE_TYPE_ERROR:
        "Rate must be a number",
    LISTING_VALIDATION_PRICING_HALFFULL_RATE_MIN: "Rate must be greater than 0",
    LISTING_VALIDATION_PRICING_HALFFULL_HALF_RATE_CHECK_AND_FILL:
        "Please check half day and fill number",
    LISTING_VALIDATION_PRICING_HALFFULL_FULL_RATE_CHECK_AND_FILL:
        "Please check full day and fill number",
    LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_REQUIRED:
        "Minimum charge is required",
    LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_TYPE_ERROR:
        "Minimum charge must be a number",
    LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_MIN:
        "Minimum charge must be greater than 0",
    LISTING_VALIDATION_PRICING_MINIMUM_CHARGE_CHECK_AND_FILL:
        "Please check minimum charge and fill number",
    LISTING_VALIDATION_PRICING_TIME_BLOCK_REQUIRED: "Time block is required",
    LISTING_VALIDATION_PRICING_TIME_BLOCK_TYPE_ERROR:
        "Time block must be a number",
    LISTING_VALIDATION_PRICING_TIME_BLOCK_MIN:
        "Time block must be greater than 0",
    LISTING_VALIDATION_PRICING_BOOKING_TIME_INCREMENTS_REQUIRED:
        "Booking time increments is required",

    LISTING_VALIDATION_MEETING_ROOM_AMENITIES_ID_REQUIRED:
        "Amenity id is required",
    LISTING_VALIDATION_MEETING_ROOM_AMENITIES_NAME_REQUIRED:
        "Amenity name is required",
    LISTING_VALIDATION_MEETING_ROOM_AMENITIES_TITLE_REQUIRED:
        "Amenity title is required",
    LISTING_VALIDATION_MEETING_ROOM_AMENITIES_ICON_REQUIRED:
        "Amenity icon is required",
    LISTING_VALIDATION_MEETING_ROOM_AMENITIES_DESCRIPTION_REQUIRED:
        "Amenity description is required",

    LISTING_VALIDATION_MEETING_ROOM_AMENITY_MIN:
        "Please choose at least 1 amenity",
    LISTING_VALIDATION_CUSTOM_MEETING_ROOM_AMENITIES_ID_REQUIRED:
        "Custom amenity id is required",
    LISTING_VALIDATION_CUSTOM_MEETING_ROOM_AMENITIES_TITLE_REQUIRED:
        "Custom amenity title is required",
    LISTING_VALIDATION_CUSTOM_MEETING_ROOM_AMENITIES_ICON_REQUIRED:
        "Custom amenity icon is required",
    LISTING_VALIDATION_CUSTOM_MEETING_ROOM_AMENITIES_DESCRIPTION_REQUIRED:
        "Custom amenity description is required",

    LISTING_VALIDATION_MOOD_REQUIRED: "Mood is required",
    LISTING_VALIDATION_DESCRIPTION_REQUIRED:
        "Meeting room description is required",
    LISTING_VALIDATION_IMAGES_TITLE_REQUIRED: "Image name is required",
    LISTING_VALIDATION_IMAGES_URL_REQUIRED: "Image public url is required",
    LISTING_VALIDATION_IMAGES_REFERENCE_REQUIRED:
        "Image private url is required",
    LISTING_VALIDATION_IMAGES_MIN: "Please upload at least 1 image",
    LISTING_VALIDATION_CANCELLATION_POLICY_REQUIRED:
        "Please select 1 cancellation policy",

    VENDOR_PROFILE_VALIDATION_IMAGES_URL_REQUIRED:
        "Image public url is required",
    VENDOR_PROFILE_VALIDATION_IMAGES_REFERENCE_REQUIRED:
        "Image private url is required",
    VENDOR_PROFILE_VALIDATION_FIRST_NAME_REQUIRED: "First name is required",
    VENDOR_PROFILE_VALIDATION_LAST_NAME_REQUIRED: "Last name is required",
    VENDOR_PROFILE_VALIDATION_COMPANY_REQUIRED: "Company name is required",
    VENDOR_PROFILE_VALIDATION_ABOUT_REQUIRED: "About is required",

    ADD_ON_VALIDATION_TITLE_REQUIRED: "Add-on name is required",
    ADD_ON_VALIDATION_DESCRIPTION_REQUIRED: "Add-on description is required",
    ADD_ON_VALIDATION_PER_UNIT_TYPE_ERROR:
        "Add-on price per unit should be number",
    ADD_ON_VALIDATION_PER_UNIT_INVALID_FORMAT:
        "Add-on price per unit should be number and valid format",
    ADD_ON_VALIDATION_PER_UNIT_REQUIRED: "Add-on price per unit is required",
    ADD_ON_VALIDATION_PER_UNIT_MIN: "Price must be greater than or equal 0",

    LOGIN_VALIDATION_EMAIL_REQUIRED: "Email address is required",
    LOGIN_VALIDATION_EMAIL_INVALID: "Invalid email address",
    LOGIN_VALIDATION_EMAIL_MAX: "Email address is too long",

    LOGIN_VALIDATION_PASSWORD_REQUIRED: "Password is required",
    LOGIN_VALIDATION_PASSWORD_MIN: "Password is too short",
    LOGIN_VALIDATION_PASSWORD_MAX: "Password is too long",

    LOGIN_VALIDATION_EMAIL_PASSWORD_INCORRECT:
        "The email address or password is incorrect",
    PROFILE_IMAGE_VALIDATION_FILE_SIZE: "File size must not exceed",
    PROFILE_IMAGE_VALIDATION_FILE_TYPE:
        "Please upload images with the allowed file types",
    PROFILE_UPDATE_SUCCESSFULLY: "Updated successfully",
    LISTING_DELETE_CONFIRMATION:
        "Are you sure you want to delete this listing?",
    LISTING_DELETE_BODY_CONFIRMATION:
        "Note: You cannot delete the meeting room if booked by a customer.",
    LISTING_DELETE_CONFIRMATION_BUTTON: "Yes",
    LISTING_DELETE_CANCEL_BUTTON: "No",
    LISTING_DELETE_SUCCESSFULLY: "Meeting room deleted.",
    LISTING_DELETE_UNSUCCESSFULLY: "Cannot delete meeting room.",

    LISTING_ADD_SUCCESSFULLY: "Your new listing has been added successfully.",
    LISTING_UPDATED_SUCCESSFULLY: "The listing has been updated successfully.",
    LISTING_UNLISTING_SUCCESSFULLY: "Meeting room has been unlisted.",
    LISTING_DRAFT_SAVE_SUCCESSFULLY: "Your draft has been saved successfully.",

    LISTING_UNLISTING_CONFIRMATION:
        "Are you sure you want to unlist your meeting room?",
    LISTING_UNLISTING_BODY_CONFIRMATION:
        "Once it is unlisted you can still find it in your list, but it won’t appear on the Joes & Cos app before you re-list it.",
    LISTING_UNLISTING_CONFIRMATION_BUTTON: "Yes",
    LISTING_UNLISTING_CANCEL_BUTTON: "No",

    LISTING_NAVIGATE_AWAY_CONFIRMATION:
        "You have unsaved changes. Are you sure you want to leave without saving?",

    RESERVATION_ACCEPT_BOOKING_SUCCESSFULLY:
        "The meeting room request has been accepted.",
    RESERVATION_DECLINE_BOOKING_SUCCESSFULLY:
        "The meeting room request has been declined.",
    RESERVATION_DECLINE_BOOKING_MODAL_CONFIRMATION_TITLE:
        "Are you sure you want to decline the meeting room request?",
    RESERVATION_DECLINE_BOOKING_MODAL_CONFIRMATION_BODY:
        "Declining a booking request will cancel the reservation. You can still chat with the user and explain why you declined.",
    RESERVATION_CONFIRM_BOOKING_MODAL_CONFIRMATION_TITLE:
        "Are you sure you want to confirm the meeting room request?",
    RESERVATION_CONFIRM_BOOKING_MODAL_CONFIRMATION_BODY:
        "Confirming a meeting room booking will send a payment request to the user.",
    YES: "Yes",
    NO: "No",

    NOTIFICATION_DELETE_SUCCESSFULLY: "Successfully deleted.",
    AMENITIES_DELETE_MODAL_CONFIRMATION_TITLE: "Confirm delete",
    AMENITIES_DELETE_MODAL_CONFIRMATION_BODY:
        "Would you like to delete this amenity? If other listings use this amenity, they will be removed also.",

    ADDON_DELETE_MODAL_CONFIRMATION_TITLE: "Confirm delete",
    ADDON_DELETE_MODAL_CONFIRMATION_BODY:
        "Would you like to delete this add-on?",

    LISTING_ADD_UPDATE_ERROR: "Something went wrong. Please try again.",
    BASIC_ERROR: "Something went wrong",

    ACCOUNT_INFORMATION_EMAIL_HELP_TEXT:
        "This is your contact email address and is also where you will receive email notifications.",
    ACCOUNT_INFORMATION_PHONENUMBER_HELP_TEXT:
        "Enter your phone number with or without spaces.",
    ACCOUNT_INFORMATION_VALIDATION_EMAIL_REQUIRE: "Email is required",
    ACCOUNT_INFORMATION_VALIDATION_FIRSTNAME_REQUIRE: "First name is required",
    ACCOUNT_INFORMATION_VALIDATION_LASTNAME_REQUIRE: "Last name is required",
    ACCOUNT_INFORMATION_VALIDATION_PHONENUMBER_REQUIRE:
        "Phone number is required",
    ACCOUNT_INFORMATION_VALIDATION_ADDRESSLINE1_REQUIRE:
        "Address line 1 is required",
    ACCOUNT_INFORMATION_VALIDATION_CITY_REQUIRE: "City is required",
    ACCOUNT_INFORMATION_VALIDATION_POSTALCODE_REQUIRE:
        "Postal code is required",
    ACCOUNT_INFORMATION_VALIDATION_COUNTRY_REQUIRE: "Country is required",

    ACCOUNT_INFORMATION_VALIDATION_REGNUMBER_REQUIRE: "Reg. number is required",
    ACCOUNT_INFORMATION_VALIDATION_BANKACCOUNTNUMBER_REQUIRE:
        "Bank account number is required",

    ACCOUNT_INFORMATION_UPDATE_SUCCESSFULLY: "Updated successfully",
    VENDOR_ACCOUNT_WORKSPACE_HELP_TEXT:
        "Please select the workspaces that the vendor belongs to, if any.",
    VENDOR_ACCOUNT_SERVICE_FEE_HELP_TEXT: "Enter a service fee for this vendor",
    VENDOR_ACCOUNT_VAT_SETTINGS_HELP_TEXT:
        "Select which VAT rule should apply to this vendor",

    INVALID_FILE_TYPE_FILE_ERROR_MESSAGE:
        "Please upload images with the allowed file types.",

    LISTING_FIND_MY_ADDRESS_TOOLTIP:
        "<span class='space-y-2'><span class='block'>Enter your address here to find it on the map. The address fields will automatically populate with information from Google Maps. If there are any mistakes or the address is not quite right, you can easily make changes below in the individual address fields.</span><span class='block'> If the address does not match the exact location or entrance, drag the pin on the map to the right location or area, so that guests can know where to find the meeting space. </span><span>",

    LISTING_AMENITIES_TOOLTIP:
        "<span>Please select all the amenities that your meeting room provides.</span>",
    LISTING_CUSTOM_AMENITIES_TOOLTIP:
        '<span>Add a custom amenity not listed in the "Amenities" section. This allows you to share with potential guests any special features that are not included in the standard list.</span>',
    LISTING_ADD_ONS_TOOLTIP:
        "<span class='space-y-2'><span class='block'>If you have any food, beverages, services, packages, or other things you would like to offer your guests to facilitate a better meeting experience, you can add them here. Add- ons can be listed with a price or as a free offering. Items you add here will be shown to the user when making a booking request. They will then be able to select them freely with the amount needed, adding these add-ons to their meeting room request.</span><span class='block'>Note: All add-ons are subject to the same commission percentage as meeting rooms. The commission fee will be deducted from your payout based on the total price of the meeting room reservation.</span><span>",
    LISTING_SELECT_MOOD_TOOLTIP:
        "<span>Select a mood that corresponds to the vibe and atmosphere that the meeting space provides. This helps users narrow down their meeting room choice according to their needs and atmosphere they would like to have.</span>",
    LISTING_MEETING_ROOM_DESC_TOOLTIP:
        "<span>Write all the information that you would like to share with guests about your meeting room. It's best to provide an overview of the meeting room with any notable features about the space, location or furnishings. This is your time to shine to provide the best and most pertinent description a potential booker needs when making their decision.</span>",
    LISTING_ADD_PICTURE_TOOLTIP:
        "<span>Please use an aspect ratio of 4:3 for your photos to come out there best. Accepted file types are JPEG, PNG, GIF.</span>",
    LISTING_CANCELLATION_POLICY_TOOLTIP:
        "<span>Pick a policy that suits your needs. For example, you might want to pick a stricter cancellation policy for bigger meeting rooms to allow for better management of the space. On the other hand, a flexible cancellation policy might be easier to manage and more attractive to meeting room bookers.</span>",
    LISTING_OPENING_HOURS_TOOLTIP:
        '<span>For a 24-hour opening day, please enter "00:00 - 23:59".</span>',
    LISTING_WORKSPACE_TOOLTIP:
        "<span>Select the workspace that corresponds to your meeting room listing. In this way, users can see that the meeting room is part of a workspace. This setting also gives you maximum visibility as users searching for workspaces will see that there is a meeting room associated with it.</span>",
    LISTING_NEIGHBORHOOD_TOOLTIP:
        "<span>Select the neighborhood that corresponds to the location of the meeting room listing. In this way, users are able to easily see what area the meeting room is in and be able to easily filter their search criteria by neighborhood to find the right location for their next event.</span>",

    LISTING_ADD_ONS_EMPTY_LIST:
        "You can add add-ons by clicking the button above.",
    LISTING_CUSTOM_AMENITIES_EMPTY_LIST:
        "You can add custom amenity by clicking the button above.",

    LISTING_PRICING_TOOLTIP:
        'Select a scheme that matches your needs. You must first select whether you prefer to have your meeting room priced "per meeting room" or "per person". From there, select whether you would like your price to be calculated by "the hour" or by "half/full day" pricing. Each scheme allows you to select the option that bests suits your needs.',
    LISTING_PRICING_PER_MEETINGROOM_HOURLY_MINIMUMHOURS_TOOLTIP:
        "Choose the minimum amount of hours necessary for booking this meeting room. A user will not be able to send a meeting request for a time less than the time selected.",
    LISTING_PRICING_PER_MEETINGROOM_HOURLY_DISCOUNT_TOOLTIP:
        "Select whether you would like to give the user a discount for booking half day, full day, or both. The discount shall be applied when the user selects a timeframe between the specified hours for each category. The entered percentage will be taken of the hourly rate.",
    LISTING_PRICING_PER_MEETINGROOM_HOURLY_BOOKING_TIME_INCREMENTS_TOOLTIP:
        "Set the time increments that best suits your needs for this meeting room. You have the ability to specify whether you want 15, 30, 45 or 60 minute increments. Potential bookers will only be able to select meeting times by the time increment chosen",
    LISTING_PRICING_PER_MEETINGROOM_HALFFULLDAY_HALFDAY_TOOLTIP:
        "Select whether you would like to offer your meeting room for half a day, a full day or both. Choose the length of time that corresponds to each option and input the rate. Remember the rate corresponds to the total time selected for each section.",
    LISTING_PRICING_PER_MEETINGROOM_HALFFULLDAY_FULLDAY_TOOLTIP:
        "If the length of time selected does not match the hours listed for availability, for example 10 hours is selected for the full day length, but the amount of time open on a given day is 6 hours, a full day will be shown as the lower amount ie. matching the availability hours.",
    LISTING_PRICING_PER_PERSON_HOURLY_MINIMUM_HOURS_TOOLTIP:
        "Choose the minimum amount of hours necessary for booking this meeting room. A user will not be able to send a meeting request for a time less than the time selected.",

    LISTING_PRICING_PER_PERSON_HOURLY_MINIMUM_CHARGE_TOOLTIP:
        "Input the minimum charge necessary for booking this meeting room. When a user sends a meeting room request and does not meet the minimum price based on his' or her's selection, the default price will be the minimum charge inputted in this section. Remember that any discounts that go below the minimum charge specified will be overwritten with the minimum charge amount.",
    LISTING_PRICING_PER_PERSON_HOURLY_DISCOUNT_TOOLTIP:
        "Select whether you would like to give the user a discount for booking half day, full day, or both. The discount shall be applied when the user selects a timeframe between the specified hours for each category. The entered percentage will be taken of the hourly rate.",
    LISTING_PRICING_PER_PERSON_HOURLY_BOOKING_TIME_INCREMENTS_TOOLTIP:
        "Set the time increments that best suits your needs for this meeting room. You have the ability to specify whether you want 15, 30, 45 or 60 minute increments. Potential bookers will only be able to select meeting times by the time increment chosen",
    LISTING_PRICING_PER_PERSON_HALFFULLDAY_HALFDAY_TOOLTIP:
        "Select whether you would like to offer your meeting room for half a day, a full day or both. Choose the length of time that corresponds to each option and input the rate. Remember the rate corresponds to the total time selected for each section.",
    LISTING_PRICING_PER_PERSON_HALFFULLDAY_HALFDAY_MINIMUM_CHARGE_TOOLTIP:
        "Input the minimum charge necessary for a half day booking for this meeting room. When a user sends a half day meeting room request and does not meet the minimum price based on his' or her's selection, the default price will be the minimum charge inputted in this section. Remember the rate corresponds to the total time selected for a half day booking.",
    LISTING_PRICING_PER_PERSON_HALFFULLDAY_FULLDAY_TOOLTIP:
        "If the length of time selected does not match the hours listed for availability, for example 10 hours is selected for the full day length, but the amount of time open on a given day is 6 hours, a full day will be shown as the lower amount ie. matching the availability hours.",
    LISTING_PRICING_PER_PERSON_HALFFULLDAY_FULLDAY_MINIMUM_CHARGE_TOOLTIP:
        "Input the minimum charge necessary for a full day booking for this meeting room. When a user sends a full day meeting room request and does not meet the minimum price based on his' or her's selection, the default price will be the minimum charge inputted in this section. Remember the rate corresponds to the total time selected for a full day booking.",
    LISTING_PRICING_TIME_BLOCK_TOOLTIP:
        "Add a block of time to the end of a booking, as a transition time, where e.g. cleaning can be done.",
    NOTIFICATION_EMPTY_LIST: "No new notifications",
    LISTING_ADD_ONS_PRICE_PER_UNIT_TOOLTIP:
        '<span>If the add-on is free, type "0" to show it as a free item in your listing\'s add-on section.</span>',
    LISTING_EMPTY_LIST: "You have no listings",
    RESERVATION_LIST_PENDING_EMPTY: "You have no pending reservations",
    RESERVATION_LIST_WAITING_FOR_PAYMENT_EMPTY:
        "You have no reservations waiting for payment",
    RESERVATION_LIST_UPCOMING_EMPTY: "You have no upcoming reservations",
    RESERVATION_LIST_COMPLETED_EMPTY: "You have no completed reservations",
    RESERVATION_LIST_CANCELED_EMPTY: "You have no canceled reservations",
    RESERVATION_LIST_DECLINED_EMPTY: "You have no declined reservations",
    RESERVATION_LIST_EXPIRED_EMPTY: "You have no expired reservations",
    RESERVATION_LIST_ALL_EMPTY: "You have no reservations",

    VENDOR_PROFILE_VALIDATION_NAME_REQUIRE: "Vendor name is required",
    VENDOR_PROFILE_VALIDATION_EMAIL_REQUIRE: "Email address is required",
    VENDOR_PROFILE_VALIDATION_SERVICE_FEE_REQUIRE: "Service fee is required",
    VENDOR_PROFILE_VALIDATION_VAT_TYPE_REQUIRE: "Vat type is required",
    VENDOR_PROFILE_VALIDATION_WORKSPACE_REQUIRE: "Workspace is required",
    VENDOR_PROFILE_ADD_SUCCESSFULLY: "The vendor has been added successfully",
    VENDOR_PROFILE_UPDATE_SUCCESSFULLY:
        "The vendor has been updated successfully",
    VENDOR_PROFILE_ERROR_UNSUCCESSFULLY:
        "Adding/updating the vendor was unsuccessful",

    VENDOR_LIST_EMPTY_LIST: "No vendors match!",

    VENDOR_DELETE_CONFIRMATION: "Are you sure you want to delete this vendor?",

    VENDOR_DELETE_CONFIRMATION_BODY:
        "This will permanently remove all information associated with it, and cannot be undone.",
    VENDOR_DELETE_CONFIRMATION_BUTTON: "Yes",
    VENDOR_DELETE_CANCEL_BUTTON: "No",
    VENDOR_DELETE_SUCCESSFULLY: "The vendor was deleted successfully.",
};
export const USERNAME_PLACEHOLDER = "{userName}";
export const VENDOR_SYSTEM_MESSAGES = {
    BookingRequest: `${USERNAME_PLACEHOLDER} has sent you a booking request.`,
    BookingChangeRequest: `${USERNAME_PLACEHOLDER} has sent you a change request.`,
    VendorAccepted: `You have accepted ${USERNAME_PLACEHOLDER}’s request. A payment request has now sent to the booker.`,
    VendorDeclined: `You have declined the booking request.`,
    BookingComplete: `${USERNAME_PLACEHOLDER} has paid for the meeting room. You are now good to go!`,
    UserCanceled: `${USERNAME_PLACEHOLDER} canceled the booking.`,
    BookingRequestExpired: "The booking has expired.",
};

export const getNoBookingRequestMessage = (
    consolidateStatus: CONSOLIDATE_STATUS,
) => {
    return `You have no ${consolidateStatus.toLocaleLowerCase()} requests`;
};

export const getMaxFilesExceedFileErrorMessage = (maxFileNumber: number) => {
    return `Max. ${maxFileNumber} images can be added.`;
};

export const getFileSizeExceedFileErrorMessage = (maxFileSizeKb: number) => {
    return `File size must not exceed ${maxFileSizeKb} KB.`;
};

export default MESSAGES;
