import {
    API_ENDPOINT_ฺMEETING_ROOM_AMENITIES,
    API_ENDPOINT_ฺVENDOR_AMENITY,
} from "shared/constants/api.constant";
import { Amenity } from "models";
import { requestService } from "./request.service";

const getMeeingRoomAmenities = async (): Promise<Amenity[]> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺMEETING_ROOM_AMENITIES.getMeetingRoomAmenities,
    )) as Amenity[];
};
const getVendorAmenities = async (): Promise<Amenity[]> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_AMENITY.getVendorAmenitiesByVendorId,
    )) as Amenity[];
};
const getVendorAmenityByAmenityId = async (id: string): Promise<Amenity[]> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_AMENITY.getVendorAmenityByAmenityId,
        { id: id } as Amenity,
    )) as Amenity[];
};
const addNewVendorAmenity = async (amenity: Amenity): Promise<Amenity[]> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_AMENITY.addNewVendorAmenity,
        amenity,
    )) as Amenity[];
};
const updateVendorAmenityByAmenityId = async (
    amenity: Amenity,
): Promise<Amenity[]> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_AMENITY.updateVendorAmenityByAmenityId,
        amenity,
    )) as Amenity[];
};
const deleteVendorAmenityByAmenityId = async (
    id: string,
): Promise<Amenity[]> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺVENDOR_AMENITY.deleteVendorAmenityByAmenityId,
        { id: id } as Amenity,
    )) as Amenity[];
};

export const amenityService = {
    getMeeingRoomAmenities,
    getVendorAmenities,
    getVendorAmenityByAmenityId,
    addNewVendorAmenity,
    updateVendorAmenityByAmenityId,
    deleteVendorAmenityByAmenityId,
};
