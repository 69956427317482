import { JCRadioProps } from "./type";

export const RadioDesc: React.FC<JCRadioProps> = (props) => {
    return (
        <div className="radio-desc">
            <div className="font-bold">{props.children}</div>
            <div
                className="pl-7 ml-0.5 -mt-1"
                dangerouslySetInnerHTML={{ __html: props.description }}
            />
        </div>
    );
};
