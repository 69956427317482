import { Grid, Skeleton, Typography } from "@mui/material";

export const VendorInformationSkeleton = () => {
    return (
        <>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Vendor name</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Email address</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
            </Grid>
            <div className="mb-6 md:mb-8">
                <Skeleton variant="text">
                    <Typography>Workspaces</Typography>
                </Skeleton>
                <Skeleton variant="text" className="w-full sm:w-1/2"></Skeleton>
                <Skeleton variant="text" className="w-full"></Skeleton>
                <Skeleton variant="text" className="w-full"></Skeleton>
                <Skeleton variant="text" className="w-full"></Skeleton>
            </div>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>Service Fee</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="text"
                        className="w-full sm:w-1/2"
                    ></Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="text">
                        <Typography>VAT Settings</Typography>
                    </Skeleton>
                    <Skeleton
                        variant="text"
                        className="w-full sm:w-1/2"
                    ></Skeleton>
                    <Skeleton
                        variant="rectangular"
                        className="mt-1 mb-5 w-full h-7 rounded"
                    ></Skeleton>
                </Grid>
            </Grid>
            <Skeleton
                variant="rectangular"
                className="mt-1 mb-5 w-1/2 sm:w-1/4 h-7 rounded"
            ></Skeleton>
        </>
    );
};
