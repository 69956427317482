import { API_ENDPOINT_ฺSPACE } from "shared/constants/api.constant";
import { PaginationModel } from "models/pagination.model";
import { Workspace } from "../models/workspace.model";
import { requestService } from "./request.service";

const getSpaces = async (
    amount: number,
    lastId: string | null,
    orderByField: string = "title",
    sortingDirection: string = "asc",
): Promise<PaginationModel<Workspace[]>> => {
    return (await requestService.onCall(API_ENDPOINT_ฺSPACE.getSpaces, {
        amount,
        lastId,
        orderByField,
        sortingDirection,
    })) as PaginationModel<Workspace[]>;
};

export const spaceService = {
    getSpaces,
};
