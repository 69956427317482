import { DEFAULT_END_TIME, DEFAULT_START_TIME } from "appSettings";
import { PublishStatus } from "enum/publish-status.enum";
import {
    defaultEndTime,
    defaultStartTime,
} from "shared/constants/shared.constant";
import { defaultLat, defaultLng } from "../shared/constants/shared.constant";
import { AddOn } from "./add-on.model";
import { Amenity, CustomAmenity } from "./amenity.model";
import { OptionValueBase, PricingOptionValue } from "./common-base.model";
import { DiscountFullDay } from "./discount-full-day.model";
import { DiscountHalfDay } from "./discount-half-day.model";
import { ImageModel } from "./image.model";
import { Location } from "./location.model";
import { Mood } from "./mood.model";
import { Neighborhood } from "./neighborhood.model";
import { OpeningHours } from "./opening-hours.model";
import { Vendor } from "./vendor.model";
import { Workspace } from "./workspace.model";

export interface MeetingRoom {
    id?: string;
    workspace?: Workspace;
    vendor?: Vendor;
    title?: string;
    location?: Location;
    address1?: string;
    address2?: string;
    city?: string;
    postalCode?: string;
    neighborhood?: Neighborhood;
    capacity?: number;
    openingHours?: OpeningHours[];
    pricePerHour?: number;
    discountHalfDay?: DiscountHalfDay;
    discountFullDay?: DiscountFullDay;
    meetingRoomAmenities?: Amenity[];
    customMeetingRoomAmenities?: CustomAmenity[];
    addOns?: AddOn[];
    mood?: Mood;
    description?: string;
    images?: ImageModel[];
    cancellationPolicyId?: string;
    published?: boolean;
    status: PublishStatus;
    pricingStyle?: string; // perMeetingRoom | perPerson
    calculationStyle?: string; // hourly | halfFullDayPassed
    halfDayPricing?: PricingOptionValue;
    fullDayPricing?: PricingOptionValue;
    minimumHours?: OptionValueBase;
    minimumChargeHourly?: OptionValueBase;
    minimumChargeHalfDay?: OptionValueBase;
    minimumChargeFullDay?: OptionValueBase;
    timeBlock?: OptionValueBase;
    bookingTimeIncrements?: number;
}

export const defaultMeetingRoom = {
    id: "",
    workspace: null,
    title: "",
    location: {
        address: "",
        lat: defaultLat,
        lng: defaultLng,
    },
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    neighborhood: {
        title: "",
        id: "",
    },
    capacity: 1,
    openingHours: [
        {
            uid: "1",
            dayOfWeek: "Monday",
            openingTime: DEFAULT_START_TIME ?? defaultStartTime,
            closingTime: DEFAULT_END_TIME ?? defaultEndTime,
            isOpen: false,
        },
        {
            uid: "2",
            dayOfWeek: "Tuesday",
            openingTime: DEFAULT_START_TIME ?? defaultStartTime,
            closingTime: DEFAULT_END_TIME ?? defaultEndTime,
            isOpen: false,
        },
        {
            uid: "3",
            dayOfWeek: "Wednesday",
            openingTime: DEFAULT_START_TIME ?? defaultStartTime,
            closingTime: DEFAULT_END_TIME ?? defaultEndTime,
            isOpen: false,
        },
        {
            uid: "4",
            dayOfWeek: "Thursday",
            openingTime: DEFAULT_START_TIME ?? defaultStartTime,
            closingTime: DEFAULT_END_TIME ?? defaultEndTime,
            isOpen: false,
        },
        {
            uid: "5",
            dayOfWeek: "Friday",
            openingTime: DEFAULT_START_TIME ?? defaultStartTime,
            closingTime: DEFAULT_END_TIME ?? defaultEndTime,
            isOpen: false,
        },
        {
            uid: "6",
            dayOfWeek: "Saturday",
            openingTime: DEFAULT_START_TIME ?? defaultStartTime,
            closingTime: DEFAULT_END_TIME ?? defaultEndTime,
            isOpen: false,
        },
        {
            uid: "7",
            dayOfWeek: "Sunday",
            openingTime: DEFAULT_START_TIME ?? defaultStartTime,
            closingTime: DEFAULT_END_TIME ?? defaultEndTime,
            isOpen: false,
        },
    ],
    pricePerHour: 0,
    discountHalfDay: {
        percent: 0,
        isEnable: false,
    },
    discountFullDay: {
        percent: 0,
        isEnable: false,
    },
    meetingRoomAmenities: [],
    customMeetingRoomAmenities: [],
    addOns: [],
    mood: {
        id: "",
        title: "",
        description: "",
        icon: "",
    },
    description: "",
    images: [],
    cancellationPolicyId: "",
    status: PublishStatus.Draft,
    pricingStyle: "",
    calculationStyle: "",
    halfDayPricing: {
        isEnable: false,
        value: 0,
        length: 0,
    } as PricingOptionValue,
    fullDayPricing: {
        isEnable: false,
        value: 0,
        length: 0,
    } as PricingOptionValue,
    minimumHours: { isEnable: false, value: 1 } as OptionValueBase,
    minimumChargeHourly: { isEnable: false, value: 0 } as OptionValueBase,
    minimumChargeHalfDay: { isEnable: false, value: 0 } as OptionValueBase,
    minimumChargeFullDay: { isEnable: false, value: 0 } as OptionValueBase,
    timeBlock: { isEnable: false, value: 15 } as OptionValueBase,
    bookingTimeIncrements: 15,
} as MeetingRoom;
