import { IoEllipse } from "react-icons/io5";
import {
    BG_COLOR,
    CONSOLIDATE_STATUS,
    STATUS,
    SUB_STATUS,
    TEXT_COLOR,
} from "shared/constants/status";
import { StatusReservationProps } from "types/dashboard";

export function modifyStatusForDisplay(
    status: string,
    firstLetterCapitalize: boolean = false,
) {
    let modifiedText = "";
    if (!status.includes(" ")) {
        modifiedText = status = status.replace(/([a-z])([A-Z])/g, "$1 $2");
    } else {
        modifiedText = status;
    }
    if (firstLetterCapitalize) return capitalizeFirstLetter(modifiedText);

    return modifiedText;
}
const capitalizeFirstLetter = (text: string) => {
    text = text.toLocaleLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
};

const StatusReservation: React.FC<StatusReservationProps> = (props) => {
    let textColor;
    let backgroundColor;
    (() => {
        switch (props.status) {
            case CONSOLIDATE_STATUS.Pending:
                textColor = TEXT_COLOR.PENDING_ORANGE;
                backgroundColor = BG_COLOR.PENDING_ORANGE;
                return;
            case SUB_STATUS.WAITING_RESPONSE:
                textColor = TEXT_COLOR.WAITING_RESPONSE_ORANGE;
                backgroundColor = BG_COLOR.WAITING_RESPONSE_ORANGE;
                return;
            case SUB_STATUS.PAYMENT_PENDING:
                textColor = TEXT_COLOR.PAYMENT_PENDING_PURPLE;
                backgroundColor = BG_COLOR.PAYMENT_PENDING_PURPLE;
                return;
            case SUB_STATUS.DECLINED:
                textColor = TEXT_COLOR.DECLINED_RED;
                backgroundColor = BG_COLOR.DECLINED_RED;
                return;
            case STATUS.Confirmed:
                textColor = TEXT_COLOR.UPCOMING_GREEN;
                backgroundColor = BG_COLOR.UPCOMING_GREEN;
                return;
            case SUB_STATUS.PAID:
                textColor = TEXT_COLOR.PAID_GREEN;
                backgroundColor = BG_COLOR.PAID_GREEN;
                return;
            case SUB_STATUS.COMPLETED:
                textColor = TEXT_COLOR.COMPLETED_BLACK;
                backgroundColor = BG_COLOR.COMPLETED_BLACK;
                return;
            case SUB_STATUS.CANCELED:
                textColor = TEXT_COLOR.CANCELED_RED;
                backgroundColor = BG_COLOR.CANCELED_RED;
                return;
            case SUB_STATUS.EXPIRED:
                textColor = TEXT_COLOR.EXPIRED_RED;
                backgroundColor = BG_COLOR.EXPIRED_RED;
                return;
            default:
                textColor = TEXT_COLOR.DEFAULT_BLACK;
                backgroundColor = BG_COLOR.DEFAULT_BLACK;
        }
    })();
    return (
        <>
            {props.hasBackground ? (
                <span
                    className={`font-semibold bg-opacity-10 inline-block py-1.5 px-4 rounded-lg whitespace-nowrap ${textColor} ${backgroundColor} ${
                        props.className !== undefined ? props.className : ""
                    }`}
                >
                    {modifyStatusForDisplay(props.status, true)}
                </span>
            ) : (
                <span
                    className={`font-semibold ${textColor} ${
                        props.className !== undefined ? props.className : ""
                    }`}
                >
                    {props.hasIcon && (
                        <IoEllipse className="mr-1 text-[10px]" />
                    )}
                    {modifyStatusForDisplay(props.status, true)}
                </span>
            )}
        </>
    );
};

export default StatusReservation;
