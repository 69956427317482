export const convertToCurrency = (
    value: number,
    locale: string = "da-DK",
    option: Intl.NumberFormatOptions = null,
) => {
    if (!option) return value.toLocaleString(locale);
    return value.toLocaleString(locale, {
        style: option.style,
        currency: option.currency,
        currencyDisplay: option.currencyDisplay,
    });
};

export const displayAsDecimal = (
    input: number,
    digits: number,
    skipAbsoluteNumber: boolean = false,
) => {
    if (skipAbsoluteNumber)
        return input % 1 !== 0 ? input.toFixed(digits) : input;
    else return input.toFixed(digits);
};

export const roundedToFixed = (input: number, digits: number) => {
    var rounded = Math.pow(10, digits);
    return (Math.round(input * rounded) / rounded).toFixed(digits);
};
