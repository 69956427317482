import { Button, DialogProps } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useEffect, useState } from "react";

interface ModalConfirmationProps extends DialogProps {
    className?: string;
    title?: string;
    btnText?: string;
    btnCancelText?: string;
    closeFn?: () => void;
    confirmFn?: () => void;
    severity?:
        | "inherit"
        | "error"
        | "info"
        | "success"
        | "warning"
        | "primary"
        | "secondary";
}

const useStyles = makeStyles<Theme>((theme) => ({
    MuiDialogScrollPaper: {
        alignItems: "flex-start",
    },
    MuiDialogPaperScrollBody: {
        verticalAlign: "top",
    },
    MuiDialogPaperScrollPaper: {
        margin: "13% 16px 16px !important",
        maxWidth: "480px !important",
        borderRadius: 0,
    },
}));

export const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
    closeFn = () => null,
    confirmFn = () => null,
    open,
    title,
    className,
    btnText,
    btnCancelText,
    children,
    severity,
    ...props
}) => {
    const classes = useStyles();
    const [buttonClicked, setButtonClicked] = useState(false);
    useEffect(() => {
        if (open) setButtonClicked(false);
    }, [open]);

    const handleClickCancel = () => {
        closeFn();
    };

    const handleClickConfirm = async () => {
        setButtonClicked(true);
        await confirmFn();
    };

    return (
        <Dialog
            open={open}
            // onClose={closeFn}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll="paper"
            fullWidth={true}
            maxWidth="sm"
            disableEscapeKeyDown
            classes={{
                scrollPaper: classes.MuiDialogScrollPaper,
                paperScrollBody: classes.MuiDialogPaperScrollBody,
                paperScrollPaper: classes.MuiDialogPaperScrollPaper,
            }}
            className={`${className}`}
            {...props}
        >
            <DialogTitle className="text-lg font-bold text-center pt-7">
                {title}
            </DialogTitle>
            <DialogContent className="pb-3 text-center">
                {children}
            </DialogContent>
            <DialogActions className="justify-center pb-7">
                {btnCancelText && (
                    <Button
                        variant="outlined"
                        onClick={handleClickCancel}
                        color="primary"
                        className="min-w-[120px] min-h-[45px] rounded-lg"
                    >
                        {btnCancelText}
                    </Button>
                )}
                {btnText && (
                    <Button
                        variant="contained"
                        onClick={handleClickConfirm}
                        autoFocus
                        color={severity}
                        className="min-w-[120px] min-h-[45px] rounded-lg"
                        disabled={buttonClicked}
                    >
                        {btnText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
