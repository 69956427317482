import { AppBar, Box, Divider, IconButton, Menu, Toolbar } from "@mui/material";
import { Logo } from "components";
import { DocumentData, QuerySnapshot } from "firebaseSetup";
import { Vendor } from "models";
import { VendorInbox } from "models/inbox.model";
import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import {
    IoDocumentTextOutline,
    IoExitOutline,
    IoMailOutline,
    IoNotificationsOutline,
    IoPersonCircleOutline,
    IoPersonOutline,
} from "react-icons/io5";
import { useQuery } from "react-query";
import { generatePath, NavLink, useNavigate } from "react-router-dom";
import { chatInboxService } from "services/chatInbox.service";
import { vendorNotificationService } from "services/vendor-notification.service";
import { vendorService } from "services/vendor.service";
import { useAuthContext } from "../../../context/AuthContext";
import ROUTES from "../../../shared/constants/routes.constant";
import { DropdownItem } from "./DropdownItem";
import { HeaderIcon } from "./HeaderIcon";
import { nav } from "./nav";
interface AdminHeaderProps {
    onOpen: (bool: any) => void;
}

export const AdminHeader: React.FC<any> = (props: AdminHeaderProps) => {
    const auth = useAuthContext();

    const isAdmin = auth.authUser?.isAdmin;
    const isVendor = auth.authUser?.isVendor;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { onOpen } = props;
    const menuClasses =
        "flex flex-grow h-full items-center  my-0 block text-xl font-bold text-primary hover:bg-transparent transition ease-in duration-100 no-underline relative";

    const [hasNewNotification, setHasNewNotification] = useState(false);
    const [hasNewUnreadMessage, setHasNewUnreadMessage] = useState(false);
    useEffect(() => {
        if (isAdmin) return;
        const unsub = vendorNotificationService.subscribeHasNewNotification(
            auth.authUser.userId,
            setHasNewNotification,
            "dateTimeCreated",
            "desc",
        );
        const unsubVendor = vendorService.subScribeVendorInactive(
            auth.authUser.userId,
            async (doc: DocumentData) => {
                const vendor = doc.data() as Vendor;
                if (!vendor)
                    auth.logout(() => {
                        navigate(ROUTES.SignIn);
                    });

                if (vendor.active !== undefined && vendor.active === false)
                    auth.logout(() => {
                        navigate(ROUTES.SignIn);
                    });
            },
        );
        return () => {
            unsub();
            unsubVendor();
        };
    }, []);

    const [latestInbox, setLatestInbox] = useState<VendorInbox>(null);
    const [messageURL, setMessageURL] = useState<string>(ROUTES.Messages);
    useEffect(() => {
        if (isAdmin) return;
        const unsub = chatInboxService.subscribeFirstUnreadInboxes(
            auth.authUser.userId,
            snapShotFirstUnreadInboxes,
        );
        return unsub;
    }, []);

    const snapShotFirstUnreadInboxes = async (
        querySnapshot: QuerySnapshot<DocumentData>,
    ) => {
        const inboxes = await querySnapshot.docs.map((doc) => {
            const inbox = doc.data();
            return inbox as VendorInbox;
        });
        setLatestInbox(inboxes.length > 0 ? inboxes[0] : null);
    };

    useEffect(() => {
        if (latestInbox !== null) {
            setHasNewUnreadMessage(
                latestInbox &&
                    latestInbox.unreadBookingIds &&
                    latestInbox.unreadBookingIds.length > 0,
            );
        }
    }, [latestInbox]);

    const latestInboxQuery = useQuery(
        "latestInbox",
        () => {
            if (isAdmin) return null;
            return chatInboxService.getLatestInbox(auth.authUser.userId);
        },
        {
            refetchOnWindowFocus: false,
        },
    );
    useEffect(() => {
        if (latestInboxQuery.data != null) {
            setMessageURL(
                generatePath(ROUTES.Messages, {
                    chatRoomId: latestInboxQuery.data.id,
                    bookingId: latestInboxQuery.data.lastVisitBookingId,
                }),
            );
        } else setMessageURL(ROUTES.Messages);
    }, [latestInboxQuery, latestInbox]);

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <AppBar
                    position="fixed"
                    color="transparent"
                    sx={{
                        height: 62,
                        width: { lg: "100%" },
                        boxShadow: "0 3px 6px 4px rgb(0,0,0,0.06)",
                    }}
                >
                    <Toolbar className="px-4 bg-primary-200 lg:px-10">
                        {isVendor && (
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                edge="start"
                                onClick={onOpen}
                                sx={{ mr: 1, display: { lg: "none" } }}
                            >
                                <AiOutlineMenu />
                            </IconButton>
                        )}
                        <NavLink
                            to={isVendor ? ROUTES.Dashboard : ROUTES.Vendors}
                            className="flex items-center"
                        >
                            <Logo
                                width="84"
                                height="28"
                                alt="Joes & Cos Let's Cowork Together"
                                className="w-[84px]"
                                src="/images/jc-logo.svg"
                            />
                        </NavLink>
                        {isAdmin && (
                            <>
                                <Divider
                                    orientation="vertical"
                                    className="mx-2 border-r-2 sm:mx-4 max-h-9"
                                />
                                <div className="text-base font-bold text-grey-500 sm:text-lg">
                                    Administration
                                </div>
                            </>
                        )}
                        <Box className="flex items-baseline justify-end flex-grow h-full">
                            {isVendor && (
                                <div className="items-baseline justify-center hidden h-full mx-auto lg:flex">
                                    <ul className="lg:flex list-none p-0 m-0 h-full space-x-[64px]">
                                        {nav.map(
                                            (page) =>
                                                auth.hasPermission(
                                                    page.allowRoles,
                                                ) && (
                                                    <li
                                                        key={page.name}
                                                        className="h-full"
                                                    >
                                                        <NavLink
                                                            to={page.path}
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                isActive
                                                                    ? menuClasses +
                                                                      `text-primary hover:text-primary relative menu-active`
                                                                    : menuClasses
                                                            }
                                                        >
                                                            {page.name}
                                                        </NavLink>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                </div>
                            )}
                            <div className="flex items-center justify-center h-full space-x-2 md:space-x-4 nav-icons">
                                {isVendor && (
                                    <>
                                        <NavLink
                                            to={messageURL}
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "nav-icon-active"
                                                    : ""
                                            }
                                        >
                                            <HeaderIcon
                                                children={<IoMailOutline />}
                                                hasNotification={
                                                    hasNewUnreadMessage
                                                }
                                            />
                                        </NavLink>
                                        <NavLink
                                            to={ROUTES.Notifications}
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "nav-icon-active"
                                                    : ""
                                            }
                                        >
                                            <HeaderIcon
                                                children={
                                                    <IoNotificationsOutline />
                                                }
                                                hasNotification={
                                                    hasNewNotification
                                                }
                                            />
                                        </NavLink>
                                    </>
                                )}
                                <HeaderIcon
                                    children={<IoPersonOutline />}
                                    id="basic-button"
                                    aria-controls={
                                        openMenu ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        openMenu ? "true" : undefined
                                    }
                                    onClick={handleClick}
                                    buttonclass={
                                        openMenu ? "nav-icon-active" : ""
                                    }
                                />
                            </div>
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleClose}
                                id="basic-menu"
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                {isVendor && (
                                    <div>
                                        <DropdownItem
                                            label="Profile"
                                            icon={<IoPersonCircleOutline />}
                                            url={ROUTES.Profile}
                                        />
                                        <Divider className="!m-0" />
                                        <DropdownItem
                                            label="Account"
                                            icon={<IoDocumentTextOutline />}
                                            url={ROUTES.Account}
                                        />
                                        <Divider className="!m-0" />
                                        <DropdownItem
                                            label="Logout"
                                            icon={
                                                <IoExitOutline className="relative left-0.5" />
                                            }
                                            onClick={() =>
                                                auth.logout(() => {
                                                    auth.authUser = null;
                                                    auth.user = null;
                                                    navigate(ROUTES.SignIn);
                                                })
                                            }
                                        />
                                    </div>
                                )}
                                {isAdmin && (
                                    <div>
                                        <div className="flex items-center px-4 h-[38px] text-lg">
                                            {auth.user?.email}
                                        </div>
                                        <Divider className="!m-0" />
                                        <DropdownItem
                                            label="Logout"
                                            onClick={() =>
                                                auth.logout(() => {
                                                    navigate(ROUTES.SignIn);
                                                })
                                            }
                                        />
                                    </div>
                                )}
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
};

AdminHeader.propTypes = {} as any;

export * from "./DropdownItem";
export * from "./HeaderIcon";
export * from "./nav";
