import React from "react";
import { AdminLayoutProps } from "../types";

// import AdminLayoutProps from "../types";

export const AdminLayout: React.FC<AdminLayoutProps> = (props) => {
  const { classes, children } = props;
  return (
    <div className={`min-h-screen flex flex-col bg-white pt-[62px] ${classes}`}>
      {children}
    </div>
  );
};

export * from "./AdminPageTitle";
export * from "./AdminSingleBlockLayout";
export * from "./AdminContentContainer";
