import {
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from "@mui/material";
import {
    AdminContentContainer,
    AdminSingleBlockLayout,
} from "components/Layouts";
import { AdminPageTitle } from "components/Layouts/AdminLayout/AdminPageTitle";
import TableVendors from "components/UI/SuperAdmin/VendorsList/TableVendors";
import { useAuthContext } from "context/AuthContext";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { BsSearch } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import ROUTES from "shared/constants/routes.constant";
const VendorsPage = () => {
    const auth = useAuthContext();
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    const onSearchHandler = () => {
        console.log("---", searchTerm);

        let path = ROUTES.Vendors;
        if (searchTerm) {
            path += `?query=${searchTerm}`;
        }
        navigate(path);
    };
    return (
        <>
            <Helmet>
                <title>Joes & Cos | Vendors</title>
            </Helmet>
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <AdminPageTitle pageTitle="Vendors list">
                        <div className="flex items-center justify-between mt-5 space-x-4">
                            <OutlinedInput
                                placeholder="Search"
                                id="search_vendor"
                                inputProps={{ maxLength: 50 }}
                                aria-describedby="search-vendor"
                                className="w-full max-w-[240px]"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            className="hover:bg-transparent"
                                            onClick={() => onSearchHandler()}
                                        >
                                            <BsSearch className="w-4 h-4 text-primary-dark" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(event: any) => {
                                    setSearchTerm(event.target.value);
                                }}
                                onKeyDown={(event: any) => {
                                    if (event.key === "Enter") {
                                        onSearchHandler();
                                    }
                                }}
                            />
                            <NavLink to={ROUTES.CreateVendor}>
                                <Button
                                    variant="contained"
                                    className="min-w-[140px]"
                                >
                                    Create
                                </Button>
                            </NavLink>
                        </div>
                    </AdminPageTitle>
                    <TableVendors userId={auth.authUser.userId} />
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};

export default VendorsPage;
