import { Divider } from "@mui/material";
import {
    AdminContentContainer,
    AdminPageTitle,
    AdminSingleBlockLayout,
} from "components/Layouts";
import { Notification } from "components/Notification";
import { Helmet } from "react-helmet-async";

const NotificationsPage = () => {
    return (
        <>
            <Helmet>
                <title>Joes & Cos | Notifications</title>
            </Helmet>
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <div className="max-w-[720px] mx-auto">
                        <AdminPageTitle pageTitle="Notifications" />
                        <Divider className="mb-2" />
                        <Notification onlyUnread={false}></Notification>
                    </div>
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};

export default NotificationsPage;
