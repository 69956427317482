import { FormControl, FormHelperText, Grid, Typography } from "@mui/material";
import { TextInput } from "components/UI";
import { FormikProps } from "formik";
import { AccountInformationModel } from "models/account-information.model";

export interface PaymentAccountInformationProps {
    formik: FormikProps<AccountInformationModel>;
    isReadOnly: boolean;
}

export const PaymentAccountInformation = (
    props: PaymentAccountInformationProps,
) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
    } = props.formik;
    const isReadOnly = props.isReadOnly;

    return (
        <>
            <Typography variant="h3" color="initial" className="mb-5">
                Payment Method
            </Typography>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={
                            !!errors.payment?.regNumber &&
                            touched.payment?.regNumber
                        }
                    >
                        <TextInput
                            label="Reg. number"
                            placeholder=""
                            id="payment.regNumber"
                            name="payment.regNumber"
                            aria-describedby="regNo-help-text"
                            value={values.payment?.regNumber}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.payment?.regNumber &&
                            errors.payment?.regNumber && (
                                <FormHelperText
                                    id="regNumber-help-text"
                                    className="mx-0 my-1"
                                >
                                    {errors.payment?.regNumber}
                                </FormHelperText>
                            )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl
                        variant="standard"
                        className="mb-4 md:mb-8"
                        error={
                            !!errors.payment?.bankAccountNumber &&
                            touched.payment?.bankAccountNumber
                        }
                    >
                        <TextInput
                            label="Bank account number"
                            placeholder=""
                            id="payment.bankAccountNumber"
                            name="payment.bankAccountNumber"
                            aria-describedby="bankNo-help-text"
                            value={values.payment?.bankAccountNumber}
                            disabled={isReadOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showRequiredSymbol
                        />
                        {touched.payment?.bankAccountNumber &&
                            errors.payment?.bankAccountNumber && (
                                <FormHelperText
                                    id="bankAccountNumber-help-text"
                                    className="mx-0 my-1"
                                >
                                    {errors.payment?.bankAccountNumber}
                                </FormHelperText>
                            )}
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export * from "./PaymentAccountInformationSkeleton";
