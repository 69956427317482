import { VendorType } from "enum/vendor.enum";

export interface VendorProfile {
    bio?: string;
    firstName?: string;
    lastName?: string;
    image: Image;
    vendorType: VendorType;
    name: string;
    company?: string;
}
export interface Image {
    imageReference: string;
    imageUrl: string;
    file?: File | null;
}

export const defaultVendorProfile = {
    bio: "",
    firstName: "",
    lastName: "",
    image: {
        imageReference: "",
        imageUrl: "",
        file: null,
    } as Image,
    vendorType: VendorType.Company,
    name: "",
    company: "",
};
