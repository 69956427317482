import { FormControl, FormHelperText, TextareaAutosize } from "@mui/material";
import { SectionTitle } from "components";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import MESSAGES from "shared/constants/messages.constant";

export const MeetingRoomDescription = (props: FormikProps<MeetingRoom>) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
    } = props;
    return (
        <>
            <FormControl
                variant="standard"
                className="flex flex-col mb-4"
                error={!!errors.description && touched.description}
            >
                <SectionTitle
                    title="Meeting room description"
                    helpText={MESSAGES.LISTING_MEETING_ROOM_DESC_TOOLTIP}
                    hasTooltip
                    tooltipPosition="bottom"
                    showRequiredSymbol
                />
                <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Enter room description"
                    minRows="4"
                    id="description"
                    name="description"
                    defaultValue={values.description}
                    className={
                        !!errors.description && touched.description
                            ? "error"
                            : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {touched.description && errors.description && (
                    <FormHelperText
                        id="description-help-text"
                        className="mx-0 my-1"
                    >
                        {errors.description}
                    </FormHelperText>
                )}
            </FormControl>
        </>
    );
};
