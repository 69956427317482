import {
    API_ENDPOINT_MEETING_ROOM,
    API_ENDPOINT_ฺCANCELLATION_POLICY,
} from "shared/constants/api.constant";
import { PublishStatus } from "enum/publish-status.enum";
import { CancellationPolicy } from "models/cancellation-policy.model";
import { MeetingRoom } from "models/meeting-room.model";
import { PaginationModel } from "models/pagination.model";
import { requestService } from "./request.service";

const getAllCancellationPolicy = async (): Promise<CancellationPolicy[]> => {
    return (await requestService.onCall(
        API_ENDPOINT_ฺCANCELLATION_POLICY.getAllCancellationPolicies,
    )) as CancellationPolicy[];
};

const getPrefillListing = async (): Promise<MeetingRoom> => {
    return (await requestService.onCall(
        API_ENDPOINT_MEETING_ROOM.getPrefillMeetingRoom,
    )) as MeetingRoom;
};

const getListingById = async (id: string): Promise<MeetingRoom> => {
    return (await requestService.onCall(
        API_ENDPOINT_MEETING_ROOM.getMeetingRoomById,
        {
            id: id,
        },
    )) as MeetingRoom;
};

const getMeetingRooms = async (
    lastId: string[],
    amount: number,
): Promise<PaginationModel<MeetingRoom[]>> => {
    return requestService.onCall(API_ENDPOINT_MEETING_ROOM.getMeetingRooms, {
        lastId,
        amount,
    }) as Promise<PaginationModel<MeetingRoom[]>>;
};

const addOrUpdateListing = async (
    data: MeetingRoom,
    status: PublishStatus,
): Promise<MeetingRoom> => {
    try {
        if (data.id) {
            return (await requestService.onCall(
                API_ENDPOINT_MEETING_ROOM.updateMeetingRoom,
                {
                    meetingRoom: data,
                    status: status,
                },
            )) as MeetingRoom;
        }
        return (await requestService.onCall(
            API_ENDPOINT_MEETING_ROOM.addMeetingRoom,
            {
                meetingRoom: data,
                status: status,
            },
        )) as MeetingRoom;
    } catch (exception) {
        throw exception;
    }
};

const removeMeetingRoom = async (id: string): Promise<boolean> => {
    if (id) {
        return (await requestService.onCall(
            API_ENDPOINT_MEETING_ROOM.removeMeetingRoom,
            {
                id: id,
            },
        )) as boolean;
    }
    return (await requestService.onCall(
        API_ENDPOINT_MEETING_ROOM.removeMeetingRoom,
    )) as boolean;
};

export const listingService = {
    getAllCancellationPolicy,
    getListingById,
    getPrefillListing,
    addOrUpdateListing,
    getMeetingRooms,
    removeMeetingRoom,
};
