import { LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import { FormControlLabel, Switch } from "@mui/material";
import { DEFAULT_END_TIME, DEFAULT_START_TIME } from "appSettings";
import TimePicker from "components/ListingForm/OpeningHours/TimePicker";
import { FormikErrors, FormikProps } from "formik";
import { dateTimeHelper } from "helpers/date-time.helper";
import { MeetingRoom, OpeningHours } from "models";
import { useState } from "react";
import {
    defaultEndTime,
    defaultStartTime,
} from "shared/constants/shared.constant";

export const OpeningHour = (props: FormikProps<MeetingRoom>) => {
    const { errors, values, setFieldValue, touched } = props;
    const [defaultStartTimeState, setDefaultStartTimeState] =
        useState<string>(DEFAULT_START_TIME);
    const [defaultEndTimeState, setDefaultEndTimeState] =
        useState<string>(DEFAULT_END_TIME);
    const handleToggle = async (
        index: number,
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setFieldValue(
            `openingHours[${index}].isOpen`,
            !values.openingHours[index].isOpen,
        );

        if (e.target.checked) {
            await setFieldValue(
                `openingHours[${index}].openingTime`,
                defaultStartTimeState ?? DEFAULT_START_TIME,
            );
            await setFieldValue(
                `openingHours[${index}].closingTime`,
                defaultEndTimeState ?? DEFAULT_END_TIME,
            );
        } else {
            await setFieldValue(
                `openingHours[${index}].openingTime`,
                DEFAULT_START_TIME ?? defaultStartTime,
            );
            await setFieldValue(
                `openingHours[${index}].closingTime`,
                DEFAULT_END_TIME ?? defaultEndTime,
            );
        }
    };
    return (
        <>
            {values.openingHours &&
                values.openingHours.map((openingHour, index) => (
                    <div
                        key={`openingHour-${index}`}
                        className="opening-hour-wrapper sm:space-x-8"
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    id={`openingHours[${index}].isOpen`}
                                    name={`openingHours[${index}].isOpen`}
                                    checked={values.openingHours[index].isOpen}
                                    onClick={(e: any) => handleToggle(index, e)}
                                />
                            }
                            label={values.openingHours[index].dayOfWeek}
                            className="min-w-[160px] mr-0 pointer-events-auto"
                        />
                        <LocalizationProvider dateAdapter={DateAdapter}>
                            {values.openingHours[index].isOpen && (
                                <div className="flex space-x-2">
                                    <TimePicker
                                        id={`openingHours[${index}].openingTime`}
                                        startName={`openingHours[${index}].openingTime`}
                                        onChangeStart={async (
                                            newValue: Date,
                                        ) => {
                                            const timeStringValue =
                                                dateTimeHelper.convertDateToTimeString(
                                                    newValue,
                                                );
                                            await setFieldValue(
                                                `openingHours[${index}].openingTime`,
                                                timeStringValue,
                                            );
                                            setDefaultStartTimeState(
                                                timeStringValue,
                                            );
                                        }}
                                        startTime={dateTimeHelper.convertTimeStringToDate(
                                            values.openingHours[index]
                                                .openingTime,
                                        )}
                                        isStartError={
                                            !!(
                                                errors &&
                                                errors.openingHours &&
                                                errors.openingHours[index] &&
                                                (
                                                    errors.openingHours[
                                                        index
                                                    ] as FormikErrors<OpeningHours>
                                                )?.openingTime
                                            )
                                        }
                                        startErrorMessage={
                                            errors &&
                                            errors.openingHours &&
                                            errors.openingHours[index] &&
                                            (
                                                errors.openingHours[
                                                    index
                                                ] as FormikErrors<OpeningHours>
                                            )?.openingTime
                                        }
                                        endName={`openingHours[${index}].closingTime`}
                                        onChangeEnd={async (newValue: Date) => {
                                            const timeStringValue =
                                                dateTimeHelper.convertDateToTimeString(
                                                    newValue,
                                                );
                                            await setFieldValue(
                                                `openingHours[${index}].closingTime`,
                                                timeStringValue,
                                            );
                                            setDefaultEndTimeState(
                                                timeStringValue,
                                            );
                                        }}
                                        endTime={dateTimeHelper.convertTimeStringToDate(
                                            values.openingHours[index]
                                                .closingTime,
                                        )}
                                        isEndError={
                                            !!(
                                                errors &&
                                                errors.openingHours &&
                                                errors.openingHours[index] &&
                                                (
                                                    errors.openingHours[
                                                        index
                                                    ] as FormikErrors<OpeningHours>
                                                )?.closingTime
                                            )
                                        }
                                        endErrorMessage={
                                            errors &&
                                            errors.openingHours &&
                                            errors.openingHours[index] &&
                                            (
                                                errors.openingHours[
                                                    index
                                                ] as FormikErrors<OpeningHours>
                                            )?.closingTime
                                        }
                                    />
                                </div>
                            )}
                        </LocalizationProvider>
                    </div>
                ))}
        </>
    );
};
