import { SystemMessageType } from "models/chat.model";
import {
    USERNAME_PLACEHOLDER,
    VENDOR_SYSTEM_MESSAGES
} from "shared/constants/messages.constant";

export const getSystemMessageBySystemMessageType = (
    systemMessageType: SystemMessageType,
    userName: string,
) => {
    return VENDOR_SYSTEM_MESSAGES[systemMessageType]?.replace(
        USERNAME_PLACEHOLDER,
        userName,
    );
};
