import { Typography } from "@mui/material";
import { SubmitStatus } from "models/chat.model";
import { IoWarningOutline } from "react-icons/io5";
import { ChatProps } from "./chat";

import { memo } from "react";
const ChatBubbleRight = (props: ChatProps) => {
    return (
        <>
            <div className="flex justify-end">
                <div className="flex flex-col items-end ml-4">
                    <div
                        className={`max-w-[400px] px-4 py-2 border border-solid border-grey-400 rounded-[10px] ${
                            props.status === SubmitStatus.failed &&
                            "bg-grey-400"
                        }`}
                    >
                        <p
                            className={`my-0 text-sm font-semibold whitespace-pre-line text-secondary xl:text-base ${
                                props.status === SubmitStatus.failed &&
                                "!text-grey-500"
                            }`}
                        >
                            {props.message}
                        </p>
                    </div>
                    {props.status === SubmitStatus.failed ? (
                        <Typography
                            variant="body1"
                            color="secondary"
                            className="text-grey-500 text-[12px] mt-0.5"
                        >
                            <IoWarningOutline className="text-lg relative top-0.5 mr-1" />
                            Not Delivered
                        </Typography>
                    ) : (
                        <Typography
                            variant="body1"
                            color="secondary"
                            className="text-grey-500 text-[12px] mt-0.5"
                        >
                            {props.date}
                        </Typography>
                    )}
                </div>
            </div>
        </>
    );
};

export default memo(ChatBubbleRight);
