import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { JCSnackbar, JCTooltip, Logo } from "components";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoEyeOff, IoEyeSharp } from "react-icons/io5";
import MESSAGES from "shared/constants/messages.constant";
import * as Yup from "yup";
import { useAuthContext } from "../../../context/AuthContext";
import { TextInput } from "../TextInput";

interface State {
    showPassword: boolean;
}

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email(MESSAGES.LOGIN_VALIDATION_EMAIL_INVALID)
        .max(50, MESSAGES.LOGIN_VALIDATION_EMAIL_MAX)
        .required(MESSAGES.LOGIN_VALIDATION_EMAIL_REQUIRED),
    password: Yup.string()
        .min(2, MESSAGES.LOGIN_VALIDATION_PASSWORD_MIN)
        .max(50, MESSAGES.LOGIN_VALIDATION_PASSWORD_MAX)
        .required(MESSAGES.LOGIN_VALIDATION_PASSWORD_REQUIRED),
});

export const SignInForm = () => {
    const auth = useAuthContext();

    const [openTooltip, setOpenTooltip] = useState(false);
    const [openSnackbarError, setOpenSnackbarError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setOpenSnackbarError(auth.isLoginFail);
    }, [auth.isLoginFail]);

    return (
        <Box sx={{ display: "flex", minHeight: "100vh", alignItems: "center" }}>
            <div className="flex-1 py-4">
                <div className="max-w-lg mx-auto">
                    <div className="p-2 mb-8 text-center">
                        <Logo
                            width="178"
                            height="80"
                            alt="Joes & Cos Let's Cowork Together"
                        />
                    </div>
                    <div className="max-w-[350px] mx-auto">
                        <Formik
                            initialValues={{
                                password: "",
                                email: "",
                            }}
                            validationSchema={LoginSchema}
                            onSubmit={(values) => {
                                auth.login(
                                    values.email,
                                    values.password,
                                    () => {},
                                );
                            }}
                        >
                            {({
                                errors,
                                touched,
                                handleSubmit,
                                isValid,
                                handleChange,
                                handleBlur,
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    className="space-y-5 rounded-lg md:space-y-8"
                                >
                                    <FormControl
                                        variant="standard"
                                        error={!!errors.email && touched.email}
                                        className="mb-4 md:mb-8"
                                    >
                                        <TextInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Email address"
                                            placeholder="Enter your email"
                                            id="login_email"
                                            name="email"
                                            aria-describedby="login-email"
                                        />
                                        {touched.email && errors.email && (
                                            <FormHelperText
                                                id="login-email"
                                                className="mx-0 my-1"
                                            >
                                                {errors.email}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        variant="standard"
                                        error={
                                            !!errors.password &&
                                            touched.password
                                        }
                                        className="mb-4 md:mb-8"
                                    >
                                        <TextInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Password"
                                            placeholder="Enter your password"
                                            id="login_password"
                                            name="password"
                                            aria-describedby="login-password"
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => {
                                                            setShowPassword(
                                                                !showPassword,
                                                            );
                                                        }}
                                                        onMouseDown={(
                                                            event: React.MouseEvent<HTMLButtonElement>,
                                                        ) => {
                                                            event.preventDefault();
                                                        }}
                                                        edge="end"
                                                        sx={{
                                                            fontSize: 20,
                                                            color: "#b5b5b5 !important",
                                                        }}
                                                    >
                                                        {showPassword ? (
                                                            <IoEyeOff />
                                                        ) : (
                                                            <IoEyeSharp />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {touched.password && errors.password && (
                                            <FormHelperText
                                                id="login-password"
                                                className="mx-0 my-1"
                                            >
                                                {errors.password}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        fullWidth
                                        disabled={!isValid}
                                    >
                                        Login
                                    </Button>

                                    <div className="flex items-center justify-center">
                                        <p className="my-0 mr-1 text-sm font-bold">
                                            Forgot password
                                        </p>
                                        <JCTooltip
                                            title={
                                                <>
                                                    <span className="m-0 font-bold">
                                                        You must reset your
                                                        password on
                                                        <br />
                                                        the Joes & Cos app's
                                                        login screen.
                                                    </span>
                                                </>
                                            }
                                            tooltipOpen={openTooltip}
                                            onClose={() =>
                                                setOpenTooltip(false)
                                            }
                                            placement="right"
                                        >
                                            <Button
                                                variant="text"
                                                sx={{
                                                    minWidth: "auto",
                                                    p: 0,
                                                    "&:hover, &:focus ,&:active":
                                                        {
                                                            backgroundColor:
                                                                "transparent",
                                                        },
                                                }}
                                                className="group"
                                                onClick={() =>
                                                    setOpenTooltip(true)
                                                }
                                            >
                                                <AiOutlineInfoCircle className="group-hover:text-primary-light btn-transition" />
                                            </Button>
                                        </JCTooltip>
                                    </div>
                                    <Divider />
                                    <div className="flex items-center justify-center">
                                        <p className="my-0 text-sm font-bold">
                                            Need help?
                                            <a
                                                href="mailto:hello@joesandcos.com"
                                                className="ml-1 underline text-primary"
                                                data-test="contact-email"
                                            >
                                                Contact support
                                            </a>
                                        </p>
                                    </div>
                                </form>
                            )}
                        </Formik>
                        <JCSnackbar
                            open={openSnackbarError}
                            onClose={() => setOpenSnackbarError(false)}
                            vertical={"bottom"}
                            horizontal={"right"}
                            severity="error"
                            description="The email address or password is incorrect"
                        ></JCSnackbar>
                    </div>
                </div>
            </div>
        </Box>
    );
};
