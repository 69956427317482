import { JCSnackbarProps } from "components/UI/Snackbar";
import React, { useState } from "react";
import { NotificationContext } from "../context/NotificationContext";

export const NotificationProvider: React.FC = ({ children }) => {
    const [notification, setNotification] = useState({} as JCSnackbarProps);

    const showSuccess = (description: string) => {
        setNotification({
            open: true,
            description: description,
            severity: "success",
        } as JCSnackbarProps);
    };

    const showError = (description: string) => {
        setNotification({
            open: true,
            description: description,
            severity: "error",
        } as JCSnackbarProps);
    };

    const clear = () => {
        setNotification(
            (previousState: JCSnackbarProps) =>
                ({
                    open: false,
                    description: previousState.description,
                    severity: previousState.severity,
                } as JCSnackbarProps),
        );
    };

    const notificationContext = {
        showSuccess,
        showError,
        clear,
        notification,
    };

    return (
        <NotificationContext.Provider value={notificationContext}>
            {children}
        </NotificationContext.Provider>
    );
};
