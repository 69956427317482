import {
    Button,
    DialogProps,
    FormControl,
    FormHelperText,
    Stack,
    TextareaAutosize,
    Typography,
} from "@mui/material";
import { BaseModal, TextInput } from "components";
import IconPicker from "components/UI/IconPicker";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { Formik } from "formik";
import { Amenity } from "models";
import { useEffect, useState } from "react";
import MESSAGES from "shared/constants/messages.constant";
import * as Yup from "yup";

export const enum AmenityModalMode {
    edit,
    addNew,
}
export interface AmenityModalSetup {
    mode: AmenityModalMode;
    initValue?: Amenity;
    action?: (amenity: Amenity) => void;
}
export interface AddAmenitiesModalProps extends DialogProps {
    closeFn?: () => void;
    modalSetup?: AmenityModalSetup;
}
const inputValidation = Yup.object().shape({
    title: Yup.string().required(
        MESSAGES.LISTING_VALIDATION_MEETING_ROOM_AMENITIES_NAME_REQUIRED,
    ),
    description: Yup.string().required(
        MESSAGES.LISTING_VALIDATION_MEETING_ROOM_AMENITIES_DESCRIPTION_REQUIRED,
    ),
    icon: Yup.string().required(
        MESSAGES.LISTING_VALIDATION_MEETING_ROOM_AMENITIES_ICON_REQUIRED,
    ),
});

const AddAmenitiesModal: React.FC<AddAmenitiesModalProps> = (props) => {
    const { modalSetup } = props;
    const isEdit = modalSetup?.mode === AmenityModalMode.edit ?? false;
    const [isLoading, setIsLoading] = useState(false);
    const fullPageLoaderContext = useFullPageLoadingContext();

    useEffect(() => {
        setIsLoading(false);
    }, [modalSetup?.initValue]);

    useEffect(() => {
        if (isLoading) fullPageLoaderContext.show();
        else fullPageLoaderContext.hide();
    }, [isLoading]);

    return (
        <BaseModal
            title={isEdit ? "Edit amenity" : "Add new amenity"}
            open={props.open}
        >
            <Formik
                initialValues={{
                    id: modalSetup?.initValue?.id ?? "",
                    icon: modalSetup?.initValue?.icon ?? "",
                    title: modalSetup?.initValue?.title ?? "",
                    description: modalSetup?.initValue?.description ?? "",
                }}
                validationSchema={inputValidation}
                onSubmit={(values: Amenity) => {
                    setIsLoading(true);
                    modalSetup.action(values);
                }}
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl
                            variant="standard"
                            error={
                                !!formik.errors.title && formik.touched.title
                            }
                            className="mb-4 md:mb-7"
                        >
                            <Typography
                                variant="body2"
                                className="mb-1 font-bold"
                            >
                                Amenity name
                            </Typography>
                            <TextInput
                                id="title"
                                name="title"
                                placeholder=""
                                style={{ width: "100%" }}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.title && formik.errors.title && (
                                <FormHelperText
                                    id="amenity-name-help-text"
                                    className="mx-0 my-1"
                                >
                                    {formik.errors.title}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            variant="standard"
                            error={!!formik.errors.icon && formik.touched.icon}
                            className="mb-4 md:mb-7"
                        >
                            <Typography
                                variant="body2"
                                className="mb-1 font-bold"
                            >
                                Select an icon
                            </Typography>
                            <IconPicker
                                icon={formik.values.icon}
                                formik={formik}
                            ></IconPicker>
                            {formik.touched.icon && formik.errors.icon && (
                                <FormHelperText
                                    id="amenity-icon-help-text"
                                    className="mx-0 mt-0 mb-2"
                                >
                                    {formik.errors.icon}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            variant="standard"
                            error={
                                !!formik.errors.description &&
                                formik.touched.description
                            }
                            className="mb-4 md:mb-6"
                        >
                            <Typography
                                variant="body2"
                                className="mb-1 font-bold"
                            >
                                Amenity description
                            </Typography>
                            <TextareaAutosize
                                id="description"
                                name="description"
                                placeholder=""
                                minRows={3}
                                maxRows={6}
                                style={{ width: "100%" }}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.description &&
                                formik.errors.description && (
                                    <FormHelperText
                                        id="amenity-description-help-text"
                                        className="mx-0 mt-0 mb-2"
                                    >
                                        {formik.errors.description}
                                    </FormHelperText>
                                )}
                        </FormControl>

                        {/* Modal Footer */}
                        <Stack
                            className="mt-4"
                            spacing={2}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Button
                                className="min-w-[100px]"
                                type="submit"
                                variant="contained"
                                disabled={
                                    !formik.dirty ||
                                    !formik.isValid ||
                                    formik.isSubmitting
                                }
                            >
                                {isEdit ? "Update" : "Add"}
                            </Button>
                            <Button
                                className="min-w-[100px]"
                                variant="outlined"
                                onClick={props.closeFn}
                            >
                                Close
                            </Button>
                        </Stack>
                    </form>
                )}
            </Formik>
        </BaseModal>
    );
};

export default AddAmenitiesModal;
