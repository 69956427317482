import { Divider, Typography } from "@mui/material";
import { FullPageLoading } from "components";
import { AccountInformation } from "components/AccountInformation";
import { GeneralAccountInformationSkeleton } from "components/AccountInformationForm/GeneralAccountInformation/GeneralAccountInformationSkeleton";
import { PaymentAccountInformationSkeleton } from "components/AccountInformationForm/PaymentAccountInformation/PaymentAccountInformationSkeleton";
import {
    AdminContentContainer,
    AdminPageTitle,
    AdminSingleBlockLayout,
} from "components/Layouts";
import {
    VendorInformationForm,
    VendorInformationSkeleton,
} from "components/UI/SuperAdmin/VendorInformationForm";
import { Helmet } from "react-helmet-async";
import { IoChevronBack } from "react-icons/io5";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { vendorService } from "services/vendor.service";
import ROUTES from "shared/constants/routes.constant";

const VendorProfilePage = () => {
    const { id } = useParams();

    // API get account information initial data
    const { data: vendorData, isFetching: isFetchingVendorData } = useQuery(
        `vendorDetail-${id}`,
        async () => (id ? await vendorService.getVendorDetailById(id) : null),
        {
            refetchOnWindowFocus: false,
            onSuccess: async (err: any) => {},
            onError: async (err: any) => {},
        },
    );
    // API get account information initial data
    const {
        data: accountInformationData,
        isFetching: isFetchingAccountInformation,
        refetch: refetchAccountInformation,
    } = useQuery(
        `vendorAccountInformation-${id}`,
        async () =>
            id ? await vendorService.getVendorAccountInformationById(id) : null,
        {
            refetchOnWindowFocus: false,
            onSuccess: async (err: any) => {},
            onError: async (err: any) => {},
        },
    );

    if (id && (isFetchingAccountInformation || isFetchingVendorData))
        return <FullPageLoading />;

    return (
        <>
            <Helmet>
                <title>
                    Joes & Cos |{" "}
                    {vendorData ? vendorData.name : "Create Vendor"}
                </title>
            </Helmet>
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <div className="max-w-[720px] mx-auto">
                        <AdminPageTitle
                            pageTitle={
                                vendorData ? vendorData.name : "Create Vendor"
                            }
                            icon={<IoChevronBack />}
                            iconURL={ROUTES.Vendors}
                        />
                        <Divider className="mb-8" />

                        {!id || (id && !isFetchingVendorData) ? (
                            <>
                                <VendorInformationForm
                                    vendorData={vendorData}
                                    vendorId={id}
                                />
                            </>
                        ) : (
                            <VendorInformationSkeleton />
                        )}
                        {(id || accountInformationData) && (
                            <Divider className="mt-8 mb-7" />
                        )}

                        {(id || accountInformationData) && (
                            <>
                                <Typography
                                    variant="h2"
                                    className="text-[28px]"
                                >
                                    Account Information
                                </Typography>
                                {isFetchingAccountInformation ? (
                                    <>
                                        <GeneralAccountInformationSkeleton />
                                        <Divider className="mt-2 mb-5 sm:mb-6" />
                                        <PaymentAccountInformationSkeleton />
                                    </>
                                ) : (
                                    <AccountInformation
                                        accountInformationData={
                                            accountInformationData
                                        }
                                        vendorId={id}
                                        refetch={refetchAccountInformation}
                                        isReadOnly={true}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};

export default VendorProfilePage;
