import styles from "./Loading.module.scss";

export const Loading = () => {
  return (
    <div className={styles.ldsEllipsis}>
      <div className={styles.firstDot}></div>
      <div className={styles.secondDot}></div>
      <div className={styles.thirdDot}></div>
      <div className={styles.fourthDot}></div>
    </div>
  );
};

export * from "./FullPageLoading";
