import { TabContext, TabList } from "@mui/lab";
import { Box, Divider, IconButton, Tab, Typography } from "@mui/material";
import { DocumentData, QuerySnapshot } from "firebaseSetup";
import { dateTimeHelper } from "helpers/date-time.helper";
import { getConsolidateStatusTextByStatus } from "helpers/status.helper";
import { ReservationDetail } from "models/bookings.model";
import { VendorInbox } from "models/inbox.model";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { IoChevronBack, IoEllipse } from "react-icons/io5";
import { bookingService } from "services/booking.service";
import { chatService } from "services/chat.service";
import { CONSOLIDATE_STATUS } from "shared/constants/status";
import BookingsSkeleton from "./BookingsSkeleton";
import { ConsolidateStatusPanelTab } from "./ConsolidateStatusPanelTab";

export interface BookingProps {
    chatRoomId: string;
    bookingId: string;
    vendorId: string;
    userSelected: VendorInbox;
    setActiveBookingItem: (item: ReservationDetail) => void;
    showMobileMode: boolean;
    openMobileRequestList: (open: boolean) => void;
}

interface UnreadModel {
    Pending: boolean;
    Upcoming: boolean;
    Past: boolean;
}
const defaultUnread = {
    Pending: false,
    Upcoming: false,
    Past: false,
} as UnreadModel;

const IconUnreadOnTab = () => {
    return (
        <>
            <IoEllipse className="text-error text-[10px] relative -top-1.5 left-0.5" />
        </>
    );
};
export const Bookings = (props: BookingProps) => {
    const { chatRoomId, bookingId, vendorId, userSelected } = props;
    const [booking, setBooking] = useState(null);
    const [consolidateStatus, setConsolidateStatus] = useState(
        booking
            ? (booking.consolidateStatus as CONSOLIDATE_STATUS)
            : CONSOLIDATE_STATUS.Pending,
    );
    const [unread, setUnread] = useState<UnreadModel>(defaultUnread);

    useEffect(() => {
        const fetchBookingData = async () => {
            const data =
                await bookingService.getBookingByIdWithoutPayoutDetails(
                    bookingId,
                );
            if (!data) return;
            data.meetingDate = dateTimeHelper.convertFirestoreTimestampToDate(
                data.meetingDate._seconds,
                data.meetingDate._nanoseconds,
            );
            setConsolidateStatus(data.consolidateStatus as CONSOLIDATE_STATUS);
            setBooking(data);
        };
        fetchBookingData();
    }, [chatRoomId]);
    useLayoutEffect(() => {
        const unSubPending = chatService.subscribeUnreadOnConsolidateStatusTab(
            snapShotUnReadBooking,
            chatRoomId,
            CONSOLIDATE_STATUS.Pending,
        );
        const unSubUpcoming = chatService.subscribeUnreadOnConsolidateStatusTab(
            snapShotUnReadBooking,
            chatRoomId,
            CONSOLIDATE_STATUS.Upcoming,
        );
        const unSubPast = chatService.subscribeUnreadOnConsolidateStatusTab(
            snapShotUnReadBooking,
            chatRoomId,
            CONSOLIDATE_STATUS.Past,
        );

        return () => {
            setUnread(defaultUnread);
            unSubPending();
            unSubUpcoming();
            unSubPast();
        };
    }, [chatRoomId]);
    const snapShotUnReadBooking = async (
        querySnapshot: QuerySnapshot<DocumentData>,
    ) => {
        const size = querySnapshot.docs.length;
        const consolidateStatus = await querySnapshot
            .docChanges()
            .map((change) => {
                const booking = change.doc.data() as ReservationDetail;
                return booking.consolidateStatus as string;
            });
        if (consolidateStatus.length > 0)
            if (size === 0) {
                setUnread((previous) => ({
                    ...previous,
                    [consolidateStatus[0]]: false,
                }));
            } else if (size === 1) {
                setUnread((previous) => ({
                    ...previous,
                    [consolidateStatus[0]]: true,
                }));
            }
    };

    return (
        <Box
            className="fixed bg-white lg:static"
            sx={{
                maxWidth: { lg: 460 },
                width: { xs: "100%", lg: "25%" },
                top: "100%",
                height: "calc(100% - 64px)",
                transition: "all ease-in-out .3s",
                zIndex: 11,
                ...(props.showMobileMode && {
                    top: 64,
                    transition: "all ease-in-out .3s",
                }),
            }}
        >
            {!userSelected && <BookingsSkeleton />}
            {userSelected && (
                <>
                    <Typography
                        variant="h2"
                        color="initial"
                        className="relative pt-6 pr-4 mb-0 text-2xl 2xl:text-3xl pl-9 lg:px-6 3xl:px-10"
                    >
                        <IconButton
                            color="primary"
                            onClick={() => {
                                props.openMobileRequestList(false);
                            }}
                            className="absolute py-0 pl-0 -ml-2 bg-transparent lg:hidden top-[26px] left-4"
                        >
                            <IoChevronBack />
                        </IconButton>
                        <span className="inline-block max-w-full truncate">
                            {userSelected ? userSelected.userName : ""}'s
                            Requests
                        </span>
                    </Typography>
                    <Box>
                        <TabContext value={consolidateStatus}>
                            <TabList
                                onChange={(
                                    event: React.SyntheticEvent,
                                    value: CONSOLIDATE_STATUS,
                                ) => setConsolidateStatus(value)}
                                variant="fullWidth"
                                scrollButtons={false}
                                className="px-0 3xl:px-10"
                            >
                                <Tab
                                    label={
                                        <>
                                            <div className="flex items-center whitespace-nowrap">
                                                {getConsolidateStatusTextByStatus(
                                                    CONSOLIDATE_STATUS.Pending,
                                                )}
                                                {unread.Pending && (
                                                    <IconUnreadOnTab />
                                                )}
                                            </div>
                                        </>
                                    }
                                    value={CONSOLIDATE_STATUS.Pending}
                                />
                                <Tab
                                    label={
                                        <>
                                            <div className="flex items-center whitespace-nowrap">
                                                {getConsolidateStatusTextByStatus(
                                                    CONSOLIDATE_STATUS.Upcoming,
                                                )}
                                                {unread.Upcoming && (
                                                    <IconUnreadOnTab />
                                                )}
                                            </div>
                                        </>
                                    }
                                    value={CONSOLIDATE_STATUS.Upcoming}
                                />
                                <Tab
                                    label={
                                        <>
                                            <div className="flex items-center whitespace-nowrap">
                                                {getConsolidateStatusTextByStatus(
                                                    CONSOLIDATE_STATUS.Past,
                                                )}
                                                {unread.Past && (
                                                    <IconUnreadOnTab />
                                                )}
                                            </div>
                                        </>
                                    }
                                    value={CONSOLIDATE_STATUS.Past}
                                />
                            </TabList>
                            <Divider />
                            <ConsolidateStatusPanelTab
                                chatRoomId={chatRoomId}
                                bookingId={bookingId}
                                userId={userSelected.userId}
                                consolidateStatus={consolidateStatus}
                                setActiveBookingItem={(item) => {
                                    props.setActiveBookingItem(item);
                                    props.openMobileRequestList(false);
                                }}
                            />
                        </TabContext>
                    </Box>
                </>
            )}
        </Box>
    );
};
