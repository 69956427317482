import { Divider } from "@mui/material";
import { FormikProps } from "formik";
import { AccountInformationModel } from "models/account-information.model";
import { GeneralAccountInformation } from "./GeneralAccountInformation";
import { PaymentAccountInformation } from "./PaymentAccountInformation";

export interface AccountInformationFormProps {
    formik: FormikProps<AccountInformationModel>;
    isReadOnly: boolean;
}

export const AccountInformationForm = (props: AccountInformationFormProps) => {
    return (
        <>
            <GeneralAccountInformation
                formik={props.formik}
                isReadOnly={props.isReadOnly}
            />
            <Divider className="mt-2 mb-5 sm:mb-6" />
            <PaymentAccountInformation
                formik={props.formik}
                isReadOnly={props.isReadOnly}
            />
        </>
    );
};
