import {
    CONSOLIDATE_STATUS_TEXT,
    SUB_STATUS_TEXT,
} from "shared/constants/status";

export const getStatusTextByStatus = (status: string) => {
    return SUB_STATUS_TEXT[status];
};
export const getConsolidateStatusTextByStatus = (consolidateStatus: string) => {
    return CONSOLIDATE_STATUS_TEXT[consolidateStatus];
};
