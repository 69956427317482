import {
    Button,
    FormControl,
    InputLabel,
    Select,
    SelectProps,
    Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { IoInformationCircleOutline } from "react-icons/io5";
import { JCTooltip } from "../Tooltip";
interface JCSelectProps extends SelectProps {
    className?: string;
    label?: string;
    labelClassName?: string;
    placeholder?: string;
    //id: string;
    horizontal?: boolean;
    hasTooltip?: boolean;
    tooltipContent?: string;
    tooltipPosition?:
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left-start"
        | "left"
        | "right-end"
        | "right-start"
        | "right"
        | "top-end"
        | "top-start"
        | "top";
    showRequiredSymbol?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        "& .MuiSelect-outlined": {
            paddingTop: 10,
            paddingBottom: 10,
            fontFamily: "inherit",
            fontSize: "14px",
            lineHeight: "26px",
            minWidth: 160,
        },
        "& .MuiSelect-icon": {
            color: theme.palette.primary.main,
            width: 20,
            height: 20,
            top: 10,
        },
    },
}));

const JCSelect: React.FC<JCSelectProps> = ({
    children,
    value,
    defaultValue,
    label,
    labelClassName,
    className,
    id,
    horizontal,
    hasTooltip,
    tooltipContent: textTooltip,
    tooltipPosition,
    showRequiredSymbol,
    disabled,
    onChange,
    error,
    ...props
}) => {
    const classes = useStyles();
    const [openTooltip, setOpenTooltip] = useState(false);

    return (
        <>
            <FormControl
                sx={{ minWidth: 120 }}
                className={classes.root}
                error={error}
            >
                {label !== undefined && (
                    <InputLabel
                        shrink
                        disableAnimation
                        className={`font-title font-bold text-sm md:text-base static transform-none mb-1 ${labelClassName}`}
                        sx={{
                            "&.Mui-focused, &.Mui-error": {
                                color: "#2e2e2e",
                            },
                        }}
                        htmlFor={id}
                    >
                        {label}
                        {/* REVIEW: Tooltip title > Use <span> instead <div> because the console show the error when <p> wrap <div>. Normally, tooltip content renders to <p> tag we cannot use <p> or <div> inside <p> */}
                        {hasTooltip && (
                            <JCTooltip
                                title={
                                    <span
                                        className="whitespace-normal inline-block"
                                        dangerouslySetInnerHTML={{
                                            __html: textTooltip,
                                        }}
                                    />
                                }
                                placement={tooltipPosition}
                                tooltipOpen={openTooltip}
                                onClose={() => setOpenTooltip(false)}
                            >
                                <Button
                                    className="min-w-0 py-0 text-xl relative -top-0.5 px-1"
                                    onClick={() => setOpenTooltip(true)}
                                >
                                    <IoInformationCircleOutline />
                                </Button>
                            </JCTooltip>
                        )}
                        {showRequiredSymbol && (
                            <span className="text-black ml-0.5">*</span>
                        )}
                    </InputLabel>
                )}
                <Select
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    displayEmpty
                    IconComponent={FiChevronDown}
                    label={label}
                    className={`${classes.selectRoot} ${className}`}
                    id={id}
                    name={id}
                    disabled={disabled}
                >
                    {children}
                </Select>
            </FormControl>
        </>
    );
};

export default JCSelect;
