import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
} from "@mui/material";
import { SectionTitle } from "components/UI";
import JCSelect from "components/UI/SelectDropdown";
import { FormikProps } from "formik";
import { MeetingRoom } from "models/meeting-room.model";
import { useState } from "react";
import MESSAGES from "shared/constants/messages.constant";

const AddTimeBlock = (props: FormikProps<MeetingRoom>) => {
    const {
        errors,
        touched,
        handleSubmit,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
    } = props;

    const [timeBlocks, setTimeBlocks] = useState<Number[]>([15, 30, 45, 60]);
    return (
        <>
            <SectionTitle
                title="Efficiency settings"
                helpText={MESSAGES.LISTING_PRICING_TIME_BLOCK_TOOLTIP}
                tooltipPosition="right"
                hasTooltip
            />
            <FormControlLabel
                className="items-baseline sm:align-middle sm:items-baseline"
                control={
                    <Checkbox
                        id="timeBlock.isEnable"
                        name="timeBlock.isEnable"
                        checked={values.timeBlock?.isEnable ?? false}
                        value={values.timeBlock?.isEnable ?? false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="relative pt-2 self-baseline top-2"
                    />
                }
                label={
                    <FormControl
                        className="flex flex-col flex-wrap pt-2 sm:flex-row sm:items-baseline sm:pt-0"
                        error={
                            !!errors.timeBlock?.value &&
                            touched.timeBlock?.value
                        }
                    >
                        <SectionTitle
                            title="Add transition time"
                            labelClassName="mr-4"
                            labelVariantInherit
                            className="!mb-0 label-clickable"
                        />
                        <div className="flex items-center">
                            <JCSelect
                                labelId="demo-simple-select-label-2"
                                id="timeBlock.value"
                                defaultValue=""
                                name="timeBlock.value"
                                onChange={(e) =>
                                    props.setFieldValue(
                                        "timeBlock.value",
                                        Number(e.target.value),
                                    )
                                }
                                onBlur={handleBlur}
                                value={values.timeBlock?.value ?? ""}
                                disabled={!values.timeBlock?.isEnable ?? false}
                                className="pointer-events-auto"
                            >
                                {timeBlocks &&
                                    timeBlocks.map((timeBlock) => (
                                        <MenuItem
                                            value={String(timeBlock)}
                                            key={String(timeBlock)}
                                            sx={{
                                                fontSize: "14px !important;",
                                            }}
                                        >
                                            {timeBlock} mins
                                        </MenuItem>
                                    ))}
                            </JCSelect>
                        </div>
                        {touched.timeBlock?.value && errors.timeBlock?.value && (
                            <FormHelperText
                                id="timeBlock-help-text"
                                className="w-full mx-0 my-2"
                            >
                                {errors.timeBlock?.value}
                            </FormHelperText>
                        )}
                    </FormControl>
                }
            />
        </>
    );
};

export default AddTimeBlock;
