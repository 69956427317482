import { Divider } from "@mui/material";
import { FullPageLoading } from "components";
import { VendorProfileForm } from "components/EditVendorProfile";
import {
    AdminContentContainer,
    AdminPageTitle,
    AdminSingleBlockLayout,
} from "components/Layouts";
import { useAuthContext } from "context/AuthContext";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { vendorService } from "services/vendor.service";

const ProfilePage = () => {
    const auth = useAuthContext();
    const vendorId = auth.authUser.userId;
    const { data: vendor, isFetching } = useQuery(
        "",
        async () => await vendorService.getVendorProfileById(vendorId),
        {
            refetchOnWindowFocus: false,
        },
    );
    if (isFetching) return <FullPageLoading />;
    return (
        <>
            <Helmet>
                <title>Joes & Cos | Profile</title>
            </Helmet>
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <div className="max-w-[720px] mx-auto">
                        <AdminPageTitle pageTitle="Public Profile" />
                        <Divider className="mb-8" />
                        <VendorProfileForm vendor={vendor} />
                    </div>
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};

export default ProfilePage;
