import { Skeleton, TableCell, TableRow } from "@mui/material";

const TableVendorsSkeletonLoading = () => {
    return (
        <TableRow role="checkbox" tabIndex={-1}>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
        </TableRow>
    );
};

export default TableVendorsSkeletonLoading;
