import { Box } from "@mui/material";
import { ReservationDetail } from "components/ReservationDetail";
import { useAuthContext } from "context/AuthContext";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { ReservationDetail as BookingModel } from "models/bookings.model";
import { ChatMessage } from "models/chat.model";
import { defaultVendorInbox, VendorInbox } from "models/inbox.model";
import { useEffect, useState } from "react";
import { IoArrowRedoOutline, IoEyeOutline } from "react-icons/io5";
import {
    generatePath,
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import ROUTES from "shared/constants/routes.constant";
import { BaseModal } from "../UI";
import { Bookings } from "./Bookings";
import { Inboxes } from "./Inboxes";
import { Messages } from "./Messages";
import MessagesSkeleton from "./Messages/MessagesSkeleton";

export const Chat = () => {
    const [openBookingDetailModal, setOpenBookingDetailModal] = useState(false);
    const [mobileOpenRequestList, setMobileOpenRequestList] = useState(false);
    const [mobileOpenMessage, setMobileOpenMessage] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [activeInboxItem, setActiveInboxItem] = useState<VendorInbox>(null);
    const [lastMessage, setLastMessage] = useState<ChatMessage>(null);
    const [activeBookingItem, setActiveBookingItem] =
        useState<BookingModel>(null);
    const [activeBookingId, setActiveBookingId] = useState<string>("");
    let { chatRoomId, bookingId } = useParams();
    const navigate = useNavigate();
    const auth = useAuthContext();
    let vendorId = auth.authUser.userId;
    defaultVendorInbox.id = vendorId;
    const [searchParams] = useSearchParams();
    const vendorIdSearchParam = searchParams.get("vendorId");
    const vendorNameSearchParam = searchParams.get("vendorName");
    vendorId = vendorIdSearchParam ? vendorIdSearchParam : vendorId;
    const fullPageLoaderContext = useFullPageLoadingContext();

    useEffect(() => {
        if (activeInboxItem) {
            let inboxUrl = generatePath(ROUTES.Messages, {
                chatRoomId: activeInboxItem.id,
                bookingId: activeInboxItem.lastVisitBookingId,
            });
            if (vendorIdSearchParam)
                inboxUrl +=
                    "?vendorId=" +
                    vendorIdSearchParam +
                    "&vendorName=" +
                    vendorNameSearchParam;
            navigate(inboxUrl);
        }
    }, [activeInboxItem]);
    useEffect(() => {
        setMobileOpenRequestList(mobileOpenRequestList);
    }, [mobileOpenRequestList]);
    useEffect(() => {
        if (activeBookingItem) setActiveBookingId(activeBookingItem.id);
    }, [activeBookingItem]);
    useEffect(() => {
        if (isPageLoading) fullPageLoaderContext.show();
        else fullPageLoaderContext.hide();
    }, [isPageLoading]);

    return (
        <>
            {!isPageLoading && (
                <Box
                    className="relative flex overflow-hidden"
                    sx={{ height: "calc(100vh - 64px)" }}
                >
                    <Inboxes
                        setActiveInboxItem={(item: VendorInbox) =>
                            setActiveInboxItem(item)
                        }
                        setIsPageLoading={(isPageLoading: boolean) =>
                            setIsPageLoading(isPageLoading)
                        }
                        activeInboxId={
                            activeInboxItem ? activeInboxItem.id : chatRoomId
                        }
                        openMobileRequestList={(open: boolean) => {
                            setMobileOpenRequestList(open);
                            setMobileOpenMessage(true);
                        }}
                        activeBookingId={activeBookingId}
                        resetActiveBookingId={() => setActiveBookingId("")}
                        activeBookingItem={activeBookingItem}
                        lastMessage={lastMessage}
                    />

                    {!activeBookingItem && <MessagesSkeleton />}
                    {activeBookingItem && (
                        <Messages
                            activeBookingItem={activeBookingItem}
                            openMobileMessageSection={mobileOpenMessage}
                            closeMobileMessageSection={() =>
                                setMobileOpenMessage(false)
                            }
                            setMobileBookingSection={(open: boolean) =>
                                setMobileOpenRequestList(open)
                            }
                            activeInboxItem={activeInboxItem}
                            setOpenReservationDetial={(open: boolean) =>
                                setOpenBookingDetailModal(open)
                            }
                            setLastMessage={(lastMessage: ChatMessage) => {
                                setLastMessage(lastMessage);
                            }}
                        />
                    )}
                    <Bookings
                        chatRoomId={chatRoomId}
                        bookingId={bookingId}
                        vendorId={vendorId}
                        userSelected={activeInboxItem}
                        setActiveBookingItem={(booking) => {
                            setActiveBookingItem(booking);
                            setMobileOpenMessage(true);
                        }}
                        showMobileMode={mobileOpenRequestList}
                        openMobileRequestList={(open: boolean) => {
                            setMobileOpenRequestList(open);
                        }}
                    />
                </Box>
            )}
            <BaseModal
                title="Reservation Details"
                titleClassName="text-2xl xl:text-3xl"
                wrapperClassName="!pb-0"
                open={openBookingDetailModal}
                hasCloseBtn
                closeFn={() => setOpenBookingDetailModal(false)}
            >
                <ReservationDetail
                    setOpenBookingDetailModal={setOpenBookingDetailModal}
                />
            </BaseModal>
            {auth.authUser.isAdmin && (
                <div className="fixed top-1.5 left-1/2 -translate-x-1/2 bg-[#000] rounded-[40px] z-[9999] text-white text-sm sm:text-base xl:text-lg flex flex-nowrap min-w-[300px]">
                    <div className="flex items-center justify-center w-full px-2 py-1 sm:px-4 sm:pl-6 sm:w-auto whitespace-nowrap">
                        <IoEyeOutline className="mr-0.5 xl:mr-2 xl:text-2xl min-w-[20px] " />
                        <div className="flex items-center justify-center">
                            Vendor Inbox -
                            <span className="truncate max-w-[90px] sm:max-w-none sm:text-overflow-clip mx-1">
                                {vendorNameSearchParam ?? ""}
                            </span>
                            (View-only Mode)
                        </div>
                    </div>
                    <Link
                        className="flex items-center justify-center w-full py-1 pl-1 pr-2 text-white border-0 border-l border-solid sm:px-4 sm:pr-6 sm:w-auto border-grey-400"
                        to={ROUTES.Vendors}
                    >
                        <IoArrowRedoOutline className="mr-0.5 xl:mr-2 xl:text-2xl min-w-[20px] -mt-0.5" />
                        <span>Exit</span>
                    </Link>
                </div>
            )}
        </>
    );
};

export * from "./Messages";
