import { StyledEngineProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/system";
import { useAuthContext } from "context/AuthContext";
import { PageNotFound } from "pages/404";
import VendorsPage from "pages/admin/vendor-list";
import VendorProfilePage from "pages/admin/vendor-profile";
import MessagesPage from "pages/chat/messages";
import { ComponentsPage } from "pages/components";
import CreateListingPage from "pages/listing/create-listing";
import EditListingPage from "pages/listing/edit-listing";
import ListingsPage from "pages/listing/listings";
import NotificationsPage from "pages/notification/notifications";
import ReservationDetailPage from "pages/reservation/reservation-detail";
import SignInPage from "pages/sign-in";
import AccountPage from "pages/vendor/vendor-account";
import { DashboardPage } from "pages/vendor/vendor-dashboard";
import ProfilePage from "pages/vendor/vendor-profile";
import ProtectedRoute from "provider/ProtectedRoute";
import PublicRoute from "provider/PublicRoute";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import store from "redux/store";
import ROLES from "shared/constants/roles.constant";
import ROUTES from "shared/constants/routes.constant";
import "styles/simplebar.scss";
import "styles/styles.scss";
import "styles/tailwind.scss";
import theme from "styles/theme";
import { Unauthorized } from "./pages/Unauthorized";
const queryClient = new QueryClient();
function App(): JSX.Element {
    let auth = useAuthContext();
    if (
        !(
            auth?.authUser?.roles?.includes(ROLES.Vendor) ||
            auth?.authUser?.roles?.includes(ROLES.Admin)
        )
    ) {
        auth.authUser = null;
        auth.user = null;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <HelmetProvider>
                    <CssBaseline />
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <div className="App">
                                {/* <Header /> */}
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            (!auth.user && (
                                                <Navigate to={ROUTES.SignIn} />
                                            )) ||
                                            (auth.user &&
                                                auth.authUser?.isAdmin && (
                                                    <Navigate
                                                        to={ROUTES.Vendors}
                                                    />
                                                )) ||
                                            (auth.user &&
                                                auth.authUser?.isVendor && (
                                                    <Navigate
                                                        to={ROUTES.Dashboard}
                                                    />
                                                ))
                                        }
                                    />
                                    <Route
                                        path={ROUTES.SignIn}
                                        element={
                                            <PublicRoute>
                                                <SignInPage />
                                            </PublicRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.Listings}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <ListingsPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.ListCreate}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <CreateListingPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.ListEdit}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <EditListingPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.CreateVendor}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[ROLES.Admin]}
                                            >
                                                <VendorProfilePage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.EditVendorProfile}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[ROLES.Admin]}
                                            >
                                                <VendorProfilePage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.Dashboard}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <DashboardPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.Notifications}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <NotificationsPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.Messages}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <MessagesPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.Profile}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <ProfilePage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.Account}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <AccountPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.ReservationDetail}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <ReservationDetailPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.Components}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[
                                                    ROLES.Admin,
                                                    ROLES.Vendor,
                                                ]}
                                            >
                                                <ComponentsPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path={ROUTES.Vendors}
                                        element={
                                            <ProtectedRoute
                                                allowRoles={[ROLES.Admin]}
                                            >
                                                <VendorsPage />
                                            </ProtectedRoute>
                                        }
                                    ></Route>
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    ></Route>
                                    <Route
                                        path={ROUTES.PageNotFound}
                                        element={<PageNotFound />}
                                    ></Route>
                                    <Route
                                        path={ROUTES.Unauthorized}
                                        element={<Unauthorized />}
                                    ></Route>
                                </Routes>
                                {/* <Home /> */}
                            </div>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </HelmetProvider>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
