import firebase, {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateProfile,
} from "firebase/auth";
import { auth } from "firebaseSetup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ROLES from "shared/constants/roles.constant";
import ROUTES from "shared/constants/routes.constant";
import { AuthContext, AuthUser } from "../context/AuthContext";

export const AuthProvider: React.FC = ({ children }) => {
    const [authUser, setAuthUser] = useState<AuthUser>(null);
    const [loading, setLoading] = useState(true);
    const [isLoginFail, setIsLoginFail] = useState(false);
    const [user, setUser] = useState<firebase.User | null>(null);
    const navigate = useNavigate();

    const getUserRolesFromIdToken = (idTokenResult: any | null) => {
        return (idTokenResult.claims.roles || []) as string[];
    };

    useEffect(() => {
        const setUserContext = (
            firebaseUser: firebase.User | null,
            idTokenResult: any | null,
        ) => {
            if (firebaseUser == null) setAuthUser(null);
            else {
                const roles = getUserRolesFromIdToken(idTokenResult);
                const currentUser = {
                    userId: firebaseUser.uid,
                    name: firebaseUser.displayName,
                    email: firebaseUser.email,
                    roles: getUserRolesFromIdToken(idTokenResult),
                    isAdmin: roles.includes(ROLES.Admin),
                    isVendor: roles.includes(ROLES.Vendor),
                };
                setAuthUser(currentUser);
            }
        };

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);
                const idTokenResult = await user.getIdTokenResult();
                setUserContext(user, idTokenResult);
            } else {
                setUser(null);
                setUserContext(null, null);
            }
            setLoading(false);
            setIsLoginFail(false);
        });
        return unsubscribe;
    }, []);

    const login = async (
        username: string,
        password: string,
        callback: VoidFunction,
    ) => {
        try {
            setLoading(true);
            setIsLoginFail(false);

            const currentUser = await signInWithEmailAndPassword(
                auth,
                username,
                password,
            );

            const idToken = await currentUser.user.getIdTokenResult();
            if (getUserRolesFromIdToken(idToken).includes(ROLES.Admin))
                navigate(ROUTES.Vendors);
            else navigate(ROUTES.Dashboard);

            setLoading(false);
            callback();
        } catch {
            setLoading(false);
            setIsLoginFail(true);
        }
    };

    const logout = async (callback: VoidFunction) => {
        try {
            setLoading(true);
            await signOut(auth);
            setLoading(false);
            callback();
        } catch (err) {
            setLoading(false);
            console.error(err);
        }
    };

    const sendPasswordReset = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (err) {
            console.error(err);
        }
    };

    const createUser = async (username: string, password: string) => {
        try {
            await createUserWithEmailAndPassword(auth, username, password);
        } catch (error) {
            console.error(error);
        }
    };

    const updateUserProfile = async (displayName: string, photoUrl: string) => {
        try {
            await updateProfile(auth.currentUser, {
                displayName: displayName,
                photoURL: photoUrl,
            });
        } catch (err) {
            console.error(err);
        }
    };

    const hasPermission = (roles: string[]) => {
        return authUser?.roles?.some((role) => roles?.includes(role));
    };

    const getToken = (): string | null => {
        return authUser.userId;
    };
    const authContext = {
        user,
        authUser,
        loading,
        isLoginFail,
        login,
        logout,
        sendPasswordReset,
        createUser,
        updateUserProfile,
        hasPermission,
        getToken,
    };

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    );
};
