import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";

const TableRowBody = () => {
    return (
        <TableRow role="checkbox" tabIndex={-1}>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </TableCell>
        </TableRow>
    );
};
const TableSkeleton = () => {
    return (
        <>
            <div className="w-full overflow-hidden hidden sm:block">
                <TableContainer>
                    <Table aria-label="Table skeleton">
                        <TableBody>
                            <TableRowBody />
                            <TableRowBody />
                            <TableRowBody />
                            <TableRowBody />
                            <TableRowBody />
                            <TableRowBody />
                            <TableRowBody />
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="sm:hidden">
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
                <Skeleton variant="text" className="m-1 py-1 h-4 w-full" />
            </div>
        </>
    );
};

export default TableSkeleton;
