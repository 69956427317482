export const enum DBCollection {
    VendorNotifications = "vendorNotifications",
    Vendors = "vendors",
    CustomAmenities = "customAmenities",
    Private = "private",
    ChatRooms = "chatRooms",
    CheckIns = "checkIns",
    Coupons = "coupons",
    Reviews = "reviews",
    Users = "users",
    Bookings = "bookings",
    InBoxes = "inboxes",
    Messages = "messages",
    MonthlyReports = "monthlyReports",
}
