import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    RadioGroup,
} from "@mui/material";
import { SectionTitle, TextInput } from "components";
import { BaseRadio } from "components/UI/Radio";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { useNotificationContext } from "context/NotificationContext";
import { Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { vendorService } from "services/vendor.service";
import MESSAGES from "shared/constants/messages.constant";
import ROUTES from "shared/constants/routes.constant";
import {
    defaultVendorSettingCustomErrors,
    defaultVendorSettings,
    VendorSettingsModel,
} from "../../../../models/vendor-settings.model";
import { VendorSettingsValidation } from "../../../../validation-schema/vendor-settings.validation";
import { VendorWorkspaces } from "./VendorWorkspaces";

export interface VendorInformationFormProps {
    vendorData?: VendorSettingsModel;
    vendorId?: string;
}

export const VendorInformationForm = (props: VendorInformationFormProps) => {
    const notificationContext = useNotificationContext();
    const fullPageLoaderContext = useFullPageLoadingContext();
    const navigate = useNavigate();

    const [inputValues, setInputValues] = useState(
        props.vendorData ?? defaultVendorSettings,
    );
    const [customErrors, setCustomErrors] = useState(
        defaultVendorSettingCustomErrors,
    );
    useEffect(() => {
        setInputValues(props.vendorData);
    }, [props.vendorData]);

    // API submit data
    const { mutateAsync: mutateAddVendor, isLoading: isLoadingAddVendor } =
        useMutation(
            async (data: VendorSettingsModel) => {
                const res =
                    props.vendorId && props.vendorId !== ""
                        ? await vendorService.updateVendor({
                              ...data,
                              id: props.vendorId,
                          })
                        : await vendorService.addVendor(data);
                return res;
            },
            {
                onSuccess: async (res) => {
                    notificationContext.showSuccess(
                        props.vendorId && props.vendorId !== ""
                            ? MESSAGES.VENDOR_PROFILE_UPDATE_SUCCESSFULLY
                            : MESSAGES.VENDOR_PROFILE_ADD_SUCCESSFULLY,
                    );
                    fullPageLoaderContext.hide();
                    navigate(ROUTES.Vendors);
                },
                onError: async (err: any) => {
                    fullPageLoaderContext.hide();
                    notificationContext.showError(err.message);
                    if (err.message.includes("email")) {
                        setCustomErrors({
                            ownerEmail: err.message,
                        });
                    }
                },
            },
        );

    const saveVendorSettings = async (values: VendorSettingsModel) => {
        try {
            values
                ? await mutateAddVendor(values)
                : await mutateAddVendor(null);
        } catch (ex) {
            notificationContext.showError(ex.message);
            throw new Error(ex.message);
        }
    };

    const handleSubmitBtn = async (
        formik: FormikProps<VendorSettingsModel>,
    ) => {
        try {
            setCustomErrors(defaultVendorSettingCustomErrors);
            setInputValues(formik.values);
            await saveVendorSettings(formik.values);
        } catch (ex) {
            notificationContext.showError(ex.message);
        }
    };

    useEffect(() => {
        if (isLoadingAddVendor) fullPageLoaderContext.show();
        else fullPageLoaderContext.hide();
    }, [isLoadingAddVendor]);

    return (
        <>
            <Formik
                initialValues={inputValues ?? defaultVendorSettings}
                enableReinitialize
                validationSchema={VendorSettingsValidation}
                onSubmit={async (values: VendorSettingsModel) => {}}
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            rowSpacing={0}
                            columnSpacing={{ xs: 3 }}
                        >
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    variant="standard"
                                    className="mb-6 md:mb-8"
                                    error={
                                        !!formik.errors.name &&
                                        formik.touched.name
                                    }
                                >
                                    <TextInput
                                        label="Vendor name"
                                        placeholder=""
                                        id="name"
                                        name="name"
                                        aria-describedby="vendorName-help-text"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        showRequiredSymbol
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <FormHelperText
                                            id="vendorName-help-text"
                                            className="mx-0 my-1"
                                        >
                                            {formik.errors.name}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    variant="standard"
                                    className="mb-6 md:mb-8"
                                    error={
                                        (!!formik.errors.ownerEmail &&
                                            formik.touched.ownerEmail) ||
                                        !!customErrors.ownerEmail
                                    }
                                >
                                    <TextInput
                                        label="Email address"
                                        placeholder=""
                                        id="ownerEmail"
                                        name="ownerEmail"
                                        aria-describedby="ownerEmail-help-text"
                                        value={formik.values.ownerEmail}
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                            setCustomErrors(
                                                defaultVendorSettingCustomErrors,
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        showRequiredSymbol
                                    />
                                    {!customErrors.ownerEmail &&
                                        formik.touched.ownerEmail &&
                                        formik.errors.ownerEmail && (
                                            <FormHelperText
                                                id="ownerEmail-help-text"
                                                className="mx-0 my-1"
                                            >
                                                {formik.errors.ownerEmail}
                                            </FormHelperText>
                                        )}
                                    {customErrors.ownerEmail &&
                                        !formik.touched.ownerEmail &&
                                        !formik.errors.ownerEmail && (
                                            <FormHelperText
                                                id="ownerEmail-help-text"
                                                className="mx-0 my-1"
                                            >
                                                {customErrors.ownerEmail}
                                            </FormHelperText>
                                        )}
                                    {customErrors.ownerEmail &&
                                        formik.touched.ownerEmail &&
                                        formik.errors.ownerEmail && (
                                            <FormHelperText
                                                id="ownerEmail-help-text"
                                                className="mx-0 my-1"
                                            >
                                                {formik.errors.ownerEmail ??
                                                    customErrors.ownerEmail}
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                        </Grid>

                        <VendorWorkspaces formik={formik} />
                        <Grid
                            container
                            rowSpacing={0}
                            columnSpacing={{ xs: 3 }}
                        >
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    variant="standard"
                                    className="mb-6 md:mb-8"
                                    error={
                                        !!formik.errors.vendorSettings
                                            ?.serviceFeePercent &&
                                        formik.touched.vendorSettings
                                            ?.serviceFeePercent
                                    }
                                >
                                    <label htmlFor="serviceFee">
                                        <SectionTitle
                                            title="Service Fee"
                                            helpText={
                                                MESSAGES.VENDOR_ACCOUNT_SERVICE_FEE_HELP_TEXT
                                            }
                                            hasTooltip
                                            showRequiredSymbol
                                        />
                                    </label>
                                    <TextInput
                                        id="vendorSettings.serviceFeePercent"
                                        name="vendorSettings.serviceFeePercent"
                                        placeholder=""
                                        endAdornment={
                                            <InputAdornment
                                                position="end"
                                                className="-mr-2"
                                            >
                                                %
                                            </InputAdornment>
                                        }
                                        style={{
                                            width: "100%",
                                            maxWidth: 175,
                                        }}
                                        value={
                                            formik.values.vendorSettings
                                                .serviceFeePercent
                                        }
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.vendorSettings
                                        ?.serviceFeePercent &&
                                        formik.errors.vendorSettings
                                            ?.serviceFeePercent && (
                                            <FormHelperText
                                                id="serviceFeePercent-help-text"
                                                className="mx-0 my-1"
                                            >
                                                {
                                                    formik.errors.vendorSettings
                                                        ?.serviceFeePercent
                                                }
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    variant="standard"
                                    className="mb-6 md:mb-8"
                                >
                                    <label htmlFor="vat">
                                        <SectionTitle
                                            title="VAT Settings"
                                            helpText={
                                                MESSAGES.VENDOR_ACCOUNT_VAT_SETTINGS_HELP_TEXT
                                            }
                                            hasTooltip
                                            showRequiredSymbol
                                        />
                                    </label>
                                    <FormControl
                                        error={
                                            !!formik.errors.vendorSettings
                                                ?.vatType &&
                                            formik.touched.vendorSettings
                                                ?.vatType
                                        }
                                        className="mt-2"
                                    >
                                        <RadioGroup
                                            aria-labelledby="type-group-label"
                                            name="vendorSettings.vatType"
                                            id="vendorSettings.vatType"
                                            className="flex flex-col sm:flex-row sm:space-x-0.5"
                                            value={
                                                formik.values.vendorSettings
                                                    .vatType
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <FormControlLabel
                                                value="Partially"
                                                control={<BaseRadio />}
                                                label="Partially taxable"
                                                className="font-bold"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <FormControlLabel
                                                value="Fully"
                                                control={<BaseRadio />}
                                                label="Fully taxable"
                                                className="font-bold"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </RadioGroup>
                                        {formik.touched.vendorSettings
                                            ?.vatType &&
                                            formik.errors.vendorSettings
                                                ?.vatType && (
                                                <FormHelperText
                                                    id="vatType-help-text"
                                                    className="mx-0 my-1"
                                                >
                                                    {
                                                        formik.errors
                                                            .vendorSettings
                                                            ?.vatType
                                                    }
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div className="flex flex-col sm:flex-row justify-between space-y-3 sm:space-y-0 sm:space-x-3 -mt-0.5">
                            <Button
                                variant="contained"
                                color="primary"
                                className="w-full sm:max-w-[175px]"
                                fullWidth
                                disabled={
                                    !formik.dirty ||
                                    !formik.isValid ||
                                    formik.isSubmitting
                                }
                                onClick={() => handleSubmitBtn(formik)}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};
export * from "./VendorInformationSkeleton";
export * from "./WorkspaceWithCheckbox";
