import {
    AdminContentContainer,
    AdminSingleBlockLayout,
} from "components/Layouts";
import { ListingForm } from "components/ListingForm";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { listingService } from "services/listing.service";

const CreateListingPage = () => {
    const { data: listing, isFetching } = useQuery(
        `add-listing`,
        async () => await listingService.getPrefillListing(),
        {
            refetchOnWindowFocus: false,
        },
    );
    return (
        <>
            <Helmet>
                <title>Joes & Cos | Add New Listing</title>
            </Helmet>
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <div className="max-w-[720px] mx-auto relative">
                        <ListingForm
                            listing={listing}
                            isAddNewListing={true}
                            pageTitle="Add New Listing"
                            isFetchingInitialData={isFetching}
                        />
                    </div>
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};

export default CreateListingPage;
