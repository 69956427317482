import { Avatar, Typography } from "@mui/material";
import { memo } from "react";
import { ChatProps } from "./chat";

const ChatBubbleLeft = (props: ChatProps) => {
    return (
        <>
            <div className="flex items-start">
                <Avatar
                    alt={`${props.guestName} image`}
                    src={props.avatarImage}
                    sx={{ width: 38, height: 38 }}
                />
                <div className="flex flex-col ml-4">
                    <div className="max-w-[400px] px-4 py-2 bg-grey-200 rounded-[10px]">
                        <p className="my-0 text-sm font-semibold whitespace-pre-line text-secondary xl:text-base">
                            {props.message}
                        </p>
                    </div>
                    <Typography
                        variant="body1"
                        color="secondary"
                        className="text-grey-500 text-[12px] mt-0.5"
                    >
                        {props.date}
                    </Typography>
                </div>
            </div>
        </>
    );
};
export default memo(ChatBubbleLeft);
