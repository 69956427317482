import React from "react";
import { Box, Drawer } from "@mui/material";
import { NavLink } from "react-router-dom";
import { nav } from "../AdminHeader/nav";
import { useAuthContext } from "context/AuthContext";
export type WindowType = (Window & typeof globalThis) | any;

const drawerWidth = 278;
interface AdminMobileDrawerProps {
  mobileOpen: boolean;
  onClose: () => void;
  window?: WindowType;
}

export const AdminMobileDrawer: React.FC<any> = (
  props: AdminMobileDrawerProps
) => {
  const auth = useAuthContext();
  const { window, mobileOpen, onClose } = props;
  const container = window ? () => window.document.body : undefined;
  const menuMobileClasses =
    "block w-full  px-4 py-3 text-lg font-bold text-primary hover:bg-primary hover:text-white btn-transition";
  return (
    <Box
      component="nav"
      sx={{
        width: { lg: drawerWidth },
        flexShrink: { lg: 0 },
        display: { lg: "none" },
      }}
      aria-label="nav"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={onClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <ul className="list-none p-0 m-0">
          {nav.map(
            (page, id) =>
              auth.hasPermission(page.allowRoles) && (
                <li key={page.name}>
                  <NavLink
                    to={page.path}
                    className={({ isActive }) =>
                      isActive
                        ? menuMobileClasses + ` bg-primary !text-white`
                        : menuMobileClasses
                    }
                  >
                    {page.name}
                  </NavLink>
                </li>
              )
          )}
        </ul>
      </Drawer>
    </Box>
  );
};

AdminMobileDrawer.propTypes = {} as any;
