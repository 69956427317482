import { IconButton } from "@mui/material";
import { IoCloseCircleOutline, IoEllipse } from "react-icons/io5";
import { dateTimeHelper } from "../../helpers/date-time.helper";
import { VendorNotification } from "../../models";

type ItemNotificationProps = {
    notification: VendorNotification;
    className?: string;
    removeNotificationFn?: (id: string) => void;
};

export const ItemNotification: React.FC<ItemNotificationProps> = (props) => {
    return (
        <div className="relative flex flex-wrap items-center justify-between p-3 pl-7 pr-14 hover:bg-row-light btn-transition">
            <div className="text-base mr-2 my-0.5 sm:w-[375px] flex-inline items-baseline relative">
                {!props.notification.read && (
                    <IoEllipse className="w-2.5 h-2.5 rounded text-notification mr-2 absolute -left-4 top-[7px]" />
                )}
                <span className="font-bold">{props.notification.userName}</span>
                &nbsp;{props.notification.description}
            </div>
            <div className="text-xs flex items-center my-0.5">
                <span className="text-grey-900">
                    {dateTimeHelper.displayDateTime(
                        props.notification.dateTimeCreated as Date,
                    )}
                </span>
            </div>
            {props.removeNotificationFn && (
                <IconButton
                    aria-label="delete"
                    className="absolute text-xl -translate-y-1/2 right-3 top-1/2"
                    color="primary"
                    title="Remove"
                    onClick={(e) => {
                        props.removeNotificationFn(props.notification.id);
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <IoCloseCircleOutline />
                </IconButton>
            )}
        </div>
    );
};
