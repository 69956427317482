import {
    Button,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { EmptyList } from "components";
import TableSkeleton from "components/UI/Table/TableSkeleton";
import { HeadCellProps } from "components/UI/Table/type";
import { useAuthContext } from "context/AuthContext";
import { dateTimeHelper } from "helpers/date-time.helper";
import { ReservationDetail } from "models/bookings.model";
import { PaginationModel } from "models/pagination.model";
import * as React from "react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { Link } from "react-router-dom";
import { bookingService } from "services/booking.service";
import MESSAGES from "shared/constants/messages.constant";
import { defaultPaginationLimit } from "shared/constants/shared.constant";
import { STATUS } from "shared/constants/status";
import { CustomHeadCellProps, TableHeadCell } from "../UI/Table/TableHeadCell";
import StatusReservation from "./StatusReservation";
import TableReservationSkeletonLoading from "./TableReservationSkeletonLoading";

export const tableReservationHeadCells: readonly HeadCellProps[] = [
    {
        id: "status",
        numeric: false,
        disablePadding: true,
        disabledSortColumn: true,
        label: "Status",
        sortingDirection: false,
    },
    {
        id: "userName",
        numeric: false,
        disablePadding: false,
        label: "Guest",
    },
    {
        id: "meetingDate",
        numeric: false,
        disablePadding: false,
        label: "Meeting date",
        disabledSortColumn: false,
        sortingDirection: "asc",
    },
    {
        id: "meetingStartTime",
        numeric: false,
        disablePadding: false,
        label: "Start time",
        sortingDirection: "asc",
    },
    {
        id: "meetingEndTime",
        numeric: false,
        disablePadding: false,
        label: "End time",
        sortingDirection: "asc",
    },
    {
        id: "dateTimeCreated",
        numeric: false,
        disablePadding: false,
        label: "Booked",
        // disabledSortColumn: true,
        sortingDirection: "desc",
    },
    {
        id: "location",
        numeric: false,
        disablePadding: false,
        label: "Location",
        sortingDirection: "asc",
    },
    {
        id: "totalPayout",
        numeric: true,
        disablePadding: false,
        label: "Total payout",
        sortingDirection: "asc",
    },
];

export function EnhancedTableHead(props) {
    const [headers, setHeaders] = useState<HeadCellProps[]>(props.data);
    const [activeColumn, setActiveColumn] = useState<HeadCellProps>(
        props.data[5],
    );
    const updateActiveColumn = (activeColumn: HeadCellProps) => {
        setHeaders((prev) => [
            ...prev.map((header: HeadCellProps) => {
                if (header.id === activeColumn.id) {
                    header.sortingActive = true;
                    header.sortingDirection = activeColumn.sortingDirection;
                    setActiveColumn(header);
                } else {
                    header.sortingActive = false;
                    header.sortingDirection = "asc";
                }
                return header;
            }),
        ]);
        props.updateActiveColumn(activeColumn);
    };
    useEffect(() => {
        const defaultSortingColumn = props.data[5];
        setHeaders([
            ...headers.map((header: HeadCellProps) => {
                if (header.id === defaultSortingColumn.id) {
                    header.sortingActive = true;
                } else {
                    header.sortingActive = false;
                }
                if (header.id === "status") {
                    if (props.tabIndex === 7) {
                        header.disabledSortColumn = false;
                    } else {
                        header.disabledSortColumn = true;
                    }
                }
                return header;
            }),
        ]);
    }, [props.tabIndex]);
    useEffect(() => {
        setHeaders([...headers]);
    }, [activeColumn]);
    return (
        <>
            <TableHead className="sticky top-0 z-10 bg-white">
                <TableRow>
                    {headers.map((headCell: CustomHeadCellProps) => (
                        <TableHeadCell
                            className="sticky top-0 bg-white"
                            key={headCell.id}
                            {...headCell}
                            updateActiveColumn={updateActiveColumn}
                        />
                    ))}
                    <TableCell className="sticky top-0 right-0 bg-white">
                        <span className="sr-only">Actions</span>
                    </TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface TableReservationProps {
    vendorId: string;
    statuses: STATUS[];
    tabIndex: number;
}
let currentColumn: HeadCellProps = tableReservationHeadCells[5];
export const TableReservation: React.FC<TableReservationProps> = (props) => {
    const { vendorId, tabIndex, statuses } = props;
    const [selectedstatuses, setSelectedstatuses] =
        useState<STATUS[]>(statuses);
    const [activeColumn, setActiveColumn] = useState<HeadCellProps>(
        tableReservationHeadCells[5],
    );

    const fetchReservationList = async ({ pageParam }: any) => {
        const result = (await bookingService.getBookingsByStatuses(
            vendorId,
            selectedstatuses,
            defaultPaginationLimit,
            pageParam,
            currentColumn.id,
            typeof currentColumn.sortingDirection === "boolean"
                ? "desc"
                : currentColumn.sortingDirection.valueOf(),
        )) as PaginationModel<ReservationDetail[]>;

        result.lists.map((booking) => {
            booking.meetingDate =
                dateTimeHelper.convertFirestoreTimestampToDate(
                    booking.meetingDate._seconds,
                    booking.meetingDate._nanoseconds,
                );
            booking.dateTimeCreated =
                dateTimeHelper.convertFirestoreTimestampToDate(
                    booking.dateTimeCreated._seconds,
                    booking.dateTimeCreated._nanoseconds,
                );
            return booking;
        });
        return result;
    };
    const {
        data: reservationItems,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
        isRefetching,
        refetch,
    } = useInfiniteQuery(
        `reservation-list-${
            useAuthContext().authUser.userId
        }-${selectedstatuses.join("-")}`,
        fetchReservationList,
        {
            getNextPageParam: (lastPage, pages) =>
                lastPage.page.isLastPage ? undefined : lastPage.page.lastId,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    );

    const itemSize =
        reservationItems?.pages?.reduce((x, y) => x + y.lists.length, 0) ?? 0;

    const getEmpyTextByTabIndex = (): string => {
        switch (props.tabIndex) {
            case 0:
                return MESSAGES.RESERVATION_LIST_PENDING_EMPTY;
            case 1:
                return MESSAGES.RESERVATION_LIST_WAITING_FOR_PAYMENT_EMPTY;
            case 2:
                return MESSAGES.RESERVATION_LIST_UPCOMING_EMPTY;
            case 3:
                return MESSAGES.RESERVATION_LIST_COMPLETED_EMPTY;
            case 4:
                return MESSAGES.RESERVATION_LIST_CANCELED_EMPTY;
            case 5:
                return MESSAGES.RESERVATION_LIST_DECLINED_EMPTY;
            case 6:
                return MESSAGES.RESERVATION_LIST_EXPIRED_EMPTY;
            default:
                return MESSAGES.RESERVATION_LIST_ALL_EMPTY;
        }
    };
    const updateActiveColumn = async (activeColumn: CustomHeadCellProps) => {
        setActiveColumn({ ...activeColumn });
        currentColumn = activeColumn;
        refetch();
    };
    useEffect(() => {
        setSelectedstatuses([...props.statuses]);
    }, [props]);
    useEffect(() => {
        currentColumn = tableReservationHeadCells[5];
    }, [props.tabIndex]);
    return (
        <>
            {isLoading && <TableSkeleton />}
            {!isFetching && itemSize === 0 && (
                <>
                    <Divider />
                    <EmptyList label={getEmpyTextByTabIndex()} bgTransparent />
                </>
            )}
            <TableContainer component={Paper}>
                <div
                    id="scrollableBookings"
                    className="custom-scrollbar"
                    style={{
                        maxHeight: "500px",
                        overflow: "auto",
                    }}
                >
                    <InfiniteScroll
                        dataLength={reservationItems?.pages ? itemSize : 0}
                        next={fetchNextPage}
                        hasMore={hasNextPage ?? false}
                        loader={null}
                        scrollableTarget="scrollableBookings"
                        className="custom-scrollbar"
                    >
                        {itemSize > 0 && (
                            <>
                                <Table aria-label="Vendors table">
                                    <EnhancedTableHead
                                        data={tableReservationHeadCells}
                                        updateActiveColumn={updateActiveColumn}
                                        tabIndex={props.tabIndex}
                                    />
                                    <TableBody>
                                        {!isRefetching &&
                                            reservationItems &&
                                            reservationItems?.pages?.map(
                                                (bookings, indexPage) =>
                                                    bookings?.lists?.map(
                                                        (
                                                            booking: ReservationDetail,
                                                        ) => (
                                                            <React.Fragment
                                                                key={booking.id}
                                                            >
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                >
                                                                    <TableCell
                                                                        data-th={
                                                                            tableReservationHeadCells[0]
                                                                                .label
                                                                        }
                                                                    >
                                                                        <StatusReservation
                                                                            consolidateStatus={
                                                                                booking.consolidateStatus
                                                                            }
                                                                            status={
                                                                                booking.status
                                                                            }
                                                                            hasBackground
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        data-th={
                                                                            tableReservationHeadCells[1]
                                                                                .label
                                                                        }
                                                                    >
                                                                        <span className="whitespace-nowrap">
                                                                            {
                                                                                booking.userName
                                                                            }
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        data-th={
                                                                            tableReservationHeadCells[2]
                                                                                .label
                                                                        }
                                                                    >
                                                                        <span className="whitespace-nowrap">
                                                                            {dateTimeHelper.convertTimeStampToString(
                                                                                booking.meetingDate,
                                                                            )}
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        data-th={
                                                                            tableReservationHeadCells[3]
                                                                                .label
                                                                        }
                                                                    >
                                                                        <span className="whitespace-nowrap">
                                                                            {
                                                                                booking.meetingStartTimeString
                                                                            }
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        data-th={
                                                                            tableReservationHeadCells[4]
                                                                                .label
                                                                        }
                                                                    >
                                                                        <span className="whitespace-nowrap">
                                                                            {
                                                                                booking.meetingEndTimeString
                                                                            }
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        data-th={
                                                                            tableReservationHeadCells[5]
                                                                                .label
                                                                        }
                                                                    >
                                                                        <span className="whitespace-nowrap">
                                                                            {dateTimeHelper.convertTimeStampToString(
                                                                                booking.dateTimeCreated,
                                                                            )}
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        data-th={
                                                                            tableReservationHeadCells[6]
                                                                                .label
                                                                        }
                                                                    >
                                                                        <span className="line-clamp-none sm:line-clamp-1">
                                                                            {
                                                                                booking.location
                                                                            }
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        data-th={
                                                                            tableReservationHeadCells[7]
                                                                                .label
                                                                        }
                                                                        align="right"
                                                                    >
                                                                        {
                                                                            booking.totalPayout
                                                                        }
                                                                        &nbsp;DKK
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="right"
                                                                        data-th="Action"
                                                                        className="sticky right-0 bg-white"
                                                                    >
                                                                        <Link
                                                                            to={`/reservation-details/${booking.id}`}
                                                                        >
                                                                            <Button
                                                                                variant="outlined"
                                                                                className="h-8 py-1 m-1"
                                                                            >
                                                                                Details
                                                                            </Button>
                                                                        </Link>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        ),
                                                    ),
                                            )}
                                        {isRefetching && (
                                            <>
                                                <TableReservationSkeletonLoading />
                                                <TableReservationSkeletonLoading />
                                                <TableReservationSkeletonLoading />
                                                <TableReservationSkeletonLoading />
                                                <TableReservationSkeletonLoading />
                                                <TableReservationSkeletonLoading />
                                                <TableReservationSkeletonLoading />
                                                <TableReservationSkeletonLoading />
                                                <TableReservationSkeletonLoading />
                                                <TableReservationSkeletonLoading />
                                            </>
                                        )}
                                        {(isFetching || isFetchingNextPage) && (
                                            <>
                                                <TableReservationSkeletonLoading />
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </InfiniteScroll>
                </div>
            </TableContainer>
        </>
    );
};
