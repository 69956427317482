import { Grid, Typography } from "@mui/material";
import { useAuthContext } from "context/AuthContext";
import { DocumentData } from "firebaseSetup";
import { convertToCurrency, roundedToFixed } from "helpers/currency.helper";
import { MonthlyReport } from "models/monthly-report.model";
import moment from "moment";
import { useEffect, useState } from "react";
import {
    IoNewspaperOutline,
    IoStarOutline,
    IoWalletOutline,
} from "react-icons/io5";
import { vendorService } from "services/vendor.service";
import { MonthlyOverviewProps } from "types/dashboard";
import MonthlyOverviewSkeleton from "./MonthlyOverviewSkeleton";

type MonthlyOverviewDataProps = {
    data: MonthlyOverviewProps;
};
export const MonthlyOverview: React.FC<MonthlyOverviewDataProps> = (props) => {
    const auth = useAuthContext();
    const [monthlyReport, setMonthlyReport] = useState<MonthlyReport>(null);
    const today = new Date();

    useEffect(() => {
        const unsub = vendorService.subScribeMonthlyReport(
            auth.authUser.userId,
            today.getFullYear(),
            today.getMonth() + 1,
            snapshotMonthlyReport,
        );
        return unsub;
    }, []);
    const snapshotMonthlyReport = async (doc: DocumentData) => {
        const monthlyReport = doc.data();
        setMonthlyReport(monthlyReport as MonthlyReport);
    };
    return (
        <>
            <Typography variant="h3">
                {" "}
                {moment()
                    .month(
                        monthlyReport?.month
                            ? monthlyReport?.month - 1
                            : today.getMonth(),
                    )
                    .format("MMMM")}{" "}
                overview
            </Typography>
            <div className="flex justify-between">
                {monthlyReport === null ? (
                    <MonthlyOverviewSkeleton />
                ) : (
                    <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 3 }}
                        className="mt-4 mb-6 space-y-5 sm:space-y-0"
                    >
                        <Grid item xs={12} sm={4}>
                            <div className="p-7 lg:py-7 lg:px-4 2xl:px-7 bg-grey-200 rounded-[20px]">
                                <div className="flex flex-col min-h-[145px] justify-between">
                                    <div>
                                        <div className="text-4xl font-bold break-all sm:text-2xl lg:text-4xl xl:text-2xl">
                                            {convertToCurrency(
                                                Math.round(
                                                    monthlyReport?.totalBooking ??
                                                        0,
                                                ),
                                            )}
                                        </div>
                                        <div className="text-lg font-bold text-grey-900">
                                            {Math.round(
                                                monthlyReport?.totalBooking ??
                                                    0,
                                            ) === 1
                                                ? "Booking"
                                                : "Bookings"}
                                        </div>
                                    </div>
                                    <IoNewspaperOutline className="text-4xl" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="p-7 lg:py-7 lg:px-4 2xl:px-7 bg-grey-200 rounded-[20px]">
                                <div className="flex flex-col min-h-[145px] justify-between">
                                    <div>
                                        <div className="text-4xl font-bold break-all sm:text-2xl lg:text-4xl xl:text-2xl">
                                            <span className="mr-1">
                                                {convertToCurrency(
                                                    Math.round(
                                                        monthlyReport?.revenue ??
                                                            0,
                                                    ),
                                                )}
                                            </span>
                                            <span className="inline-block text-lg">
                                                DKK
                                            </span>
                                        </div>
                                        <div className="text-lg font-bold text-grey-900">
                                            Revenue
                                        </div>
                                    </div>
                                    <IoWalletOutline className="text-4xl" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="p-7 lg:py-7 lg:px-4 2xl:px-7 bg-grey-200 rounded-[20px]">
                                <div className="flex flex-col min-h-[145px] justify-between">
                                    <div>
                                        <div className="text-4xl font-bold break-all sm:text-2xl lg:text-4xl xl:text-2xl">
                                            {roundedToFixed(
                                                monthlyReport?.averageRating ??
                                                    0,
                                                1,
                                            )}
                                        </div>
                                        <div className="text-lg font-bold text-grey-900">
                                            Average rating
                                        </div>
                                    </div>
                                    <IoStarOutline className="text-4xl" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </div>
        </>
    );
};
