import {
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
} from "@mui/material";
import theme from "styles/theme";

export const BookingItemsSkeleton = () => {
    return (
        <>
            <ListItem
                alignItems="flex-start"
                className="px-4 xl:py-5 2xl:px-10"
                component={"div"}
            >
                <Skeleton variant="rectangular">
                    <ListItemAvatar
                        sx={{
                            width: 100,
                            height: 60,
                            maxWidth: 120,
                            [theme.breakpoints.up(1600)]: {
                                width: 130,
                                height: 80,
                                maxWidth: "none",
                            },
                        }}
                        className="mt-0 mr-3 relative leading-[0] aspect-[5/3]"
                    ></ListItemAvatar>
                </Skeleton>
                <ListItemText className="my-0 ml-4">
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                </ListItemText>
            </ListItem>
            <Divider component="div" />
        </>
    );
};
const BookingsSkeleton = () => {
    return (
        <>
            <div className="px-4 xl:pt-5 2xl:px-10 pt-6 mb-0">
                <Skeleton variant="text" width="80%"></Skeleton>
            </div>
            <div className="flex space-x-2 px-4 pt-5 2xl:px-10">
                <Skeleton
                    variant="rectangular"
                    width="30%"
                    height="33px"
                ></Skeleton>
                <Skeleton
                    variant="rectangular"
                    width="30%"
                    height="33px"
                ></Skeleton>
                <Skeleton
                    variant="rectangular"
                    width="30%"
                    height="33px"
                ></Skeleton>
            </div>
            <Divider className="mt-4" />
            <BookingItemsSkeleton />
            <BookingItemsSkeleton />
            <BookingItemsSkeleton />
            <BookingItemsSkeleton />
        </>
    );
};

export default BookingsSkeleton;
