import DateAdapter from "@mui/lab/AdapterMoment";
import DesktopTimePicker from "@mui/lab/DesktopTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import { useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect } from "react";

interface TimePickerProps {
    id: string;
    startName: string;
    startTime?: Date;
    isStartError?: boolean;
    startErrorMessage?: string;
    endName: string;
    endTime?: Date;
    isEndError?: boolean;
    endErrorMessage?: string;
    onChangeStart: (newValue: Date) => void;
    onChangeEnd: (newValue: Date) => void;
}

const TimePicker = (props: TimePickerProps) => {
    const {
        id,
        startName,
        startTime,
        isStartError,
        startErrorMessage,
        endName,
        endTime,
        isEndError,
        endErrorMessage,
        onChangeStart,
        onChangeEnd,
    } = props;
    const [openMobile, setOpenMobile] = React.useState(false);

    const theme = useTheme();
    const mobileScreen = useMediaQuery(theme.breakpoints.down("xl"));

    useEffect(() => {
        if (mobileScreen) {
            setOpenMobile(true);
        } else {
            setOpenMobile(false);
        }
    }, [mobileScreen]);

    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            {openMobile ? (
                <div className="block">
                    <div className="flex space-x-2">
                        <MobileTimePicker
                            value={startTime}
                            onChange={onChangeStart}
                            ampm={false}
                            renderInput={(params) => (
                                <TextField
                                    name={startName}
                                    {...params}
                                    error={isStartError}
                                    helperText={startErrorMessage}
                                />
                            )}
                            DialogProps={{
                                className: "mobile-timepicker-modal",
                            }}
                            {...props}
                        />
                        <span className="flex h-full items-baseline mt-1.5">
                            -
                        </span>
                        <MobileTimePicker
                            value={endTime}
                            onChange={onChangeEnd}
                            ampm={false}
                            renderInput={(params) => (
                                <TextField
                                    name={endName}
                                    {...params}
                                    error={isEndError}
                                    helperText={endErrorMessage}
                                />
                            )}
                            DialogProps={{
                                className: "mobile-timepicker-modal",
                            }}
                            {...props}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="flex space-x-2">
                        <DesktopTimePicker
                            value={startTime}
                            onChange={onChangeStart}
                            ampm={false}
                            renderInput={(params) => (
                                <TextField
                                    name={startName}
                                    sx={{
                                        maxWidth: 160,
                                    }}
                                    {...params}
                                    error={isStartError}
                                    helperText={startErrorMessage}
                                />
                            )}
                            {...props}
                        />
                        <span className="flex h-full items-baseline mt-1.5">
                            -
                        </span>
                        <DesktopTimePicker
                            value={endTime}
                            onChange={onChangeEnd}
                            ampm={false}
                            renderInput={(params) => (
                                <TextField
                                    name={endName}
                                    sx={{
                                        maxWidth: 160,
                                    }}
                                    {...params}
                                    error={isEndError}
                                    helperText={endErrorMessage}
                                />
                            )}
                            {...props}
                        />
                    </div>
                </>
            )}
        </LocalizationProvider>
    );
};

export default TimePicker;
