import { Button, Divider, Grid, Typography } from "@mui/material";
import { useAuthContext } from "context/AuthContext";
import { useNotificationContext } from "context/NotificationContext";
import { HalfFullDayPassedType } from "enum/booking-enum";
import { CalculationStyle, PricingStyle } from "enum/meeting-room.enum";
import { Roles } from "enum/role.enum";
import { dateTimeHelper } from "helpers/date-time.helper";
import { ReservationDetail as BookingData } from "models/bookings.model";
import { useEffect, useState } from "react";
import { IoChevronForwardOutline, IoManOutline } from "react-icons/io5";
import { useMutation, useQuery } from "react-query";
import {
    generatePath,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { bookingService } from "services/booking.service";
import { chatService } from "services/chat.service";
import MESSAGES from "shared/constants/messages.constant";
import ROUTES from "shared/constants/routes.constant";
import { SUB_STATUS } from "shared/constants/status";
import StatusReservation from "../Dashboard/StatusReservation";
import { BaseModal, ModalConfirmation } from "../UI";
import { CancellationDetails } from "./CancellationDetails";
import { ProfileDetails } from "./ProfileDetails";
import { ReservationDetailSkeleton } from "./ReservationDetailSkeleton";

export const ReservationDetail = (props) => {
    const { setOpenBookingDetailModal, redirectToChat } = props;
    const [openGuestModal, setOpenGuestModal] = useState(false);
    const [openCancellationModal, setOpenCancellationModal] = useState(false);
    const [openDeclineWarning, setOpenDeclineWarning] = useState(false);
    const [openAcceptWarning, setOpenAcceptWarning] = useState(false);

    let { bookingId, chatRoomId } = useParams();

    const callToast = useNotificationContext();
    const navigate = useNavigate();

    const auth = useAuthContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const vendorIdSearchParam = searchParams.get("vendorId");
    const vendorNameSearchParam = searchParams.get("vendorName");
    let vendorId = auth.authUser.userId ?? auth.getToken();
    if (vendorIdSearchParam && auth.authUser.isAdmin)
        vendorId = vendorIdSearchParam;
    const handleAfterStatusChanged = () => {
        // redirect to chatroom page / close modal
        if (chatRoomId) {
            setOpenBookingDetailModal(false);
            return;
        }
        navigate(
            generatePath(ROUTES.Messages, {
                chatRoomId: reservationDetail.chatRoomId,
                bookingId: bookingId,
            }),
        );
    };
    useEffect(() => {
        if (redirectToChat)
            navigate(
                generatePath(ROUTES.Messages, {
                    chatRoomId: reservationDetail.chatRoomId,
                    bookingId: bookingId,
                }),
            );
    }, [redirectToChat]);

    // API update booking data
    const { mutate: mutateApprove, isLoading: isLoadingApprove } = useMutation(
        async (bookingId: string) => {
            return await bookingService.approveBookingById(bookingId);
        },
        {
            onSuccess: async (data) => {
                callToast.showSuccess(
                    MESSAGES.RESERVATION_ACCEPT_BOOKING_SUCCESSFULLY,
                );
                handleAfterStatusChanged();
            },
            onError: async (err: any) => {
                callToast.showError(err.message);
            },
        },
    );
    const { mutate: mutateDecline, isLoading: isLoadingDecline } = useMutation(
        async (bookingId: string) => {
            return await bookingService.declineBookingById(bookingId);
        },
        {
            onSuccess: async (data) => {
                callToast.showSuccess(
                    MESSAGES.RESERVATION_DECLINE_BOOKING_SUCCESSFULLY,
                );
                handleAfterStatusChanged();
            },
            onError: async (err: any) => {
                callToast.showError(err.message);
            },
        },
    );

    // API get initial data
    const { data: reservationDetail, isFetching } = useQuery(
        chatRoomId ? "getBookingByChatRoomIdAndBookingId" : "getBookingById",
        async () => {
            const result = chatRoomId
                ? await bookingService.getBookingByChatRoomIdAndBookingId(
                      chatRoomId,
                      bookingId,
                      vendorId,
                      Roles.Vendor,
                  )
                : await bookingService.getBookingById(
                      bookingId,
                      vendorId,
                      Roles.Vendor,
                  );
            result.meetingDate = dateTimeHelper.convertFirestoreTimestampToDate(
                result.meetingDate._seconds,
                result.meetingDate._nanoseconds,
            );
            result.dateTimeCreated =
                dateTimeHelper.convertFirestoreTimestampToDate(
                    result.dateTimeCreated._seconds,
                    result.dateTimeCreated._nanoseconds,
                );
            return result as BookingData;
        },
        {
            refetchOnWindowFocus: false,
            onError: async (err: any) => {
                navigate(ROUTES.PageNotFound);
            },
        },
    );

    if (isFetching || isLoadingApprove || isLoadingDecline) {
        return <ReservationDetailSkeleton />;
    }

    const handleApproveBtn = async () => {
        try {
            if (auth.authUser.isVendor)
                await chatService.updateLastVisitBookingId(bookingId);
            mutateApprove(bookingId);
        } catch (error) {
            callToast.showError(error);
        }
    };

    const handleDeclineBtn = async () => {
        try {
            if (auth.authUser.isVendor)
                await chatService.updateLastVisitBookingId(bookingId);
            mutateDecline(bookingId);
        } catch (error) {
            callToast.showError(error);
        }
    };

    return (
        <>
            <Divider className="mt-5 mb-6" />
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="mb-3 space-y-3 md:space-y-0 md:mb-7"
            >
                <Grid item xs={12} md={12}>
                    <div className="flex flex-wrap justify-between">
                        <div>
                            <Typography
                                variant="h4"
                                color="initial"
                                className="mb-1"
                            >
                                Booking date
                            </Typography>
                            <Typography variant="body2" color="initial">
                                {dateTimeHelper.convertTimeStampToString(
                                    reservationDetail.dateTimeCreated,
                                )
                                    ? dateTimeHelper.convertTimeStampToString(
                                          reservationDetail.dateTimeCreated,
                                      )
                                    : "-"}
                            </Typography>
                        </div>
                        <div className="mt-1">
                            <StatusReservation
                                consolidateStatus={reservationDetail.status}
                                status={reservationDetail.status}
                                hasBackground
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="mb-3 space-y-3 md:space-y-0 md:mb-7"
            >
                <Grid item xs={12} md={8}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        Guest
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="flex items-baseline"
                    >
                        <>
                            {reservationDetail.userName
                                ? reservationDetail.userName
                                : "-"}
                            <Button
                                variant="text"
                                className="py-0 text-base"
                                onClick={() => setOpenGuestModal(true)}
                            >
                                <span className="underline">See detail</span>
                            </Button>
                        </>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        Company name
                    </Typography>
                    <Typography variant="body2" color="initial">
                        {reservationDetail.company
                            ? reservationDetail.company
                            : "-"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="mb-3 space-y-3 md:space-y-0 md:mb-7"
            >
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        Meeting room
                    </Typography>
                    <Typography variant="body2" color="initial">
                        {reservationDetail.meetingRoomTitle
                            ? reservationDetail.meetingRoomTitle
                            : "-"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="mb-3 space-y-3 md:space-y-0 md:mb-7"
            >
                <Grid item xs={12} md={4}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        Meeting date
                    </Typography>
                    <Typography variant="body2" color="initial">
                        {dateTimeHelper.convertTimeStampToString(
                            reservationDetail.meetingDate,
                        )
                            ? dateTimeHelper.convertTimeStampToString(
                                  reservationDetail.meetingDate,
                              )
                            : "-"}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        People
                    </Typography>
                    <Typography variant="body2" color="initial">
                        <IoManOutline className="mr-0.5" />
                        {reservationDetail.totalPeople
                            ? reservationDetail.totalPeople
                            : "-"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="mb-3 space-y-3 md:space-y-0 md:mb-7"
            >
                <Grid item xs={12} md={4}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        Start time
                    </Typography>
                    <Typography variant="body2" color="initial">
                        {reservationDetail.meetingStartTimeString
                            ? reservationDetail.meetingStartTimeString
                            : "-"}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        End time
                    </Typography>
                    <Typography variant="body2" color="initial">
                        {reservationDetail.meetingEndTimeString
                            ? reservationDetail.meetingEndTimeString
                            : "-"}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        Total time
                    </Typography>
                    <Typography variant="body2" color="initial">
                        {reservationDetail.payoutDetails &&
                        reservationDetail.payoutDetails.bookingHours &&
                        reservationDetail.payoutDetails.bookingHours.hours &&
                        reservationDetail.payoutDetails.bookingHours.hours > 0
                            ? `${
                                  reservationDetail.payoutDetails.bookingHours
                                      .hours
                              } hour${
                                  reservationDetail.payoutDetails.bookingHours
                                      .hours === 1
                                      ? ""
                                      : "s"
                              } `
                            : ""}

                        {reservationDetail.payoutDetails &&
                        reservationDetail.payoutDetails.bookingHours &&
                        reservationDetail.payoutDetails.bookingHours.minutes &&
                        reservationDetail.payoutDetails.bookingHours.minutes !==
                            0
                            ? reservationDetail.payoutDetails.bookingHours
                                  .minutes +
                              ` minute${
                                  reservationDetail.payoutDetails.bookingHours
                                      .minutes === 1
                                      ? ""
                                      : "s"
                              }`
                            : ""}
                        {!reservationDetail.payoutDetails ||
                            !reservationDetail.payoutDetails.bookingHours ||
                            ((!reservationDetail.payoutDetails.bookingHours
                                .hours ||
                                reservationDetail.payoutDetails.bookingHours
                                    .hours === 0) &&
                                (!reservationDetail.payoutDetails.bookingHours
                                    .minutes ||
                                    reservationDetail.payoutDetails.bookingHours
                                        .minutes === 0) &&
                                "-")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="mb-3 space-y-3 md:space-y-0 md:mb-7"
            >
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        Type of meeting
                    </Typography>
                    <Typography variant="body2" color="initial">
                        {reservationDetail.typeOfMeeting
                            ? reservationDetail.typeOfMeeting
                            : "-"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="mb-3 space-y-3 md:space-y-0 md:mb-7"
            >
                <Grid item xs={12} md={12}>
                    <Typography variant="h4" color="initial" className="mb-1">
                        Comments
                    </Typography>
                    <Typography variant="body2" color="initial">
                        {reservationDetail.comment
                            ? reservationDetail.comment
                            : "-"}
                    </Typography>
                </Grid>
            </Grid>
            {!!(
                reservationDetail.payoutDetails.addOns &&
                reservationDetail.payoutDetails.addOns.length > 0
            ) && (
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 3 }}
                    sx={{ maxWidth: 1230 }}
                    className="mb-3 space-y-3 md:space-y-0 md:mb-7"
                >
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h4"
                            color="initial"
                            className="mb-1"
                        >
                            Add-ons
                        </Typography>
                        {reservationDetail.payoutDetails.addOns.map(
                            (addOn, key) => {
                                return (
                                    <Typography
                                        variant="body2"
                                        color="initial"
                                        key={`add-on-${key}`}
                                    >
                                        {addOn.title} x {addOn.unit}
                                    </Typography>
                                );
                            },
                        )}
                    </Grid>
                </Grid>
            )}
            <Divider className="mt-8" />
            <Grid
                container
                rowSpacing={0}
                columnSpacing={{ xs: 3 }}
                sx={{ maxWidth: 1230 }}
                className="py-5"
            >
                <Grid item xs={12} md={12}>
                    <Typography
                        variant="h4"
                        color="initial"
                        className="flex items-center justify-between mb-1 cursor-pointer"
                        onClick={() => setOpenCancellationModal(true)}
                    >
                        Cancellation Policy{" "}
                        <IoChevronForwardOutline className="text-[22px]" />
                    </Typography>
                </Grid>
            </Grid>
            <Divider className="mb-8" />
            <Typography
                variant="h3"
                color="initial"
                className="flex flex-wrap items-center mb-2"
            >
                <span className="inline-block mr-3">Payout Details</span>
                {!!reservationDetail.payoutDetails.isRefunded && (
                    <span className="font-semibold bg-opacity-10 inline-block py-1.5 px-4 rounded-lg whitespace-nowrap text-status-declined bg-status-declined text-base">
                        Refunded
                    </span>
                )}
            </Typography>
            {/* Meeting room */}
            {!!(
                reservationDetail.payoutDetails.totalRoomPrice &&
                reservationDetail.payoutDetails.pricePerItem &&
                reservationDetail.payoutDetails.bookingHours
            ) && (
                <div
                    className={`flex flex-col justify-between py-4 sm:flex-row ${
                        !(
                            reservationDetail.pricingStyle &&
                            reservationDetail.pricingStyle ===
                                PricingStyle.perPerson &&
                            reservationDetail.payoutDetails &&
                            reservationDetail.payoutDetails.minimumChargeApplied
                        )
                            ? "border-b-dashed"
                            : ""
                    }`}
                >
                    <Typography variant="body2" color="initial">
                        {reservationDetail.calculationStyle ===
                        CalculationStyle.halfFullDayPassed ? (
                            <>
                                Meeting Room -{" "}
                                <br/>
                                {reservationDetail.halfFullDayPassedType ===
                                HalfFullDayPassedType.fullDayPassed
                                    ? "Full Day"
                                    : " Half Day"}{" "}
                                (
                                {
                                    reservationDetail.payoutDetails.bookingHours
                                        .hours
                                }{" "}
                                hour
                                {reservationDetail.payoutDetails.bookingHours
                                    .hours === 1
                                    ? ""
                                    : "s"}
                                {reservationDetail.payoutDetails.bookingHours
                                    .minutes !== 0 && " "}
                                {reservationDetail.payoutDetails.bookingHours
                                    .minutes !== 0
                                    ? reservationDetail.payoutDetails
                                          .bookingHours.minutes +
                                      ` minute${
                                          reservationDetail.payoutDetails
                                              .bookingHours.minutes === 1
                                              ? ""
                                              : "s"
                                      }`
                                    : ""}
                                )
                            </>
                        ) : (
                            <>
                                Meeting Room -{" "}
                                <br/>
                                {reservationDetail.payoutDetails.pricePerItem}{" "}
                                DKK / hour
                            </>
                        )}
                        {/* Hours */}
                        {reservationDetail.calculationStyle &&
                            reservationDetail.calculationStyle ===
                                CalculationStyle.hourly && (
                                <>
                                    <br />x{" "}
                                    {reservationDetail.payoutDetails
                                        .bookingHours.hours !== 0
                                        ? reservationDetail.payoutDetails
                                              .bookingHours.hours +
                                          ` hour${
                                              reservationDetail.payoutDetails
                                                  .bookingHours.hours === 1
                                                  ? " "
                                                  : "s"
                                          }`
                                        : ""}
                                    {reservationDetail.payoutDetails
                                        .bookingHours.minutes !== 0 && " "}
                                    {reservationDetail.payoutDetails
                                        .bookingHours.minutes !== 0
                                        ? reservationDetail.payoutDetails
                                              .bookingHours.minutes +
                                          ` minute${
                                              reservationDetail.payoutDetails
                                                  .bookingHours.minutes === 1
                                                  ? ""
                                                  : "s"
                                          }`
                                        : ""}
                                </>
                            )}
                        {/* People*/}
                        {!!(
                            reservationDetail.pricingStyle &&
                            reservationDetail.pricingStyle ===
                                PricingStyle.perPerson
                        ) && (
                            <>
                                <br />x {reservationDetail.totalPeople}{" "}
                                {reservationDetail.totalPeople === 1
                                    ? "person"
                                    : "people"}
                            </>
                        )}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="font-semibold"
                    >
                        {reservationDetail.payoutDetails
                            .minimumChargeApplied ? (
                            <s>
                                {reservationDetail.payoutDetails.totalRoomPrice}
                            </s>
                        ) : (
                            reservationDetail.payoutDetails.totalRoomPrice
                        )}{" "}
                        DKK
                    </Typography>
                </div>
            )}
            {/* Minimum Charge */}
            {!!(
                reservationDetail.pricingStyle &&
                reservationDetail.pricingStyle === PricingStyle.perPerson &&
                reservationDetail.payoutDetails &&
                reservationDetail.payoutDetails.minimumChargeApplied
            ) && (
                <div className="flex flex-col justify-between pb-4 sm:flex-row border-b-dashed">
                    <Typography variant="body2" color="initial">
                        Minimum charge applied
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="font-semibold"
                    >
                        {reservationDetail.payoutDetails.minimumCharge
                            ? reservationDetail.payoutDetails.minimumCharge
                            : 0}{" "}
                        DKK
                    </Typography>
                </div>
            )}
            {!!(
                reservationDetail.payoutDetails.discountType &&
                reservationDetail.payoutDetails.totalDiscount &&
                reservationDetail.payoutDetails.totalDiscount !== 0 &&
                reservationDetail.payoutDetails.discountPercent &&
                reservationDetail.payoutDetails.discountPercent !== 0
            ) && (
                <div className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed">
                    <Typography variant="body2" color="initial">
                        {reservationDetail.payoutDetails.discountType ===
                        "halfDay"
                            ? "Half Day Meeting Room Discount "
                            : reservationDetail.payoutDetails.discountType ===
                              "fullDay"
                            ? "Full Day Meeting Room Discount "
                            : ""}
                        (
                        {reservationDetail.payoutDetails.discountPercent
                            ? reservationDetail.payoutDetails.discountPercent
                            : 0}
                        %)
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="font-semibold"
                    >
                        {reservationDetail.payoutDetails.totalDiscount
                            ? reservationDetail.payoutDetails.totalDiscount
                            : 0}{" "}
                        DKK
                    </Typography>
                </div>
            )}
            {!!reservationDetail.payoutDetails.addOns &&
                reservationDetail.payoutDetails.addOns.map((addOn, index) => {
                    return (
                        <div
                            className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed"
                            key={`add-on-payout${index}`}
                        >
                            <Typography variant="body2" color="initial">
                                {addOn.title} x {addOn.unit}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="initial"
                                className="font-semibold"
                            >
                                {!addOn.totalPrice ||
                                addOn.totalPrice === "0,00"
                                    ? "FREE"
                                    : addOn.totalPrice}{" "}
                                DKK
                            </Typography>
                        </div>
                    );
                })}
            {!!reservationDetail.payoutDetails.subTotal && (
                <div className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed">
                    <Typography variant="body2" color="initial">
                        Subtotal
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="font-semibold"
                    >
                        {reservationDetail.payoutDetails.subTotal} DKK
                    </Typography>
                </div>
            )}
            {!!(
                reservationDetail.payoutDetails.vatPercent &&
                reservationDetail.payoutDetails.vatPrice
            ) && (
                <div className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed">
                    <Typography variant="body2" color="initial">
                        VAT ({reservationDetail.payoutDetails.vatPercent}%)
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="font-semibold"
                    >
                        {reservationDetail.payoutDetails.vatPrice} DKK
                    </Typography>
                </div>
            )}
            {!!reservationDetail.payoutDetails.totalSale && (
                <div className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed">
                    <Typography
                        variant="body2"
                        color="initial"
                        className="text-lg font-bold"
                    >
                        Total Sale
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="text-lg font-bold"
                    >
                        {reservationDetail.payoutDetails.totalSale} DKK
                    </Typography>
                </div>
            )}
            {!!(
                reservationDetail.payoutDetails.serviceFeeOnSubTotalPercent &&
                reservationDetail.payoutDetails.serviceFeeOnSubTotalPrice
            ) && (
                <div className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed">
                    <Typography variant="body2" color="initial">
                        Service Fee on Subtotal (
                        {
                            reservationDetail.payoutDetails
                                .serviceFeeOnSubTotalPercent
                        }
                        %)
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="font-semibold"
                    >
                        {
                            reservationDetail.payoutDetails
                                .serviceFeeOnSubTotalPrice
                        }{" "}
                        DKK
                    </Typography>
                </div>
            )}
            {!!(
                reservationDetail.payoutDetails.serviceFeeVatPercent &&
                reservationDetail.payoutDetails.serviceFeeVatPrice
            ) && (
                <div className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed">
                    <Typography variant="body2" color="initial">
                        Service Fee VAT (
                        {reservationDetail.payoutDetails.serviceFeeVatPercent}
                        %)
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="font-semibold"
                    >
                        {reservationDetail.payoutDetails.serviceFeeVatPrice} DKK
                    </Typography>
                </div>
            )}
            {!!reservationDetail.payoutDetails.isRefunded && (
                <div className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed">
                    <Typography variant="body2" color="initial">
                        Refund
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="font-semibold"
                    >
                        {reservationDetail.payoutDetails.refundPrice} DKK
                    </Typography>
                </div>
            )}
            {!!(
                !reservationDetail.payoutDetails.isRefunded &&
                reservationDetail.payoutDetails.totalPayout
            ) && (
                <div className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed">
                    <Typography
                        variant="body2"
                        color="initial"
                        className="text-lg font-bold"
                    >
                        Total Payout
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="text-lg font-bold"
                    >
                        {reservationDetail.payoutDetails.totalPayout} DKK
                    </Typography>
                </div>
            )}
            {!!(
                reservationDetail.payoutDetails.isRefunded &&
                reservationDetail.payoutDetails.totalPayout === 0
            ) && (
                <div className="flex flex-col justify-between py-4 sm:flex-row border-b-dashed">
                    <Typography
                        variant="body2"
                        color="initial"
                        className="text-lg font-bold"
                    >
                        Total Payout
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="text-lg font-bold"
                    >
                        {reservationDetail.payoutDetails.totalPayout} DKK
                    </Typography>
                </div>
            )}
            <div
                className="sticky bottom-0 bg-white "
                hidden={
                    reservationDetail.status !== SUB_STATUS.WAITING_RESPONSE
                }
            >
                <Divider className="mb-2 border-primary" />
                <div className="inset-x-0 pt-5 pb-10 ">
                    <div className="flex flex-col items-center justify-end space-y-3 sm:flex-row sm:space-y-0">
                        <div className="flex flex-col w-full space-y-3 sm:space-y-0 sm:space-x-3 sm:flex-row sm:w-auto">
                            <Button
                                variant="outlined"
                                color="primary"
                                className="w-full min-w-[175px]"
                                onClick={() => setOpenDeclineWarning(true)}
                            >
                                Decline
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className="w-full min-w-[175px]"
                                onClick={() => setOpenAcceptWarning(true)}
                            >
                                Accept
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <BaseModal
                open={openGuestModal}
                hasCloseBtn
                closeFn={() => setOpenGuestModal(false)}
            >
                <ProfileDetails userId={reservationDetail.userId} />
            </BaseModal>
            <BaseModal
                open={openCancellationModal}
                hasCloseBtn
                closeFn={() => setOpenCancellationModal(false)}
            >
                <div className="py-4">
                    <CancellationDetails
                        cancellationPolicy={
                            reservationDetail.cancellationPolicy
                        }
                    />
                </div>
            </BaseModal>
            <ModalConfirmation
                severity="primary"
                title={
                    MESSAGES.RESERVATION_DECLINE_BOOKING_MODAL_CONFIRMATION_TITLE
                }
                btnCancelText={MESSAGES.NO}
                btnText={MESSAGES.YES}
                open={openDeclineWarning}
                closeFn={() => setOpenDeclineWarning(false)}
                confirmFn={() => {
                    handleDeclineBtn();
                    setOpenDeclineWarning(false);
                }}
            >
                <Typography variant="body2" color="initial">
                    {
                        MESSAGES.RESERVATION_DECLINE_BOOKING_MODAL_CONFIRMATION_BODY
                    }
                </Typography>
            </ModalConfirmation>
            <ModalConfirmation
                severity="primary"
                title={
                    MESSAGES.RESERVATION_CONFIRM_BOOKING_MODAL_CONFIRMATION_TITLE
                }
                btnCancelText={MESSAGES.NO}
                btnText={MESSAGES.YES}
                open={openAcceptWarning}
                closeFn={() => setOpenAcceptWarning(false)}
                confirmFn={() => {
                    handleApproveBtn();
                    setOpenAcceptWarning(false);
                }}
            >
                <Typography variant="body2" color="initial">
                    {
                        MESSAGES.RESERVATION_CONFIRM_BOOKING_MODAL_CONFIRMATION_BODY
                    }
                </Typography>
            </ModalConfirmation>
        </>
    );
};

export * from "./CancellationDetails";
export * from "./ProfileDetails";
export * from "./ProfileDetailsSkeleton";
export * from "./ReservationDetailSkeleton";
