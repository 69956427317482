import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FullPageLoader } from "components/UI/FullPageLoader";
import { JCSnackbar } from "components/UI/Snackbar";
import { useFullPageLoadingContext } from "context/FullPageLoadingContext";
import { useNotificationContext } from "context/NotificationContext";
import React, { useEffect } from "react";
import { AdminLayout } from ".";
import { AdminHeader } from "../AdminHeader";
import { AdminMobileDrawer } from "./AdminMobileDrawer";

interface AdminContentContainerProps {
    children?: any;
    breadcrumbItems?: any[];
}

export const AdminContentContainer: React.FC<AdminContentContainerProps> = (
    props,
) => {
    const { children } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const theme = useTheme();
    const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));

    const notificationContext = useNotificationContext();
    const fullPageLoaderContext = useFullPageLoadingContext();

    useEffect(() => {
        if (lgScreen && mobileOpen) {
            document.getElementsByTagName("body")[0].style.overflow = "initial";
        } else if (!lgScreen && mobileOpen) {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        }
    }, [mobileOpen, setMobileOpen, lgScreen]);

    const openMobileDrawer = () => {
        setMobileOpen(true);
    };

    const closeMobileDrawer = () => {
        setMobileOpen(false);
    };

    return (
        <>
            <AdminLayout>
                <AdminHeader onOpen={openMobileDrawer} {...props} />
                <AdminMobileDrawer
                    mobileOpen={mobileOpen}
                    onClose={closeMobileDrawer}
                    {...props}
                />
                {children}
                {notificationContext?.notification && (
                    <JCSnackbar
                        open={notificationContext.notification.open}
                        onClose={() => notificationContext.clear()}
                        vertical={"bottom"}
                        horizontal={"right"}
                        severity={notificationContext.notification.severity}
                        description={
                            notificationContext.notification.description
                        }
                    ></JCSnackbar>
                )}
                {fullPageLoaderContext?.fullPageLoader && (
                    <FullPageLoader
                        isLoading={
                            fullPageLoaderContext?.fullPageLoader.isLoading
                        }
                    />
                )}
            </AdminLayout>
        </>
    );
};
