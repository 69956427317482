import { Box, Tab, Tabs } from "@mui/material";
import { useAuthContext } from "context/AuthContext";
import { getStatusTextByStatus } from "helpers/status.helper";
import { useEffect, useState } from "react";
import { IoEllipse, IoList } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import {
    CONSOLIDATE_STATUS,
    STATUS,
    SUB_STATUS,
} from "shared/constants/status";
import { TableReservation } from "./TableReservation";
export interface TableReservationTabContentProps {
    consolidateStatus: CONSOLIDATE_STATUS;
    userId: string;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const TableReservationStatusTab = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const auth = useAuthContext();
    const currentTab = searchParams.get("tab");
    const [value, setValue] = useState<number>(
        currentTab ? Number(currentTab) : 0,
    );
    const getStatusByTabIndex = (index: number) => {
        let statuses: STATUS[] = [];
        switch (index) {
            case 0:
                statuses = [STATUS.AwaitingResponse];
                break;
            case 1:
                statuses = [STATUS.AwaitingPayment];
                break;
            case 2:
                statuses = [STATUS.Confirmed];
                break;
            case 3:
                statuses = [STATUS.Completed];
                break;
            case 4:
                statuses = [STATUS.CanceledByGuest];
                break;
            case 5:
                statuses = [STATUS.RequestDeclined];
                break;
            case 6:
                statuses = [STATUS.RequestExpired];
                break;
            default:
                statuses = [];
                break;
        }
        return statuses;
    };
    const [selectedStatuses, setSelectedStatuses] = useState<STATUS[]>(
        getStatusByTabIndex(currentTab ? Number(currentTab) : 0),
    );
    const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
        let statuses: STATUS[] = getStatusByTabIndex(tabIndex);
        setValue(tabIndex);
        setSelectedStatuses(statuses);
        setSearchParams({ tab: tabIndex.toString() });
    };
    useEffect(() => {
        handleChange(null, value);
    }, []);
    return (
        <>
            <div className="w-full overflow-hidden">
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs example"
                    >
                        <Tab
                            icon={
                                <IoEllipse
                                    className={`w-2 h-2 mr-1.5 text-status-pending`}
                                />
                            }
                            iconPosition="start"
                            label="Response Pending"
                            {...a11yProps(0)}
                        />
                        <Tab
                            icon={
                                <IoEllipse
                                    className={`w-2 h-2 mr-1.5 text-status-paymentPending`}
                                />
                            }
                            iconPosition="start"
                            label="Payment Pending"
                            {...a11yProps(1)}
                        />
                        <Tab
                            icon={
                                <IoEllipse
                                    className={`w-2 h-2 mr-1.5 text-status-upcoming`}
                                />
                            }
                            iconPosition="start"
                            label="Upcoming"
                            {...a11yProps(2)}
                        />
                        <Tab
                            icon={
                                <IoEllipse
                                    className={`w-2 h-2 mr-1.5 text-status-completed`}
                                />
                            }
                            iconPosition="start"
                            label={getStatusTextByStatus(
                                SUB_STATUS.COMPLETED.toString(),
                            )}
                            {...a11yProps(3)}
                        />
                        <Tab
                            icon={
                                <IoEllipse
                                    className={`w-2 h-2 mr-1.5 text-status-canceled`}
                                />
                            }
                            iconPosition="start"
                            label="Canceled"
                            {...a11yProps(4)}
                        />
                        <Tab
                            icon={
                                <IoEllipse
                                    className={`w-2 h-2 mr-1.5 text-status-canceled`}
                                />
                            }
                            iconPosition="start"
                            label="Declined"
                            {...a11yProps(5)}
                        />
                        <Tab
                            icon={
                                <IoEllipse
                                    className={`w-2 h-2 mr-1.5 text-status-canceled`}
                                />
                            }
                            iconPosition="start"
                            label="Expired"
                            {...a11yProps(6)}
                        />
                        <Tab
                            icon={<IoList className="mr-1.5 text-2xl" />}
                            iconPosition="start"
                            label="All"
                            {...a11yProps(7)}
                        />
                    </Tabs>
                </Box>
                <TableReservation
                    vendorId={auth?.authUser?.userId}
                    statuses={selectedStatuses}
                    tabIndex={value}
                />
            </div>
        </>
    );
};

export default TableReservationStatusTab;
