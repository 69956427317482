import { DocumentBase } from "./document-base.model";

export interface AccountInformationModel extends DocumentBase {
    email: string;
    firstName: string;
    lastName: string;
    company?: string;
    vatNumber?: string;
    phoneNumber: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    country: string;
    postalCode: string;
    payment: paymentModel;
}

export interface paymentModel {
    regNumber: string;
    bankAccountNumber: string;
}

export const defaultAccountInformation = {
    email: "",
    firstName: "",
    lastName: "",
    company: "",
    vatNumber: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postalCode: "",
    payment: {
        regNumber: "",
        bankAccountNumber: "",
    },
} as AccountInformationModel;
