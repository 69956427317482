import { Card, Typography } from "@mui/material";
import { JCRadioProps } from "./type";

export const RadioBoxCardView: React.FC<JCRadioProps> = (props) => {
    return (
        <>
            <Card
                style={{
                    minHeight:
                        props.cardMinHeight != undefined
                            ? `${props.cardMinHeight}px`
                            : "80px",
                }}
                className={`flex items-center justify-start w-full relative py-2 pr-2 pl-7 drop-shadow-none shadow-none border border-solid border-[#707070] ${
                    props.iconOnLeft && "flex-row-reverse justify-end"
                } ${props.cardClassName !== undefined && props.cardClassName}`}
            >
                <Typography
                    variant="body2"
                    color="initial"
                    className={`flex items-center font-bold ${
                        props.iconOnLeft && "pl-0"
                    }`}
                >
                    {props.icon && (
                        <span className="flex text-[27px] mr-3">
                            {props.icon}
                        </span>
                    )}

                    {props.label}
                </Typography>
                {props.children}
            </Card>
        </>
    );
};
