import { Box, FormHelperText } from "@mui/material";
import * as appConfigs from "appSettings";
import { SectionTitle } from "components";
import ImageList from "components/image-list";
import cuid from "cuid";
import { FormikProps } from "formik";
import update from "immutability-helper";
import { ImageModel } from "models";
import { MeetingRoom } from "models/meeting-room.model";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { FileError } from "react-dropzone";
import MESSAGES, {
    getFileSizeExceedFileErrorMessage,
    getMaxFilesExceedFileErrorMessage,
} from "shared/constants/messages.constant";
import { Dropzone } from "./Dropzone";

const backendForDND = false ? TouchBackend : HTML5Backend;
let preventDoubleDrop = false;
let justMovedImage = false;
let imagesCount = 0;
const fileErrorMessage = {
    maxFilesExceed: getMaxFilesExceedFileErrorMessage(
        appConfigs.MAX_FILE_NUMBER,
    ),
    fileSizeExceed: getFileSizeExceedFileErrorMessage(
        appConfigs.MAX_FILE_SIZE_KB,
    ),
    invalidFileType: MESSAGES.INVALID_FILE_TYPE_FILE_ERROR_MESSAGE,
};

//let rejectedFilesRef;
const FileUpload = (props: FormikProps<MeetingRoom>) => {
    const { errors, touched, values, setFieldValue } = props;
    const [rejectedFilesRef, setRejectedFilesRef] = useState([]);
    //Event handlers
    const onDrop = (acceptedFiles, rejectedFiles) => {
        function setErrorsInDeropZone(errors: any[], rejectedFiles: any) {
            errors.forEach((error) => {
                if (rejectedFiles) {
                    rejectedFiles.push({ errors: [], file: null });
                }
                rejectedFiles[0].errors.push({
                    code: "custom",
                    message: error,
                } as FileError);
            });
            setRejectedFilesRef([...rejectedFiles]);
        }
        let errors = [];
        imagesCount = values.images.length;
        acceptedFiles.map((file) => {
            imagesCount = values.images.length;
            if (imagesCount >= appConfigs.MAX_FILE_NUMBER && !justMovedImage) {
                errors.push(fileErrorMessage.maxFilesExceed);
                return undefined;
            }
            const reader = new FileReader();
            var image = new Image();
            reader.onload = function (e) {
                const binaryUrl = e.target.result;
                const images = values.images;
                imagesCount = images.length;
                const recentDroppedImage = images.find(
                    (x) => x.url === binaryUrl,
                );
                //Prevent double drop files
                if (recentDroppedImage && justMovedImage) {
                    justMovedImage = false;
                    return;
                }
                if (imagesCount >= appConfigs.MAX_FILE_NUMBER) {
                    errors.push(fileErrorMessage.maxFilesExceed);
                    setErrorsInDeropZone(errors, rejectedFiles);
                } else {
                    images.push({
                        uid: cuid(),
                        title: file.name,
                        url: binaryUrl,
                        thumbUrl: "",
                        reference: `meetingRooms/${file.name}`,
                        thumbReference: `meetingRooms/${file.name}`,
                        file: file,
                    } as ImageModel);
                    setFieldValue(
                        "images",
                        images.map((img) => {
                            return {
                                uid: img.uid,
                                title: img.title,
                                url: img.url,
                                thumbUrl: img.thumbUrl,
                                reference: img.reference,
                                thumbReference: img.thumbReference,
                                file: img.file,
                            } as ImageModel;
                        }),
                    );
                    image.src = binaryUrl as string;
                }
                justMovedImage = false;
            };
            imagesCount++;
            reader.readAsDataURL(file);
            setRejectedFilesRef([...rejectedFiles]);
            return file;
        });

        setErrorsInDeropZone(errors, rejectedFiles);
    };
    const moveImage = (dragIndex: number, hoverIndex: number) => {
        setRejectedFilesRef([]);
        justMovedImage = true;
        const draggedImage = values.images[dragIndex];
        var draggedImages = update(values.images, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, draggedImage],
            ],
        });
        setFieldValue("images", draggedImages);
        props.setFieldTouched("images", true, true);
    };
    const deleteImage = async (image: ImageModel) => {
        setRejectedFilesRef([]);
        justMovedImage = false;
        await setFieldValue(
            "images",
            [...values.images.filter((x) => x.uid !== image.uid)],
            true,
        );
        imagesCount--;
        props.setFieldTouched("images", true, true);
    };
    const customValidateMessages = (originalMessage: string) => {
        switch (originalMessage) {
            case "Too many files":
                return fileErrorMessage.maxFilesExceed;
            default:
                break;
        }
        if (
            originalMessage &&
            originalMessage.startsWith("Maximum of allowed files")
        )
            return fileErrorMessage.maxFilesExceed;
        if (originalMessage && originalMessage.startsWith("File type must be"))
            return fileErrorMessage.invalidFileType;
        if (
            originalMessage &&
            originalMessage.startsWith("File is larger than")
        )
            return fileErrorMessage.fileSizeExceed;

        return originalMessage;
    };
    const onFileDialogCancel = () => {
        setRejectedFilesRef([]);
        justMovedImage = false;
        props.setFieldTouched("images", true, false);
    };

    return (
        <>
            <div className="flex flex-col mb-2 md:flex-row md:items-center md:justify-between">
                <SectionTitle
                    title="Add Picture"
                    helpText={MESSAGES.LISTING_ADD_PICTURE_TOOLTIP}
                    className="!mb-2"
                    hasTooltip
                    tooltipPosition="right"
                    showRequiredSymbol
                />
            </div>
            <Box
                className={`text-center flex flex-col justify-center items-start border-dashed border relative ${
                    !!errors.images && touched.images && "border-error"
                }`}
                sx={{ borderColor: "#707070", minHeight: 190 }}
            >
                <Dropzone
                    onDrop={onDrop}
                    accept={appConfigs.SUPPORTED_IMAGE_TYPES}
                    maxSize={appConfigs.MAX_FILE_SIZE_KB * 1024}
                    maxFiles={appConfigs.MAX_FILE_NUMBER}
                    customValidateMessages={customValidateMessages}
                    onFileDialogCancel={onFileDialogCancel}
                    rejectedFilesRef={rejectedFilesRef}
                >
                    <DndProvider backend={backendForDND}>
                        <ImageList
                            images={values.images}
                            moveImage={moveImage}
                            deleteImage={deleteImage}
                        />
                    </DndProvider>
                </Dropzone>
            </Box>
            {touched.images && errors.images && (
                <FormHelperText
                    id="capacity-help-text"
                    className={`mx-0 my-1 text-error`}
                    style={{ fontSize: "13px" }}
                >
                    {errors.images}
                </FormHelperText>
            )}
        </>
    );
};
export default FileUpload;
export * from "./Dropzone";
export * from "./ImageList";
