import { Grid, Typography } from "@mui/material";
import TableReservationStatusTab from "components/Dashboard/TableReservationStatusTabs";
import { AdminPageTitle, AdminSingleBlockLayout } from "components/Layouts";
import MonthlyOverviewData from "mocks/dashboard.mock";
import { MonthlyOverview } from "./MonthlyOverview";
import { NotificationOverview } from "./NotificationOverview";
export const Dashboard = (params) => {
    return (
        <>
            <AdminSingleBlockLayout>
                <AdminPageTitle pageTitle="Dashboard" />
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 5, xl: "60px" }}
                    sx={{ maxWidth: 1560 }}
                    className="mt-4 mb-6"
                >
                    <Grid item xs={12} xl={5}>
                        {MonthlyOverviewData.map((data, index) => {
                            return <MonthlyOverview data={data} key={index} />;
                        })}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        xl={7}
                        sx={{ maxWidth: { xl: "780px !important" } }}
                    >
                        <NotificationOverview />
                    </Grid>
                </Grid>
                <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 3 }}
                    className="mt-4 mb-6"
                >
                    <Grid item xs={12} xl={12}>
                        <Typography variant="h3">Reservations</Typography>
                        {/* {Bookings && (
                                <TableReservation bookings={Bookings} />
                            )} */}
                        <TableReservationStatusTab />
                    </Grid>
                </Grid>
                {/* <DashboardSkeleton /> */}
            </AdminSingleBlockLayout>
        </>
    );
};

export * from "./MonthlyOverview";
export * from "./NotificationOverview";
export * from "./TableReservation";
