import {
    AdminContentContainer,
    AdminSingleBlockLayout,
} from "components/Layouts";
import MeetingRooms from "components/MeetingRooms";
import React from "react";
import { Helmet } from "react-helmet-async";

const ListingsPage = () => {
    return (
        <>
            <Helmet>
                <title>Joes & Cos | Listings</title>
            </Helmet>
            <AdminContentContainer>
                <AdminSingleBlockLayout>
                    <MeetingRooms></MeetingRooms>
                </AdminSingleBlockLayout>
            </AdminContentContainer>
        </>
    );
};

export default ListingsPage;
