import { Box, FormGroup } from "@mui/material";
import { EmptyList } from "components/UI/EmptyList";
import { FormikProps } from "formik";
import { Amenity, CustomAmenity, MeetingRoom } from "models";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { amenityService } from "services/amenity.service";
import MESSAGES from "shared/constants/messages.constant";
import SimpleBarReact from "simplebar-react";
import { AmenityItem } from "./AmenityItem";
export interface AmenityProps {
    formik: FormikProps<MeetingRoom>;
    deleteAction: (id: string) => void;
    updateAction: (amenity: Amenity) => void;
    refetch: boolean;
}

export const CustomAmenitiesList = (props: AmenityProps) => {
    const { values, setFieldValue, setFieldTouched, touched, errors } =
        props.formik;

    const {
        data: initCustomAmenitiesList,
        isFetching,
        refetch,
    } = useQuery(
        "initCustomAmenitiesList",
        async () => await amenityService.getVendorAmenities(),
        {
            refetchOnWindowFocus: false,
        },
    );

    const [showSuggestionText, setShowSuggestionText] = useState(false);

    useEffect(() => {
        setShowSuggestionText(
            !(initCustomAmenitiesList && initCustomAmenitiesList.length > 0),
        );
    }, [initCustomAmenitiesList]);

    useEffect(() => {
        if (props.refetch) {
            refetch();
        }
    }, [props.refetch]);

    const handleCheckedChange = (e) => {
        if (e.event.target.checked === true) {
            const item = initCustomAmenitiesList.filter(
                (x) => x.id === e.item.id,
            )[0];
            let customAmenityItem: CustomAmenity = {
                uid: item.id,
                title: item.title,
                description: item.description,
                icon: item.icon,
            };
            setFieldValue("customMeetingRoomAmenities", [
                ...values.customMeetingRoomAmenities,
                customAmenityItem,
            ]);
        } else {
            setFieldValue(
                "customMeetingRoomAmenities",
                values.customMeetingRoomAmenities.filter(
                    (x) => x.uid !== e.item.id,
                ),
            );
        }
        setFieldTouched("customMeetingRoomAmenities", true, false);
    };
    const itemIsSelected = (id) => {
        return (
            values.customMeetingRoomAmenities &&
            values.customMeetingRoomAmenities.length > 0 &&
            values.customMeetingRoomAmenities.map((x) => x.uid).indexOf(id) !==
                -1
        );
    };

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <FormGroup
                    sx={{
                        width: "auto",
                    }}
                >
                    <SimpleBarReact style={{ maxHeight: 180 }}>
                        {initCustomAmenitiesList &&
                            initCustomAmenitiesList.map((data, index) => (
                                <AmenityItem
                                    formik={props.formik}
                                    data={data}
                                    handleCheckedChange={handleCheckedChange}
                                    deleteAction={props.deleteAction}
                                    updateAction={props.updateAction}
                                    itemIsSelected={itemIsSelected(data.id)}
                                    showButtons={true}
                                    key={index + "customAmenity"}
                                ></AmenityItem>
                            ))}
                    </SimpleBarReact>
                </FormGroup>
            </Box>

            {showSuggestionText === true ? (
                <EmptyList
                    label={MESSAGES.LISTING_CUSTOM_AMENITIES_EMPTY_LIST}
                />
            ) : (
                <></>
            )}
        </>
    );
};
