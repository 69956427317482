import { Avatar, Typography } from "@mui/material";
import {
    IoBriefcaseOutline,
    IoBusinessOutline,
    IoLocationOutline,
} from "react-icons/io5";
import { useQuery } from "react-query";
import { userService } from "services/user.service";
import ProfileDetailsSkeleton from "./ProfileDetailsSkeleton";

export const ProfileDetails = (props) => {
    // API get initial data
    const { data: guestData, isFetching } = useQuery(
        "getUserProfileById",
        () => userService.getUserProfileById(props.userId),
        {
            refetchOnWindowFocus: false,
        },
    );
    if (isFetching) {
        return (
            <div className="text-center">
                <ProfileDetailsSkeleton />
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col sm:flex-row">
                <Avatar
                    alt="Remy Sharp"
                    src={guestData.image === "" ? "" : guestData.image}
                    sx={{ width: 130, height: 130 }}
                    className="mr-9"
                />
                <div className="flex flex-col mt-3 sm:mt-0">
                    <Typography
                        variant="h2"
                        color="initial"
                        className="mb-3 text-3xl font-semibold"
                    >
                        {guestData.firstName === "" ? "-" : guestData.firstName}{" "}
                        {guestData.lastName === "" ? "-" : guestData.lastName}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="flex items-center mb-2"
                    >
                        <IoBriefcaseOutline className="mr-4 text-xl" />
                        {guestData.company === "" ? "-" : guestData.company}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="flex items-center mb-2"
                    >
                        <IoBusinessOutline className="mr-4 text-xl" />
                        {guestData.industry === "" ? "-" : guestData.industry}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="initial"
                        className="flex items-center mb-2"
                    >
                        <IoLocationOutline className="mr-4 text-xl" />
                        {guestData.city === "" ? "-" : guestData.city}
                    </Typography>
                </div>
            </div>
            <div className="mt-5">
                <Typography variant="h3" color="initial" className="mb-3">
                    About
                </Typography>
                <Typography variant="body2" color="initial">
                    {guestData.bio === "" ? "-" : guestData.bio}
                </Typography>
            </div>
        </>
    );
};
